import { Injectable } from "@angular/core";
import { Store } from "@viewer/core";
import { IReactionDisposer, reaction } from "mobx";
import { TocMode } from "@viewer/layout/toc-mode.model";

// all properties must be set in percent of screen
export interface Bound {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const TOC_ANIM_DELAY = 350;

@Injectable({
  providedIn: "root"
})
export class PdfIosService {
  // With the pdf viewer, click event are managed by the pdf viewer
  // And we can set some elements managed by cordova
  // In debug mode you can see cordova clickable elements with a red background
  // and pdf viewers areas without background
  public fullscreenClickable = false;
  public pdfClosed = false;
  public searchColor = "#e1e000";
  public eventMap = new Map<string, EventListener>();
  public tocModeReaction: IReactionDisposer;

  // clickableElements, interactiveElements and cancelElements
  // are all the elements whose clicks are redirected to the cordova view
  private clickableElements = [
    // Publication TOC
    ".toc-sidenav",
    // publications header
    "o-header",
    // non applicable header
    ".header__not-applicable",
    // user manual header
    ".home-header",
    // notes buttons
    "o-notes-button",
    // preprint header
    ".header-preprint"
  ];

  // after click the whole window is managed by cordova
  private interactiveElements = [
    // left menu button
    ".toc-button",
    // right menu button
    ".setting-button",
    // historic navigation button
    "o-historic button",
    // back button
    "o-history-switch button",
    // add note icon
    ".icon-note",
    // open info icon
    ".icon-info",
    // all items in right menu
    ".matlist--top",
    // all item in toc
    "o-toc",
    // notes button
    "o-notes-button",
    // close button of preprint header
    ".header-preprint .chevron",
    // button in toc to open applic modal
    ".applic-button",
    // Bell button in header to open news modal
    ".news-button"
  ];

  // the click cancel interactiveElement click
  // and we return on initial distribution of click events
  private cancelElements = [
    // close button of right menu
    ".cross-logo",
    // print icon (because that close the sidenav)
    ".icon-print",
    // print item of setting pub-list (because that close the sidenav)
    ".print-item",
    // layout backdrop click (when toc or settings panel are open)
    ".layoutContainer .mat-drawer-backdrop",
    // note backdrop click (when notes panel is open)
    ".noteContainer .mat-drawer-backdrop",
    // layout pub list backdrop click (when settings panel is open)
    ".layout-home-container .mat-drawer-backdrop",
    // close button of notes panel
    ".notes--title .close-button",
    // close button of "Terms of Use" or "Privacy Policy" pop up
    "o-disclaimer .header mat-icon",
    // close button of "Settings" pop up
    "o-settings-form .icon-close",
    // close button of "Release Info" pop up
    "o-release-info button",
    // close button of "Infos" pop up
    "o-info-dialog button",
    // close button of "historic" pop up
    "o-historic-dialog .icon-close",
    // cancel button of "confirm dialog" pop up
    "o-confirm-dialog .cancel-buttons",
    // all pop-up backdrop
    ".cdk-overlay-backdrop",
    // close button of "Applicabilities" pop up
    "o-applic-modal .icon-close",
    // apply button of "Applicabilities" pop up
    "o-applic-modal .button-apply",
    // Button to close the News modal
    "o-news-modal .icon-close"
  ];

  constructor(public store: Store) {}

  public setup(): void {
    this.tocModeReaction = reaction(
      () => this.store.tocMode,
      () => {
        setTimeout(() => {
          const tocOpened = document.querySelector(".toc-button").classList.contains("opened");
          if (tocOpened && this.store.tocMode === TocMode.OVER) {
            this.fullscreenClickable = true;
            this.addListeners(this.cancelElements, this.cancelElementsClick);
          } else {
            this.fullscreenClickable = false;
          }
          this.setClickableAreas();
        }, TOC_ANIM_DELAY);
      }
    );
    setTimeout(() => {
      this.eventMap.set("orientation", this.setPdfPosition.bind(this));
      window.addEventListener("orientationchange", this.eventMap.get("orientation"));
      this.addListeners(this.interactiveElements, this.interactiveElementsClick);
      this.setSafeAreaGuide();
      this.setTransparentBackground();
      this.setPdfViewerPosition();
    }, 0);

    setTimeout(() => {
      this.setClickableAreas();
    }, TOC_ANIM_DELAY);
  }

  public displayPDF(filepath: string, searchQuery: string): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.loadPDF(
      filepath,
      () => {
        console.log("[Native PDF Viewer] PDF loaded successfully");
      },
      err => {
        console.error(`[Native PDF Viewer] Error while loading PDF: ${err}`);
      }
    );
    this.pdfClosed = false;
    this.fullscreenClickable = false;
    this.setClickableAreas();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.search(
      searchQuery,
      this.searchColor,
      () => {
        console.log("[Native PDF Viewer] Success to search" + searchQuery);
      },
      err => {
        console.error(`[Native PDF Viewer] Error on search: ${err}`);
      }
    );
  }

  public dispose(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.loadPDF(null, null, null);
    // remove click listeners
    window.removeEventListener("orientationchange", this.eventMap.get("orientation"));
    this.removeListeners([...this.cancelElements, ...this.interactiveElements]);
    this.eventMap.clear();
    this.setTransparentBackground(true);
    this.fullscreenClickable = true;
    this.pdfClosed = true;
    this.tocModeReaction();
    this.setClickableAreas();
  }

  private setClickableAreas(): void {
    const clickableElementsBounds: Bound[] = [];
    if (this.fullscreenClickable) {
      const fullscreenBound: Bound = {
        x: 0.0,
        y: 0.0,
        width: 1.0,
        height: 1.0
      };
      clickableElementsBounds.push(fullscreenBound);
    } else {
      const windowsBound = this.getWindowBound();
      this.clickableElements.forEach((element: string) => {
        const clientBound = document.querySelector(element)?.getBoundingClientRect();
        if (clientBound) {
          const percentBound: Bound = {
            x: clientBound.x / windowsBound.width,
            y: clientBound.y / windowsBound.height,
            width: clientBound.width / windowsBound.width,
            height: clientBound.height / windowsBound.height
          };
          clickableElementsBounds.push(percentBound);
        }
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.setCordovaClickableBounds(
      clickableElementsBounds,
      this.store.debugMode,
      () => {
        console.log("[Native PDF Viewer] Success to set clickable bounds");
      },
      err => {
        console.error(`[Native PDF Viewer] Error to set clickable bounds ${err}`);
      }
    );
  }

  /**
   * Set fullscreen management by cordova and add cancel elements clicks events
   */
  private interactiveElementsClick(query: string): void {
    // Toc is not considered as an interactive element when on side mode
    if (this.pdfClosed || (query === "o-toc" && this.store.tocMode === TocMode.SIDE)) {
      return;
    }

    // We need to re-set the pdf position when toggling the TOC in side mode
    // because it changes the available space for the pdf
    if (query === ".toc-button" && this.store.tocMode === TocMode.SIDE) {
      setTimeout(() => {
        this.setPdfPosition();
      }, TOC_ANIM_DELAY);
      return;
    }

    // Toc and Settings buttons acts like Cancel Elements when sidenav is opened
    // On click, we need to remove listeners on Cancel Elements, and re-set clickable area
    if (query === ".toc-button" || query === ".setting-button") {
      const sidenavOpened = document.querySelector(query).classList.contains("opened");
      if (!sidenavOpened) {
        this.cancelElementsClick();
        return;
      }
    }

    if (query === ".header-preprint .chevron") {
      this.pdfClosed = true;
    }

    this.fullscreenClickable = true;
    setTimeout(() => {
      this.setClickableAreas();
      this.addListeners(this.cancelElements, this.cancelElementsClick);
    }, TOC_ANIM_DELAY);
  }

  /**
   * Set initial management add remove cancel elements clicks events
   *
   * @param query A css selector
   */
  private cancelElementsClick(): void {
    if (this.pdfClosed) {
      return;
    }
    this.removeListeners(this.cancelElements);
    this.fullscreenClickable = false;
    this.pdfClosed = false;
    setTimeout(() => {
      this.setPdfViewerPosition();
      this.setClickableAreas();
    }, TOC_ANIM_DELAY);
  }

  /**
   * Set pdf viewer origin position
   */
  private setPdfViewerPosition() {
    let topMargin = 0.0;
    let leftMargin = 0.0;
    const clientBound = document.querySelector("o-pdf")?.getBoundingClientRect();
    if (clientBound) {
      topMargin = clientBound.y;
      leftMargin = clientBound.x;
    }
    const windowsBound = this.getWindowBound();
    const marginTop = topMargin / windowsBound.height;
    const marginLeft = leftMargin / windowsBound.width;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.setPDFViewerOffset(
      marginTop,
      marginLeft,
      () => {
        console.log("[Native PDF Viewer] Success to position pdf");
      },
      err => {
        console.error(`[Native PDF Viewer] Error to position pdf ${err}`);
      }
    );
  }

  /**
   * Safe area guide includes status bar and notch
   */
  private setSafeAreaGuide(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).plugins.nativePdfViewer.useSafeAreaGuide(
      true,
      false,
      () => {
        console.log("[Native PDF Viewer] success to set area guide");
      },
      err => {
        console.error(`[Native PDF Viewer] error to set area guide ${err}`);
      }
    );
  }

  /**
   * Add or remove transparent background on all parent element of o-pdf.
   * The native pdf player is behind the orion app so to see the pdf we
   * need to set transparent background on all parent elements of o-pdf.
   * There is concurrency with class non-applicable-content and
   * preprint-media which also set a background important. So we need to also
   * remove these class.
   */
  private setTransparentBackground(reset = false): void {
    let target = document.querySelector("o-pdf");
    const noteContainer = document.querySelector(".noteContainer");
    const main = document.querySelector("main");
    if (!reset) {
      noteContainer?.classList.remove("non-applicable-content");
      main?.classList.remove("preprint-media");
    }

    do {
      if (reset) {
        target?.classList.remove("tranparent_background");
      } else {
        target?.classList.add("tranparent_background");
      }
      target = target?.parentElement;
    } while (target && target.nodeName !== "HTML");
  }

  private getWindowBound(): Bound {
    return {
      x: 0.0,
      y: 0.0,
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  private setPdfPosition(): void {
    this.setPdfViewerPosition();
    this.setClickableAreas();
  }

  /**
   * Add a given callback as event listeners on a list of selectors.
   */
  private addListeners(selectors: string[], callback: Function): void {
    for (const selector of selectors) {
      const element = document.querySelector(selector);
      if (element && !this.eventMap.has(selector)) {
        this.eventMap.set(selector, callback.bind(this, selector));
        element.addEventListener("click", this.eventMap.get(selector));
      }
    }
  }

  /**
   * Remove event listeners from all elements associated to the given list of selectors.
   *
   * @param selectors An array of css selectors.
   */
  private removeListeners(selectors: string[]): void {
    for (const selector of selectors) {
      const element = document.querySelector(selector);
      if (element && this.eventMap.has(selector)) {
        element.removeEventListener("click", this.eventMap.get(selector));
        this.eventMap.delete(selector);
      }
    }
  }
}
