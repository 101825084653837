export enum Constant {
  cacheKey = "useCache",
  historyKey = "history",
  uriCouchKey = "uricouchdb",
  criteriasKey = "criterias",
  pubRev = "001",
  // Those property are remainder from the past, but need to be kept for retro-compatibility
  tmpUserName = "John_Doe",
  tmpCorpName = "corpName",
  tmpPublicName = "publicName"
}
