import { Inject, Injectable } from "@angular/core";
import { News } from "@orion2/models/tocitem.models";
import { combineLatest, map, Observable, ReplaySubject, tap } from "rxjs";
import { Store } from "@viewer/core";
import { reaction } from "mobx";
import { NewsService, NewsServices } from "@viewer/news/services/news.service";

@Injectable({
  providedIn: "root"
})
export class NewsUtilsService {
  public applicationNews = new ReplaySubject<News[]>(1);
  public publicationNews = new ReplaySubject<News[]>(1);

  constructor(
    @Inject(NewsServices.APP) private newsAppService: NewsService,
    @Inject(NewsServices.APP_USER) private newsAppUserService: NewsService,
    @Inject(NewsServices.PUB) private newsPubService: NewsService,
    @Inject(NewsServices.PUB_USER) private newsPubUserService: NewsService,
    private store: Store
  ) {
    this.getApplicationNews().subscribe();

    reaction(
      () => this.store.isLoggedIn,
      () => {
        this.newsAppService.refresh();
        this.newsAppUserService.refresh();
      }
    );

    reaction(
      () => this.store.publicationID,
      (pubId: string) => {
        if (pubId) {
          this.newsPubService.refresh();
          this.newsPubUserService.refresh();
          this.getPublicationNews().subscribe();
        } else {
          this.publicationNews = new ReplaySubject<News[]>(1);
        }
      },
      { fireImmediately: true }
    );
  }

  public markAsRead(news: News): Promise<void> {
    const newsToSave = { _id: news._id, type: news.type, lastRevRead: news._rev };
    const service = news.isGlobal ? this.newsAppUserService : this.newsPubUserService;
    return service.save(newsToSave).then(() => service.refresh());
  }

  public getContent(news: News): Promise<string> {
    const service = news.isGlobal ? this.newsAppService : this.newsPubService;
    return service.getAttachementFile(news._id).then((blob: Blob) => blob.text());
  }

  public countUnreadNews(newsList: News[]): number {
    return newsList.filter((news: News) => !news.read).length;
  }

  private getPublicationNews(): Observable<News[]> {
    return combineLatest([
      this.newsPubService.tocItemsOfType,
      this.newsPubUserService.tocItemsOfType
    ]).pipe(
      map(([publicNews, userNews]: News[][]) => this.checkReadNews(publicNews, userNews)),
      map((newsList: News[]) => this.sortByDate(newsList)),
      tap((newsList: News[]) => {
        this.publicationNews.next(newsList);
      })
    );
  }

  private getApplicationNews(): Observable<News[]> {
    return combineLatest([
      this.newsAppService.tocItemsOfType,
      this.newsAppUserService.tocItemsOfType
    ]).pipe(
      map(([publicNews, userNews]: News[][]) => this.checkReadNews(publicNews, userNews)),
      map((newsList: News[]) => this.sortByDate(newsList)),
      tap((newsList: News[]) => {
        this.applicationNews.next(newsList);
      })
    );
  }

  /**
   * Compare the news found in "toc_public" and in "user" db in order to determine
   * which news are already read.
   * SPEC: We only store news that are read in the user DB
   * So if a news is also found in the user's news, it is already read.
   */
  private checkReadNews(publicNewsList: News[], userNewsList: News[]): News[] {
    return publicNewsList.map((publicNews: News) => {
      publicNews.read = userNewsList.some(
        (userNews: News) =>
          publicNews._id === userNews._id && publicNews._rev === userNews.lastRevRead
      );
      return publicNews;
    });
  }

  private sortByDate(newsList: News[]): News[] {
    return newsList.sort(
      (newsA: News, newsB: News) => new Date(newsB.date).getTime() - new Date(newsA.date).getTime()
    );
  }
}
