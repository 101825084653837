import { Component, Inject } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { PubReplicateService } from "@viewer/replication/service/pub-replicate.service";
import { PubDoc } from "libs/models/couch.models";
import { Store } from "@viewer/core";
import { PubDestroyService } from "@viewer/replication/service/pub-destroy.service";
import { HomeRoute } from "@viewer/home/models";

@Component({
  selector: "o-pub-destroy-modal",
  templateUrl: "pub-destroy-modal.component.html",
  styleUrls: ["./pub-destroy-modal.component.scss"]
})
export class PubDestroyModalComponent {
  public isDeleting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pub: PubDoc },
    private store: Store,
    private dialogRef: MatDialogRef<PubDestroyModalComponent>,
    private router: Router,
    private pubDestroyService: PubDestroyService,
    private pubReplicateService: PubReplicateService
  ) {}

  public confirm(): Promise<boolean> {
    this.isDeleting = true;
    const pubToDelete = this.data.pub;

    // This is to deal with cases where we delete a pub partially replicated.
    this.pubReplicateService.resetProgress(pubToDelete);
    this.store.pubInfo = pubToDelete;

    return this.pubDestroyService.destroyPub(pubToDelete.occurrenceCode).then(() => {
      this.dialogRef.close();
      // We make a dummy navigate to /online route before navigating again to /local to force page refresh
      return this.router
        .navigateByUrl(HomeRoute.ONLINE, { skipLocationChange: true })
        .then(() => this.router.navigate([HomeRoute.LOCAL]));
    });
  }
}
