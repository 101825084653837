import { PymNode } from "@orion2/models/pym.models";
import { Pym } from "@orion2/models/tocitem.models";

/**
 * Add the children attribute to a Pym object
 * in order to preview the Pym tree
 * The root node is saved as a copy in the class propertie "root"
 *
 * @export
 * @class PymTree
 */
export class PymTree {
  public root: Pym;

  constructor(docPym: Pym) {
    const treeMap = new Map<number, PymNode[]>();
    docPym.dmList.forEach(element => {
      const levelDept = +element.levelDept;
      if (!treeMap.has(levelDept)) {
        treeMap.set(levelDept, [element]);
      } else {
        const array = treeMap.get(levelDept);
        array.push(element);
        treeMap.set(levelDept, array);
      }
    });

    this.root = this.createChildren(docPym, treeMap) as Pym;
  }

  private createChildren(
    node: Pym | PymNode,
    treeMap: Map<number, PymNode[]>,
    level = 1
  ): Pym | PymNode {
    if (level === 1) {
      node.children = treeMap.get(level);
    } else {
      node.children = [];
      const availableChildren = treeMap.get(level);

      if (availableChildren && availableChildren.length > 0) {
        availableChildren.forEach(child => {
          if (child.parentCode === (node as PymNode).treeNodeId) {
            node.children.push(child);
          }
        });
      }
    }

    // Recurse
    node.children.forEach(child => this.createChildren(child, treeMap, level + 1));

    return node;
  }
}
