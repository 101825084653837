import { Injectable } from "@angular/core";
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from "@angular/material/legacy-paginator";
import { TranslateService } from "@ngx-translate/core";

/**
 * Utility service necessary to translate the mat-paginator
 * References:
 * https://material.angular.io/components/paginator/overview
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
 * https://dsebastien.medium.com/translating-the-matpaginator-angular-material-component-f72b52158dfc
 */

@Injectable()
export class MatPaginationIntlCustom extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();

    // React whenever the language is changed
    this.translate.onLangChange.subscribe(() => {
      this.itemsPerPageLabel = this.translate.instant("paginator.perpage");
      // Fire a change event to make sure that the labels are refreshed
      this.changes.next();
    });

    // Initialize the translations once at construction time
    this.itemsPerPageLabel = this.translate.instant("paginator.perpage");
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translate.instant("paginator.of");
    if (length === 0 || pageSize === 0) {
      return `0 ${of} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex =
      page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
  };
}
