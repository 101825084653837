import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";

/**
 * @export
 * @class PdfCaller
 * @extends {CoreCaller}
 */

export class PdfCaller extends CoreCaller {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(processor: any, protected injector: Injector, dbSchema: DbSchema) {
    super(dbSchema, processor, injector);
  }
  /*
   * Get attachment blob for PDF with pdfId
   */
  public getAttachmentsBlob(pdfId: string): Promise<Blob> {
    return this.callFunction("getAttachmentsBlob", [pdfId]);
  }
}
