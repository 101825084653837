import { Injectable } from "@angular/core";
import { PouchService, Store } from "@viewer/core";
import {
  getDocumentFromString,
  handleXSLTTransform,
  defineXSLTParameters,
  XSLParam,
  getXSD
} from "@viewer/shared-module/xslt.utils";
import { legendXSL } from "@orion2/xslt/xsl_legend";
import { infoDuXSL } from "@orion2/xslt/xsl_infoDu";
import xpath, { SelectedValue } from "xpath";
import { LangService } from "@viewer/core/lang/lang.service";
import { TranslatePropertiesService } from "@viewer/core/translate-utils/translatePropertiesService";
import { TocInfo, XmlDoc } from "@orion2/models/couch.models";
import { FullHttpService } from "libs/http/fullHttp.service";
import { MimeType } from "@orion2/models/enums";

export interface XSLCacheObject {
  parameters: XSLParam;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documentFragment: any;
}

@Injectable()
export class XSLService {
  readonly loapXSL = "LOAPMergedStylesheet";
  readonly loapKey = "loap";
  readonly ipcXSD = "ipd.xsd";
  ipc: boolean;
  readonly cacheMap: Map<string, XSLCacheObject>;

  public loapMapping: Map<string, string>;
  constructor(
    private store: Store,
    private pouchService: PouchService,
    private langService: LangService,
    private propertiesService: TranslatePropertiesService,
    private httpService: FullHttpService
  ) {
    this.cacheMap = new Map<string, XSLCacheObject>();
  }

  public get legend() {
    return handleXSLTTransform(this.store.duObject.xml, legendXSL, null);
  }

  public get duInfo(): Promise<Document> {
    return this.translateXSL(infoDuXSL).then(xsl =>
      handleXSLTTransform(this.store.duObject.xml, xsl, null)
    );
  }

  public get mediaToGraphicMap(): {} {
    const sheets = xpath.select(
      // query LEGACY or S1000D
      "/dmodule//GRAPHIC/LEGEND/../SHEET | /dmodule//figure/legend/../graphic",
      this.store.duObject.xml
    );
    return sheets.reduce((_mediaToGraphicMap, sheet) => {
      const icnAttribute = this.store.isS1000D ? "infoEntityIdent" : "GNBR";
      const idAttribute = this.store.isS1000D ? "id" : "KEY";
      _mediaToGraphicMap[(sheet as HTMLElement).getAttribute(icnAttribute)] = (
        sheet as HTMLElement
      ).parentElement.getAttribute(idAttribute);
      return _mediaToGraphicMap;
    }, {});
  }

  /**
   * Get XSL document fragment from dmc
   *
   * @param dmc
   */
  public async getXMLNode(dmc: string): Promise<Document> {
    const xmlDocument: XmlDoc = (await this.pouchService.xmlCaller.get(dmc)) as XmlDoc;
    if (!xmlDocument) {
      // eslint-disable-next-line no-throw-literal
      throw { status: 404, message: `XML not found for ${dmc}` };
    }
    return getDocumentFromString(xmlDocument.data);
  }

  /**
   * Get loap document and use it for get the mapping of pmNumber to manual
   *
   */
  public getLoapMapping(): Promise<Map<string, string>> {
    if (this.loapMapping) {
      return Promise.resolve(this.loapMapping);
    }
    return this.pouchService.xmlCaller.get(this.loapKey).then((loap: XmlDoc) => {
      this.loapMapping = new Map<string, string>();
      const loapDoc = getDocumentFromString(loap.data);
      const pmrefs = xpath.select("//pmRef", loapDoc);
      Array.from(pmrefs).forEach((pmref: Node) => {
        const pmNumber = xpath.select("string(pmRefIdent/pmCode/@pmNumber)", pmref).toString();
        const manual = xpath
          .select("string(pmRefAddressItems/pubMedia/@pubMediaCode)", pmref)
          .toString()
          // Remove text between parentheses
          .replace(/\([^)]*\)/g, "")
          .trim();
        this.loapMapping.set(pmNumber, manual);
      });
      return this.loapMapping;
    });
  }

  /**
   * Get XSL from dmc
   *
   * @param dmc
   */
  public async getXSLCacheObject(xsltId: string): Promise<XSLCacheObject> {
    if (this.cacheMap.has(xsltId)) {
      return this.cacheMap.get(xsltId);
    }
    return this._getXSLT(xsltId);
  }

  /**
   * Get the xslt name to use to transform the given dmc
   *
   * @param dmc
   */
  public async getXSLTName(dmc: string): Promise<string> {
    const isLoap = dmc.toLowerCase().includes(this.loapKey);
    const xsltId = await (isLoap ? Promise.resolve(this.loapXSL) : this._xsltNameFor(dmc));
    return `${this.store.publicationID}__${xsltId}__${this.store.pubInfo.lang}`;
  }

  /**
   * Use the xsd to definine if the given xsd is
   *
   * @param xsd
   */
  public isIpc(xmlNodes: Document): boolean {
    const xsd = getXSD(xmlNodes);

    const xmlDoc = xmlNodes;
    if (xmlDoc.querySelector("content > IPC")) {
      return true;
    }
    // check S1000D
    if (xmlDoc.querySelector("content > illustratedPartsCatalog")) {
      return true;
    }
    return xsd.endsWith(this.ipcXSD);
  }

  // we check if a task is present.
  public isMSM(xmlDoc: Document): boolean {
    if (
      xmlDoc.querySelector("taskDefinition") ||
      xmlDoc.querySelector("timeLimitInfo") ||
      xmlDoc.querySelector("MAINTASK")
    ) {
      return true;
    }
    return false;
  }
  public isMMA(xmlNodes: Document): boolean {
    const manualLabel = xmlNodes.evaluate(
      "dmodule/WP6Status/manualLabel",
      xmlNodes,
      undefined,
      XPathResult.STRING_TYPE,
      undefined
    );

    return manualLabel.stringValue === "MMA" ? true : false;
  }

  /**
   * Use XML to determine if it's a PDF DMC
   *
   * @param xmlNode
   * @returns
   * @memberof XSLService
   */
  public isPdfManual(xmlNode: Document): boolean {
    return xmlNode?.querySelector("pubMedia")?.getAttribute("pubMediaType") === "PDF";
  }

  /**
   * Verify that content of DMC has only pictures
   *
   * @param xmlNode
   * @returns
   * @memberof XSLService
   */
  public hasOnlyGraphics(xmlNode: Document): boolean {
    const res = xmlNode.evaluate(
      // eslint-disable-next-line max-len
      "/dmodule/content[(count(./*) = count(./GRAPHIC|./description/foldout/figure)) and not(./GRAPHIC/WMLIST)] and (count(/dmodule/ENTITE_GRAPHIQUE) >= 1)",
      xmlNode,
      undefined,
      XPathResult.BOOLEAN_TYPE,
      undefined
    );
    return res.booleanValue;
  }

  /**
   * Special case : Verify if the DMC is S1000D and has exactly one illustration and its a PDF
   *
   * @param xmlNode
   * @returns
   * @memberof XSLService
   */
  public hasOnePdfAndS1000D(xmlNode: Document): boolean {
    if (!xmlNode) {
      return false;
    }

    const schema = xmlNode.querySelector("dmodule")?.getAttribute("xsi:noNamespaceSchemaLocation");
    const graphics = xmlNode.querySelectorAll("ENTITE_GRAPHIQUE");

    return (
      schema?.match(/descript.xsd|proced.xsd/) && // Check the schema type is either descript.xsd or proced.xsd
      graphics?.length === 1 &&
      graphics[0]?.querySelector("FORMAT")?.innerHTML === "pdf"
    );
  }

  /**
   * Return the xsl transformation result
   * XmlNode is already computed so we do not get it from the db again
   *
   * @param dmc
   * @param xmlNodes
   */
  public async doXSLTTransform(
    dmc: string,
    xmlNodes: Document,
    isVendors: boolean
  ): Promise<Element> {
    const xsltId = await this.getXSLTName(dmc);
    const xslObject = await this.getXSLCacheObject(xsltId);
    if (isVendors) {
      xslObject.parameters = new XSLParam("vendors", "true");
    }
    const frag = handleXSLTTransform(xmlNodes, xslObject.documentFragment, xslObject.parameters);

    if (frag.childElementCount === 0) {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: 500,
        message: `Empty content for XSL transformation for ${dmc} with ${xsltId}`
      };
    }
    return frag.querySelector("div");
  }

  /**
   * Evaluates the xpath expression by translating the labels encapsulated by brackets beforehand
   * Ex: a valid xpath wold be "concat([MOD_LABEL])"
   *
   * @param xpathSelection the xpath to evaluate
   * @param xml the node
   * @param lang the langage to translate to
   * @returns the result of the xpath expression
   */
  public evaluateXPathWithTranslation(
    xpathSelection: string,
    xml: Node,
    lang = this.store.pubInfo.lang
  ): Promise<SelectedValue[]> {
    return this.propertiesService
      .translate(xpathSelection, lang)
      .then((translatedXPath: string) => xpath.select(translatedXPath, xml));
  }

  /**
   * Get the xslt parameters for the transformation
   * update the cache map
   */
  private _getXSLT(xsltFullId: string): Promise<XSLCacheObject> {
    const xslId = this.getXSLId(xsltFullId);

    const xsltFilename = `${xslId}.xsl`;
    return this.httpService
      .getAsset(`assets/xslt/${xsltFilename}`, MimeType.PLAIN)
      .then((xsl: string) =>
        this.translateXSL(xsl, this.store.pubInfo.lang).then((translatedXsl: Document) => {
          const params = defineXSLTParameters(xslId);
          const returnObject = {
            parameters: params,
            documentFragment: translatedXsl
          } as XSLCacheObject;

          this.cacheMap.set(xsltFullId, returnObject);
          return returnObject;
        })
      )
      .catch((err: Error) => {
        console.error(`Error during get XSL ${xslId} : `, err);
        return undefined;
      });
  }

  /**
   * get the xsl id from xsltFullId
   * example xsltFullId = pubId__xslId
   */
  private getXSLId(xsltFullId: string): string {
    const xslId = xsltFullId.split("__")[1];
    // SPEC: this is for backward compatibility, some S1000D stylesheets have been renamed
    switch (xslId.toLowerCase()) {
      case "msmh160mergedstylesheet":
      case "msm_als":
        return "MSM_ALS_S1000D";
      case "wdm":
        return "WDM_S1000D";
      case "ipcamossmergedstylesheet":
        return "IPCAMOSSMergedStyleSheet";
      case "h160_fm":
      case "front-matter":
        return "FRONT_MATTER_S1000D";
      default:
        return xslId;
    }
  }

  /**
   * Return the xslt to use for the given dmc
   */
  private async _xsltNameFor(dmc: string): Promise<string> {
    const info = (await this.pouchService.tocCaller.get(dmc)) as TocInfo;
    return info.xslt.split("/")[1].split(".")[0];
  }

  private translateXSL(rawXSL: string, lang = this.langService.lang): Promise<Document> {
    return this.propertiesService
      .translate(rawXSL, lang)
      .then(xsl => new DOMParser().parseFromString(xsl, "text/xml"));
  }
}
