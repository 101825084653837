import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { TocPublicCaller } from "@viewer/core/pouchdb/caller";
import { reaction } from "mobx";
import { TocItem, ServiceBulletin } from "@orion2/models/tocitem.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { SbStatus } from "@orion2/models/enums";
import {
  convertToNewDMC,
  S1000D_DM_REPLACE,
  S1000D_DM_WITH_Z_REGEX
} from "@viewer/shared-module/helper.utils";
import { Observable, filter, map } from "rxjs";

@Injectable()
export class ServiceBulletinService extends TocItemService {
  protected tiType = "sb";
  protected tiScope = "public";
  protected tiTarget = DBConnexionType.BOTH;

  private sbList: Promise<TocItem[]>;
  private impactedDocumentMap = new Map<string, Promise<ServiceBulletin[]>>();

  constructor(injector: Injector) {
    super(injector);

    reaction(
      () => this.store.publicationID,
      () => {
        if (this.store.publicationID) {
          // we reset sbList when we change publication
          this.sbList = undefined;
          this.impactedDocumentMap.clear();
        }
      }
    );
  }

  public get tocItemsOfType(): Observable<ServiceBulletin[]> {
    return super.tocItemsOfType.pipe(
      filter(Boolean),
      map((sbs: TocItem[]) => sbs as ServiceBulletin[])
    );
  }

  // If sb.service is a singleton instantiated with the app
  // Even if sb.service instantiate once when the sb module
  // is loaded
  // then there's no need to fetch the sb in db every time.
  public async getAllServiceBulletin(
    parent: string,
    all = false
  ): Promise<ServiceBulletin | ServiceBulletin[]> {
    if (!this.sbList) {
      this.sbList = this.getItemsOfType(this.tiType, this.tiScope);
    }
    return this.sbList.then((docs: ServiceBulletin[]) => {
      const filteredDocs = docs.filter(
        (doc: ServiceBulletin) =>
          doc.parents.find((p: string) => p === parent) || doc._id === parent
      );
      return filteredDocs && filteredDocs.length
        ? all
          ? filteredDocs
          : filteredDocs[0]
        : undefined;
    });
  }

  // We want to find all sb that contains on parents fields a dmc coresponding to fullDMC
  // (a DM container with Z reference can be on parents)
  // exemple fullDMC : DMC-H160-ORION-A-25-53-2001-00A-520A-A_en-US
  // impactedDM : ["H160-A-25-53-2001-00Z-520A-A"]
  // dmcParent (fullDMC converted with convertToNewDMC() method) : H160-A-25-53-2001-00A-520A-A
  // regex : /H160-A([0-9]{3})?-25-53-2001-00[A-Z]-520[A-Z]-A/g
  public getAllImpactingSB(fullDmc: string): Promise<ServiceBulletin[]> {
    if (!this.impactedDocumentMap.has(fullDmc)) {
      if (!this.sbList) {
        this.sbList = this.getItemsOfType(this.tiType, this.tiScope);
      }
      const impactingSB = this.sbList.then((docs: ServiceBulletin[]) => {
        const dmcParent = convertToNewDMC(fullDmc);
        const filteredDocs = docs.filter((doc: ServiceBulletin) =>
          doc.impactedDM?.find((dmc: string) =>
            dmcParent.match(new RegExp(dmc.replace(S1000D_DM_WITH_Z_REGEX, S1000D_DM_REPLACE), "g"))
          )
        );
        return filteredDocs?.filter(
          (sb: ServiceBulletin) => sb.status === SbStatus.notIncorporated
        );
      });
      this.impactedDocumentMap.set(fullDmc, impactingSB);
      if (this.impactedDocumentMap.size > 50) {
        // I want to remove the first key in the map
        this.impactedDocumentMap.delete(Array.from(this.impactedDocumentMap.keys())[0]);
      }
    }
    return this.impactedDocumentMap.get(fullDmc);
  }

  public getServiceBulletinPdf(pdfId: string): Promise<Blob> {
    return (this.getCaller(this.tiScope) as TocPublicCaller).getAttachmentsBlob(pdfId);
  }
}
