/* eslint-disable @typescript-eslint/no-explicit-any */
export class BackgroundMode {
  static isFirstCall = true;

  /**
   * Start backGround Mode after quit the app
   */
  static enableBackGroundMode() {
    // Disable background download for IOS
    if ((window as any).cordova && (window as any).cordova.platformId !== "ios") {
      if (this.isFirstCall) {
        this.isFirstCall = false;
        (window as any).cordova.plugins.backgroundMode.on("activate", () => {
          console.log("background mode activated !!!");
          (window as any).cordova.plugins.backgroundMode.disableWebViewOptimizations();
          (window as any).cordova.plugins.backgroundMode.disableBatteryOptimizations();
          this.dimPM();
        });
      }
      (window as any).cordova.plugins.backgroundMode.enable();
    }
  }

  /**
   * Disable PowerManagement
   */
  static dimPM() {
    if ((window as any).powerManagement) {
      console.log("powerManagement");
      (window as any).powerManagement.release(() => {
        (window as any).powerManagement.dim(() => {
          console.log("enablebackground: Wakelock acquired");
          (window as any).powerManagement.setReleaseOnPause(false, () => {
            console.log("enablebackground: setReleaseOnPause success");
          });
        });
      });
    }
  }

  /**
   * Disable background mode
   */
  static disableBackGroundMode() {
    // Disable background download for IOS
    if ((window as any).cordova && (window as any).cordova.platformId !== "ios") {
      setTimeout(() => {
        (window as any).cordova.plugins.backgroundMode.disable();
      }, 1000);
    }
  }
}
