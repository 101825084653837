import { Injectable, OnDestroy } from "@angular/core";
import { runInAction } from "mobx";
import { Store } from "@viewer/core/state/store";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { environment } from "@viewer-env/environment";
// By default we will inject a fake service in order to not include "electron" package
// but for electron environment we will inject the real one (see: webpack.config.js from electron)
import { ElectronService } from "tools/electron/viewer/helpers/electron.service.browser";
import { SearchService } from "@viewer/core/search/search.service";
import { PdfMakerService } from "@viewer/pdf-maker/pdf-maker.service";
import { Subscription } from "rxjs";
import { ViewerMessageService } from "@viewer/core";
import { TranslateService } from "@ngx-translate/core";
import { CordovaService } from "libs/cordova/cordova.service";
import { MimeType } from "@orion2/models/enums";
import { SearchResult } from "@viewer/core/search/searchModel";

@Injectable({
  providedIn: "root"
})
export class PrintService implements OnDestroy {
  public printPdf: number[];
  public pdfData: Blob;
  public pdfFilepath: string;
  public printQueue: Promise<boolean>[] = [];
  private subscriptions: Subscription = new Subscription();
  private queryParamMap: ParamMap;
  constructor(
    public store: Store,
    public router: Router,
    private electronService: ElectronService,
    private searchService: SearchService,
    private pdfMakerService: PdfMakerService,
    public route: ActivatedRoute,
    private messageService: ViewerMessageService,
    private translate: TranslateService,
    private cordovaService: CordovaService
  ) {
    this.subscriptions.add(
      this.route.queryParamMap.subscribe(queryParamMap => {
        this.queryParamMap = queryParamMap;
      })
    );
    // we do a event listener on after print using the matchMedia
    // we can't use the afterprint event because is fired after window.print()
    // and our print fonction don't use window.print() on electron and cordova
    const mediaQueryList = window.matchMedia("print");
    mediaQueryList.addListener(mql => {
      if (!mql.matches) {
        runInAction(() => {
          this.store.inPrint = false;
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // when the user presses the print button:
  // we want to replace the thumbnails by the svg in the dom then start the print
  // we wait the end of this process by adding all svg media to switch before print on the printQueue array
  // and finish to resolve all promise before the print

  public addToPrintQueue(icn: string, isReadToPrint: Promise<boolean>) {
    this.printQueue[icn] = isReadToPrint;
  }

  // when a svg is destroy we remove his promise from the printQueue array
  public removeToPrintQueue(icn: string) {
    delete this.printQueue[icn];
  }

  // before running the print function we make sure that all the promise have finished to solve
  // (all thumbs have been replaced on the DOM by the SVG)
  public printFinal() {
    runInAction(() => {
      this.store.inPrint = true;
    });
    Promise.all(Object.keys(this.printQueue).map(id => this.printQueue[id])).then(() => {
      setTimeout(() => {
        this.printPage().then(() => {
          runInAction(() => {
            this.store.inPrint = false;
          });
        });
      }, 0);
    });
  }

  public printTasks(tasks: SearchResult[], url: string): Promise<void> {
    return this.pdfMakerService.createPdf(tasks, this.queryParamMap).then((val: number[]) => {
      this.printPdf = val;
      this.router.navigate([url, "print"]);
    });
  }

  public async printPage() {
    const url = this.router.url;
    const isTask = url.includes("/search/task");
    /*** for pdf-make files  */
    if (isTask) {
      return this.printTasks(
        this.searchService.dataSource._filteredResults,
        url.split("search/")[0]
      );
    }

    /*** for pdf files  */
    if (this.pdfData || this.pdfFilepath) {
      if (environment.platform === "cordova") {
        this.printPdfCordova();
        return;
      }
      const iframes = document.getElementsByTagName("iframe");
      // on electron or broswer we use the pdfJS print button
      // in case of preprint is overriding a pdf we want to print the preprint (last iframes)
      const pdfPrintButton = iframes[iframes.length - 1].contentDocument.querySelector(
        "#print"
      ) as HTMLButtonElement;
      pdfPrintButton.click();
      return;
    }
    /*** for web pages  */
    if (environment.platform === "electron") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.electronService.print().catch((err: any) => {
        if (err === "No printer available") {
          this.messageService.error(
            this.translate.instant("print.error.noPrinter"),
            undefined,
            5000
          );
          return;
        }
        console.error(JSON.stringify(err));
      });
    }
    if (environment.platform === "cordova") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).cordova.plugins.printer.print();
      return;
    }
    // platform == browser
    window.print();
  }

  private printPdfCordova(): Promise<void> {
    // In Ios case or extradoc we already have the pdf in pdfFilepath
    if (this.pdfFilepath) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).cordova.plugins.printer.print(this.pdfFilepath);
      return Promise.resolve();
    }
    // we need to write the file with the blob data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const folderpath = (window as any).cordova.file.cacheDirectory;
    const filename = "temp.pdf";
    const filePath = folderpath + filename;
    this.cordovaService.writeFile(filePath, this.pdfData, MimeType.PDF).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).cordova.plugins.printer.print(`${folderpath}/${filename}`);
    });
  }
}
