import { Pipe, PipeTransform } from "@angular/core";

/**
 * If data is undefined return "-" else return data
 *
 * @export
 * @class FormatResultPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: "formatResult"
})
export class FormatResultPipe implements PipeTransform {
  transform(data: string): string {
    if (!data) {
      return "-";
    } else {
      return data;
    }
  }
}
