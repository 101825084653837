import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  public isLoading = new Subject<boolean>();

  /**
   * Show loader.
   */
  public show(): void {
    this.isLoading.next(true);
  }

  /**
   * Hide loader.
   */
  public hide(): void {
    this.isLoading.next(false);
  }
}
