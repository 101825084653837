import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenResponse } from "@openid/appauth";
import { OakResponse } from "@orion2/auth/oak.response";
import { AbstractHttpService, HttpClientOptions } from "libs/http/abstractHttp.service";

@Injectable()
export class FullHttpService extends AbstractHttpService {
  protected getHeaders(otherHeaders?: HttpClientOptions["headers"]): Promise<HttpHeaders> {
    return this.getToken()
      .then((token: TokenResponse) =>
        this.environment.authService.oak().then((oak: OakResponse) => {
          if (token && oak) {
            return new HttpHeaders({
              authorization: `Bearer ${token.accessToken}`,
              oak: oak.oak,
              ...otherHeaders
            });
          }
          return undefined;
        })
      )
      .catch(() => undefined);
  }
}
