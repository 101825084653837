import { PouchDBReplicationParam, DbItem } from "@orion2/models/couch.models";
import { StatusCodes } from "http-status-codes";

export class OrionDBsCore {
  static readonly replicateStatusKey = "_local/replicationStatus";
  static batchesLimit = 10;
  static batchesSize = 50;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static replicate(source, target, bl?: number, bz?: number) {
    return new Promise((resolve, reject) => {
      source.replicate
        .to(target, {
          // batches_limit: bl || this.batchesLimit,
          // batch_size: bz || this.batchesSize // must match above batch_size
        })
        .on("error", reject)
        .on("complete", resolve)
        .on("denied", reject);
    });
  }

  static replicateReference(
    source: PouchDB.Database,
    target: PouchDB.Database,
    batchSize: number,
    ids?: string[]
  ): PouchDB.Replication.Replication<{}> {
    return source.replicate.to(target, {
      batches_limit: 5,
      batch_size: batchSize,
      // this is to avoid that pouchdb keeps in memory partial results until replication is
      // complete so that results can also be send with the complete event. This causes memory failure
      // as we have to much data to replicate (think media db) and that the data transfered are uncompressed.
      return_docs: false,
      // this forces attachments => mandatory here.
      attachments: true,
      checkpoint: "target",
      doc_ids: ids
      // binary: true
    } as PouchDBReplicationParam);
  }

  static async isReplicate(local): Promise<boolean> {
    try {
      await local.get(OrionDBsCore.replicateStatusKey);
      return true;
    } catch (error) {
      if (error.status !== StatusCodes.NOT_FOUND) {
        console.warn(error);
        throw new Error("error in replication status retrive");
      }
      return false;
    }
  }

  /**
   * Tell if there is notFound doc in response from pouchDB
   *
   * @private
   * @param docs
   * @returns
   * @memberof OrionDBs
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static docsNotFound(docs: any): boolean {
    if (docs && docs.rows) {
      const notFoundDocs = docs.rows.filter(row => typeof row.error !== "undefined");
      return notFoundDocs.length === docs.rows.length || docs.rows.length === 0;
    }
    return false;
  }

  /**
   * Set flag in local db to index that replication is done
   *
   * @param local
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async replicationDone(local): Promise<any> {
    if (!(await OrionDBsCore.isReplicate(local))) {
      return local.put({
        _id: OrionDBsCore.replicateStatusKey
      });
    }
    return Promise.resolve();
  }

  static async removeReplicationFlag(local) {
    try {
      const previousFlag = await local.get(OrionDBsCore.replicateStatusKey);
      if (previousFlag !== undefined) {
        return local.remove(previousFlag);
      }
    } catch (e) {
      if (e.status === StatusCodes.NOT_FOUND) {
        return Promise.resolve();
      } else {
        throw e;
      }
    }
  }

  /**
   * Compute the best batch size and limit for replication
   *
   * @protected
   * @returns
   * @memberof OrionDBs
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async batchParam(remoteDb): Promise<any> {
    const info = await remoteDb.info();
    const batchLimit = 10;
    return {
      batchLimit,
      batchSize: Math.floor(info.doc_count / batchLimit)
    };
  }

  /**
   * What to do in case of replication error
   *
   * @private
   * @param err
   * @memberof OrionDBs
   */
  static replicationErrorHandler(err): void {
    // something went wrong!
    console.error("onReplication");
    console.error(err);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static save(db, doc): Promise<any> {
    return db.put(doc);
  }

  /**
   * Transform the response from allDoc methods to keep coherence with single get
   *
   * @param res
   * @returns
   * @memberof OrionDBs
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static reshape(response: any): any {
    if (response && response.rows) {
      response.rows = response.rows.map(
        row =>
          // if response contains error or if include_docs false
          // there's no doc property.
          row.doc || row
      );
    } else if (response && Array.isArray(response)) {
      // If it's a return of multiple databse we merge all rows and reshape it
      return OrionDBsCore.reshape({
        rows: [].concat(...response.map(r => r.docs || r.rows))
      });
    } else if (response && response.docs) {
      // Filter function return docs attribute instead of rows
      return OrionDBsCore.reshape({
        rows: response.docs,
        bookmark: response.bookmark
      });
    }

    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static bulk(db: any, docs: DbItem[]): Promise<any> {
    docs.forEach(doc => {
      doc.lastUpdate = new Date();
    });
    return db.bulkDocs(docs);
  }
}
