// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .mat-progress-bar {
  margin: 5px 0;
}
:host .details {
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
}
:host .details .data > span {
  margin-right: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInB1Yi1yZXBsaWNhdGlvbi1wcm9ncmVzcy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGNBQUE7QUFERjtBQUdFO0VBQ0UsYUFBQTtBQURKO0FBSUU7RUFDRSxhQUFBO0VBQ0EsZ0JBQUE7RUFDQSw4QkFBQTtBQUZKO0FBSUk7RUFDRSxpQkFBQTtBQUZOIiwiZmlsZSI6InB1Yi1yZXBsaWNhdGlvbi1wcm9ncmVzcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJsaWJzL3RoZW1lL29yaW9uLXRoZW1lXCI7XG5cbjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG5cbiAgLm1hdC1wcm9ncmVzcy1iYXIge1xuICAgIG1hcmdpbjogNXB4IDA7XG4gIH1cblxuICAuZGV0YWlscyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmb250LXNpemU6IDAuOWVtO1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcblxuICAgIC5kYXRhID4gc3BhbiB7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDVweDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/replication/pub-replication-progress/pub-replication-progress.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,aAAA;EACA,gBAAA;EACA,8BAAA;AAFJ;AAII;EACE,iBAAA;AAFN;AACA,wwBAAwwB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  display: block;\n\n  .mat-progress-bar {\n    margin: 5px 0;\n  }\n\n  .details {\n    display: flex;\n    font-size: 0.9em;\n    justify-content: space-between;\n\n    .data > span {\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
