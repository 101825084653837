import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { TaskInspectionDoc, Parent, TaskId } from "@orion2/models/tocitem.models";
import { replaceRevisionFromDoc } from "@viewer/shared-module/helper.utils";
import { SearchResult } from "@viewer/core/search/searchModel";
import { MsmFormatter } from "@orion2/msm-formatter/index";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";

@Injectable()
export class TaskService extends TocItemService {
  tasksMap: Map<string, Parent[]> = new Map();
  notFoundTasksMap: Map<string, Parent[]> = new Map();

  protected tiType = "task";
  protected tiScope = "private";

  constructor(injector: Injector) {
    super(injector);
  }
  public get allTasks(): TaskInspectionDoc[] {
    return Array.from(this.tasksMap.entries()).map(
      entry =>
        ({
          _id: entry[0],
          parents: entry[1]
        } as TaskInspectionDoc)
    );
  }

  public get notFoundTasks(): TaskInspectionDoc[] {
    return Array.from(this.notFoundTasksMap.entries()).map(
      entry =>
        ({
          _id: entry[0],
          parents: entry[1]
        } as TaskInspectionDoc)
    );
  }

  public get(task_id: string): Promise<TaskInspectionDoc> {
    return Promise.resolve(this.allTasks.find(task => task._id === task_id));
  }

  public removeFromInspection(id: string, parentId: string): Promise<TaskInspectionDoc> {
    return this.get(id).then(doc => {
      const newDoc = {
        ...doc
      };

      const parentIdWithoutRev = replaceRevisionFromDoc(parentId);
      const parentsList = doc.parents.filter(value => value.id !== parentIdWithoutRev);
      newDoc.parents = parentsList;

      if (parentsList.length === 0) {
        newDoc._deleted = true;
      }

      // We shouldn't purge parents if the current revision is different for the tasks
      if (doc.minRevision < this.store.publicationRevision) {
        return this.delete(doc).then(() => {
          newDoc.minRevision = this.store.publicationRevision;
          return newDoc;
        });
      } else {
        return newDoc;
      }
    });
  }

  //never call
  public bulkRemoveTaskFromInspection(
    tasks: Array<TaskId | Parent>,
    inspectionId: string
  ): Promise<TaskInspectionDoc[]> {
    return Promise.all(
      tasks.map(taskArray =>
        this.removeFromInspection(
          (taskArray as TaskId).idRefDM !== undefined
            ? (taskArray as TaskId).idRefDM
            : (taskArray as Parent).id,
          inspectionId
        )
      )
    );
  }

  public getAllInspectionTasks(): Promise<TaskInspectionDoc[]> {
    return this.getItemsOfType(this.tiType) as Promise<TaskInspectionDoc[]>;
  }

  public adaptTasksFromSearch(searchResults: SearchResult[]): TaskId[] {
    return searchResults.map((taskData: SearchResult) => ({
      idRefSearch: taskData.id,
      idRefDM: replaceRevisionFromDoc(taskData.dmc)
    }));
  }

  public convertToCSV(taskMetadata: SearchResult): string[] {
    if (taskMetadata) {
      const task: TaskNamespace.MsmTask = MsmFormatter.unserialize(taskMetadata.task);
      const locs = task.documentation?.map(
        (documentation: TaskNamespace.Documentation) => documentation.loc
      );

      const csvContent = [];

      /*
      In order to fill ATA column and the section column we search in the task id
      the informations. Legacy tasks ids (ex : 63/11/00/000/000/013)
      and S1000D tasks ids are different (ex : H160-64-21-00-000-0B2-080)
      We use this regex in order to get data and save it in an array
    */

      const idSplitted = task.id
        .match(/(^|-)\d{2}[-\/]\d{2}[-\/]\d{2}/g)
        .toString()
        .replace(/^-/g, "")
        .split(/-|\//);

      const ata = idSplitted[0];
      // SPEC: chapter with manual for disallow conversion to date
      const chapter = taskMetadata.reference;

      csvContent.push(chapter);
      csvContent.push(ata);
      csvContent.push(ata + idSplitted[1]);
      csvContent.push(task.id);
      csvContent.push(task.title);
      const desc = [];
      if (task.description) {
        task.description.forEach(descriptionLine => {
          if (descriptionLine.value) {
            desc.push(descriptionLine.value);
          }
        });
      }
      csvContent.push(desc.length > 0 ? `"${desc.join("\n").replace(/"/gm, "'")}"` : "");
      // Mod column is empty for the moment
      csvContent.push("");
      csvContent.push(
        task.documentation
          ? `"${[
              task.documentation
                .map(doc => doc.shortDMC)
                .filter(link => link && link !== "")
                .join("\n"),
              locs.filter(loc => loc && loc.length > 0).join("\n")
            ]
              .filter(el => el && el !== "")
              .join("\n")}"`
          : ""
      );
      csvContent.push(task.versions);
      csvContent.push(
        task.interval
          ? '"' +
              task.interval
                .map(interval =>
                  Object.keys(interval)
                    .filter(key => key !== "changed")
                    .reduce(
                      (acc, cur) => (interval[cur] !== undefined ? `${acc}${interval[cur]} ` : ""),
                      ""
                    )
                )
                .join("\n") +
              '"'
          : ""
      );
      csvContent.push(
        task.margin
          ? '"' + task.margin.map(margin => this.generateMarginString(margin)).join("\n") + '"'
          : ""
      );

      csvContent.push(
        task.mpn_pn ? '"' + task.mpn_pn.map(mpnPn => mpnPn.mpn).join("\n") + '"' : ""
      );
      csvContent.push(task.mpn_pn ? '"' + task.mpn_pn.map(mpnPn => mpnPn.pn).join("\n") + '"' : "");
      csvContent.push(task.limitType);
      csvContent.push(task.status);
      return csvContent;
    }
    return undefined;
  }

  /**
   * Utilitary function to prepare margin attribute to be set in the CSV export
   * * If there is no value, return an empty string
   * * If the value is 0, the unit is ignored
   * * If there are both value and unit and unit is not 0, returns the concatenation
   *
   * @param margin - The margin to prepare
   */
  private generateMarginString(margin: { val: string; unit: string }): string {
    const val = margin.val !== undefined ? margin.val : "";
    if (val === "" || val === "0") {
      return val;
    }

    return `${val}${margin.unit !== undefined ? margin.unit : ""}`;
  }
}
