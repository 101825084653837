import { Component, Input } from "@angular/core";

@Component({
  selector: "o-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"]
})
export class NotFoundComponent {
  @Input() errorMessage: string;
  defaultMessage = "Oups, this page does not exist (404)";
}
