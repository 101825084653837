/**
 * Handle log throw the application life
 * Only error log apeare in production mode
 */
import { Injectable } from "@angular/core";
import { environment } from "@viewer-env/environment";

@Injectable()
export class LoggerService {
  enabled: boolean;

  constructor() {
    this.enabled = !environment.production;
  }

  /**
   * Log a debug lvl message
   *
   * @readonly
   * @memberof LoggerService
   */
  get debug() {
    // eslint-disable-next-line no-console
    return this.bind(console.debug);
  }

  /**
   * Log an error lvl message
   *
   * @readonly
   * @memberof LoggerService
   */
  get error() {
    return console.error;
  }

  /**
   * Log a log lvl message
   *
   * @readonly
   * @memberof LoggerService
   */
  get log() {
    return this.bind(console.log);
  }

  /**
   * Log a ingo lvl log
   *
   * @readonly
   * @memberof LoggerService
   */
  get info() {
    // eslint-disable-next-line no-console
    return this.bind(console.info);
  }

  /**
   * Log a warn lvl log
   *
   * @readonly
   * @memberof LoggerService
   */
  get warn() {
    return this.bind(console.warn);
  }

  /**
   *
   * Redirect the log to console if enabled
   *
   * @param c
   * @returns
   * @memberof LoggerService
   */
  private bind(c) {
    return this.enabled ? c.bind(console) : () => {};
  }
}
