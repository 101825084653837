import { Component, OnInit, Inject } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from "@angular/material/legacy-dialog";
import { TranslateService } from "@ngx-translate/core";
import { ViewerMessageService } from "@viewer/core";
import { SynchroService } from "@viewer/core/synchro/synchro.service";

export enum Step {
  MIGRATION = "migration",
  SYNCHRO = "synchro"
}

@Component({
  selector: "o-synchro-modal",
  templateUrl: "./synchro-modal.component.html",
  styleUrls: ["./synchro-modal.component.scss"]
})
export class SynchroModalComponent implements OnInit {
  public currentStep: Step;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Promise<boolean>,
    private dialog: MatDialogRef<SynchroModalComponent>,
    private synchroService: SynchroService,
    private messageService: ViewerMessageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): Promise<void> {
    // this.data is a promise for TI migration
    // We wait for migration to end to start the synchro
    if (this.data) {
      this.currentStep = Step.MIGRATION;
      return this.data.then(() => this.initSynchro());
    } else {
      return this.initSynchro();
    }
  }

  /**
   * Start the synchronization and closes the modal once it's over.
   */
  private initSynchro(): Promise<void> {
    this.currentStep = Step.SYNCHRO;
    return this.synchroService.synchronize().then(() => {
      this.messageService.success(this.translate.instant("synchronize.ok"));
      this.dialog.close();
    });
  }
}
