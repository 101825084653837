import { Injectable, Injector } from "@angular/core";
import { TocItem, Folder } from "@orion2/models/tocitem.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { reaction } from "mobx";

@Injectable()
export class FolderService extends TocItemService {
  public folderList: Promise<TocItem[]>;

  protected tiType = "folder";
  protected tiScope = "public";
  protected tiTarget = DBConnexionType.BOTH;

  constructor(injector: Injector) {
    super(injector);

    reaction(
      () => this.store.publicationID,
      () => {
        if (this.store.publicationID) {
          // We reset folderList when we change publication
          this.folderList = undefined;
        }
      }
    );
  }

  // If folder.service is a singleton instantiated with the app
  // Even if folder.service is instantiated once when the folder module
  // is loaded
  // then there's no need to fetch the folder in db every time.
  public getAllFolder(parent: string, all = false): Promise<Folder | Folder[]> {
    if (!this.folderList) {
      this.folderList = this.getItemsOfType(this.tiType, this.tiScope);
    }
    return this.folderList.then((docs: Folder[]) => {
      const filteredDocs = docs.filter(doc =>
        all ? doc.parents.find(p => p === parent) : doc._id === parent
      );
      return filteredDocs && filteredDocs.length
        ? all
          ? filteredDocs
          : filteredDocs[0]
        : undefined;
    });
  }
}
