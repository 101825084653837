import { BaseTranslateUtils } from "libs/translate/base.translate.utils";

/**
 * Handle translation of key in dmc content , will use the translate file base on package lang
 * Keep in mind to update translation file as soon as Orion1
 */
export class TranslateUtils extends BaseTranslateUtils {
  public translateLine(line: string, prop: Map<string, string>, toc = false): string {
    return super.translateLine(line, prop, toc);
  }
}
