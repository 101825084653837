import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: "o-transfert-ti-dialog",
  templateUrl: "./transfert-ti-dialog.component.html",
  styleUrls: ["./transfert-ti-dialog.component.scss"]
})
export class TransfertTiDialogComponent {
  constructor(public dialogRef: MatDialogRef<TransfertTiDialogComponent>) {}
}
