import {
  Directive,
  Input,
  Inject,
  PLATFORM_ID,
  SimpleChanges,
  ElementRef,
  OnChanges,
  OnDestroy,
  AfterViewInit
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import stickybits, { StickyBits } from "stickybits";

/**
 * Directive to position an element with sticky value
 * It uses stickybits, a alternative to position sticky polyfills
 *
 * @export
 * @class StickyDirective
 * @implements {AfterContentInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Directive({
  selector: "[oSticky]"
})
export class StickyDirective implements AfterViewInit, OnChanges, OnDestroy {
  // Whether the directive should be applied or not. Used to make the directive conditionnal.
  @Input() applySticky = true;
  // Add/remove classes from element according to it's sticky state
  @Input() useStickyClasses = true;
  // desired offset from the top of the viewport to which the element will stick
  @Input() stickyOffset = 0;
  // Stick the element to the bottom instead of top
  @Input() stickToBottom = false;

  private instance: StickyBits;

  // options
  private stuckClass = "is-stuck";
  private stickyClass = "is-sticky";
  private changeClass = "is-sticky-change";
  private parentClass = "is-sticky-parent";

  constructor(private element: ElementRef, @Inject(PLATFORM_ID) private platformId: string) {}

  ngAfterViewInit() {
    if (this.applySticky) {
      this.init();
    }
  }

  /**
   * Detect and init when browser changes
   *
   * @param changes
   * @memberof StickyDirective
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.applySticky) {
      const change = changes[Object.keys(changes)[0]];
      if (isPlatformBrowser(this.platformId) && change?.currentValue !== change?.previousValue) {
        this.init();
      }
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  /**
   * Create and init stickybits instance
   *
   * @private
   * @memberof StickyDirective
   */
  private init(): void {
    this.destroy();
    const element = this.element.nativeElement as HTMLElement;
    this.element.nativeElement.classList.add("sticky");
    if (element) {
      this.instance = stickybits(element, {
        useStickyClasses: this.useStickyClasses,
        stickyBitStickyOffset: this.stickyOffset,
        verticalPosition: this.getVerticalPosition(),
        stuckClass: this.stuckClass,
        stickyClass: this.stickyClass,
        stickyChangeClass: this.changeClass,
        parentClass: this.parentClass
      });
    }
  }

  private destroy(): void {
    if (this.instance) {
      this.instance.cleanup();
      this.instance = null;
    }
  }
  /**
   * Get position (bottom or top) according to stickToBottom
   *
   * @private
   * @returns
   * @memberof StickyDirective
   */
  private getVerticalPosition(): "bottom" | "top" {
    if (this.stickToBottom) {
      this.element.nativeElement.classList.add("sticky-bottom");
      return "bottom";
    } else {
      return "top";
    }
  }
}
