import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotesButtonComponent } from "@viewer/notes-button/notes-button.component";
import { MatBadgeModule } from "@angular/material/badge";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [CommonModule, MatIconModule, MatBadgeModule, MatButtonModule],
  declarations: [NotesButtonComponent],
  exports: [NotesButtonComponent]
})
export class NotesButtonModule {}
