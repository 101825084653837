import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { PubDoc } from "@orion2/models/couch.models";
import { ConfService, Store } from "@viewer/core";
import { SearchProductsService } from "@viewer/home/search-products/service/search-products.service";
import { ProductsService } from "@viewer/home/service/products.service";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";
import { filter, map, merge, Subscription } from "rxjs";

const DEFAULT_DEGRADED_SEARCH_PARAMS: Params = {
  manualCode: "ORIO",
  status: "AVAILABLE",
  productType: "online"
};

@Component({
  selector: "o-online-products",
  templateUrl: "./online-products.component.html",
  styleUrls: ["./online-products.component.scss"]
})
export class OnlineProductsComponent implements OnInit, OnDestroy {
  public isRecentlyConsulted: boolean;
  public products: PubDoc[];
  private subscriptions: Subscription = new Subscription();

  constructor(
    public store: Store,
    public conf: ConfService,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private searchProductsService: SearchProductsService,
    private degradedStateHandler: DegradedStateService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      merge(
        this.route.queryParams.pipe(filter(() => !this.degradedStateHandler.isDegraded)),
        this.degradedStateHandler.degradedState.pipe(
          filter(isDegraded => !!isDegraded),
          map(() => DEFAULT_DEGRADED_SEARCH_PARAMS)
        )
      ).subscribe((params: Params) => {
        this.isRecentlyConsulted = this.conf.conf.hasBP2 && params.lastConsulted === "true";
        this.searchProductsService.selectedFilters = this.isRecentlyConsulted ? {} : params;
        this.getOnlineProducts(params);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public removeChip(category: string): void {
    this.searchProductsService.removeFilter(category);
  }

  private getOnlineProducts(params?: Params): Promise<void> {
    this.products = undefined; // Reset products during process to display spinner
    const filters = this.searchProductsService.splitFilters(params);
    return this.productsService.getOnlineProducts(filters.server).then((products: PubDoc[]) => {
      this.products = this.searchProductsService.filterProducts(products, filters.client);
    });
  }
}
