import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "safeDate"
})
export class SafeDatePipe extends DatePipe implements PipeTransform {
  transform(value: Date | string | number, format?: string): string | null;
  transform(value: null | undefined, format?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string): string | null {
    try {
      return super.transform(value, format);
    } catch (err) {
      return value as string;
    }
  }
}
