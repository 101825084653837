import { Injector } from "@angular/core";
import { News, TocItem } from "@orion2/models/tocitem.models";
import { TocItemService } from "@viewer/core";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { filter, map, Observable } from "rxjs";

export enum NewsServices {
  APP = "NewsAppService",
  APP_USER = "NewsAppUserService",
  PUB = "NewsPubService",
  PUB_USER = "NewsPubUserService"
}

export function newsServiceFactory(scope: string): Function {
  return (injector: Injector) => new NewsService(scope, injector);
}

export class NewsService extends TocItemService {
  protected tiTarget = DBConnexionType.BOTH;
  protected tiType = "news";

  constructor(protected scope: string, protected injector: Injector) {
    super(injector);
    this.tiScope = scope;
  }

  public get tocItemsOfType(): Observable<News[]> {
    return super.tocItemsOfType.pipe(
      filter(Boolean),
      map((news: TocItem[]) => news as News[])
    );
  }

  public save(tocItem: Partial<News>): Promise<boolean> {
    return super.save(tocItem as unknown as News, this.tiScope);
  }
}
