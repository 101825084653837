import { Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { HistoricService } from "@viewer/core/toc-items/historic.service";
import { HistoryElem, Navigation } from "@orion2/models/tocitem.models";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "o-historic-dialog",
  templateUrl: "./historic-dialog.component.html",
  styleUrls: ["./historic-dialog.component.scss"]
})
export class HistoricDialogComponent implements OnInit {
  navigation: Navigation;
  history: HistoryElem[];

  constructor(
    public dialogRef: MatDialogRef<HistoricDialogComponent>,
    private historicService: HistoricService,
    // Use to force the load of the translateService
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.navigation = this.historicService.getNavigation();
    this.history = this.historicService.getHistory();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public click(item: Navigation): void {
    if (item) {
      this.historicService.backTo(item);
    }
    this.close();
  }
}
