import { Injectable } from "@angular/core";
import { PouchService, Store } from "@viewer/core";
import { XSLService } from "@viewer/content-provider/xsl.service";

export interface Links {
  dmc: string;
  text: string;
  title: string;
}

export interface CmData {
  cmNumber?: Element;
  links?: Links[];
  material?: Element;
  notes?: Element[];
  specs?: Element[];
}

@Injectable()
export class CmPreviewService {
  links: {};
  metadata: Object[] = [];
  cmCache = new Map<string, Document>();

  constructor(
    private pouchService: PouchService,
    private xslService: XSLService,
    private store: Store
  ) {}

  // One of the two Async methods never returns anything.
  public retrieveData(dmc, anchor): Promise<CmData> {
    if (dmc !== undefined && anchor !== undefined) {
      return this.retrieveDoc(dmc, anchor).then((extract: Element) => this.extractData(extract));
    } else {
      return Promise.resolve(undefined);
    }
  }

  getXMLNode(dmc): Promise<Document> {
    const key = `${this.store.pubInfo._id}__${dmc}`;
    return this.cmCache.has(key)
      ? Promise.resolve(this.cmCache.get(key))
      : this.xslService.getXMLNode(dmc);
  }

  async retrieveDoc(dmc, anchor) {
    return this.getXMLNode(dmc).then(xml => {
      const row = xml.querySelector(`CONNBR[WP6TargetId='${anchor}']`);
      return row !== null ? row.closest("ROW") : undefined;
    });
  }

  private extractData(docRow: Element): Promise<CmData> {
    const columns = docRow.querySelectorAll("ENTRY");
    const metaData = {};
    metaData["cmNumber"] = columns[0].querySelector("CONNBR");
    metaData["material"] = columns[1].querySelector("PARA");
    const specs = columns[2].querySelectorAll("PARA");
    metaData["specs"] = Array.from(specs);
    const notes = columns[3].querySelectorAll("PARA");
    metaData["notes"] = Array.from(notes);

    const links = columns[4].querySelectorAll("REFINT");
    const tocDMCPromises = [];
    for (const link of Array.from(links)) {
      const linkDMC = link.getAttribute("xlink:href");
      // Prevent bad authoring (CF: Jira -> ORION20-2149)
      if (linkDMC) {
        // get("" || undefined) is equivalent to allDocs()
        tocDMCPromises.push(this.pouchService.tocCaller.get(linkDMC));
      }
    }

    metaData["links"] = [];
    return Promise.all(tocDMCPromises)
      .then(tocItems =>
        tocItems.forEach(tocItem => {
          const text = tocItem.reference;
          metaData["links"].push({
            dmc: tocItem.dmc,
            title: tocItem.title,
            text
          });
        })
      )
      .then(() => metaData);
  }
}
