import xpath from "xpath";
import { Util, OBJECT_SEPARATOR, ARRAY_SEPARATOR } from "@orion2/utils/datamodule.utils";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";

export class Interval {
  public static getIntervalUnit(unit: string): string {
    switch (unit) {
      case "th01":
        return "FH";
      case "th03":
        return "M";
      case "th04":
        return "W";
      case "th05":
        return "Y";
      case "th06":
        return "D";
      case "th08":
        return "PPCY";
      case "th13":
        return "LGCY";
      case "th19":
        return "LCY";
      case "th20":
        return "OPC";
      case "th21":
        return "OPH";
      case "th32":
        return "SC";
      case "th51":
        return "U";
      case "th52":
        return "BFF";
      case "th53":
        return "ALF";
      case "th54":
        return "ED";
      case "th55":
        return "LC";
      case "th56":
        return "HC";
      case "th57":
        return "NCY";
      case "th58":
        return "BH";
      case "th59":
        return "CFH1";
      case "th60":
        return "CFH2";
      case "th61":
        return "TCY";
      case "th62":
        return "TIO1";
      case "th63":
        return "TIO2";
      case "th64":
        return "RBCY";
      case "th65":
        return "OLHCY";
      case "th66":
        return "ORHCY";
      case "th67":
        return "ONCY";
      case "th68":
        return "HCY";
      case "th69":
        return "SCY";
      case "th70":
        return "SOH";
      case "th71":
        return "EMERCY";
      case "th72":
        return "BCY";
      default:
        return "";
    }
  }
  public static getIntervalFrequency(frequency: string) {
    switch (frequency) {
      case "tht01":
        return "TSO";
      case "tht02":
        return "TSI";
      case "tht03":
        return "TSM";
      case "tht04":
        return "TSN";
      case "tht05":
        return "TSE";
      case "tht51":
        return "HASD";
      case "tht52":
        return "TSM//TSO";
      default:
        return "";
    }
  }

  public static extractLegacy(xmlNode: Node): string {
    const data: string[] = [];

    const intervals = xpath.select("MAINLIMI/NEXTPERF/INTERV", xmlNode);

    intervals.forEach((interval: Node) => {
      const changed = xpath.select("string(.//@CHANGEMARK)", interval).length > 0;
      const val = Util.cleanXml(xpath.select("string(VAL)", interval));
      const unit = Util.cleanXml(xpath.select("string(UNIT)", interval));
      const operator = Util.cleanXml(xpath.select("string(MSMLIMITOPERATOR)", interval));
      // always empty for ATA100
      const sampling = "";

      // periodic value PO or empty
      let limitTypeValue = "";
      if (intervals.indexOf(interval) === 0) {
        limitTypeValue = Util.cleanXml(
          xpath.select("string(ancestor::MSMFREQUENCY/@FREQUENCY)", interval)
        ).toString();
        if (limitTypeValue === "Punctual") {
          limitTypeValue = "PO";
        } else {
          limitTypeValue = "";
        }
      }
      // (P) value or empty
      let temporaryLimit = xpath.select("string(MSMTEMPORARYLIMIT)", interval).toString();
      if (temporaryLimit === "True") {
        temporaryLimit = "(P)";
      } else {
        temporaryLimit = "";
      }

      // TSI , TSN , TSM value
      let freqStart = Util.cleanXml(xpath.select("string(FREQSTART)", interval));
      if (freqStart === "space char" || freqStart === "non-breaking space char") {
        freqStart = "";
      }

      data.push(
        operator +
          OBJECT_SEPARATOR +
          val +
          OBJECT_SEPARATOR +
          unit +
          OBJECT_SEPARATOR +
          sampling +
          OBJECT_SEPARATOR +
          limitTypeValue +
          OBJECT_SEPARATOR +
          temporaryLimit +
          OBJECT_SEPARATOR +
          freqStart +
          OBJECT_SEPARATOR +
          changed
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static extractS1000D(xmlNode: Node, model: string): string {
    const data: string[] = [];
    const intervals = xpath.select("timeLimit/limitType | limit", xmlNode);

    intervals.forEach((interval: Node) => {
      const changed = xpath.select("string(.//@changeMark)", interval).length > 0;

      const val = Util.cleanXml(xpath.select("string(descendant::thresholdValue)", interval));
      const unit = this.getIntervalUnit(
        Util.cleanXml(
          xpath.select("string(descendant::threshold/@thresholdUnitOfMeasure)", interval)
        )
      );
      const operator = Util.cleanXml(xpath.select("string(remarks/simplePara)", interval));

      let sampling = Util.cleanXml(xpath.select("string(sampling)", interval));
      const threshold =
        xpath.select("string(name())", interval).toString() === "limit" &&
        xpath.select("string(threshold)", interval);

      if (sampling && sampling === "yes") {
        sampling = "S";
      } else {
        sampling = "";
      }
      if (threshold && model === "H160") {
        sampling += "T";
      }

      let limitTypeValue = "";
      if (intervals.indexOf(interval) === 0) {
        limitTypeValue = Util.cleanXml(
          xpath.select("string(@limitTypeValue)", interval)
        ).toString();
        if (limitTypeValue === "po" || limitTypeValue === "oc") {
          limitTypeValue = limitTypeValue.toUpperCase();
        } else {
          limitTypeValue = "";
        }
      }
      let temporaryLimit = xpath.select("string(@temporaryLimit)", interval).toString();
      if (temporaryLimit === "1") {
        temporaryLimit = "(P)";
      } else {
        temporaryLimit = "";
      }

      const freqStart = this.getIntervalFrequency(
        Util.cleanXml(
          xpath.select("string(descendant::threshold/@thresholdFrequencyStartType)", interval)
        )
      );

      data.push(
        operator +
          OBJECT_SEPARATOR +
          val +
          OBJECT_SEPARATOR +
          unit +
          OBJECT_SEPARATOR +
          sampling +
          OBJECT_SEPARATOR +
          limitTypeValue +
          OBJECT_SEPARATOR +
          temporaryLimit +
          OBJECT_SEPARATOR +
          freqStart +
          OBJECT_SEPARATOR +
          changed
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static unserialize(data: string): TaskNamespace.Interval[] {
    return data
      ?.split(ARRAY_SEPARATOR)
      .map((interval: string) => interval.split(OBJECT_SEPARATOR))
      .map((val: string[]) => ({
        operator: val[0],
        val: val[1],
        unit: val[2],
        sampling: val[3],
        limitTypeValue: val[4],
        temporaryLimit: val[5],
        freqStart: val[6],
        changed: val[7] === "true"
      }));
  }
}
