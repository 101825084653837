import { Injectable } from "@angular/core";
import {
  BaseStore,
  PushItemsReturnBody
} from "@viewer/toc-items/shopping-basket/shopping-basket.module";
import { ItemBasket } from "@orion2/models/tocitem.models";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { FullHttpService } from "libs/http/fullHttp.service";
import { MimeType } from "@orion2/models/enums";

@Injectable({
  providedIn: "root"
})
export class EOrderingService {
  constructor(private httpService: FullHttpService) {}

  /**
   * Fetch available base stores from the 'basestores' API
   *
   * @returns The available base stores as an array in a promise
   */
  public getAvailableBaseStores(): Promise<BaseStore[]> {
    return this.httpService
      .sendRequest(HttpMethod.GET, "eOrdering/basestores")
      .then((res: any) => res.unit.basestores);
  }

  /**
   * Push the given parts to eOrdering with the given base store
   *
   * @param items {ItemBasket[]} - The parts to be pushed into the shopping basket
   * @param baseStore {BaseStore} - The base store where to push items
   * @returns The body response of the request (see 'PushItemsReturnBody') as a promise
   */
  public pushItems(items: ItemBasket[], baseStore: BaseStore): Promise<PushItemsReturnBody> {
    return this.httpService.sendRequest(HttpMethod.POST, "eOrdering/carts/uploadParts", {
      body: {
        salesOrgCode: baseStore.uid,
        uploadedParts: items.map(item => ({
          mpn: item.mpn,
          quantity: item.qtyInBasket
        }))
      },
      headers: {
        "Content-Type": MimeType.JSON
      }
    });
  }
}
