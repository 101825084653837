import xpath from "xpath";

export const ARRAY_SEPARATOR = "~~";
export const OBJECT_SEPARATOR = "@@";
export const STRING_SEPARATOR = "^^";

export class Util {
  public static cleanXml(xml: xpath.SelectedValue[]) {
    const str = xml.toString();
    return str.replace(/(\r\n|\n|\r)/gm, " ").trim();
  }

  /**
   * Return true if document is S1000D Norme
   *
   * @param xmlnodes
   */
  public static checkS1000D(xmlnodes): boolean {
    // we redefine the select function of xpath to access the namespace xsi
    const select = xpath.useNamespaces({
      xsi: "http://www.w3.org/2001/XMLSchema-instance"
    });
    const xsi = select("string(/dmodule/@xsi:noNamespaceSchemaLocation)", xmlnodes);
    // we should have http://www.s1000d.org/ on all S1000D xml document
    return xsi.includes("ipd.xsd") || xsi.includes("s1000d.org");
  }

  /**
   * Return true if document is IPC S1000D Norme
   *
   * @param xmlnodes
   */
  public static checkPartsS1000D(xmlnodes: Node): boolean {
    // we redefine the select function of xpath to access the namespace xsi
    const select = xpath.useNamespaces({
      xsi: "http://www.w3.org/2001/XMLSchema-instance"
    });
    const xsi = select("string(/dmodule/@xsi:noNamespaceSchemaLocation)", xmlnodes);
    return xsi.includes("ipd.xsd");
  }
}
