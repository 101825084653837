import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

interface AircraftType {
  webTekId: number;
  webTekLabel: string;
  versions: Map<string, AircraftVersion>; // map: orionVersionLabel -> AircraftType
}

interface AircraftVersion {
  webTekId: number;
  webTekLabel: string;
}

export interface WebTekAircraftId {
  typeId: number;
  typeLabel: string;
  versionId?: number;
  versionLabel?: string;
}

@Injectable()
export class WebTekIdsService {
  public confAircraft: Promise<Map<string, AircraftType>> = undefined;
  public confAtas: Promise<Map<string, number>> = undefined;

  constructor(private http: HttpClient) {}

  /**
   * Fetch the CSV file containing the configuration of the WebTek aircraft Ids mapping (only once) and create the conf object from it
   *
   * @returns The singleton promise containing the conf
   */
  getAircraftConf(): Promise<Map<string, AircraftType>> {
    return (
      this.confAircraft ||
      (this.confAircraft = new Promise((_resolve, _reject) => {
        this.http
          .get("./assets/webtek-conf/aircrafts.csv", { responseType: "text" })
          .toPromise()
          .then((data: string) => {
            const confMap = new Map<string, AircraftType>();
            data.split(/\r\n|\n/).forEach(row => {
              // row = [ OrionTypeLabel ; WebTekTypeId ; WebTekTypeLabel ; OrionVersionLabel ; WebTekVersionId ; WebTekVersionLabel ]
              const aircraftData = row.split(",");
              // We verify first that all the fields exists (i.e. the line is well formatted) otherwise we skip it
              if (aircraftData.length !== 6) {
                return;
              }

              let aircraft: AircraftType = confMap.get(aircraftData[0]);
              // If we don't know this type of aircraft we add it to the confMap
              if (!aircraft) {
                aircraft = {
                  versions: new Map<string, AircraftVersion>(),
                  webTekId: Number.parseInt(aircraftData[1], 10),
                  webTekLabel: aircraftData[2]
                };
                confMap.set(aircraftData[0], aircraft);
              }

              // We add the new version of the aircraft to the versions list of the type entry in conf
              aircraft.versions.set(aircraftData[3], {
                webTekId: Number.parseInt(aircraftData[4], 10),
                webTekLabel: aircraftData[5]
              });
            });

            // Finally we set the conf fetch as completed by resolving the map
            _resolve(confMap);
          })
          .catch(err => {
            console.error("Can't get aircraft configuration file", err);
            // Fallback to an empty configuration
            _resolve(new Map<string, AircraftType>());
          });
      }))
    );
  }

  /**
   * Get the WebTek ids from the conf of the given aircrafts
   *
   * @param type {string} - The type of the aircrafts
   * @param versions - An array (default empty) containing a set of versions of the given type (if empty no versions are returned)
   * @returns An array of data containing the found mappings
   */
  getWebTekAircraftIds(type: string, versions: string[] = []): Promise<WebTekAircraftId[]> {
    return this.getAircraftConf().then((conf: Map<string, AircraftType>) => {
      const aircraft = conf.get(type.toUpperCase());
      if (!aircraft || isNaN(aircraft.webTekId)) {
        return [];
      }
      const ids: WebTekAircraftId[] = [];

      // We go through all the available versions to find the corresponding ids
      aircraft.versions.forEach((version, label, _) => {
        if (versions.includes(label)) {
          ids.push({
            typeId: aircraft.webTekId,
            typeLabel: aircraft.webTekLabel,
            versionId: isNaN(version.webTekId) ? undefined : version.webTekId,
            versionLabel: version.webTekLabel === "" ? undefined : version.webTekLabel
          });
        }
      });

      // If no corresponding version were found, we fallback only with the type
      if (ids.length === 0) {
        ids.push({
          typeId: aircraft.webTekId,
          typeLabel: aircraft.webTekLabel
        });
      }
      return ids;
    });
  }

  /**
   * Fetch the json file containing the configuration of the WebTek chapter Atas Ids mapping (only once) and create the conf object from it
   *
   * @returns The singleton promise containing the map conf (Map<AtaLabel,WebTekAtaId>)
   */
  getAtasChapterConf(): Promise<Map<string, number>> {
    return (
      this.confAtas ||
      (this.confAtas = new Promise((_resolve, _reject) => {
        this.http
          .get("./assets/webtek-conf/atas.json")
          .toPromise()

          // Format of the conf : {id = 'WebTekId', label, code = 'AtaCode' } []
          .then((data: { id: number; label: string; code: string }[]) => {
            const conf = new Map<string, number>();
            data.forEach(({ id, code }) => {
              conf.set(code, id);
            });
            _resolve(conf);
          })
          .catch(err => {
            console.error("Can't get atas configuration file", err);
            // Fallback to an empty configuration
            _resolve(new Map<string, number>());
          });
      }))
    );
  }

  /**
   * Return the WebTek id as a promise given a chapter Ata code
   *
   * @param ata {string} - The chapter Ata code (ex: '64')
   */
  getWebTekAtaChapterId(ata: string): Promise<number> {
    return this.getAtasChapterConf().then((conf: Map<string, number>) => {
      const ataId = conf.get(ata);
      // If the ata is invalid (i.e. isn't in conf) we fallback in 'General' chapter '00'
      return ataId !== undefined ? ataId : conf.get("00");
    });
  }
}
