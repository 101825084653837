import { Pipe, PipeTransform } from "@angular/core";
import { PubDoc } from "libs/models/couch.models";

interface ProductRevisions {
  lastRev: PubDoc;
  oldRevs?: PubDoc[];
}

@Pipe({ name: "groupProducts" })
export class GroupProductsPipe implements PipeTransform {
  public transform(products: PubDoc[] = []): ProductRevisions[] {
    const revisionsMap = new Map<string, ProductRevisions>();

    products.forEach((product: PubDoc) => {
      if (revisionsMap.has(product.occurrenceCode)) {
        revisionsMap.get(product.occurrenceCode).oldRevs.push(product);
      } else {
        revisionsMap.set(product.occurrenceCode, { lastRev: product, oldRevs: [] });
      }
    });

    return Array.from(revisionsMap.values());
  }
}
