import { Directive, Input, OnInit } from "@angular/core";
import { MatSelectSearchComponent } from "ngx-mat-select-search";

/**
 * This directive is applied to every instances of MatSelectSearchComponent.
 * It will update the inner offset of the component in order to take into account
 * whether a default map-option is used in the template.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "ngx-mat-select-search"
})
export class MatSelectSearchDirective implements OnInit {
  @Input() hasDefaultOption = false;

  constructor(private searchComponent: MatSelectSearchComponent) {}

  ngOnInit() {
    // We need to override getOptionsLengthOffset that is private in MatSelectSearchComponent
    this.searchComponent["getOptionsLengthOffset"] = () => {
      if (this.hasDefaultOption && this.searchComponent.matOption) {
        return 2;
      } else if (this.hasDefaultOption || this.searchComponent.matOption) {
        return 1;
      } else {
        return 0;
      }
    };
  }
}
