import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { HomeRoute } from "@viewer/home/models";
import { Subject } from "rxjs";

@Injectable()
export class SearchContractsService {
  public filterReset = new Subject();
  public selectedFilters: Params;

  constructor(private router: Router) {}

  public searchContracts(searchForm?: Params): void {
    if (!searchForm) {
      searchForm = { lastConsulted: true };
    }

    const queryParams = {};
    Object.entries(searchForm)
      .filter(([_, value]: [string, string | Date]) => value)
      .forEach(([key, value]: [string, string | Date]) => {
        if (value instanceof Date) {
          value = value.toISOString();
        }
        queryParams[key] = value;
      });

    this.router.navigate([HomeRoute.CONTRACTS], { queryParams });
  }

  public removeFilter(category: string): void {
    this.filterReset.next(category);
  }
}
