import { PdfComponent } from "@viewer/pdf/pdf.component";
import { NotesButtonModule } from "@viewer/notes-button/notes-button.module";
import { NgModule } from "@angular/core";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer"; // <-- Import PdfJsViewerModule module

@NgModule({
  imports: [NotesButtonModule, PdfJsViewerModule],
  declarations: [PdfComponent],
  exports: [PdfComponent]
})
export class PdfModule {}
