import { Inject, Injectable } from "@angular/core";
import { CordovaService } from "libs/cordova/cordova.service";
import { AppEnvironnment } from "libs/http/abstractHttp.service";

export enum Charset {
  CSV_UTF8 = "data:text/csv;charset=utf-8",
  CSV_ANSI = "data:text/csv;charset=windows-1252"
}

export interface FileCordova extends Omit<File, "name"> {
  localURL: string;
  name: Blob[];
}

@Injectable()
export class DownloadFileService {
  constructor(
    @Inject("envFile") private environment: AppEnvironnment,
    @Inject("downloadZip") private downloadZip: Function,
    private cordovaService: CordovaService
  ) {}

  /**
   * Create and download a file from a content string or File
   */
  public download(
    files: string | File | File[],
    zipFilename?: string,
    charset?: Charset
  ): Promise<void> {
    if (typeof files === "string") {
      if (charset === Charset.CSV_ANSI) {
        // We use Byte Order Mark (BOM) character to enforce ANSI encoding.
        // Without it, Excel still does not recognize the encoding properly.
        files = "\uFEFF" + files;
      }
      return Promise.resolve(this.processDownload(files, zipFilename, charset));
    } else if (Array.isArray(files) && files.length > 1) {
      return this.downloadZip(
        files.map((file: File | FileCordova) =>
          "localURL" in file
            ? {
                name: file.localURL,
                input: new Blob([...file.name])
              }
            : file
        )
      )
        .blob()
        .then((blob: Blob) => this.processDownload(blob, `${zipFilename}.zip`));
    } else {
      const file = Array.isArray(files) ? files[0] : files;
      return Promise.resolve(this.processDownload(file));
    }
  }

  public downloadAsZip(file: File, zipFilename: string): Promise<void> {
    return this.downloadZip([
      {
        name: file.name,
        input: new Blob([file], { type: "application/zip" })
      }
    ])
      .blob()
      .then((blob: Blob) => this.processDownload(blob, `${zipFilename}.zip`));
  }

  private processDownload(
    content: File | Blob | string,
    filename = (content as File).name,
    charset?: Charset
  ): void {
    if (this.environment.platform === "cordova") {
      // With the plugin cordova-plugin-file the File class is overridden
      // See for more information : https://github.com/apache/cordova-plugin-file/issues/453
      filename = content instanceof File ? (content as unknown as FileCordova).localURL : filename;
      content = content instanceof File ? new Blob([...content.name]) : content;
      // We don't need to wait the end of this promise
      this.cordovaService.downloadFile(filename, content);
    } else {
      const link = document.createElement("a");
      link.download = filename;
      link.href = charset
        ? // SPEC: Here we use encodeURIComponent because we can have some characters which can brake the href (for example #)
          `${charset},${encodeURIComponent(content as string)}`
        : window.URL.createObjectURL(content as File | Blob);
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }
}
