import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store, PouchService } from "@viewer/core";
import { reaction, runInAction } from "mobx";
import { LangService } from "@viewer/core/lang/lang.service";
import { SettingsService } from "@viewer/core/toc-items/settings.service";

@Injectable()
export class HomeResolver {
  constructor(
    private store: Store,
    private pouchService: PouchService,
    private langService: LangService,
    private settingsService: SettingsService
  ) {}

  public resolve(_route: ActivatedRouteSnapshot): Promise<void> {
    // We don't initialize obsApplicableMd5 and search index when we quit a publication
    runInAction(() => {
      this.store.searchInput = undefined;
      this.store.setOfflineSearchNotReady();
      this.store.isFilteringAvailable = undefined;
      this.store.publicationID = undefined;
      this.store.pubInfo = undefined;
    });

    // When we returning to the store we want to ensure to get remote document if needed beceause store.pubInfo can be already defined and caller are not reset
    if (this.store.pouchReady) {
      this.pouchService.pubCaller.resetIsOffline();
      this.pouchService.orionTocPublicCaller.resetIsOffline();
      return this.store.isLoggedIn ? this.settingsService.getSettings() : Promise.resolve();
    }

    return this.pouchService
      .init()
      .then(() =>
        Promise.all([
          this.langService.getLang(),
          this.pouchService.userCaller.getUserActivate3D(),
          this.pouchService.userCaller.getUserItemsPerPage(),
          this.pouchService.userCaller.getUserRedirectLastDm()
        ])
      )
      .then(
        ([lang, userActivate3D, itemsPerPage, redirectLastDm]: [
          string,
          boolean,
          number,
          boolean
        ]) => {
          this.langService.setLang(lang);
          this.store.userActivate3D = userActivate3D;
          this.store.userItemsPerPage = itemsPerPage;
          this.store.redirectLastDm = redirectLastDm;

          reaction(
            () => this.store.isLoggedIn,
            () => this.settingsService.getSettings(),
            { fireImmediately: true }
          );
        }
      );
  }
}
