import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "@viewer/app.module";
import { environment } from "@viewer-env/environment";
import posthog from "posthog-js";

if (environment.production) {
  enableProdMode();
}

if (environment.postHog.project_api_key && environment.postHog.api_host) {
  posthog.init(environment.postHog.project_api_key, {
    api_host: environment.postHog.api_host
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
