import { AppConf } from "libs/models/conf.models";

export class ConfUtils {
  public static getEnvFilePath(appName: string): string {
    let fileEnv = process.env.ENV_FILE;

    if (process.env.NODE_ENV === "development" && !fileEnv) {
      fileEnv = `${process.cwd()}/apps/${appName}/env/dev.env`;
    }

    return fileEnv;
  }

  public static getRedirectUri(uri: string | string[]): string[] {
    return typeof uri === "string" ? [uri] : uri;
  }

  public static loadConfWithEnv(
    configuration: AppConf,
    processEnv: { [key: string]: string | number } = process.env
  ): void {
    Object.keys(configuration).forEach((key: string) => {
      if (typeof configuration[key] === "string") {
        const regex = /\${(\w*)+}/g;

        let match = regex.exec(configuration[key]);
        const envToReplace: string[] = [];
        while (match != null) {
          envToReplace.push(match[1]);
          match = regex.exec(configuration[key]); // find next match
        }

        // Replace all enviroment variable by the value
        envToReplace?.forEach((env: string) => {
          const value = configuration[key].replace(`\$\{${env}\}`, processEnv[env] || "");

          if (!isNaN(value) && value !== "") {
            configuration[key] = +value;
          } else if (/^(true|false)$/.test(value)) {
            configuration[key] = value === "true";
          } else {
            configuration[key] = value;
          }
        });
      } else if (typeof configuration[key] === "object") {
        ConfUtils.loadConfWithEnv(configuration[key], processEnv);
      }
    });
  }
}
