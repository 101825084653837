import { Injectable, Injector } from "@angular/core";
import { ConfService } from "@viewer/core/conf/conf.service";
import { WebWorkerService } from "@viewer/core/web-worker/web-worker.service";
import { runInAction } from "mobx";
import { CallerManager } from "@viewer/core/pouchdb/core/callerManager";
import { DatabaseConfObject } from "@viewer/core/pouchdb/types";

/**
 * Service to interact with database for the current publication
 * Will be the link between the dbprocessor and angular
 *
 * @export
 * @class PouchService
 */
@Injectable()
export class PouchService extends CallerManager {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly SearchSuccessMessage = "Search is available offline";
  private _databaseConf: DatabaseConfObject;

  constructor(
    private confService: ConfService,
    private workerService: WebWorkerService,
    protected injector: Injector
  ) {
    super(injector, confService);
  }

  get databaseConf() {
    return this._databaseConf;
  }

  /**
   * Create the dbProcessor and associate the publication
   * Will read data from userDBs to use user preference
   *
   * @memberof PouchService
   */
  public init(): Promise<boolean> {
    runInAction(() => {
      this.store.pouchReady = false;
    });

    this._databaseConf = this.confService.conf.database as DatabaseConfObject;
    this._databaseConf.cordova = this.cordovaData();
    // Becareful, not creating remoteDB, will lead to a rework of OrionDBs see replicationStrategy for example.
    this.setProcessor(this._databaseConf, this.workerService);

    runInAction(() => {
      this.store.pouchReady = true;
    });

    return Promise.resolve(true);
  }

  public loadLocalURL(): Promise<boolean> {
    return this.userCaller
      .couchURI()
      .then(url => {
        if (url && url.value !== this.confService.conf.database.couchBaseUrl) {
          this.confService.conf.database.couchBaseUrl = url.value;
          return true;
        }
        return false;
      })
      .catch(() => {
        console.warn("PouchService: local couchdbURL not found");
        return false;
      });
  }

  public loadUseCache(): Promise<boolean> {
    return this.userCaller
      .useCache()
      .then(document => {
        if (document && document.value !== this.confService.conf.database.useCache) {
          this.confService.conf.database.useCache = document.value;
          return true;
        }
        return false;
      })
      .catch(() => {
        console.warn("PouchService: local app setting useCache not found for user");
        return false;
      });
  }

  /**
   * Alter use cache value from dbconf depending on user preference
   * TODO WINDOW is not available everywhere => check
   */
  cordovaData() {
    return !!window["cordova"] && !!window["sqlitePlugin"];
  }

  /**
   * Disabled cache on all instanciate Dbs
   *
   * @returns
   * @memberof PouchService
   */
  disableCacheAll() {
    return this.applyOnAll("disabledCache");
  }

  /**
   * Empty all Dbs (destroy)
   *
   * @private
   * @returns
   * @memberof PouchService
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public clearAllLocal(): Promise<any[]> {
    return this.applyOnAll("emptyLocal");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updatePouchURI(uri: string): Promise<any> {
    runInAction(() => {
      this.store.pouchReady = false;
    });
    this.confService.conf.database.couchBaseUrl = uri;
    this._databaseConf = this.confService.conf.database as DatabaseConfObject;
    return this.userCaller
      .setCouchURI(uri)
      .then(() => this.setProcessor(this._databaseConf, this.workerService))
      .then(() => {
        if (this.store.pubInfo) {
          return this.switchPublication(this.store.pubInfo);
        } else {
          return this.forceReset();
        }
      });
  }

  /**
   * Give the replication status
   */
  public replicationsStatus() {
    return this.applyOnAll("info");
  }

  /**
   * Enabled cache for all Dbs
   *
   * @returns
   * @memberof PouchService
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enableCacheAll(): Promise<any[]> {
    return this.applyOnAll("enableCache");
  }
}
