import { MessageService } from "@orion2/message-service/message.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { Injectable, NgZone } from "@angular/core";
@Injectable()
export class ViewerMessageService extends MessageService {
  constructor(snackBar: MatSnackBar, router: Router, ngZone: NgZone) {
    super(snackBar, router, ngZone);
  }
}
