/**
 * Represents the OakResponse as a JSON Object.
 */
export interface OakResponseJson {
  oak: string;
  expireIn?: number /* lifetime in seconds. */;
  issued_at?: number /* when was it issued ? */;
  deprecated?: boolean /* true when role API is KO */;
}

/**
 * Represents the OakError as a JSON Object.
 */
export interface OakErrorJson {
  error: "unknown";
  error_description?: string;
  error_uri?: string;
}

// constants
const OAK_EXPIRY_BUFFER = 10 * 60; // 10 mins in seconds

/**
 * Returns the instant of time in seconds.
 */
export const nowInSeconds = () => Math.round(new Date().getTime() / 1000);

/**
 * Represents the Oak Response type
 */
export class OakResponse {
  oak: string;
  expiresIn: number | undefined;
  issuedAt: number;
  accessToken: string;
  deprecated: boolean;

  // In case of error, code and message are set.
  code: number;
  message: string;

  constructor(response: OakResponseJson, accessToken: string) {
    this.oak = response.oak;
    this.expiresIn = response.expireIn;
    this.issuedAt = response.issued_at || nowInSeconds();
    this.accessToken = accessToken;
    this.deprecated = response.deprecated ?? false;
  }

  toJson(): OakResponseJson {
    return {
      oak: this.oak,
      issued_at: this.issuedAt,
      expireIn: this.expiresIn,
      deprecated: this.deprecated
    };
  }

  isValid(buffer: number = OAK_EXPIRY_BUFFER): boolean {
    if (this.expiresIn) {
      const now = nowInSeconds();
      return now < this.issuedAt + this.expiresIn + buffer;
    } else {
      return true;
    }
  }
}

/**
 * Represents the Oak Error type.
 */
export class OakError {
  error: "unknown";
  errorDescription: string | undefined;
  errorUri: string | undefined;

  constructor(oakError: OakErrorJson) {
    this.error = oakError.error;
    this.errorDescription = oakError.error_description;
    this.errorUri = oakError.error_uri;
  }

  toJson(): OakErrorJson {
    return {
      error: this.error,
      error_description: this.errorDescription,
      error_uri: this.errorUri
    };
  }
}
