import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { TocCaller } from "@viewer/core/pouchdb/caller/tocCaller";

/**
 * A Toc Caller implementation forcing the caller to connect to the local database if the user is not logged in.
 *
 * @export
 * @class localTocCaller
 * @extends {TocCaller}
 */
export class LocalTocCaller extends TocCaller {
  protected override getRealTarget(target: DBConnexionType): DBConnexionType {
    return this.store.isLoggedIn ? target : DBConnexionType.LOCAL;
  }
}
