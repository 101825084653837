import { Injectable } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { TocItem } from "@orion2/models/tocitem.models";
import { Observable } from "rxjs";
import { getIncrementalPackageId } from "@orion2/utils/functions.utils";
import { PubDoc } from "@orion2/models/couch.models";
import { TocItemUtil } from "@orion2/utils/toc-items.utils";
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class TocItemLocalStorageService extends TocItemService {
  public pub: PubDoc;

  public get tocItemsOfType(): Observable<TocItem[]> {
    // If a component ask for tocItemOfTypeSubject, we know it's
    // time to call getItemsOfType to initialize _tocItemsForTarget
    this.getLocalItemsOfType();
    return this.tocItemOfTypeSubject.asObservable();
  }

  public refreshLocal(): void {
    if (this.tiType) {
      this.getLocalItemsOfType();
      this.getLocalItemsForTarget(this.store.currentDMC);
    }
  }

  public getLocalItemsOfType(type = this.tiType): TocItem[] {
    this._tocItemsOfType = [];

    for (let i = 0; i < localStorage.length; ++i) {
      // Start with samAccountName__packageId__type__
      if (localStorage.key(i).startsWith(this.getDocPrefix(type))) {
        const docs = JSON.parse(localStorage.getItem(localStorage.key(i)));
        this._tocItemsOfType.push(docs);
      }
    }

    this._tocItemsOfType = TocItemUtil.filter(
      this._tocItemsOfType,
      this.tiType,
      this.store.pubInfo?.revision || this.pub.revision
    );

    this.updateTocItemOfTypeToNext();
    return this._tocItemsOfType;
  }

  public getLocalItemsForTarget(targetId: string, type = this.tiType): TocItem[] {
    if (!this._tocItemsForTarget) {
      this._tocItemsForTarget = [];
    }

    for (let i = 0; i < localStorage.length; ++i) {
      // start with packageId__type__targetId
      if (localStorage.key(i).startsWith(this.getDocPrefix(type) + targetId)) {
        const docs = JSON.parse(localStorage.getItem(localStorage.key(i)));
        this._tocItemsForTarget.push(docs);
      }
    }

    this._tocItemsForTarget = TocItemUtil.filter(
      this._tocItemsForTarget,
      this.tiType,
      this.store.pubInfo?.revision || this.pub.revision
    );

    this.updateTocItemForTargetToNext();
    return this._tocItemsForTarget;
  }

  public saveLocal(tocItem: TocItem): boolean {
    const oldTocItem = JSON.parse(localStorage.getItem(tocItem._id)) as TocItem;

    if (!tocItem.date) {
      tocItem.date = new Date();
      tocItem.lastUpdate = tocItem.date;
    } else {
      tocItem.lastUpdate = new Date();
    }

    if (oldTocItem && oldTocItem.minRevision < this.store.publicationRevision) {
      oldTocItem.maxRevision = this.store.publicationRevision;
      tocItem.minRevision = this.store.publicationRevision;

      const tmp = tocItem._id.split("__");
      tmp[tmp.length - 1] = uuidv4();
      tocItem._id = tmp.join("__");

      this.removeFromCache(oldTocItem);
      localStorage.setItem(oldTocItem._id, JSON.stringify(oldTocItem));
    } else {
      this.removeFromCache(tocItem);
    }

    localStorage.setItem(tocItem._id, JSON.stringify(tocItem));
    this.addToCache(tocItem, true);

    return true;
  }

  // Incremental system can be init and use during the synchro for example
  public deleteLocal(doc: TocItem): boolean {
    if (doc) {
      if (doc.minRevision === this.store.publicationRevision) {
        localStorage.removeItem(doc._id);
      } else {
        doc.maxRevision = this.store.publicationRevision;
        this.saveLocal(doc);
      }
    }

    this.removeFromCache(doc);
    return true;
  }

  protected getDocPrefix(type = this.tiType) {
    const packageId = getIncrementalPackageId(
      this.store.pubInfo?.pubSchema.defaultPrefix || this.pub.pubSchema.defaultPrefix
    );

    return `${this.store.user.samAccountName}__${packageId}__${type}__`;
  }

  protected preSync(): Promise<void> {
    // Get all localStorage docs
    return Promise.all(
      this.getLocalItemsOfType().map((lsTi: TocItem) => {
        // Remove localStorage prefix
        const id = lsTi._id.replace(this.getDocPrefix(), "");
        // Save doc in localDB
        return this.save({ ...lsTi, _id: id });
      })
    ).then(() => {});
  }

  protected postSync(): Promise<void> {
    // Reset cache to retrieve docs from localDB
    this.resetCache(false);
    // Get all localDB docs
    return this.getItemsOfType()
      .then((ldbTiList: TocItem[]) =>
        ldbTiList.map((ldbTi: TocItem) => {
          // Add localStorage prefix
          const id = `${this.getDocPrefix()}${ldbTi._id}`;
          // Sav doc in localStorage
          return this.saveLocal({ ...ldbTi, _id: id });
        })
      )
      .then(() => this.refreshLocal());
  }
}
