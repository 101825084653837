import { runInAction } from "mobx";
import { Injectable } from "@angular/core";
import { DEFAULT_ORION_SETTINGS } from "@orion2/utils/constants.utils";
import { TocItemService } from "@viewer/core";
import {
  Settings,
  SettingsCategory,
  SettingsObject,
  WebtekSettings,
  HistorySettings,
  NoteSettings,
  SyncSettings,
  AircraftMappingSettings,
  PlayerSettings,
  DataCollectionSettings
} from "@orion2/models/settings.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";

@Injectable()
export class SettingsService extends TocItemService {
  protected tiType = "settings";
  protected tiScope = "app";
  protected tiTarget = DBConnexionType.BOTH;
  private settingsObject: SettingsObject = {};

  /**
   * Get the Settings docs from DB and set the related variables in store.
   */
  public getSettings(): Promise<void> {
    return this.getItemsOfType(this.tiType, this.tiScope).then((settings: Settings[]) => {
      settings.forEach((settingsDoc: Settings) => {
        this.settingsObject[settingsDoc.category] = settingsDoc;
      });
      runInAction(() => {
        this.store.webtekSettings = this.getValue<WebtekSettings>(SettingsCategory.WEBTEK);
        this.store.historySettings = this.getValue<HistorySettings>(SettingsCategory.HISTORY);
        this.store.noteSettings = this.getValue<NoteSettings>(SettingsCategory.NOTES);
        this.store.playerSettings = this.getValue<PlayerSettings>(SettingsCategory.PLAYER);
        this.store.syncSettings = this.getValue<SyncSettings>(SettingsCategory.SYNC);
        this.store.dataCollectionSettings = this.getValue<DataCollectionSettings>(
          SettingsCategory.DATA_COLLECTION
        );
        this.store.aircraftMappingSettings = this.getValue<AircraftMappingSettings>(
          SettingsCategory.AIRCRAFT_MAPPING
        );
      });
    });
  }

  private getValue<T extends Settings>(category: SettingsCategory): T {
    return (this.settingsObject[category] || DEFAULT_ORION_SETTINGS[category]) as T;
  }
}
