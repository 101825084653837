import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "@orion2/message-service/message.service";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";

@Injectable()
export abstract class AbstractBasepubService {
  protected abstract apiPath: string;

  constructor(
    protected messageService: MessageService,
    protected translate: TranslateService,
    protected degradedStateHandler: DegradedStateService
  ) {}

  protected whithErrorHandling<T>(
    stream: () => Promise<T>,
    valueIfError: T = null,
    rethrowError = false
  ): Promise<T> {
    return stream().catch(error => {
      this.sendErrorMessage(error);
      if (rethrowError) {
        throw error;
      }
      return valueIfError;
    });
  }

  /**
   * Log error message and send it in snackbar.
   */
  private sendErrorMessage(err: HttpErrorResponse | Error): void {
    if (!this.degradedStateHandler.isDegraded) {
      console.error("[Basepub]:", err);
      if ("status" in err && err["status"] >= 400 && err["status"] < 500) {
        this.messageService.error(this.translate.instant("basepub.message.error.available"));
      } else {
        this.messageService.error(this.translate.instant("basepub.message.error.service"));
      }
    }
  }
}
