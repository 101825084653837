// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
}
@media only screen and (max-width: 900px) {
  :host {
    width: 95%;
  }
}
:host o-search-bar {
  display: block;
  margin-top: 10px;
}
:host .header-sticky {
  padding-bottom: 5px;
  z-index: 2;
}
:host a {
  min-width: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhvbWUtdGFicy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGtCQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxXQUFBO0FBREY7QUFHRTtFQVBGO0lBUUksVUFBQTtFQUFGO0FBQ0Y7QUFFRTtFQUNFLGNBQUE7RUFDQSxnQkFBQTtBQUFKO0FBR0U7RUFDRSxtQkFBQTtFQUNBLFVBQUE7QUFESjtBQUlFO0VBQ0UsWUFBQTtBQUZKIiwiZmlsZSI6ImhvbWUtdGFicy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJsaWJzL3RoZW1lL29yaW9uLXRoZW1lXCI7XG5cbjpob3N0IHtcbiAgYWxpZ24tc2VsZjogY2VudGVyO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBtYXgtd2lkdGg6IDkwMHB4O1xuICB3aWR0aDogMTAwJTtcblxuICBAbWVkaWEgb25seSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDkwMHB4KSB7XG4gICAgd2lkdGg6IDk1JTtcbiAgfVxuXG4gIG8tc2VhcmNoLWJhciB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgbWFyZ2luLXRvcDogMTBweDtcbiAgfVxuXG4gIC5oZWFkZXItc3RpY2t5IHtcbiAgICBwYWRkaW5nLWJvdHRvbTogNXB4O1xuICAgIHotaW5kZXg6IDI7XG4gIH1cblxuICBhIHtcbiAgICBtaW4td2lkdGg6IDA7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/home/home-tabs/home-tabs.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;AADF;AAGE;EAPF;IAQI,UAAA;EAAF;AACF;AAEE;EACE,cAAA;EACA,gBAAA;AAAJ;AAGE;EACE,mBAAA;EACA,UAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AACA,o+BAAo+B","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  max-width: 900px;\n  width: 100%;\n\n  @media only screen and (max-width: 900px) {\n    width: 95%;\n  }\n\n  o-search-bar {\n    display: block;\n    margin-top: 10px;\n  }\n\n  .header-sticky {\n    padding-bottom: 5px;\n    z-index: 2;\n  }\n\n  a {\n    min-width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
