/**
 * Represents a OakRequest as JSON.
 */
export interface OakRequestJson {
  bearer: OakRequestBearerJson;
  oak?: string;
  occCode?: string;
}

export interface OakRequestBearerJson {
  access_token: string;
}

/**
 * Represents the OakRequest.
 */
export class OakRequest {
  bearer: OakRequestBearerJson;
  oak?: string;
  occCode?: string;

  constructor(request: OakRequestJson) {
    this.bearer = request.bearer;
    this.oak = request.oak;
    this.occCode = request.occCode;
  }

  /**
   * Serializes the OakRequest to a JavaScript Object.
   */
  toJson(): OakRequestJson {
    return {
      bearer: { access_token: this.bearer.access_token },
      oak: this.oak,
      occCode: this.occCode
    };
  }
}
