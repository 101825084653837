import { DbsFactory } from "@viewer/core/pouchdb/core/dbsFactory";
import { ProcessorGateway, DatabaseConfObject, DbQuery } from "@viewer/core/pouchdb/types";
import { PubDoc } from "libs/models/couch.models";

/**
 * Contain all instance of pouchdb and will run all query
 * Can be execute in thread/browser
 *
 *
 * @export
 * @abstract
 * @class PouchTargetable
 */

// Every call are async due to worker message
export class DbProcessor implements ProcessorGateway {
  public publicationId: string;
  public publicationRevision: string;
  private _dbFactory: DbsFactory;

  // worker need dataconf to create factory but can't have it in constructor
  initializeFactory(databaseConf: DatabaseConfObject): Promise<void> {
    this._dbFactory = new DbsFactory(databaseConf);
    return Promise.resolve();
  }

  public switchPublication(pubInfo: PubDoc): Promise<boolean> {
    if (pubInfo) {
      this.publicationId = pubInfo.packageId;
      this.publicationRevision = pubInfo.revision;
    }
    // This forces dbFactory to recreate the dbs with the new pub params
    return this._dbFactory.resetCacheMap();
  }

  getPublicationInfo(): { id: string; revision: string } {
    return {
      id: this.publicationId,
      revision: this.publicationRevision
    };
  }

  /**
   * Process request
   *
   * @param dbQuery
   * @returns
   * @memberof PouchTargetable
   */
  public exec(dbQuery: DbQuery) {
    const { suffix, prefix } = dbQuery.dbSchema;

    if (!this._dbFactory) {
      throw new Error("Factory is not set please initialise it ");
    }
    const db = this._dbFactory.getDBs(prefix, suffix, dbQuery.dbParams);

    db.updateOfflineStatus(dbQuery.dbParams.isOffline);

    if (!db[dbQuery.query]) {
      throw new Error(`${dbQuery.query} do not exist`);
    }

    if (!Array.isArray(dbQuery.params)) {
      dbQuery.params = [dbQuery.params];
    }

    // TODO: Find in params the object with all callback function,
    // for the moment we just replace the first element (for replicateRemote)
    if (dbQuery.callbacksName) {
      dbQuery.params[0] = this.createCustomCallbacks(dbQuery.callbacksName, dbQuery.isWorker);
    }

    return db[dbQuery.query](...dbQuery.params, dbQuery.targetDb);
  }

  private createCustomCallbacks(
    callbacksName: string[],
    isWorker: boolean
  ): { [key: string]: Function } {
    const res = {};

    callbacksName.forEach((name: string, id: number) => {
      res[name] = value => {
        const payload = {
          id,
          value,
          db: name
        };
        if (!isWorker) {
          const event = new CustomEvent("message", {
            detail: {
              message: `DbProcessor.callback.${name}`,
              payload
            }
          });
          dispatchEvent(event);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          postMessage({
            message: `WorkerProcessorGateway.callback.${name}`,
            origin: "OrionDBs",
            payload
          });
        }
      };
    });

    return res;
  }
}
