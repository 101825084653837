import { Injectable } from "@angular/core";
import { Pm2 } from "tools/electron/on-prem/helpers/pm2.models";

/**
 * This service is a mock other than electron environment in order
 * to not include electron dependencies (like fs), the real service will be
 * added with file replacement (see: angular.json from electron)
 *
 * @export
 * @class ElectronService
 */
@Injectable({
  providedIn: "root"
})
export class ElectronService {
  public get ipcRenderer() {
    return {};
  }

  public isInstalled(): boolean {
    return true;
  }

  public getRootPath(): string {
    return "";
  }

  public getLogsDir(): string {
    return this.getRootPath() + "/logs";
  }

  public getFilePath(file: string): string {
    return "";
  }

  public fileExist(pathFile: string): boolean {
    return true;
  }

  public readFile(pathFile: string): Promise<string> {
    return Promise.resolve("");
  }

  public writeFile(path: string, data: string): void {}

  public getAppConf(app_key: string): Promise<Object> {
    return Promise.resolve({});
  }

  public getApps(): void {}

  public startApp(appKey: string): void {}

  public startApps(processList: Pm2.List): void {}

  public stopApp(appKey: string, pid: number): void {}

  public stopApps(): void {}

  public restartApp(appKey: string, pid: number): void {}

  public registerOnPremListener(event: string, callback: Function): void {}

  public removeOnPremListeners(event: string): void {}

  public initGetProcessComplete(callback: Function): void {}

  public initGetProcessError(callback: Function): void {}

  public initStopAllProcessError(callback: Function): void {}

  public initStartAllProcessError(callback: Function): void {}

  public print(): Promise<void> {
    return Promise.resolve();
  }
}
