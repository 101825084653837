import { PromisesRepository } from "libs/stream/promises-repository.service";

export abstract class RequestHandler<T> {
  abstract get key(): string;

  get promise(): Promise<T> {
    return PromisesRepository.getPromise(this.key);
  }

  constructor() {
    this.resetPromise();
  }

  resetPromise() {
    PromisesRepository.create(this.key);
  }

  protected resolvePromise(response: T) {
    PromisesRepository.resolve(this.key, response);
  }

  protected rejectPromise(error: Error | unknown) {
    PromisesRepository.reject(this.key, error);
  }
}
