import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { Bookmark, TocItem } from "@orion2/models/tocitem.models";
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class BookmarkService extends TocItemService {
  public currentEditedBookmark: Bookmark;

  protected tiType = "bookmarks";
  protected tiScope = "private";

  constructor(injector: Injector) {
    super(injector);
  }

  public get currentDMC(): string {
    return this.router.url.split("/du")[1].split("/")[1];
  }

  public get currentDMCTitle(): string {
    return this.store.currentTocNode.shortTitle || this.store.currentTocNode.title;
  }

  /**
   * Create and save bookmarks
   *
   * @param {number} nbBookkmark
   * @param {string} title
   * @param {string} dmc optional
   * @returns {Promise<Bookmark[]>}
   * @memberof BookmarkService
   */
  public addBookmarks(
    nbBookkmark: number,
    title: string,
    dmc: string = undefined
  ): Promise<Bookmark[]> {
    const bookmarks: Bookmark[] = [];
    dmc = this.store.currentTocNode?._id || dmc;
    for (let i = 0; i < nbBookkmark; i++) {
      const bookmark: Bookmark = {
        _id: `bookmarks_${uuidv4()}__${this.store.publicationRevision}`,
        dmc,
        title,
        type: "bookmarks",
        minRevision: this.store.publicationRevision,
        date: new Date(),
        author: this.store.user.userName
      };
      bookmarks.push(bookmark);
    }
    return this.bulk(bookmarks).then(() => bookmarks);
  }

  public async getBookmarks(): Promise<Bookmark[]> {
    return this.getItemsOfType(this.tiType) as unknown as Bookmark[];
  }

  public update(
    oldBookmark: TocItem,
    newBookmark: TocItem,
    oldScope = this.tiScope,
    newScope = this.tiScope,
    forceUpdateCache = true
  ): Promise<boolean> {
    return super.update(oldBookmark, newBookmark, oldScope, newScope, forceUpdateCache);
  }

  /**
   * Get bookmark by id
   * TODO DG get by ID ... no need to get all
   *
   * @param id
   * @returns
   * @memberof BookmarkService
   */
  public getBookmarkById(id: string): Promise<Bookmark> {
    return this.getBookmarks().then(docs => docs.find(x => x._id === id));
  }

  /**
   * Check if a bookmark corresponding to the current URL
   * already exists in local db.
   *
   * @param dmc optional
   * @returns {Promise<boolean>}
   * @memberof BookmarkService
   */
  public isBookmarked(dmc: string = this.currentDMC): Promise<boolean> {
    return this.getBookmarksByDMC(dmc).then((bookmarks: Bookmark[]) => !!bookmarks.length);
  }

  public getBookmarksByDMC(dmc: string = this.currentDMC): Promise<Bookmark[]> {
    return this.getBookmarks().then((bookmarks: Bookmark[]) =>
      bookmarks.filter((bookmark: Bookmark) => bookmark.dmc === dmc)
    );
  }

  public deleteBookmarkById(id: string): Promise<boolean> {
    return this.getBookmarkById(id).then((doc: Bookmark) => this.delete(doc));
  }
}
