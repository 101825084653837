import xpath from "xpath";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import { ARRAY_SEPARATOR, OBJECT_SEPARATOR } from "@orion2/msm-formatter/util";

export class TaskInfoChange {
  public static extractLegacy(taskXml: Node, xmlNode: Node): string {
    const data: string[] = [];
    const title = xpath.select("string(TITLE/@CHANGEMARK)", taskXml).length > 0;

    const limitType = xpath.select("string(MAINLIMI/LIMIT/@CHANGEMARK)", taskXml).length > 0;

    const from = xpath.select("string(dmodule/WP6Status//@CHANGEMARK)", xmlNode).length > 0;

    const task = xpath.select("./@CHANGEMARK", taskXml).length > 0;

    data.push(
      title + OBJECT_SEPARATOR + limitType + OBJECT_SEPARATOR + from + OBJECT_SEPARATOR + task
    );
    return data.join(ARRAY_SEPARATOR);
  }

  public static extractH160(taskXml: Node, xmlNode: Node): string {
    const data: string[] = [];
    const title =
      xpath.select(
        "string(timeLimit/@changeMark | timeLimit/remarks/@changeMark | timeLimit/remarks/simplePara[1]/@changeMark | task/@changeMark | task/taskTitle/@changeMark)",
        taskXml
      ).length > 0;

    const limitType = xpath.select("string(timeLimit/limitType/@changeMark)", taskXml).length > 0;

    const from = xpath.select("string(dmodule/WP6Status//@changeMark)", xmlNode).length > 0;

    const task = xpath.select("./@changeMark", taskXml).length > 0;

    data.push(
      title + OBJECT_SEPARATOR + limitType + OBJECT_SEPARATOR + from + OBJECT_SEPARATOR + task
    );
    return data.join(ARRAY_SEPARATOR);
  }

  public static unserialize(data: string): TaskNamespace.TaskInfoChanged {
    if (data !== undefined) {
      const val = data.split(OBJECT_SEPARATOR);
      return {
        title: val[0] === "true",
        limitType: val[1] === "true",
        from: val[2] === "true",
        task: val[3] === "true"
      };
    }
  }
}
