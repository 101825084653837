import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ParseRevisionPipe } from "@orion2/utils/ParseRevisionPipe";

@NgModule({
  imports: [CommonModule],
  declarations: [ParseRevisionPipe],
  exports: [ParseRevisionPipe]
})
export class ParseRevisionPipeModule {}
