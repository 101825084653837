import { Injectable } from "@angular/core";
import { PromiseWorker } from "promise-worker";
import { workerProcessor } from "apps/viewer/src/web-workers/worker-index/index.worker.processor";
import { WorkerProcessorGateway } from "@viewer/core/pouchdb/core/workerProcessorGateway";
import { environment } from "@viewer-env/environment";
import { MainDbProcessor } from "@viewer/core/pouchdb/core/mainDbProcessor";

@Injectable()
export class WebWorkerService {
  public canUseWorker: boolean;
  private dbWorkerProcessor: PromiseWorker;
  constructor() {
    this.canUseWorker = typeof Worker !== "undefined" && environment.useWorker;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDbProcessor(_isCordova, _electronConf): any {
    // cordova sqlite plugin does not work well on worker
    const workerWithPouch = this.canUseWorker;
    if (workerWithPouch) {
      if (this.dbWorkerProcessor) {
        this.dbWorkerProcessor._worker.terminate();
      }
      const pouchDBWorker = new Worker(
        new URL("@web-workers/worker-pouchdb/pouchdb.worker.bundle.js")
      );
      this.dbWorkerProcessor = new PromiseWorker(pouchDBWorker);
      return new WorkerProcessorGateway(this.dbWorkerProcessor);
    }
    return new MainDbProcessor();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buildIndex(docs: any) {
    if (this.canUseWorker) {
      const indexWorker = new Worker(new URL("@web-workers/worker-index/index.worker.bundle.js"));
      const promiseWorker = new PromiseWorker(indexWorker);
      try {
        // Warning : can't put array, will be empty on worker side
        return promiseWorker.postMessage(docs);
      } catch (e) {
        console.error(e);
      }
      indexWorker.terminate();
    } else {
      return Promise.resolve(workerProcessor(docs));
    }
  }
}
