import { BehaviorSubject } from "rxjs";
import { DataSource } from "@angular/cdk/table";
import { intersection } from "lodash";
import { SearchResult } from "@viewer/core/search/searchModel";
import { getStatus } from "@orion2/utils/functions.utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class SearchResultsDataSource extends DataSource<any> {
  results = new BehaviorSubject<SearchResult[]>([]);
  _rawResults: SearchResult[] = [];
  _filteredResults: SearchResult[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _applicableResults: any;
  _selectedUniverse = "documents";
  _selectedIds;
  _searchMetaData: SearchResult[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _paginator: any;

  get count() {
    return this._filteredResults.length;
  }

  get supersededCount(): number {
    return this._rawResults.filter(res => res.from === "superseded").length;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connect(): BehaviorSubject<any[]> {
    return this.results;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPaginator(paginator: any): void {
    this._paginator = paginator;
  }

  disconnect(): void {
    this.results.unsubscribe();
    this.results = new BehaviorSubject<SearchResult[]>([]);
    this._rawResults = [];
    this._filteredResults = [];
  }

  updateMetaData(metaData: SearchResult[] = []) {
    // We need to update the revision (status in the search filter) for the document to have only the requested possibilities
    metaData.forEach(data => {
      if (!data.task || data.task.length === 0) {
        data.revision = getStatus(data.revision);
      }
    });
    this._searchMetaData = metaData;
  }

  updateResults(results: SearchResult[]) {
    if (this._rawResults !== results) {
      this._rawResults = results;
      this._filteredResults = results;
      this.resetPaginator();
    }
  }

  filterByIds(ids: number | number[], searchInput: string, universe = "documents") {
    this._selectedUniverse = universe;
    const metaDatas = this.getUniverseMetasdatas();
    if (universe === "task" && !searchInput) {
      metaDatas.sort((data1, data2) => (data1.task[0] < data2.task[0] ? -1 : 1));
    }

    if (universe === "part" && !searchInput) {
      metaDatas.sort((data1, data2) => data1.part[0].localeCompare(data2.part[0]));
    }

    const idsFlat = metaDatas.map(r => r.id);
    if ((ids as number) === -1) {
      this._selectedIds = idsFlat;
      this._filteredResults = metaDatas;
      this.resetPaginator();
      return;
    }
    this._selectedIds = ids;
    if (this._searchMetaData) {
      // idsFlat is sorted by score
      // as idsFlat is first arg of intersection, res is also sorted by score
      const intersectionIds = intersection(idsFlat, ids as number[]);
      const filteredResults = intersectionIds.map(x => this._searchMetaData[x]);
      this._filteredResults = filteredResults;
    } else {
      console.error("you should have used updateMetaData before using this.");
    }
    this.resetPaginator();
  }

  public resetPaginator(): void {
    if (this._paginator) {
      this._paginator.firstPage();
      this.paginate(this._paginator.pageIndex, this._paginator.pageSize);
    }
  }

  public getUniverseMetasdatas() {
    switch (this._selectedUniverse) {
      case "task":
        return this._rawResults.filter(metaData => metaData.task?.length);
      case "ipc":
        return this._rawResults.filter(metaData => metaData.from === "superseded");
      case "part":
        return this._rawResults.filter(metaData => metaData.part?.length);
      // documents universe
      default:
        return this._rawResults.filter(
          metaData => !metaData.task?.length && !metaData.part?.length
        );
    }
  }

  paginate(index, size) {
    this.results.next(this._filteredResults.slice(index * size, index * size + size));
  }
}
