// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  max-height: 90vh;
  position: relative;
}

.loading-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.loading-container mat-progress-spinner {
  display: flex;
  margin: 0 auto;
}

.dialog-action {
  display: flex;
  justify-content: flex-end;
}
.dialog-action mat-icon {
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm11bHRpbGlua3MuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRSxjQUFBO0VBQ0EsZ0JBQUE7RUFDQSxrQkFBQTtBQURGOztBQUlBO0VBQ0UsbUJBQUE7RUFDQSxhQUFBO0VBQ0EsWUFBQTtFQUNBLHVCQUFBO0VBQ0EsV0FBQTtBQURGO0FBR0U7RUFDRSxhQUFBO0VBQ0EsY0FBQTtBQURKOztBQUtBO0VBQ0UsYUFBQTtFQUNBLHlCQUFBO0FBRkY7QUFJRTtFQUNFLGVBQUE7QUFGSiIsImZpbGUiOiJtdWx0aWxpbmtzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcImxpYnMvdGhlbWUvb3Jpb24tdGhlbWVcIjtcblxuOmhvc3Qge1xuICBkaXNwbGF5OiBibG9jaztcbiAgbWF4LWhlaWdodDogOTB2aDtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xufVxuXG4ubG9hZGluZy1jb250YWluZXIge1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBkaXNwbGF5OiBmbGV4O1xuICBoZWlnaHQ6IDEwMCU7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICB3aWR0aDogMTAwJTtcblxuICBtYXQtcHJvZ3Jlc3Mtc3Bpbm5lciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBtYXJnaW46IDAgYXV0bztcbiAgfVxufVxuXG4uZGlhbG9nLWFjdGlvbiB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7XG5cbiAgbWF0LWljb24ge1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/shared-module/multilinks/multilinks.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;AADF;AAGE;EACE,aAAA;EACA,cAAA;AADJ;;AAKA;EACE,aAAA;EACA,yBAAA;AAFF;AAIE;EACE,eAAA;AAFJ;AACA,giCAAgiC","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  display: block;\n  max-height: 90vh;\n  position: relative;\n}\n\n.loading-container {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n\n  mat-progress-spinner {\n    display: flex;\n    margin: 0 auto;\n  }\n}\n\n.dialog-action {\n  display: flex;\n  justify-content: flex-end;\n\n  mat-icon {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
