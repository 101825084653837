import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "@orion2/message-service/message.service";
import { PubDoc } from "@orion2/models/couch.models";
import { HomeRoute } from "@viewer/home/models";
import { ProductsService } from "@viewer/home/service/products.service";

@Injectable()
export class HomeRedirectResolver {
  constructor(
    private productsService: ProductsService,
    private messageService: MessageService,
    private translate: TranslateService,
    private router: Router
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Promise<PubDoc[]> {
    if (!route.queryParams.search) {
      this.router.navigateByUrl(HomeRoute.LOCAL);
      return Promise.resolve([]);
    }

    return this.productsService.getOnlineProducts().then((products: PubDoc[]) => {
      products = this.filterProducts(products, route.queryParams);
      this.checkForRedirect(products, route.queryParams.search);
      return products;
    });
  }

  private filterProducts(products: PubDoc[], params: Params): PubDoc[] {
    if (params.model) {
      const modelsToFilter = new Set(JSON.parse(params.model));
      // SPEC: the links to models = "MBB-BK117" must match with pub's model = "BK117" and reciprocally
      // https://gitlab.altengroup.net/tma/airbus/orion/orion2/orion2/-/issues/2625
      if (modelsToFilter.has("MBB-BK117") || modelsToFilter.has("BK117")) {
        modelsToFilter.add("MBB-BK117");
        modelsToFilter.add("BK117");
      }
      products = products.filter((pub: PubDoc) => modelsToFilter.has(pub.model));
    }

    if (params.version) {
      const versionsToFilter: string[] = JSON.parse(params.version);
      products = products.filter((pub: PubDoc) =>
        versionsToFilter.some((version: string) => pub.versions?.includes(version))
      );
    }

    return products;
  }

  private checkForRedirect(products: PubDoc[], search: string): void {
    const uniqueOccCodes = new Set(products.map((pub: PubDoc) => pub.occurrenceCode));

    switch (uniqueOccCodes.size) {
      case 0:
        this.messageService.error(
          this.translate.instant("home.redirect.filter.error"),
          undefined,
          10000
        );
        break;
      case 1:
        // SPEC: If there is only one publication, we automatically open it
        this.router.navigate([
          "pub",
          products[0].packageId,
          products[0].revision,
          "redirect",
          search
        ]);
        break;
      default:
        break;
    }
  }
}
