import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PubLangPipe } from "@viewer/core/lang/pubLang.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [PubLangPipe],
  exports: [PubLangPipe]
})
export class PubLangPipeModule {}
