export class BaseGateway {
  public publicationId: string;
  public publicationRevision: string;
  protected callbackMap: Map<string, Function> = new Map<string, Function>();

  getPublicationInfo(): { id: string; revision: string } {
    return {
      id: this.publicationId,
      revision: this.publicationRevision
    };
  }

  /**
   * Save all callbacks in the callbackMap in order to reused on the worker response
   *
   * @param callbacks
   * @protected
   */
  protected saveCallbacks(callbacks: { [key: string]: Function }): void {
    Object.keys(callbacks).forEach(key => {
      this.callbackMap.set(key, callbacks[key]);
    });
  }

  /**
   * Call a saved callback
   *
   * @param callbackName
   * @param value
   */
  protected callback(callbackName: string, value: unknown): Function {
    // callbackName is like "addressedFile.callback.success" why need to get success
    const callbackFn = this.callbackMap.get(callbackName.split(".")[2]);
    return callbackFn(value);
  }
}
