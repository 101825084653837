import { WebWorkerService } from "@viewer/core/web-worker/web-worker.service";
import {
  TocCaller,
  MediaCaller,
  SearchCaller,
  UserCaller,
  TocUserCaller,
  XmlCaller,
  ApplicCaller,
  PdfCaller,
  PubCaller,
  TocCorpCaller,
  TocPublicCaller,
  StatsCaller,
  OrionTocPublicCaller
} from "@viewer/core/pouchdb/caller";
import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";
import { Injector } from "@angular/core";
import { Store } from "@viewer/core/state/store";
import {
  PubSchema,
  DBAppSchemaEnum,
  DBPubSchemaEnum,
  DbSchema,
  createProfileInfo
} from "libs/transfert/model/pubSchema";
import { PubDoc } from "libs/models/couch.models";
import { ConfService } from "@viewer/core/conf/conf.service";
import { CallbacksFnInterface } from "@viewer/core/pouchdb/orionDBs";
import { DatabaseConfObject } from "@viewer/core/pouchdb/types";

/**
 * Represent Xml database (DMC and XSL)
 *
 * @export
 * @class XmlDBs
 * @extends {OrionDBs}
 */
export abstract class CallerManager {
  // ease manipulation
  protected processor;
  protected allDbNames;
  protected store: Store;
  protected pubSchema: PubSchema;

  private callerMap: CoreCaller[] = [];
  private batchSizeMap: number[] = [];

  constructor(protected injector: Injector, private conf: ConfService) {
    this.store = this.injector.get(Store);
  }

  /**
   * Return the curent TocCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get tocCaller(): TocCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.toc, TocCaller);
  }

  /**
   * Return the curent MediaCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get mediaCaller(): MediaCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.media, MediaCaller);
  }

  /**
   * Return the curent XmlCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get xmlCaller(): XmlCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.xml, XmlCaller);
  }

  /**
   * Return the curent TocCorpCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get tocCorpCaller(): TocCorpCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.toc_corp, TocCorpCaller);
  }

  /**
   * Return the curent TocPublicCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get tocPublicCaller(): TocPublicCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.toc_public, TocPublicCaller);
  }

  /**
   * Return the curent TocUserCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get tocUserCaller(): TocUserCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.toc_user, TocUserCaller);
  }

  /**
   * Return the curent UserCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get userCaller(): UserCaller {
    return this.getOrCreateCaller(DBAppSchemaEnum.user, UserCaller);
  }

  /**
   * Return the curent SearchCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get searchCaller(): SearchCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.search, SearchCaller);
  }

  /**
   * Return the curent ApplicCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get applicCaller(): ApplicCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.applic, ApplicCaller);
  }

  get pdfCaller(): PdfCaller {
    return this.getOrCreateCaller(DBPubSchemaEnum.pdf, PdfCaller);
  }

  /**
   * Return the curent PubCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get pubCaller(): PubCaller {
    return this.getOrCreateCaller(DBAppSchemaEnum.pub, PubCaller);
  }

  /**
   * Return the current StatsCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get statsCaller(): StatsCaller {
    return this.getOrCreateCaller(DBAppSchemaEnum.stats, StatsCaller);
  }

  /**
   * Return the current OrionTocPublicCaller for package or create it
   *
   * @returns
   * @memberof PouchService
   */
  get orionTocPublicCaller(): OrionTocPublicCaller {
    return this.getOrCreateCaller(DBAppSchemaEnum.orion_toc_public, OrionTocPublicCaller);
  }

  /**
   * Gets the processor, sets it locally and initiliaze its factory.
   */
  public setProcessor(dbConf: DatabaseConfObject, workerService: WebWorkerService) {
    this.resetCallers();
    this.batchSizeMap = [];
    this.allDbNames = this.getAllDbNames();
    this.processor = workerService.getDbProcessor(dbConf.cordova, dbConf.electron);

    this.processor.initializeFactory(dbConf);
  }

  resetCallers() {
    this.callerMap = [];
  }

  /*
   * Change the current publication ID
   */
  switchPublication(pubInfo: PubDoc): Promise<boolean> {
    if (pubInfo && pubInfo.pubSchema) {
      this.pubSchema = new PubSchema(pubInfo.pubSchema, {
        profileInfo: createProfileInfo(this.store.user.samAccountName, this.store.user.siebelId)
      });
      if (this.processor) {
        this.resetCallers();
        return this.processor.switchPublication(pubInfo);
      }
    } else {
      console.error("pubInfo schema is not defined.");
    }
    return Promise.resolve(false);
  }

  /**
   * Switch user for user related callers
   */
  switchUser() {
    this.resetUserRelatedCallers();
    if (this.store.pubInfo && this.store.pubInfo.pubSchema) {
      this.pubSchema = new PubSchema(this.store.pubInfo.pubSchema, {
        profileInfo: createProfileInfo(this.store.user.samAccountName, this.store.user.siebelId)
      });
    }
  }

  /**
   * Reset all callers related to user
   */
  resetUserRelatedCallers(): void {
    this.getAllDbNames()
      .filter(
        (dbName: string) =>
          dbName.toString().includes("pubs") ||
          dbName.toString().includes("user") ||
          dbName.toString().includes("corp")
      )
      .map((db: string) => (this.callerMap[db] = undefined));
  }

  forceReset(): Promise<boolean> {
    // If pubInfo is undefined you should be in dbStatus page we force database reset
    if (this.processor) {
      this.resetCallers();
      return this.processor.switchPublication(undefined);
    }

    return Promise.resolve(false);
  }

  getAllDbNames(): (DBPubSchemaEnum | DBAppSchemaEnum)[] {
    const dbAppSchema = Object.keys(DBAppSchemaEnum).map((name: string) => DBAppSchemaEnum[name]);

    const dbPubSchema = Object.keys(DBPubSchemaEnum).map((name: string) => DBPubSchemaEnum[name]);

    return [...dbAppSchema, ...dbPubSchema];
  }

  /**
   * Replicate all database
   * dev use
   *
   * @returns
   * @memberof PouchService
   */
  public replicateAll(callbacks: CallbacksFnInterface): Promise<void> {
    return Promise.all(
      this.getReplicableDBs().map((dbName: DBAppSchemaEnum | DBPubSchemaEnum) =>
        this.getCaller(dbName)["replicateRemote"](
          callbacks,
          this.getOptimizedBatchSizeLimit(dbName)
        )
      )
    ).then(() => {});
  }

  // We manage batchSize here to avoid passing pubInfo to every callers.
  public getOptimizedBatchSizeLimit(dbName: DBAppSchemaEnum | DBPubSchemaEnum) {
    const pubInfo = this.store.pubInfo;
    const db = `${pubInfo.pubSchema.defaultPrefix}_${dbName}`;

    if (!this.batchSizeMap[db]) {
      // batch_sizes is optimized so that each batch is approximatively 4 Mo.
      let batchVolume = 4000000;
      // It is possible to configure the batchSize for the pub or for specific database via pubschema
      if (pubInfo.pubSchema[dbName].batchVolume && pubInfo.pubSchema[dbName].batchVolume !== 0) {
        batchVolume = pubInfo.pubSchema[dbName].batchVolume;
      } else if (pubInfo.pubSchema.batchVolume && pubInfo.pubSchema.batchVolume !== 0) {
        batchVolume = pubInfo.pubSchema.batchVolume;
      }
      const disk = pubInfo.sizes[dbName] ? pubInfo.sizes[dbName].disk : 1;
      const dbs = pubInfo.dbs[dbName] ? pubInfo.dbs[dbName] : 1;
      const batchSize = batchVolume / (disk / dbs);
      this.batchSizeMap[db] = Math.floor(batchSize);
    }

    return this.batchSizeMap[db] || 1;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dbInformationAll(): Promise<any> {
    return this.applyOnCallers(this.getReplicableDBs(), "dbInformation");
  }

  public stopReplicateAll() {
    return this.applyOnCallers(this.getReplicableDBs(), "cancelReplication");
  }

  /**
   * User Db must no be replicate
   */
  public getReplicableDBs(): DBPubSchemaEnum[] {
    return this.pubSchema.getAllReplicableDbTypes();
  }

  public deleteCurrentPub(): Promise<boolean> {
    return Promise.all(
      this.getReplicableDBs().map((dbName: DBAppSchemaEnum | DBPubSchemaEnum) =>
        this.getCaller(dbName)?.["emptyLocal"]()
      )
    )
      .then(() => true)
      .catch((err: unknown) => {
        console.error("Error while deleting the publication", err);
        return false;
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deleteDbs(dbs: DBPubSchemaEnum[]): Promise<any> {
    return Promise.all(dbs.map((db: DBPubSchemaEnum) => this.getCaller(db)["emptyLocal"]())).catch(
      err => {
        console.error(`Error while deleting DBs: ${dbs} -`, err);
      }
    );
  }

  /*
   * Create or get the caller using its name
   */
  public getCaller(dbName: string): CoreCaller {
    // We transform the dbname with _ (like toc_public) to camelcase
    const dbNameFiltered = dbName
      .replace(/_(.)/g, a => a.toUpperCase())
      .replace(/_/g, "")
      .replace(/^(.)/, b => b.toLowerCase());

    return this[dbNameFiltered + "Caller"]; // calls this.tocCaller, etc.
  }

  /**
   * Apply function on all caller
   *
   * @param functionName
   */
  protected applyOnAll(functionName: string, params?: string) {
    const promiseArray = [];
    this.getReplicableDBs().forEach((key: DBAppSchemaEnum | DBPubSchemaEnum) => {
      const caller: CoreCaller = this.getCaller(key);
      if (!caller[functionName]) {
        throw new Error(functionName + " function not found on Caller");
      }
      promiseArray.push(caller[functionName](params));
    });
    return Promise.all(promiseArray);
  }

  /**
   * Apply function on given caller
   *
   * @param functionName
   */
  protected applyOnCallers(dbNames: string[], functionName: string, params?: string) {
    const promiseArray = [];
    dbNames.forEach((dbName: DBPubSchemaEnum | DBAppSchemaEnum) => {
      const caller = this.getCaller(dbName);
      if (!caller[functionName]) {
        throw new Error(functionName + " function not found on Caller");
      }
      promiseArray.push(caller[functionName](params));
    });
    return Promise.all(promiseArray);
  }

  private getOrCreateCaller(dbType: DBPubSchemaEnum | DBAppSchemaEnum, callerConstructor) {
    const dbSchema: DbSchema =
      (this.pubSchema && this.pubSchema.getDbSchema(dbType as DBPubSchemaEnum)) ||
      new DbSchema(this.conf.appSchema[dbType], {
        profileInfo: createProfileInfo(this.store.user.samAccountName, this.store.user.siebelId)
      }); // Pubs, User & Stats
    return (
      this.callerMap[dbType] ||
      (this.callerMap[dbType] = new callerConstructor(this.processor, this.injector, dbSchema))
    );
  }
}
