import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext
} from "@angular/core";
import { Store } from "@viewer/core/state/store";
import { Router } from "@angular/router";
import { PubDoc } from "libs/models/couch.models";
import { ViewerMessageService } from "@viewer/core";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import packageJSON from "@package-json";
import { PouchService } from "@viewer/core/pouchdb/pouch.service";
import { getDisplayedRevision } from "@orion2/utils/front.utils";
import { HomeRoute } from "@viewer/home/models";
import { HistoricService } from "@viewer/core/toc-items/historic.service";

@Component({
  selector: "o-card-pub",
  templateUrl: "./card-pub.component.html",
  styleUrls: ["./card-pub.component.scss"]
})
export class CardPubComponent implements OnInit, OnDestroy {
  @Input() pub: PubDoc;
  @Input() cardNavigate: boolean;
  @Input() isLight = false;
  @Input() searchInPub: string;
  @Input() isShowProductDescription = false;

  public imgUrl: SafeResourceUrl;
  public requiredAppVersion = "";
  public versionRequired = false;
  public displayedRevision: string;

  constructor(
    public store: Store,
    private router: Router,
    private messageService: ViewerMessageService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private pouchService: PouchService,
    private cd: ChangeDetectorRef,
    private historicService: HistoricService
  ) {}

  ngOnInit() {
    if (this.pub) {
      this.initCardLabel();
    }

    if (!this.isLight) {
      this.pouchService.pubCaller.getJacket(this.pub).then((data: Blob | string) => {
        const imgUrl = typeof data === "string" ? data : URL.createObjectURL(data);
        this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imgUrl);
        this.cd.detectChanges();
      });
    }
  }

  ngOnDestroy() {
    const imgUrlUnsafe = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.imgUrl);
    window.URL.revokeObjectURL(imgUrlUnsafe);
  }

  /**
   * Handle click on a publication card.
   */
  public switchPublication(): Promise<boolean> {
    if (!this.pub.isConsultable) {
      // SPEC: For ORION1 products, we open the consultUrl in a new tab
      if (this.pub.consultUrl) {
        window.open(this.pub.consultUrl);
        return Promise.resolve(true);
      }

      // SPEC: If pub is not consultable, we don't do anything
      return Promise.resolve(false);
    }

    // SPEC: Special behavior for user_manual.pdf
    if (this.pub.packageId === "user_manual") {
      return this.router.navigate([HomeRoute.EXTRADOC, "user_manual.pdf"]);
    }

    // SPEC: We send a warning message and cancel navigation if the publication
    // is incremental and the current app version is anterior to incrementale update
    if (this.versionRequired) {
      const msg = this.translate.instant("app.needUpdate", {
        requiredAppVersion: this.requiredAppVersion
      });
      this.messageService.warning(msg, undefined, 4000);
      return Promise.resolve(false);
    }

    // SPEC: We send a warning message and cancel navigation if the publication
    // is currently being downloaded
    if (!this.cardNavigate) {
      this.messageService.warning(
        `${this.translate.instant("publist.warning.dowload")}`,
        undefined,
        4000
      );
      return Promise.resolve(false);
    }

    // SPEC: When we click on the pub card in the pub list, go to the publication.
    // It's important to note that this.store.publicationID is set inside pub-access.resolve
    // and that, unless this.store.publicationID != undefined, setting this.store.publicationID
    // launches the retrieval of applicabilities.
    let route = this.searchInPub ? ["redirect", this.searchInPub] : [];

    // Redirect the user to the last consulted datamodule
    if (!this.searchInPub && this.store.redirectLastDm && this.pub.capabilities.historic) {
      const historic = this.historicService.getLastConsulted(this.pub);

      if (historic?.dmc) {
        route = ["du", historic.dmc];
      }
    }

    return this.router.navigate(["pub", this.pub.packageId, this.pub.revision, ...route]);
  }

  private initCardLabel(): void {
    this.displayedRevision = getDisplayedRevision(this.pub);
    if (this.pub.pubSchema) {
      this.requiredAppVersion = this.pub.minRelease || packageJSON.version;
      this.versionRequired = packageJSON.version.localeCompare(this.requiredAppVersion) < 0;
    }
  }
}
