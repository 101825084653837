// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 95%;
}
:host .search-message {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhvbWUtcmVkaXJlY3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRSxrQkFBQTtFQUNBLGFBQUE7RUFDQSxzQkFBQTtFQUNBLGdCQUFBO0VBQ0EsVUFBQTtBQURGO0FBR0U7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxnQkFBQTtBQURKIiwiZmlsZSI6ImhvbWUtcmVkaXJlY3QuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwibGlicy90aGVtZS9vcmlvbi10aGVtZVwiO1xuXG46aG9zdCB7XG4gIGFsaWduLXNlbGY6IGNlbnRlcjtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgbWF4LXdpZHRoOiA5MDBweDtcbiAgd2lkdGg6IDk1JTtcblxuICAuc2VhcmNoLW1lc3NhZ2Uge1xuICAgIGZvbnQtc2l6ZTogMjBweDtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICBtYXJnaW46IDIwcHggNXB4O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/home/home-redirect/home-redirect.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,UAAA;AADF;AAGE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AACA,4sBAA4sB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  max-width: 900px;\n  width: 95%;\n\n  .search-message {\n    font-size: 20px;\n    font-weight: bold;\n    margin: 20px 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
