import { Injectable } from "@angular/core";
import { Charset, DownloadFileService } from "libs/download/download-file.service";

export interface CsvData {
  mapping: Object;
  data: Object[];
}

@Injectable()
export class CsvService {
  readonly endOfLine = "\r\n";

  constructor(private downloadService: DownloadFileService) {}

  /**
   * Convert a CsvData or an array of array of string to a csv content
   * Then download it to the filesystem
   *
   * @param filename without prefix .csv
   * @param data
   * @param [delimiter=";"]
   * @memberof CsvService
   */
  public download(filename: string, data: CsvData | string[][], delimiter = ";"): void {
    const content =
      "mapping" in data
        ? this.createContent(data, delimiter)
        : this.createContentFromArray(data, delimiter);
    this.downloadService.download(
      content.join(this.endOfLine),
      filename + ".csv",
      Charset.CSV_ANSI
    );
  }

  /**
   * Convert an array of array of string to a csv string
   *
   * @private
   * @param data
   * @param delimiter
   * @returns
   * @memberof CsvService
   */
  public createContentFromArray(data: string[][], delimiter = ";"): string[] {
    return data.map((row: string[]) => row.join(delimiter));
  }

  /**
   * Convert an value to a string
   * If value is Nan, undefined or null, return ""
   * If value is object, return the serialized json representation of the object
   * Else, return the string représentation of the data
   * @private
   * @param value
   * @returns
   * @memberof CsvService
   */
  private convertToString(value: unknown): string {
    if (!value && typeof value !== "boolean") {
      return "";
    }
    return typeof value === "object" ? JSON.stringify(value) : `${value}`;
  }

  /**
   * Create CSV string from CsvData
   *
   * @private
   * @param data
   * @param delimiter
   * @returns
   * @memberof CsvService
   */
  private createContent(csvData: CsvData, delimiter: string): string[] {
    const mappingObj = csvData.mapping;
    const header = [];
    const row = [];

    csvData.data.forEach(obj => {
      const content = [];
      Object.keys(mappingObj).forEach(key => {
        if (mappingObj[key] !== undefined) {
          const headerTitle = mappingObj[key] !== "" ? mappingObj[key] : key;
          const capitalizeHeader = headerTitle.trim().replace(/^\w/, c => c.toUpperCase());
          const headerIndex = header.findIndex(el => el === capitalizeHeader);

          if (headerIndex === -1) {
            header.push(capitalizeHeader);
          }

          content.push(this.convertToString(obj[key]));
        }
      });
      row.push(content.join(delimiter));
    });

    return [header.join(delimiter), ...row];
  }
}
