import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormatResultPipe } from "@viewer/search-result-module/search-result/format-result-pipe/formatResult-pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [FormatResultPipe],
  exports: [FormatResultPipe]
})
export class FormatResultPipeModule {}
