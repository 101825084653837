import { TocItemInterface } from "libs/models/tocitem.models";

export enum SettingsCategory {
  HISTORY = "history",
  NOTES = "notes",
  PLAYER = "player",
  SYNC = "sync",
  DATA_COLLECTION = "dataCollection",
  AIRCRAFT_MAPPING = "aircraftMapping",
  WEBTEK = "webtek"
}

// Polymorphic type for settings
// To be extended with new xxxSettings interface when we create one
export type Settings =
  | HistorySettings
  | WebtekSettings
  | NoteSettings
  | PlayerSettings
  | AircraftMappingSettings
  | SyncSettings;

export type SettingsObject = {
  [category in SettingsCategory]?: Settings;
};

// Base interface for settings
interface BasicSettings extends TocItemInterface {
  category: SettingsCategory;
}

export interface HistorySettings extends BasicSettings {
  maxLength?: number;
}

export interface NoteSettings extends BasicSettings {
  publicColor?: string;
  corporateColor?: string;
  privateColor?: string;
}

export interface PlayerSettings extends BasicSettings {
  browser?: boolean;
  electron?: boolean;
  cordova?: boolean;
}

export interface WebtekSettings extends BasicSettings {
  labelMapping?: {
    [manualLabel: string]: {
      eventTypeId: number;
      subEventTypeId?: number;
    };
  };
}

export interface AircraftMappingSettings extends BasicSettings {
  mapping?: AircraftMapping[];
}

export interface SyncSettings extends BasicSettings {
  enable?: boolean;
}

export interface DataCollectionSettings extends BasicSettings {
  enable?: boolean;
}

export interface AircraftMapping {
  id: string;
  businessModel: string;
  technicalModel: string;
  businessVersion: string;
  technicalVersion: string;
  isEdit?: boolean;
}
