import { TocItem } from "@orion2/models/tocitem.models";

export namespace TocItemUtil {
  export function filter(docs: TocItem[], type: string, revision: string): TocItem[] {
    if (!docs) {
      return [];
    }

    return docs
      .filter((d: TocItem) => isType(d, type))
      .filter((d: TocItem) => superiorThanMinRevision(d, revision))
      .filter((d: TocItem) => lessThanMaxRevision(d, revision));
  }

  export function isType(d: TocItem, type: string): boolean {
    return !type || d.type === type;
  }

  export function superiorThanMinRevision(d: TocItem, revision: string): boolean {
    return typeof d.minRevision === "undefined" || d.minRevision <= revision;
  }

  export function lessThanMaxRevision(d: TocItem, revision: string): boolean {
    const maxRev = d?.maxRevision?.match(/^\d{2}\.\d{2}(\.\d{2})?/)
      ? "0" + d.maxRevision
      : d.maxRevision;
    return typeof maxRev === "undefined" || maxRev > revision;
  }
}
