import { StatusCodes } from "http-status-codes";
import { HttpError } from "libs/http/errors/http-error.model";

export const DEGRADED_STATE_CODE = "DEGRADED_STATE";

export const DEGRADED_STATE_JSON_RESPONSE = {
  status: StatusCodes.INTERNAL_SERVER_ERROR,
  message: DEGRADED_STATE_CODE
};

export function isDegradedStateError(error: Error): boolean {
  return error.message === DEGRADED_STATE_CODE;
}

export class DegradedStateError extends HttpError {
  constructor() {
    super(
      StatusCodes.INTERNAL_SERVER_ERROR,
      DegradedStateError.name,
      "application is in degraded state"
    );
  }
}

export const DEGRADED_STATE_ERROR = new DegradedStateError();
