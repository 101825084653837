import { Component, NgZone, HostListener } from "@angular/core";
import { ConfService, LoggerService, BackgroundService, Store } from "@viewer/core";
import { Router } from "@angular/router";
import { DeepLinkingNavigate } from "libs/deep-linking/src";
import { PrintService } from "@viewer/core/print/print.service";
import { reaction, runInAction } from "mobx";
import { RoleService } from "@viewer/core/role/role.service";
import { GdprService } from "@viewer/core/gdpr/gdpr.service";
import { LoginModalComponent } from "libs/login-modal/login-modal.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { HomeRoute } from "@viewer/home/models";

@Component({
  selector: "o-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "Orion2";

  constructor(
    public confService: ConfService,
    // this forces the creation of the singleton
    // this is important because BackgroundService
    // declares a reaction to both publicationId and pouchdbReady
    // that launches the search index build.
    private backgroundService: BackgroundService,
    private logger: LoggerService,
    private router: Router,
    private zone: NgZone,
    private printService: PrintService,
    private store: Store,
    private gdprService: GdprService,
    private dialogRef: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private roleService: RoleService // add role service to set listener on login event
  ) {
    this.iconRegistry.addSvgIconResolver((iconName: string) =>
      this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/svg/${iconName}.svg`)
    );

    window.addEventListener("loginRequest", (event: CustomEvent<{ url: string }>) => {
      const loginDialogRef = this.dialogRef.open(LoginModalComponent, {
        panelClass: "login-dialog-container",
        data: {
          login_url: event.detail.url
        }
      });

      // To manage the click event on the stay logged in button (log out iframe).
      const clickStayLoggedInEventHandler = (clickStayLoggedInEvent: {
        preventDefault: () => void;
      }) => {
        clickStayLoggedInEvent.preventDefault();
        window.dispatchEvent(new CustomEvent("stay_logged_in"));
        window.dispatchEvent(new CustomEvent("authRequestEnd"));
      };

      loginDialogRef.afterOpened().subscribe(() => {
        const iframe = document.getElementById("auth_iframe") as HTMLIFrameElement;

        if (iframe) {
          const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

          const button = iframeDocument.querySelectorAll("button")[1];

          if (button) {
            button.addEventListener("click", clickStayLoggedInEventHandler);
            loginDialogRef.afterClosed().subscribe(() => {
              button.removeEventListener("click", clickStayLoggedInEventHandler);
            });
          }
        }
      });
    });

    window.addEventListener("login_status", (event: CustomEvent) => {
      runInAction(() => {
        this.store.isLoggedIn = event.detail.isLoggedIn;
      });
    });
    reaction(
      () => this.store.isLoggedIn,
      isLoggedIn => {
        if (!isLoggedIn) {
          roleService.login();
        }
      }
    );

    /**
     * This event is thrown by the auth service when we can't open the auth popup because of a blocker
     * The payload contain the targeted URL, it will redirect to the '/home/login' page with the target page
     * as query parameter.
     */
    window.addEventListener(
      "redirectLogin",
      (event: CustomEvent<{ redirectPath: string; redirectSearch: string }>) => {
        const path = (event.detail && event.detail.redirectPath) || "";
        const search = (event.detail && event.detail.redirectSearch) || "";
        if (path !== HomeRoute.LOGIN) {
          return this.router.navigateByUrl(
            `${HomeRoute.LOGIN}?redirect="${encodeURIComponent(path)}"&search="${encodeURIComponent(
              search
            )}"`,
            { replaceUrl: true }
          );
        }
      }
    );

    addEventListener("role_status", (event: CustomEvent) => {
      this.roleService.handleRoleStatus(event.detail.status);
    });

    // Check event for Deeplinking
    // If resume is call without deeplinking, IonicDeeplink plugin does not generate an error.

    // the init.js script block the initialization of app.component until deviceready is fire
    // so all cordova plugins are fully loaded
    // when we run DeepLinkingNavigate.deeplinking(this,zone),
    // IonicDeeplink.route will react on any call to orion deeplink (orion://)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).cordova) {
      DeepLinkingNavigate.deeplinking(this, this.zone);
    }
    // when we click on print button or cmd + p the store.inPrint = true ; @
    // we do this to have this behavior also when going to print mode from the debugger (Rendering);
    const mediaQueryPrint = window.matchMedia("print");
    if (mediaQueryPrint.matches) {
      runInAction(() => {
        this.store.inPrint = true;
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (mediaQueryPrint as any).addListener(mqp => {
      if (mqp.matches) {
        runInAction(() => {
          this.store.inPrint = true;
        });
      } else {
        runInAction(() => {
          this.store.inPrint = false;
        });
      }
    });
    this.setColorScheme(this.getPreferredColorScheme());
  }

  @HostListener("window:keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === "p") {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.printService.printFinal();
    }
  }

  setColorScheme(scheme: string): void {
    switch (scheme) {
      case "dark":
        this.store.darkMode = true;
        document.body.classList.remove("orion-light");
        document.body.classList.add("orion-dark");
        break;
      case "light":
        this.store.darkMode = false;
        document.body.classList.remove("orion-dark");
        document.body.classList.add("orion-light");
        break;
      default:
        // Default
        break;
    }
  }

  getPreferredColorScheme(): string {
    // If print, don't change theme
    if (window.matchMedia("print").matches) {
      return;
    }
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "dark";
    }
    if (window.matchMedia("(prefers-color-scheme: light)").matches) {
      return "light";
    }
  }
}
