import { Component, Input, QueryList, ViewChildren } from "@angular/core";
import { CardNewsComponent } from "@viewer/news/card-news/card-news.component";
import { News } from "@orion2/models/tocitem.models";

@Component({
  selector: "o-news-list",
  templateUrl: "./news-list.component.html",
  styleUrls: ["./news-list.component.scss"]
})
export class NewsListComponent {
  @ViewChildren(CardNewsComponent) cards: QueryList<CardNewsComponent>;
  @Input() newsList: News[];
  @Input() emptyLabel: string;
  public allExpanded = false;

  public expandAll(): void {
    this.allExpanded = true;
    this.cards.forEach((card: CardNewsComponent) => {
      card.expand();
    });
  }

  public reduceAll(): void {
    this.allExpanded = false;
    this.cards.forEach((card: CardNewsComponent) => {
      card.reduce();
    });
  }
}
