// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .loading-dialog-container {
  max-width: 100vw !important;
}

.loading-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.loading-modal o-loading ::ng-deep .loading-wrapper {
  position: unset;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYWRpbmctbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRSwyQkFBQTtBQURGOztBQUlBO0VBQ0UsbUJBQUE7RUFDQSxhQUFBO0VBQ0Esc0JBQUE7QUFERjtBQUlJO0VBQ0UsZUFBQTtBQUZOIiwiZmlsZSI6ImxvYWRpbmctbW9kYWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwibGlicy90aGVtZS9vcmlvbi10aGVtZVwiO1xuXG46Om5nLWRlZXAgLmxvYWRpbmctZGlhbG9nLWNvbnRhaW5lciB7XG4gIG1heC13aWR0aDogMTAwdncgIWltcG9ydGFudDtcbn1cblxuLmxvYWRpbmctbW9kYWwge1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuXG4gIG8tbG9hZGluZyB7XG4gICAgOjpuZy1kZWVwIC5sb2FkaW5nLXdyYXBwZXIge1xuICAgICAgcG9zaXRpb246IHVuc2V0O1xuICAgIH1cbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/loading-modal/loading-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,2BAAA;AADF;;AAIA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAII;EACE,eAAA;AAFN;AACA,ouBAAouB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n::ng-deep .loading-dialog-container {\n  max-width: 100vw !important;\n}\n\n.loading-modal {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n\n  o-loading {\n    ::ng-deep .loading-wrapper {\n      position: unset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
