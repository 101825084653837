import xpath from "xpath";

import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import { Description } from "@orion2/msm-formatter/description";
import { Util } from "@orion2/utils/datamodule.utils";
import { Margin } from "@orion2/msm-formatter/margin";
import { Interval } from "@orion2/msm-formatter/interval";
import { PnrData } from "@orion2/msm-formatter/pnrData";
import { Documentation } from "@orion2/msm-formatter/documentation";
import { PreliminaryRqmts } from "@orion2/msm-formatter/preliminaryRqmts";
import { Applic } from "@orion2/msm-formatter/applic";
import { Camo } from "@orion2/msm-formatter/camo";
import { TaskInfoChange } from "@orion2/msm-formatter/taskInfoChanged";
import { getStatus } from "@orion2/utils/functions.utils";
import { XmlDoc, TaskDoc } from "@orion2/models/couch.models";
import { MimeType } from "@orion2/models/enums";

export class MsmFormatter {
  public static convertMaintenanceModeUnit(unit: string): string {
    switch (unit) {
      case "lt01":
        return "TBO";
      case "lt04":
        return "OTL";
      case "lt05":
        return "OC";
      case "lt06":
        return "CHK";
      case "lt51":
        return "SLL";
      case "lt52":
        return "ED";
      case "lt02":
      case "lt03":
      case "lt07":
        return "N/A";
      default:
        return "";
    }
  }

  public static serializeFromXML(
    taskXml: Node,
    parentXml: Node
  ): { taskData: string[]; taskApplicMD5: string } {
    if (taskXml.nodeName === "MAINTASK") {
      return this.extractLegacy(taskXml, parentXml);
    } else if (taskXml.nodeName === "timeLimitInfo" || taskXml.nodeName === "taskDefinition") {
      return this.extractS1000D(taskXml, parentXml);
    } else {
      throw new Error("Unkown task");
    }
  }

  public static unserialize(data: string[], enrichDocumentation?: Function): TaskNamespace.MsmTask {
    return {
      id: data[0],
      title: data[1],
      description: Description.unserialize(data[2]),
      documentation: Documentation.unserialize(data[3], enrichDocumentation),
      mpn_pn: PnrData.unserialize(data[4]),
      limitType: data[5],
      interval: Interval.unserialize(data[6]),
      margin: Margin.unserialize(data[7]),
      versions: data[8],
      revision: data[9],
      parent: data[10],
      status: getStatus(data[11]),
      preliminary: PreliminaryRqmts.unserialize(data[12]),
      applic: Applic.unserialize(data[13]),
      modelIdentCode: data[14],
      issueDate: data[15],
      section: data[16],
      changed: TaskInfoChange.unserialize(data[17])
    };
  }

  public static toCamo(couchFcts: { start: Function; getRow: Function; send: Function }): void {
    couchFcts.start({
      code: 200,
      headers: {
        "Content-Type": MimeType.CSV
      }
    });

    let row;
    Camo.createHeaders(couchFcts.send);

    while ((row = couchFcts.getRow())) {
      const msmTask = MsmFormatter.unserialize(row["value"]);
      Camo.createLine(couchFcts, msmTask);
    }
  }

  public static buildIndex(doc: XmlDoc | TaskDoc, emit: Function) {
    // Only docs containing task is allowed here
    if (doc._id.indexOf("task__") === 0) {
      emit(doc.data[0], doc.data);
    }
  }

  private static extractLegacy(
    taskXml: Node,
    parentXml: Node
  ): { taskData: string[]; taskApplicMD5: string } {
    const id = Util.cleanXml(xpath.select("string(@IDENTASK)", taskXml));
    const title = Util.cleanXml(xpath.select("string(TITLE)", taskXml));
    const description = Description.extractLegacy(taskXml);
    const documentation = Documentation.extractLegacy(taskXml);
    const mpn_pn = PnrData.extractLegacy(taskXml);
    const limitType = Util.cleanXml(xpath.select("string(MAINLIMI/LIMIT/@TYPE)", taskXml));
    const interval = Interval.extractLegacy(taskXml);
    const margin = Margin.extractLegacy(taskXml);
    const assertVersions = xpath.select(
      'dmodule/identAndStatusSection/dmStatus/applic/evaluate/assert[@applicPropertyIdent="version"]',
      parentXml
    );

    const versions = [];
    assertVersions.forEach((assert: Node) => {
      const values = Util.cleanXml(xpath.select("string(@applicPropertyValues)", assert));
      versions.push(values);
    });

    const revision = Util.cleanXml(xpath.select("string(@REVDATE)", taskXml));

    let status = Util.cleanXml(xpath.select("string(@CHG)", taskXml));
    switch (status) {
      case "N":
        status = "new";
        break;
      case "R":
        status = "changed";
        break;
      case "U":
        status = "unchanged";
        break;
      default:
        status = undefined;
        break;
    }
    const parent = Util.cleanXml(xpath.select("string(dmodule/WP6Status/dmc)", parentXml));

    const changed = TaskInfoChange.extractLegacy(taskXml, parentXml);

    const modelIdentCode = Util.cleanXml(
      xpath.select(
        "string(dmodule/identAndStatusSection/dmAddress/dmIdent/dmCode/@modelIdentCode)",
        parentXml
      )
    );

    const issueDate = Util.cleanXml(
      xpath.select(
        // eslint-disable-next-line max-len
        "concat(dmodule/identAndStatusSection/dmAddress/dmAddressItems/issueDate/@year, '.', dmodule/identAndStatusSection/dmAddress/dmAddressItems/issueDate/@month, '.', dmodule/identAndStatusSection/dmAddress/dmAddressItems/issueDate/@day)",
        parentXml
      )
    );

    const section = Util.cleanXml(
      xpath.select("string(dmodule/content//SUBCH/SUBUD/@KEY)", parentXml)
    );

    return {
      taskData: [
        id,
        title,
        description,
        documentation,
        mpn_pn,
        limitType,
        interval,
        margin,
        versions.join(", "),
        revision,
        parent,
        status,
        "", // preliminaryRqmts
        "", // applic,
        modelIdentCode,
        issueDate,
        section,
        changed
      ],
      taskApplicMD5: ""
    };
  }

  private static extractS1000D(
    taskXml: Node,
    parentXml: Node
  ): { taskData: string[]; taskApplicMD5: string } {
    const model = xpath
      .select("string(//identAndStatusSection/dmAddress/dmIdent/dmCode/@modelIdentCode)", parentXml)
      .toString();
    const id = xpath.select("string(@timeLimitIdent | @id | @taskIdent)", taskXml).toString();
    const title = xpath
      .select("string(timeLimit/remarks/simplePara[1] | task/taskTitle)", taskXml)
      .toString();
    const applicMD5 = xpath.select("string(@applicability_md5)", taskXml).toString();
    const description = Description.extractH160(taskXml);
    const documentation = Documentation.extractH160(taskXml);
    const mpn_pn = PnrData.extractH160(taskXml);
    const limitType = xpath
      .select("string(../@limitUnitType|timeLimit/limitType/@limitUnitType)", taskXml)
      .toString();
    const interval = Interval.extractS1000D(taskXml, model);
    const margin = Margin.extractH160(taskXml);
    const versions = xpath
      .select("string(dmodule/WP6Status/WP6ApplicList/applic/@version)", parentXml)
      .toString();
    const revision = xpath
      .select("string(dmodule/identAndStatusSection/dmAddress/dmAddressItems/issueDate)", taskXml)
      .toString();
    const parent = xpath.select("string(dmodule/WP6Status/dmc)", parentXml).toString();
    const preliminary = PreliminaryRqmts.extractH160(taskXml);
    const applic = Applic.extractH160(taskXml);
    const changed = TaskInfoChange.extractH160(taskXml, parentXml);
    const status = xpath.select("string(../@issueType)", taskXml).toString();

    return {
      taskData: [
        id,
        title,
        description,
        documentation,
        mpn_pn,
        this.convertMaintenanceModeUnit(limitType),
        interval,
        margin,
        versions,
        revision,
        parent,
        status || "unchanged",
        preliminary,
        applic,
        "", // modelIdentCode
        "", // issueDate
        "", // section
        changed
      ],
      taskApplicMD5: applicMD5
    };
  }
}
