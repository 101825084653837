import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { TocPublicCaller } from "@viewer/core/pouchdb/caller";
import { DocumentDoc, TocItem } from "@orion2/models/tocitem.models";
import { reaction } from "mobx";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { Observable, filter, map } from "rxjs";

@Injectable()
export class DocumentService extends TocItemService {
  protected tiType = "document";
  protected tiScope = "public";
  protected tiTarget = DBConnexionType.BOTH;

  private documentMap = {};
  private documentList: Promise<TocItem[]>;

  constructor(injector: Injector) {
    super(injector);

    reaction(
      () => this.store.publicationID,
      () => {
        if (this.store.publicationID) {
          // we reset documentMap and documentList when we change publication
          this.documentMap = {};
          this.documentList = undefined;
        }
      }
    );
  }

  public get tocItemsOfType(): Observable<DocumentDoc[]> {
    return super.tocItemsOfType.pipe(
      filter(Boolean),
      map((documents: TocItem[]) => documents as DocumentDoc[])
    );
  }

  // If document.service is a singleton instantiated with the app
  // Even if document.service instantiate once when the document module
  // is loaded
  // then there's no need to fetch the document in db every time.
  public async getAllDocument(parent: string, all = false): Promise<DocumentDoc | DocumentDoc[]> {
    if (!this.documentList) {
      this.documentList = this.getItemsOfType(this.tiType, this.tiScope);
    }
    return this.documentList.then((docs: DocumentDoc[]) => {
      const filteredDocs = docs.filter(
        doc => doc.parents.find(p => p === parent) || doc._id === parent
      );
      return filteredDocs && filteredDocs.length
        ? all
          ? filteredDocs
          : filteredDocs[0]
        : undefined;
    });
  }

  public getDocumentPdf(pdfId: string): Promise<Blob> {
    return (this.getCaller(this.tiScope) as TocPublicCaller).getAttachmentsBlob(pdfId);
  }
}
