import { Component, OnInit, Inject } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";

@Component({
  selector: "o-ipc-preview",
  templateUrl: "./ipc-preview.component.html",
  styleUrls: ["./ipc-preview.component.scss"]
})
export class IpcPreviewComponent implements OnInit {
  links: Object[] = [];

  constructor(
    public dialogRef: MatDialogRef<IpcPreviewComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.links = this.data;
  }

  close() {
    this.dialogRef.close();
  }
}
