// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-right: 5px;
}
:host .mat-icon {
  height: 28px;
  width: 28px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhpc3Rvcnktc3dpdGNoLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsaUJBQUE7QUFDRjtBQUNFO0VBQ0UsWUFBQTtFQUNBLFdBQUE7QUFDSiIsImZpbGUiOiJoaXN0b3J5LXN3aXRjaC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgbWFyZ2luLXJpZ2h0OiA1cHg7XG5cbiAgLm1hdC1pY29uIHtcbiAgICBoZWlnaHQ6IDI4cHg7XG4gICAgd2lkdGg6IDI4cHg7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/history-switch/history-switch.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACA,obAAob","sourcesContent":[":host {\n  margin-right: 5px;\n\n  .mat-icon {\n    height: 28px;\n    width: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
