import { NgModule } from "@angular/core";
import { NavigationEnd, Router, RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "@viewer/layout/layout.component";
import { NotFoundComponent } from "@viewer/not-found/not-found.component";
import { PubReplicateResolver } from "@viewer/replication/resolver/pub-replicate.resolver";
import { HomeResolver } from "@viewer/home/resolver/home.resolver";
import { PubAccessResolver } from "@viewer/pub/resolve/pub-access.resolver";
import { ContentProviderComponent } from "@viewer/content-provider/content-provider.component";
import { PdfComponent } from "@viewer/pdf/pdf.component";
import { EmptyComponent } from "@viewer/core/empty/empty.component";
import { DisclaimerComponent } from "@viewer/help-module/disclaimer/disclaimer.component";
import { AuthGuard } from "@viewer/core/auth/auth.guard";
import { NoteCanDeactivateGuard } from "@viewer/toc-items/note-module/note/note-can-deactivate-guard.service";
import { environment } from "@viewer-env/environment";
import { ExtraDocComponent } from "@viewer/extra-doc/extra-doc.component";
import { AuthLoginComponent } from "@viewer/auth-login/auth-login.component";
import { SettingsFormComponent } from "@viewer/settings-form/settings-form.component";
import { RedirectComponent } from "@viewer/redirect/redirect.component";
import { PubLeaveGuard } from "@viewer/core/guards/pub-leave.guard";
import { LastConsultedGuard } from "@viewer/core/guards/last-consulted.guard";
import { PubReplicationComponent } from "@viewer/replication/pub-replication/pub-replication.component";
import { LayoutHomeComponent } from "@viewer/home/layout-home/layout-home.component";
import { HomeTabsComponent } from "@viewer/home/home-tabs/home-tabs.component";
import { LocalProductsComponent } from "@viewer/home/local-products/local-products.component";
import { ContractListComponent } from "@viewer/home/contract-list/contract-list.component";
import { OnlineProductsComponent } from "@viewer/home/online-products/online-products.component";
import { HomeRedirectComponent } from "@viewer/home/home-redirect/home-redirect.component";
import { HomeRedirectResolver } from "@viewer/home/resolver/home-redirect.resolver";
import posthog from "posthog-js";

const pubRoutes: Routes = [
  {
    path: "dbStatus",
    loadChildren: () => import("./dbs-status-module/dbs-status.module").then(m => m.DbsStatusModule)
  },
  {
    path: "",
    redirectTo: "du/loap/loap",
    pathMatch: "full"
  },
  {
    // we need to specify the route for notes after /pubRev
    // for use note module on app module.
    // and prevent trying to navigate on the notes when opening app
    // we set path: "notes" on note-routing module
    path: "",
    loadChildren: () => import("./toc-items/note-module/note.module").then(m => m.NoteModule)
  },
  {
    path: "du/:dmc",
    component: ContentProviderComponent,
    canDeactivate: [NoteCanDeactivateGuard],
    children: [
      {
        path: "fulltext",
        loadChildren: () => import("./fulltext-module/fulltext.module").then(m => m.FulltextModule)
      },
      {
        path: "ipc",
        loadChildren: () => import("./ipc-module/ipc.module").then(m => m.IpcModule)
      },
      {
        path: "loap",
        loadChildren: () => import("./loap-module/loap.module").then(m => m.LoapModule)
      },
      {
        path: "pdf/:pdfId",
        component: PdfComponent
      }
    ]
  },
  {
    path: "bookmark",
    loadChildren: () =>
      import("./toc-items/bookmark-module/bookmark.module").then(m => m.BookmarkModule)
  },
  {
    path: "additional-documents",
    loadChildren: () =>
      import("./toc-items/list/toc-item-list.module").then(m => m.TocItemListModule)
  },
  {
    path: "shopping",
    loadChildren: () =>
      import("./toc-items/shopping-basket/shopping-basket.module").then(m => m.ShoppingBasketModule)
  },
  {
    path: "inspections",
    loadChildren: () =>
      import("./toc-items/inspection-module/inspection.module").then(m => m.InspectionModule)
  },
  {
    path: "inspection",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./search-result-module/search-result.module").then(m => m.SearchResultModule)
      },
      {
        path: ":searchInput",
        loadChildren: () =>
          import("./search-result-module/search-result.module").then(m => m.SearchResultModule)
      }
    ]
  },
  {
    path: "help",
    loadChildren: () => import("./help-module/help.module").then(m => m.HelpModule)
  },
  {
    path: "search",
    children: [
      {
        path: ":searchEnv",
        loadChildren: () =>
          import("./search-result-module/search-result.module").then(m => m.SearchResultModule)
      },
      {
        path: "",
        redirectTo: "documents",
        pathMatch: "full"
      }
    ]
  },
  {
    path: "first/:key",
    component: RedirectComponent
  },
  {
    path: "shortDMC/:key",
    component: RedirectComponent
  },
  {
    path: "dmc/:key",
    component: RedirectComponent
  },
  {
    path: "she/:key",
    component: RedirectComponent
  },
  {
    path: "external/:external/:key",
    component: RedirectComponent
  },
  {
    path: "redirect/:key",
    component: RedirectComponent
  },
  { path: "print", component: PdfComponent }
];

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "pubs/online/store",
    redirectTo: "home/redirect",
    pathMatch: "full"
  },
  {
    path: "disclaimer",
    component: DisclaimerComponent
  },
  { path: "settings", component: SettingsFormComponent },
  {
    path: "conf",
    loadChildren: () => import("./conf-module/conf.module").then(m => m.ConfModule)
  },
  {
    path: "home",
    component: LayoutHomeComponent,
    resolve: { _: HomeResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "tabs",
        pathMatch: "full"
      },
      {
        path: "dbStatus",
        loadChildren: () =>
          import("./dbs-status-module/dbs-status.module").then(m => m.DbsStatusModule)
      },
      {
        path: "login",
        component: AuthLoginComponent
      },
      {
        path: "extra-doc/:pdfId",
        component: ExtraDocComponent
      },
      {
        path: "srd/:type",
        component: PdfComponent
      },
      {
        path: "contract/:contractId",
        component: PdfComponent
      },
      {
        path: "redirect",
        component: HomeRedirectComponent,
        resolve: { products: HomeRedirectResolver }
      },
      {
        path: "replicate/:pubId/:pubRev",
        resolve: { pub: PubReplicateResolver },
        component: PubReplicationComponent
      },
      {
        path: "help",
        loadChildren: () => import("./help-module/help.module").then(m => m.HelpModule)
      },
      {
        path: "tabs",
        component: HomeTabsComponent,
        children: [
          {
            path: "",
            redirectTo: "local",
            pathMatch: "full"
          },
          {
            path: "local",
            component: LocalProductsComponent
          },
          {
            path: "online",
            component: OnlineProductsComponent,
            canActivate: [LastConsultedGuard]
          },
          {
            path: "contracts",
            component: ContractListComponent
          }
        ]
      }
    ]
  },
  {
    path: "pub/:pubId",
    resolve: { _: PubAccessResolver },
    canActivate: [AuthGuard],
    canDeactivate: [PubLeaveGuard],
    children: [
      {
        path: "",
        component: EmptyComponent
      },
      {
        path: ":pubRev",
        component: LayoutComponent,
        children: pubRoutes
      },
      {
        path: ":pubRev/latest",
        component: LayoutComponent,
        children: pubRoutes
      },
      {
        path: ":pubRev/preview",
        component: LayoutComponent,
        children: pubRoutes
      }
    ]
  },
  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: environment.hash,
      scrollPositionRestoration: "disabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        posthog.capture("$pageview");
      }
    });
  }
}
