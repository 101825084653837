import type PouchDB from "pouchdb";
import winston from "winston";

export interface DesignDoc {
  _id: string;
  _rev?: string;
  _attachments?: PouchDB.Core.Attachments;
  validate_doc_update?: string;
  views?: {
    [viewName: string]: {
      map: string;
      reduce?: string;
      xml?: string;
    };
  };
  lists?: {
    [key: string]: string;
  };
  updates?: {
    updatefun: string;
  };
  lastUpdate?: Date;
}

export enum DesignName {
  CHILDREN = "children",
  CONFLICTS = "conflicts",
  LOGIN_PASSWORD = "login_password",
  OCCURRENCE_CODE = "occurrenceCode",
  READ_ONLY = "read_only",
  UPDATE_DOC = "update_doc",
  MSM_TASKS = "msm_tasks"
}

export class Design {
  // SPEC: last update date of all _design
  public static lastUpdateDate = {
    conflicts: new Date("2020/04/13"),
    children: new Date("2020/03/07"),
    read_only: new Date("2020/03/07"),
    occurrenceCode: new Date("2023/09/26"),
    login_password: new Date("2020/11/09"),
    update_doc: new Date("2020/03/07")
  };

  public static checkDesign(
    designName: string,
    db: PouchDB.Database,
    logger: winston.Logger
  ): Promise<boolean> {
    return db
      .get(`_design/${designName}`)
      .catch((err: PouchDB.Core.Error) => {
        if (err.error === "not_found") {
          return undefined;
        }
        throw err;
      })
      .then((doc: DesignDoc) => {
        if (!doc) {
          return Design.putDesign(designName, db, logger);
        }
        const docVersion = new Date(doc.lastUpdate).getTime();
        const lastVersion = Design.lastUpdateDate[designName].getTime();
        if (!docVersion || docVersion < lastVersion) {
          return Design.putDesign(designName, db, logger, doc._rev);
        } else {
          logger?.verbose(
            `'${designName}' _design was already created and up to date for DB ${db.name}`
          );
          return true;
        }
      })
      .catch(err => {
        logger.error(`[DESIGN] Getting ${designName} failed for DB ${db.name}': ${err.message}`);
        return false;
      });
  }

  public static putDesign(
    design: string,
    db: PouchDB.Database,
    logger: winston.Logger,
    rev?: string
  ) {
    const putMode = rev ? "update" : "creation";
    return db
      .put(Design.buildDesignDoc(design, rev, true))
      .then(() => {
        logger?.verbose(`[DESIGN] ${putMode} of ${design} succeeded for DB ${db.name}`);
        return true;
      })
      .catch(err => {
        logger?.error(`[DESIGN] ${putMode} of ${design} failed for DB ${db.name}': ${err.message}`);
        return false;
      });
  }

  public static buildDesignDoc(designName: string, rev: string, tiOrUserDB?: boolean): DesignDoc {
    switch (designName) {
      case DesignName.READ_ONLY:
        return tiOrUserDB ? Design.buildReadOnlyTiDesign(rev) : Design.buildReadOnlyDesign(rev);
      case DesignName.UPDATE_DOC:
        return Design.buildUpdateDocDesign(rev);
      case DesignName.CONFLICTS:
        return Design.buildConflictsDesign(rev);
      case DesignName.CHILDREN:
        return Design.buildChildrenDesign(rev);
      case DesignName.OCCURRENCE_CODE:
        return Design.buildOccurrenceCodeDesign(rev);
      case DesignName.LOGIN_PASSWORD:
        return Design.buildLoginPasswordDesign(rev);
      case DesignName.MSM_TASKS:
        return Design.buildMsmtasksDesign();
      default:
        return undefined;
    }
  }

  public static children(): string {
    return `function(doc) {
      if(doc.parent){
        emit(doc.parent)
      }

      if(doc.type) {
        emit([doc.type, doc.minRevision], [doc._rev, doc.maxRevision])
      }

      for (var p in doc.parents) {
        if(!doc.parents.hasOwnProperty(p)){
          return
        }
        const isObject = typeof doc.parents[p] === 'object';
        const parentId = isObject ? doc.parents[p].id : doc.parents[p];

        if (parentId !== doc.type) {
          emit([parentId, doc.minRevision], [doc._rev, doc.maxRevision]);
        }
      }
    }`;
  }

  public static conflicts(): string {
    return `function(doc) {
      if(doc._deleted_conflicts) {
        emit(doc._deleted_conflicts, {"deleted":true});
      }
      if(doc._conflicts) {
        emit(doc._conflicts, {"deleted":false});
      }
    }`;
  }

  public static readOnly(): string {
    return `function(newDoc, oldDoc, userCtx, secObj) {
      var ddoc = this;
      secObj.admins = secObj.admins || {};
      secObj.admins.names = secObj.admins.names || [];
      secObj.admins.roles = secObj.admins.roles || [];
      var IS_DB_ADMIN = false;
      if(~ userCtx.roles.indexOf('_admin'))
          IS_DB_ADMIN = true;
      if(IS_DB_ADMIN)
          log('Admin change on read-only db: ' + newDoc._id);
      else
          throw {'forbidden':'This database is read-only'};
    }`;
  }

  public static updateModule(): string {
    return `"use strict";

    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports["default"] = void 0;

    function ownKeys(object, enumerableOnly) {
      var keys = Object.keys(object);
      if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly)
          symbols = symbols.filter(function(sym) {
            return Object.getOwnPropertyDescriptor(object, sym).enumerable;
          });
        keys.push.apply(keys, symbols);
      }
      return keys;
    }

    function _objectSpread(target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i] != null ? arguments[i] : {};
        if (i % 2) {
          ownKeys(Object(source), true).forEach(function(key) {
            _defineProperty(target, key, source[key]);
          });
        } else if (Object.getOwnPropertyDescriptors) {
          Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
        } else {
          ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(
              target,
              key,
              Object.getOwnPropertyDescriptor(source, key)
            );
          });
        }
      }
      return target;
    }

    function _defineProperty(obj, key, value) {
      if (key in obj) {
        Object.defineProperty(obj, key, {
          value: value,
          enumerable: true,
          configurable: true,
          writable: true
        });
      } else {
        obj[key] = value;
      }
      return obj;
    }

    function update(doc, req) {
      var body = JSON.parse(req["body"]);

      if (!doc) {
        if ("id" in req && req["id"]) {
          // create new document
          return [body, "Document created"];
        } // change nothing in database

        return [null, "we need id on url request"];
      }
      if (doc["from"] !== "ORION1") {
        return [doc, "Update conflict with ORION2 data"];
      }
      doc = _objectSpread({}, doc, {}, body);
      return [doc, "Update successful"];
    }

    var _default = update;
    exports["update"] = update;
    `;
  }

  public static updateFun(): string {
    return `function(doc, req) {
      return require('views/lib/update_module').update(doc, req);
    }`;
  }

  public static readOnlyTocItems(): string {
    return `function(newDoc, oldDoc, userCtx, secObj) {
      var ddoc = this;
      secObj.admins = secObj.admins || {};
      secObj.admins.names = secObj.admins.names || [];
      secObj.admins.roles = secObj.admins.roles || [];
      var IS_DB_ADMIN = false;
      if(~ userCtx.roles.indexOf('_admin'))
        IS_DB_ADMIN = true;
      if(~ secObj.admins.names.indexOf(userCtx.name))
        IS_DB_ADMIN = true;
      for(var i = 0; i < userCtx.roles; i++)
        if(~ secObj.admins.roles.indexOf(userCtx.roles[i]))
          IS_DB_ADMIN = true;
      if(ddoc.access && ddoc.access.read_only)
        if(IS_DB_ADMIN)
          log('Admin change on read-only db: ' + newDoc._id);
        else
          throw {'forbidden':'This database is read-only'};
    }`;
  }

  /**
   * The occurenceCode design permit 3 things :
   *  * From the store we use it to infer the last available revision of each pub using the custom reduce function.
   *  * From the resolver we can retrieve information (alias, packageId and revision) of a pub
   *      using one of the following identifiers : alias, packageId or occCode (use here to resolve
   *      pub and set the normalised URL). This can be done with a specified revision or 'latest' or none.
   *  * The same as the second point but with a "_nonPublished" status to be able to resolve non-published pubs in
   *      case we are in 'preview' mode.
   */
  public static occurrenceCodeMap(): string {
    return `function (doc) {
      var value = {
        aliasPackageId: doc.packageId.replace(/_\\d{3}-\\d{2}_/,'_'),
        packageId: doc.packageId,
        revision: doc.revision,
        defaultPrefix: doc.pubSchema.defaultPrefix,
        prefixFunction: doc.pubSchema["toc_corp"]["prefixFunction"]
      };
      if(doc.isPublished){
        emit(doc.occurrenceCode, doc.revision);
        emit([doc.packageId, doc.revision], value);
        emit([doc.packageId.replace(/_\\d{3}-\\d{2}_/,'_'), doc.revision], value);
        emit([doc.occurrenceCode, doc.revision], value);
      } else {
        emit(["_nonPublished", doc.packageId, doc.revision], value);
        emit(["_nonPublished", doc.packageId.replace(/_\\d{3}-\\d{2}_/,'_'), doc.revision], value);
        emit(["_nonPublished", doc.occurrenceCode, doc.revision], value);
      }
    }`;
  }

  public static occurrenceCodeReduce(): string {
    return `function (keys, values, rereduce) {
      var max = '0';
      for(i=0; i < values.length; i++){
        if(typeof values[i] === 'string') {
          if(values[i] > max){
            max = values[i]
          }
        }
      }
      return max;
    }`;
  }

  public static loginPassword(): string {
    return `function (doc) {
      emit([doc.login, doc.pwd], doc._id);
    }`;
  }

  public static msmTasksMap(): string {
    return `function (doc) {
      const transfoLib = require('views/lib/xml');
      transfoLib.MsmFormatter.buildIndex(doc, emit);
    }`;
  }

  public static msmTasksCamo(): string {
    return `function(head, req) {
      const couchdbFcts = {"start": start, "getRow": getRow, "send": send};
      const csvUtil = require("views/lib/xml"); csvUtil.MsmFormatter.toCamo(couchdbFcts);
    }`;
  }

  public static indexFilterType(): PouchDB.Find.CreateIndexOptions {
    return {
      index: {
        fields: ["type"],
        ddoc: "filter-type",
        name: "filter-type",
        type: "json"
      }
    };
  }

  public static indexFilterTypeMin(): PouchDB.Find.CreateIndexOptions {
    return {
      index: {
        fields: ["type", "minRevision"],
        ddoc: "filter-type-min",
        name: "filter-type-min",
        type: "json"
      }
    };
  }

  /**
   * Build the design document read_only
   */
  public static buildReadOnlyDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/read_only",
      _rev: rev,
      validate_doc_update: Design.readOnly(),
      lastUpdate: Design.lastUpdateDate[DesignName.READ_ONLY]
    };
  }

  /**
   * Build the design document read_only for tocItem and userDb
   */
  public static buildReadOnlyTiDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/read_only",
      _rev: rev,
      validate_doc_update: Design.readOnlyTocItems(),
      lastUpdate: Design.lastUpdateDate[DesignName.READ_ONLY]
    };
  }

  /**
   * Build the design document update_doc
   */
  public static buildUpdateDocDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/update_doc",
      _rev: rev,
      views: {
        lib: {
          update_module: Design.updateModule()
        }
      },
      updates: {
        updatefun: Design.updateFun()
      },
      lastUpdate: Design.lastUpdateDate[DesignName.UPDATE_DOC]
    } as unknown as DesignDoc;
  }

  /**
   * Build the design document for conflicts
   */
  public static buildConflictsDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/conflicts",
      _rev: rev,
      views: {
        conflicts: {
          map: Design.conflicts()
        }
      },
      lastUpdate: Design.lastUpdateDate[DesignName.CONFLICTS]
    };
  }

  /**
   * Build the design document for children
   */
  public static buildChildrenDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/children",
      _rev: rev,
      views: {
        children: {
          map: Design.children()
        }
      },
      lastUpdate: Design.lastUpdateDate[DesignName.CHILDREN]
    };
  }

  /**
   * Build the design document for occurrenceCode
   */
  public static buildOccurrenceCodeDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/occurrenceCode",
      _rev: rev,
      views: {
        occurrenceCode: {
          map: Design.occurrenceCodeMap(),
          reduce: Design.occurrenceCodeReduce()
        }
      },
      lastUpdate: Design.lastUpdateDate[DesignName.OCCURRENCE_CODE]
    };
  }

  /**
   * Build the design document for login_password
   */
  public static buildLoginPasswordDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/login_password",
      _rev: rev,
      views: {
        login_password: {
          map: Design.loginPassword()
        }
      },
      lastUpdate: Design.lastUpdateDate[DesignName.LOGIN_PASSWORD]
    };
  }

  /**
   * Build the design document for msm_tasks
   */
  public static buildMsmtasksDesign(rev?: string): DesignDoc {
    return {
      _id: "_design/msm_tasks",
      _rev: rev,
      views: {
        msm_tasks: {
          map: Design.msmTasksMap()
        },
        lib: {
          xml: "", // The bundled library MsmFormatter will be injected here,
          map: undefined // This map is useless but needed for typing purpose
        }
      },
      lists: {
        camo: Design.msmTasksCamo()
      }
    };
  }
}
