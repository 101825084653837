import { Component, Input } from "@angular/core";

@Component({
  selector: "o-revision-mark",
  templateUrl: "./revision.component.html",
  styleUrls: ["./revision.component.scss"]
})
export class RevisionComponent {
  @Input() dataRev: string;
}
