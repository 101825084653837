import { AuthorizationServiceConfiguration } from "@openid/appauth/built/authorization_service_configuration";
import { AppAuthError } from "@openid/appauth/built/errors";
import { BasicQueryStringUtils, QueryStringUtils } from "@openid/appauth/built/query_string_utils";
import { RevokeTokenRequest } from "@openid/appauth/built/revoke_token_request";
import { TokenRequest } from "@openid/appauth/built/token_request";
import {
  TokenError,
  TokenErrorJson,
  TokenResponse,
  TokenResponseJson
} from "@openid/appauth/built/token_response";
import { FetchRequestor, Requestor } from "@openid/appauth/built/xhr";
import { TokenRequestHandler } from "@openid/appauth/built/token_request_handler";
import { RequestHandler } from "@orion2/auth/request.handler";

/**
 * The default token request handler.
 */
export class ExtendedTokenRequestHandler
  extends RequestHandler<TokenResponse>
  implements TokenRequestHandler
{
  get key(): string {
    return "EXTENDED_TOKEN_REQUEST_HANDLER";
  }

  constructor(
    public readonly requestor: Requestor = new FetchRequestor(),
    public readonly utils: QueryStringUtils = new BasicQueryStringUtils()
  ) {
    super();
  }

  public performRevokeTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    request: RevokeTokenRequest
  ): Promise<boolean> {
    return this.requestor
      .xhr<boolean>({
        url: configuration.revocationEndpoint,
        method: "POST",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: this.utils.stringify(request.toStringMap())
      })
      .then(() => true);
  }

  performTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    request: TokenRequest
  ): Promise<TokenResponse> {
    const fetch = this.requestor.xhr<TokenResponseJson | TokenErrorJson>({
      url: configuration.tokenEndpoint,
      method: "POST",
      dataType: "json", // adding implicit dataType
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: this.utils.stringify(request.toStringMap())
    });

    fetch
      .then((response: TokenResponseJson | TokenErrorJson) => {
        if (this.isTokenResponse(response)) {
          this.resolvePromise(new TokenResponse(response));
        } else {
          this.rejectPromise(new AppAuthError(response.error, new TokenError(response)));
        }
      })
      // TODO DG rework the error message
      .catch(err => this.rejectPromise({ code: 400, message: err }));
    return this.promise;
  }

  protected isTokenResponse(
    response: TokenResponseJson | TokenErrorJson
  ): response is TokenResponseJson {
    return (response as TokenErrorJson).error === undefined;
  }
}
