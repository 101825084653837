/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { MimeType } from "@orion2/models/enums";

/**
 * Cordova service is a service with multiple function in order to
 * interact with a cordova device (read/write in filesytem, print, etc..)
 *
 * @export
 * @class CordovaService
 */
@Injectable({
  providedIn: "root"
})
export class CordovaService {
  public getFilePath(file: string): string {
    return (window as any).cordova.file.dataDirectory + file;
  }

  public getCachePath(filename: string) {
    return (window as any).cordova.file.cacheDirectory + filename;
  }

  public readFile(pathFile: string): Promise<string> {
    const [directory, fileName] = this.getFileMeta(pathFile);

    return new Promise((resolve, reject) => {
      (window as any).resolveLocalFileSystemURL(directory, fs => {
        fs.getFile(
          fileName,
          { create: true, exclusive: false },
          fileEntry => {
            fileEntry.file((file: Blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result.toString());
              };
              reader.readAsText(file);
            });
          },
          err => {
            reject(err);
          }
        );
      });
    });
  }

  public writeFile(
    pathFile: string,
    content: string | Blob | ArrayBuffer,
    mimeType = MimeType.JSON
  ): Promise<void> {
    const [directory, fileName] = this.getFileMeta(pathFile);

    return new Promise((resolve, reject) => {
      (window as any).resolveLocalFileSystemURL(directory, fs => {
        fs.getFile(
          fileName,
          { create: true, exclusive: false },
          file => {
            file.createWriter(fileWriter => {
              fileWriter.onwriteend = () => {
                resolve();
              };

              fileWriter.onerror = e => {
                console.error(e);
                reject(e);
              };

              const blob = new Blob([content], { type: mimeType });
              fileWriter.write(blob, "utf-8");
            });
          },
          err => {
            reject(err);
          }
        );
      });
    });
  }

  public print(): Promise<void> {
    console.warn("Not yet implemented");
    return Promise.resolve();
  }

  /**
   * Method to unzip a file or folder
   *
   * @param zipPath - .zip file path
   * @param unzipPath - path of the unzipped file or folder
   */
  public unzipFile(
    zipPath: string,
    unzipPath: string = zipPath.replace(/.zip$/, "")
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      (window as any).JJzip.unzip(
        zipPath,
        { target: unzipPath },
        () => {
          resolve();
        },
        err => {
          console.error("UNZIP FILE ERROR while unzipping", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Method to delete a file
   *
   * @param cordovaDirectory - cordova.file property, cordova directory of the deleted file
   * @param filePath - file's relative path to cordovaDirectory
   */
  public deleteFile(cordovaDirectory: string, filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (window as any).resolveLocalFileSystemURL(
        cordovaDirectory,
        dir => {
          dir.getFile(
            filePath,
            { create: false },
            fileEntry => {
              fileEntry.remove(
                () => resolve(),
                err => {
                  console.error("DELETE FILE ERROR while deleting file", filePath, err);
                  reject(err);
                }
              );
            },
            err => {
              console.error("ERROR file does not exist", filePath, err);
              reject("file does not exist");
            }
          );
        },
        err => {
          console.error("DELETE FILE ERROR while retrieving folder", filePath, err);
          reject(err);
        }
      );
    });
  }

  /**
   * Method to delete a folder
   *
   * @param cordovaDirectory - cordova.file property, cordova directory of the deleted folder
   * @param folderPath - folder's relative path to cordovaDirectory
   */
  public deleteFolder(cordovaDirectory: string, folderPath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (window as any).resolveLocalFileSystemURL(
        cordovaDirectory,
        dir => {
          dir.getDirectory(
            folderPath,
            { create: false },
            fileEntry => {
              fileEntry.removeRecursively(
                () => resolve(),
                err => {
                  console.error("DELETE FOLDER ERROR while deleting folder", folderPath, err);
                  reject(err);
                }
              );
            },
            err => {
              console.error("ERROR folder does not exist", folderPath, err);
              reject("folder does not exist");
            }
          );
        },
        err => {
          console.error("DELETE FOLDER ERROR while retrieving folder", folderPath, err);
          reject(err);
        }
      );
    });
  }

  public downloadFile(filename: string, content: string | Blob): Promise<void> {
    const filePath = this.getCachePath(filename);

    // we create the file named filename on cache directory device
    return this.writeFile(filePath, content).then(() => {
      // we launch the share pop-up with the file uri
      (window as any).plugins.socialsharing.share(null, null, filePath);
    });
  }

  /**
   * Get the directory and the filename from a path
   *
   * @private
   * @param pathFile
   * @returns [directory, filename]
   * @memberof CordovaService
   */
  private getFileMeta(pathFile: string): [string, string] {
    // We ignore the match[0] because it's the match for the hole path string
    return /^(.*[\\\/])(.*)$/.exec(pathFile).splice(1) as [string, string];
  }
}
