declare let ActiveXObject: (type: string) => void;

/* Represent a XSL passe dto xsltProc */
export class XSLParam {
  public namespaceURI;
  public localName: string;
  public value: string;
  constructor(key: string, value: string) {
    this.namespaceURI = null;
    this.localName = key;
    this.value = value;
  }
}

/**
 * Define legacy parameters for xslt transformation
 *
 * @param xslDocId
 */
export function defineXSLTParameters(xslDocId: string) {
  if (xslDocId.toLowerCase().includes("loap")) {
    return new XSLParam("isMobileLOAP", "mobile");
  } else if (xslDocId === "AHX4_DESCRIPTMergedStylesheet") {
    return new XSLParam("procedural", "true");
  }
  return null;
}

export function handleXSLTTransform(xml: Document, xsl: Document, params: XSLParam) {
  return typeof XSLTProcessor !== "undefined"
    ? transform(xml, xsl, params)
    : legacyTransform(xml, xsl, params);
}

function legacyTransform(xml: Document, xsl: Document, params: XSLParam) {
  const template = new ActiveXObject("Msxml2.XslTemplate.6.0");
  template.stylesheet = xsl;
  const proc = template.createProcessor();
  const xsltParams = Object.keys(params);
  for (const prop of xsltParams) {
    proc.addParameter(prop, xsltParams[prop]);
  }
  proc.input = xml;
  proc.transform();
  return proc.output;
}

export function getXSD(xml: Document) {
  const dmodule = xml.querySelector("dmodule");
  return dmodule ? dmodule.getAttribute("xsi:noNamespaceSchemaLocation") : "";
}

export function getDocumentFromString(xml: string) {
  const parser = new DOMParser();
  return parser.parseFromString(xml, "text/xml");
}

function transform(xml: Document, xsl: Document, params: XSLParam) {
  const XSLTProc = new XSLTProcessor();
  XSLTProc.importStylesheet(xsl);
  if (params) {
    XSLTProc.setParameter(params.namespaceURI, params.localName, params.value);
  }
  return XSLTProc.transformToFragment(
    xml,
    document.implementation.createDocument("", "xmlNode", null)
  );
}
