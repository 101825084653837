import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NoteListComponent } from "@viewer/toc-items/note-module/note-list/note-list.component";
import { NoteCanDeactivateGuard } from "@viewer/toc-items/note-module/note/note-can-deactivate-guard.service";

const routes: Routes = [
  {
    path: "notes",
    component: NoteListComponent,
    canDeactivate: [NoteCanDeactivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoteModuleRoutingModule {}
