export class PromiseQueue {
  private queue: { promise: Function; resolve: Function; reject: Function }[] = [];
  private pendingProm = false;

  public enqueue<T>(prom): Promise<T> {
    return new Promise((resolve, reject) => {
      this.queue.push({ promise: prom, resolve, reject });
      this.dequeue();
    });
  }

  public dequeue(): void {
    // If pending prom we don't want to continue
    if (this.pendingProm) {
      return;
    }

    const item = this.queue.shift();

    // If no item found
    if (!item) {
      return;
    }

    this.pendingProm = true;

    item
      .promise()
      .then((value: unknown) => {
        item.resolve(value);
      })
      .catch((err: Error) => {
        item.reject(err);
      })
      .finally(() => {
        this.pendingProm = false;
        this.dequeue();
      });
  }
}
