import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { NoteService } from "@viewer/core/toc-items/note.service";
import { ConfirmDialogComponent } from "@viewer/shared-module/confirm-dialog/confirm-dialog.component";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from "@angular/material/legacy-dialog";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "@orion2/message-service/message.service";
import { DeactivateGuardEnum } from "@viewer/toc-items/note-module/note/deactivateGuardEnum";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class NoteCanDeactivateGuard {
  constructor(
    private noteService: NoteService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private messageService: MessageService
  ) {}

  async canDeactivate(
    _component: CanComponentDeactivate,
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ) {
    if (this.noteService.canDeactivate()) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        content: this.translate.instant("notes.confirmDiag.text"),
        unsaveText: this.translate.instant("tocItem.doNotSave"),
        cancelText: this.translate.instant("no"),
        saveText: this.translate.instant("tocItem.save")
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      return dialogRef
        .afterClosed()
        .toPromise()
        .then(response => {
          if (response === DeactivateGuardEnum.save_all) {
            return this.noteService.saveAllNotes().then(res => res);
          }
          if (response === DeactivateGuardEnum.not_save) {
            return this.noteService.deleteNewNotes().then(() => {
              const message = this.translate.instant("tocItem.notSavedMsg");
              this.messageService.success(message);
              return true;
            });
          }
          return false;
        });
    }

    return true;
  }
}
