import packageJSON from "@package-json";

export const environment = {
  production: false,
  platform: "browser",
  useWorker: false,
  version: packageJSON.version,
  hash: false,
  service_worker: false,
  env: {}
};
