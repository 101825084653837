// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host o-card-news {
  display: block;
  margin-bottom: 20px;
}
:host .actions {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0;
}
:host .no-news {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5ld3MtbGlzdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFLGNBQUE7RUFDQSxtQkFBQTtBQUZKO0FBS0U7RUFDRSxhQUFBO0VBQ0EseUJBQUE7RUFDQSxhQUFBO0FBSEo7QUFNRTtFQUNFLGFBQUE7RUFDQSx1QkFBQTtFQUNBLGVBQUE7QUFKSiIsImZpbGUiOiJuZXdzLWxpc3QuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwibGlicy90aGVtZS9vcmlvbi10aGVtZVwiO1xuXG46aG9zdCB7XG4gIG8tY2FyZC1uZXdzIHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBtYXJnaW4tYm90dG9tOiAyMHB4O1xuICB9XG5cbiAgLmFjdGlvbnMge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBmbGV4LWVuZDtcbiAgICBtYXJnaW46IDVweCAwO1xuICB9XG5cbiAgLm5vLW5ld3Mge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgbWFyZ2luLXRvcDogNXB4O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/news/news-list/news-list.component.scss"],"names":[],"mappings":"AAGE;EACE,cAAA;EACA,mBAAA;AAFJ;AAKE;EACE,aAAA;EACA,yBAAA;EACA,aAAA;AAHJ;AAME;EACE,aAAA;EACA,uBAAA;EACA,eAAA;AAJJ;AACA,oxBAAoxB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  o-card-news {\n    display: block;\n    margin-bottom: 20px;\n  }\n\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n    margin: 5px 0;\n  }\n\n  .no-news {\n    display: flex;\n    justify-content: center;\n    margin-top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
