import { Requestor } from "@openid/appauth/built/xhr";
import { RoleRequest, UserManagementResponse, RoleErrorJson } from "@orion2/auth/role.interfaces";
import { CustomRequestor } from "libs/auth/src/auth.flow";
import { RequestHandler } from "@orion2/auth/request.handler";

/**
 * The default token request handler.
 */
export class RoleRequestHandler extends RequestHandler<UserManagementResponse> {
  get key(): string {
    return "ROLE_REQUEST_HANDLER";
  }

  private requestor: CustomRequestor;

  public setRequestor(requestor: Requestor): void {
    this.requestor = requestor;
  }

  public performRoleRequest(request: RoleRequest, url: string): Promise<UserManagementResponse> {
    const fetch = this.requestor.xhrWithErrorBody({
      url,
      method: "GET",
      dataType: "json",
      headers: {
        Authorization: `Bearer ${request.bearer.access_token}`
      }
    });

    fetch
      .then((response: UserManagementResponse) => this.resolvePromise(response))
      .catch((err: RoleErrorJson) => this.rejectPromise(err));
    return this.promise;
  }
}
