import { catchError, concatMap, defer, forkJoin, from, iif, Observable, of, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@viewer-env/environment";
import cloneDeep from "fast-copy";
import DefaultJson from "libs/pub-schema/default.json";
import { ElectronService } from "tools/electron/viewer/helpers/electron.service.browser";
import { CordovaService } from "libs/cordova/cordova.service";
import { ONPREMISE_KEY } from "@viewer/shared-module/helper.utils";
import { FullHttpService } from "libs/http/fullHttp.service";
import { BasepubExternalService } from "libs/basepub/basepub-external.service";
import { ConfUtils } from "@orion2/utils/conf.utils";
import { ViewerConf, ViewEnvConf } from "@orion2/models/conf.models";
import { OidcConfiguration } from "@orion2/auth/oidc.conf";

export function configurationServiceInitializerFactory(
  configurationService: ConfService
): () => Observable<unknown> {
  return () => from(window["deviceReady"]).pipe(concatMap(() => configurationService.init()));
}

export interface ReleaseViewer {
  messages: string[];
  features: string[];
  releaseInfoTitle: string;
  buildId: string;
}

export interface Disclaimer {
  termsOfUse: string;
  gdprPrivacyPolicy: string;
}

/**
 * Handle assets/conf.json to provide env variable
 *
 * @export
 * @class ConfService
 */
@Injectable()
export class ConfService {
  private static confPath = "assets/conf.json";
  private static releasePath = "assets/viewerRelease.json";
  private static disclaimersPath = "assets/disclaimers.json";

  public conf: ViewEnvConf;
  public useAuth: boolean;
  public isOnPrem: boolean;
  public defaultConf: ViewerConf;
  public releaseConf: ReleaseViewer;
  public disclaimersVersions: Disclaimer;
  public appSchema = DefaultJson.appSchema;

  private expireBuffer: number;

  constructor(
    private bpExternalService: BasepubExternalService,
    private http: HttpClient,
    private httpService: FullHttpService,
    private electronService: ElectronService,
    private cordovaService: CordovaService
  ) {}

  /**
   * Read the conf.json in asserts to load parameters
   * alternative to update param in bundle.js
   *
   * @memberof ConfService
   */
  public init(): Observable<unknown> {
    return forkJoin([
      this.getFileConf(),
      this.http.get(ConfService.releasePath),
      this.http.get(ConfService.disclaimersPath)
    ]).pipe(
      concatMap(([conf, release, disclaimer]: [ViewerConf, ReleaseViewer, Disclaimer]) => {
        // Load conf with environment variable
        ConfUtils.loadConfWithEnv(conf, environment.env);

        this.isOnPrem = conf.onpremise;

        this.releaseConf = release;
        this.disclaimersVersions = disclaimer;
        this.defaultConf = cloneDeep(conf);
        this.expireBuffer = conf.common.tokenExpireBuffer;

        console.log("Loaded configuration : ", conf);

        return this.selectEnv(conf);
      }),
      tap((envConf: ViewEnvConf) => {
        this.conf = envConf;

        this.useAuth = !!this.conf.authent.oidc.openIdConnectUrl && this.conf.authent.active;

        this.httpService.setEnv(environment, this.useAuth);
        this.httpService.setBaseUrl(this.conf.baseUrl);
        this.bpExternalService.setApiPath(this.conf.database.basepubExternalUrl);

        if (this.isOnPrem) {
          sessionStorage.setItem(ONPREMISE_KEY, this.isOnPrem.toString());
        }

        if (this.useAuth) {
          environment.authService.setOidcConf({
            ...this.conf.authent.oidc,
            baseUrl: this.conf.baseUrl,
            tokenExpireBuffer: this.expireBuffer,
            hasBp2: this.conf.hasBP2
          } as OidcConfiguration);
        }
      }),
      catchError((err: Error) => {
        console.error("Error during conf initialisation : ", err);
        return of(false);
      })
    );
  }

  public saveConf(conf: ViewerConf): void {
    const customJSONPath = this.getFilePath();

    if (environment.platform === "electron") {
      this.electronService.writeFile(customJSONPath, JSON.stringify(conf, undefined, 2));
    } else if (environment.platform === "cordova") {
      this.cordovaService.writeFile(customJSONPath, JSON.stringify(conf, undefined, 2));
    } else {
      // For other environment you should not save the conf
    }
  }

  private selectEnv(conf: ViewerConf): Observable<ViewEnvConf> {
    const envs = Object.values(conf.environment);
    return iif(
      () => envs.length === 1,
      defer(() => of(envs.shift())),
      defer(() => from(this.initSelect(envs)))
    );
  }

  private getFilePath(): string {
    if (environment.platform === "electron") {
      return this.electronService.getFilePath("conf.json");
    } else if (environment.platform === "cordova") {
      return this.cordovaService.getFilePath("conf.json");
    } else {
      // SPEC: Browser
      return "";
    }
  }

  private getFileConf(): Observable<ViewerConf> {
    const customJSONPath = this.getFilePath();
    if (environment.platform === "browser") {
      // For browser platform we should load server side conf
      // Load default app configuration
      return this.http.get<ViewerConf>(ConfService.confPath);
    }

    // Here we have only electron platform or cordova platform
    const service: ElectronService | CordovaService =
      environment.platform === "electron" ? this.electronService : this.cordovaService;

    return from(service.readFile(customJSONPath)).pipe(
      concatMap((data: string) =>
        data ? of(JSON.parse(data)) : this.http.get<ViewerConf>(ConfService.confPath)
      ),
      catchError((err: Error) => {
        console.error(err);
        return of(undefined);
      })
    );
  }

  private initSelect(envs: ViewEnvConf[]): Promise<ViewEnvConf> {
    return new Promise((resolve, _reject) => {
      const sendConf = (env: string) => resolve(JSON.parse(env));

      const selectConf = () => {
        const confSelector = document.querySelector(".conf-select") as any;
        const selectedKey = confSelector.item(confSelector.selectedIndex).value;
        sendConf(selectedKey);
      };

      const initSelect = () => {
        const confSelector = document.querySelector(".conf-select") as HTMLElement;
        const spinner = document.querySelector(".app-loading .spinner");

        // Remove already setted options (cordova use cases)
        confSelector.querySelectorAll("option").forEach((e: HTMLOptionElement, index: number) => {
          if (index > 0) {
            e.remove();
          }
        });

        envs.forEach((env: ViewEnvConf) => {
          const optElt = document.createElement("option");
          optElt.text = env.label;
          optElt.value = JSON.stringify(env);
          (confSelector as any).options.add(optElt);
        });

        spinner.setAttribute("class", "spinner display-none");
        confSelector.parentElement.removeAttribute("class");

        confSelector.onchange = selectConf;
      };

      initSelect();
    });
  }
}
