import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SbImpactingWarningComponent } from "@viewer/sb-impacting-warning/sb-impacting-warning.component";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule],
  declarations: [SbImpactingWarningComponent],
  exports: [SbImpactingWarningComponent]
})
export class SbImpactingWarningModule {}
