import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  ConfirmDialogResult
} from "libs/components/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of, map, tap } from "rxjs";
import { Store } from "@viewer/core/state/store";
import { Location } from "@angular/common";
import { TitleService } from "@viewer/header/title.service";

@Injectable()
export class PubLeaveGuard {
  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private store: Store,
    public location: Location,
    public titleService: TitleService
  ) {}

  canDeactivate(
    _component: unknown,
    _currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean> {
    this.titleService.setPageTitleFromDU("");
    if (this.store.byPassPopupLeavePub) {
      this.store.byPassPopupLeavePub = false;
      return of(true);
    }

    const data: ConfirmDialogData = {
      message: [this.translate.instant("leave.publication")],
      confirmLabels: [this.translate.instant("yes")],
      cancelLabels: [this.translate.instant("no")]
    };

    // SPEC: Scroll to the last known position before clicking on the back button
    window.scroll(0, this.store.scrollYSave);

    return this.dialog
      .open(ConfirmDialogComponent, { data })
      .afterClosed()
      .pipe(
        tap((result: ConfirmDialogResult) => {
          if (!result?.confirm) {
            this.location.go(currentState.url);
          }
        }),
        map((result: ConfirmDialogResult) => result?.confirm)
      );
  }
}
