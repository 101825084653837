import { Injectable, Injector, NgZone } from "@angular/core";
import { ViewerMessageService } from "@viewer/core/message/viewer.message.service";
import { TitleService } from "@viewer/header/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@viewer/core/state/store";
import { PouchService } from "@viewer/core/pouchdb/pouch.service";
import { TocCaller } from "@viewer/core/pouchdb/caller";
import { reaction } from "mobx";
import { BehaviorSubject, Observable, Subject, share, filter } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { TocItem } from "@orion2/models/tocitem.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";

/* *******************************************************************************
  More info about toc items :
  https://gitlab.altengroup.net/tma/airbus/orion2/orion2/-/blob/master/docs/pages/tocItems.md
**************************************************************************************/
// SPEC: PreSync for History
const TI_SERVICES_PRE_SYNC_TOTAL = 1;
// SPEC: PostSync for History
const TI_SERVICES_POST_SYNC_TOTAL = 1;

@Injectable()
export class TocItemService {
  public static shouldResetCache = new Subject<void>();
  public static shouldPreSync = new Subject<void>();
  public static hasPreSync = new Subject<void>();
  public static shouldPostSync = new Subject<void>();
  public static hasPostSync = new Subject<void>();
  public static shouldSyncPromMap = new Map<string, Promise<boolean>>();
  public static syncPending = false;

  protected preSyncCount = 0;
  protected postSyncCount = 0;
  protected tiType: string;
  // Defines wheter the tocItem of type tiType has a limited
  // visibility or not. (Ex. boomarks have a defined scope: "private")
  // scopes are defined as keys in the visibilityMap
  protected tiScope: string;
  // This is the database target to access all the data
  // For exemple for news the tiTarget = DBConnexionType.BOTH
  protected tiTarget = DBConnexionType.LOCAL;
  // Contains all tocItems of type tiType
  protected _tocItemsOfType: TocItem[];
  // Contains all tocItems for currentDmc
  protected _tocItemsForTarget: TocItem[];
  protected titleService: TitleService;
  protected store: Store;
  protected router: Router;
  protected route: ActivatedRoute;
  protected messageService: ViewerMessageService;
  protected pouchService: PouchService;
  protected tocItemOfTypeSubject: BehaviorSubject<TocItem[]>;
  protected tocItemForTargetSubject: BehaviorSubject<TocItem[]>;
  protected translate: TranslateService;
  protected ngZone: NgZone;
  // Beware the values should be the name of the functions in CallerManager
  // that calls the respective constructor ex toc_userCaller creates an instance
  // of TocUserCaller
  protected visibilityMap = {
    private: "tocUserCaller",
    corporate: "tocCorpCaller",
    public: "tocPublicCaller",
    app: "orionTocPublicCaller",
    user: "userCaller"
  };

  private synchroScopes = ["private", "corporate", "user", "app"];

  // To avoid repeating injection each time we inherit
  // from this class, we use injector
  // see https://stackoverflow.com/questions/39038791/inheritance-and-dependency-injection
  constructor(protected injector: Injector) {
    this.titleService = injector.get(TitleService);
    this.store = injector.get(Store);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.messageService = injector.get(ViewerMessageService);
    this.pouchService = injector.get(PouchService);
    this.translate = injector.get(TranslateService);
    this.ngZone = injector.get(NgZone);

    this.tocItemOfTypeSubject = new BehaviorSubject(this._tocItemsOfType);
    this.tocItemForTargetSubject = new BehaviorSubject(this._tocItemsForTarget);

    // As tocItemService is a singleton linked to core, there's no need to call
    // the disposers. OnDestroy will only be called when killing the app.
    reaction(
      () => ({
        pubId: this.store.publicationID,
        // this is important for technical revisions
        // for example 011.00.01 will have the same package id than
        // 011.00
        pubRev: this.store.publicationRevision
      }),
      // we should reset cache when changing pubId but also
      // when changing pubRevision (see filters)
      () => {
        // We should probably init to
        // this.getTocItemsOfType
        // but this might unecessarily increase
        // time to switch a publication.
        this._tocItemsOfType = undefined;
        this._tocItemsForTarget = undefined;
        this.updateTocItemOfTypeToNext();
        this.updateTocItemForTargetToNext();
      }
    );

    reaction(
      () => this.store.currentDMC,
      async dmc => {
        this.getItemsForTarget(dmc, this.tiType, this.tiScope).then((docs: TocItem[]) => {
          this._tocItemsForTarget = docs;
          this.updateTocItemForTargetToNext();
          return this._tocItemsForTarget;
        });
      }
    );

    TocItemService.shouldResetCache.subscribe(() => {
      if (this.tiType) {
        this.resetCache();
      }
    });

    TocItemService.shouldPreSync.subscribe(() => {
      this.preSync().then(() => TocItemService.hasPreSync.next());
    });

    TocItemService.shouldPostSync.subscribe(() => {
      this.postSync().then(() => TocItemService.hasPostSync.next());
    });
  }

  public get tocItemsForTarget(): Observable<TocItem[]> {
    return this.tocItemForTargetSubject.asObservable().pipe(filter(Boolean), share());
  }

  public get tocItemsOfType(): Observable<TocItem[]> {
    // If a component ask for tocItemOfTypeSubject, we know it's
    // time to call getItemsOfType to initialize _tocItemsForTarget
    this.getItemsOfType();
    return this.tocItemOfTypeSubject.asObservable();
  }

  public sortTocItemList(list) {
    list.sort((t1, t2) => {
      if (t1.date && t2.date) {
        const a = new Date(t1.date);
        const b = new Date(t2.date);
        if (a !== b) {
          return a > b ? -1 : 1;
        }
      }
      // if creation date is equal we look _id
      // may resolve https://gitlab.altengroup.net/tma/airbus/orion2/orion2/issues/787
      if (t1._id && t2._id) {
        return t1._id < t2._id ? -1 : 1;
      }
    });
  }

  // add signature
  // returns true if everythings ok, else returns false
  public async save(
    tocItem: TocItem,
    visibility = "private",
    push = true,
    updateItemOfType = false
  ): Promise<boolean> {
    try {
      if (!tocItem.date) {
        tocItem.date = new Date();
        tocItem.lastUpdate = tocItem.date;
      } else {
        tocItem.lastUpdate = new Date();
      }
      const resp = await this.getCaller(visibility).save(tocItem);

      // Set the revision with the result of rev in order to save it in the cache
      tocItem._rev = resp._rev;

      // TODO DG if resp = false (save KO) what should we do ?
      if (push && resp) {
        this.addToCache(tocItem, updateItemOfType);
      }
      return Promise.resolve(true);
    } catch (e) {
      // TODO DG, we should probably consider alerting the user that an error occurred
      // we should also check what kind of error we get (quota excided, conflict, bad structure ...)
      console.error(e);
      return Promise.resolve(false);
    }
  }

  public async update(
    oldTocItem: TocItem,
    newTocItem: TocItem,
    visibility = "private",
    oldVisibility = "private",
    forceUpdateCache = false
  ): Promise<boolean> {
    let ret = false;

    // SPEC: When calling getItemsForTarget, we do not set _tocItemsOfType and it may be undefined
    // in order to save a new one, we need to set this value
    if (!this._tocItemsOfType) {
      this._tocItemsOfType = [];
    }

    // We check for later if the item is the only one of its type
    const isOnlyItemOfType = this._tocItemsOfType.length === 1;

    // Remove from cache the oldTocItem
    // Remove from cache before adding the new one, because removal is based on _id
    this.removeFromCache(oldTocItem);

    // delete _rev to avoid conflict
    delete newTocItem._rev;

    if (oldTocItem.minRevision < this.store.publicationRevision) {
      // oldTocItem wasn't created under actual revision
      // update maxRevision
      oldTocItem.maxRevision = this.store.publicationRevision;

      // update minRevision on new
      newTocItem.minRevision = this.store.publicationRevision;
      // update _id on new so that it contains the new minRevision
      const tmp = newTocItem._id.split("__");
      tmp[1] = this.store.publicationRevision;
      newTocItem._id = tmp.join("__");

      // we save both old and new explicitly asking
      // not to push in _tocItemsOfType
      const res = await Promise.all([
        this.save(oldTocItem, visibility, false),
        this.save(newTocItem, visibility, true, forceUpdateCache)
      ]);

      // returns true only if both save returned true
      ret = res.every(Boolean);
    } else {
      // here oldTocItem.minRevision === this.store.publicationRevision
      // so we simply save the new
      if (oldVisibility !== visibility) {
        await this.delete(oldTocItem, oldVisibility, false);
      }

      // Save will be update cache with the new toc item
      // In case the item was the only one of its type, we force the update of _tocItemsOfType
      ret = await this.save(newTocItem, visibility, true, isOnlyItemOfType);
    }

    return ret;
  }

  public updateTocItemForTargetToNext(): void {
    this.tocItemForTargetSubject.next(this._tocItemsForTarget);
  }

  public getCaller(visibility = "private"): TocCaller {
    return this.pouchService[this.visibilityMap[visibility]];
  }

  public resetCache(emitNext = true): void {
    this._tocItemsForTarget = undefined;
    this._tocItemsOfType = undefined;
    if (emitNext) {
      this.updateTocItemForTargetToNext();
      this.updateTocItemOfTypeToNext();
    }
  }

  public refreshIndex(tiScope) {
    return this.getCaller(tiScope).refreshIndex();
  }

  public refresh(): void {
    if (this.tiType) {
      this.getItemsOfType();
      this.getItemsForTarget(this.store.currentDMC);
    }
  }

  public applyAll(
    type: string,
    tiScope = "private",
    action: Function = (_newDoc: TocItem): void => {}
  ): Promise<boolean> {
    return this.getCaller(tiScope)
      .getItemsOfType(type, this.tiTarget)
      .then(docs => {
        docs.forEach(doc => {
          action(doc);
        });
        this.resetCache(false);
        return Promise.resolve(true);
      });
  }

  public bulk(docs: TocItem[], tiScope = this.tiScope): Promise<boolean> {
    // If you should delete doc with incremental
    docs.forEach((doc, index) => {
      // If you should use incremental system for this new revision and maxRevision isn't setted
      if (doc.minRevision < this.store.publicationRevision && !doc.maxRevision) {
        // If it's delete the document
        if (doc._deleted) {
          const newDoc = {
            ...doc,
            minRevision: this.store.publicationRevision
          };

          // We should not delete the document and only set maxRevision
          delete doc._deleted;
          doc.maxRevision = this.store.publicationRevision;

          docs.push(newDoc);

          // Update the array with the oldDoc modification
          docs[index] = doc;
        }
      }
    });

    return this.getCaller(tiScope)
      .bulk(docs)
      .then(results => {
        results.forEach((res, index) => {
          if (res.ok === true) {
            const doc = docs[index];

            // Remove from cache the old doc
            this.removeFromCache(doc);

            // Add to cache only if it is an elligible document
            if (!doc.maxRevision || doc.maxRevision < this.store.publicationRevision) {
              // Add the new doc
              this.addToCache(doc);
            }
          }
        });

        return true;
      });
  }

  public getAttachementFile(docId): Promise<Blob> {
    return this.getCaller(this.tiScope).getAttachmentsBlob(docId);
  }

  /**
   * Perform the synchronization of TocItems between remote and local databases.
   */
  public synchronize(): Promise<void> {
    return this.doPreSync()
      .then(() =>
        Promise.all(this.synchroScopes.map((scope: string) => this.synchronizeScope(scope)))
      )
      .then(() => this.doPostSync());
  }

  public deleteWithUndo(
    doc: TocItem,
    deleteMsg?: string,
    undoCallback?: Function,
    displayMsg = true
  ): Promise<boolean> {
    const docCopy: TocItem = { ...doc };
    return this.delete(doc)
      .then((res: boolean) => {
        if (displayMsg) {
          const message = deleteMsg
            ? deleteMsg
            : this.translate.instant("tocItem.deletedMsg", {
                tocItemType: this.tiType
              });
          this.messageService.success(message, this.translate.instant("undo"));
        }
        this.messageService.onAction.subscribe(this.undoDeleteFactory(docCopy, undoCallback));
        return res;
      })
      .catch(err => {
        console.error(err);
        const errMessage = this.translate.instant("tocItem.errorDelete", {
          tocItemType: this.tiType
        });
        this.messageService.error(errMessage);
        return false;
      });
  }

  /**
   * Create a standard undo function to pass to the deleteWithUndo function
   *
   * @param tocItemToRestore - The tocItem(s) to restore
   * @param undoCallback - A callback function to be executed on undo success
   */
  protected undoDeleteFactory(tocItemToRestore: TocItem | TocItem[], undoCallback?: Function) {
    return () => {
      const docToSave = Array.isArray(tocItemToRestore) ? tocItemToRestore : [tocItemToRestore];

      const saveProm = docToSave.map((doc: TocItem) => {
        delete doc._rev;
        return this.save(doc);
      });

      return Promise.all(saveProm).then((res: boolean[]) => {
        if (!res.includes(false)) {
          if (undoCallback) {
            undoCallback();
          }
          const undoMessage = this.translate.instant("undo.ok");
          this.messageService.success(undoMessage);
          return this.getItemsOfType();
        } else {
          const errMessage = this.translate.instant("undo.error");
          this.messageService.error(errMessage);
          return [];
        }
      });
    };
  }

  protected async delete(
    doc: TocItem,
    visibility = "private",
    updateCache = true
  ): Promise<boolean> {
    try {
      if (doc.minRevision === this.store.publicationRevision) {
        await this.getCaller(visibility).delete(doc);
      } else {
        // We don't know if a former publication still uses this doc
        // so we only update maxRevision
        doc.maxRevision = this.store.publicationRevision;
        await this.save(doc, visibility, false);
      }

      if (updateCache) {
        this.removeFromCache(doc);
      }

      return Promise.resolve(true);
    } catch (err) {
      if (err.message === "missing") {
        // whatever the reason, if a note is note found in db
        // we should remove it from tocItem.service caches array
        // and send a success message.
        if (updateCache) {
          this.removeFromCache(doc);
        }
        return Promise.resolve(true);
      } else {
        console.error(err);
        return Promise.resolve(false);
      }
    }
  }

  protected getRemoteDoc(docId: string, tiScope = this.tiScope): Promise<TocItem> {
    return this.getCaller(tiScope).doOnInstance(DBConnexionType.REMOTE, "get", docId);
  }

  protected removeFromCache(tocItem: TocItem): void {
    // If user made a synchro before, the variables may be set to undefined
    [
      this._tocItemsOfType || (this._tocItemsOfType = []),
      this._tocItemsForTarget || (this._tocItemsForTarget = [])
    ].map(cache => {
      const index = cache.findIndex(item => item._id === tocItem._id);
      // If tocItem isn't found slice() will destroy the last element
      if (index !== -1) {
        cache.splice(index, 1);
      }
    });
    // This is necessary when delete is called from
    // a tocItem list component that relies on datasource
    // see bookmark-list and notes-list
    this.updateTocItemOfTypeToNext();
    this.updateTocItemForTargetToNext();
  }

  protected addToCache(tocItem: TocItem, forceUpdateItemOfType = false): void {
    // If user made a synchro before, the variables may be set to undefined
    this._tocItemsOfType = this._tocItemsOfType || [];
    this._tocItemsForTarget = this._tocItemsForTarget || [];
    // If this._tocItemsOfType.length == 0 then the list of tocItems has not been retrieved yet so no need to push.
    // We can also force the update (see update() method use case)
    if (this._tocItemsOfType.length > 0 || forceUpdateItemOfType) {
      this._tocItemsOfType.push(tocItem);
    }
    this._tocItemsForTarget.push(tocItem);

    this.updateTocItemOfTypeToNext();
    this.updateTocItemForTargetToNext();
  }

  /**
   * Get all TocItems linked to document or folder <parent>
   *
   * @param targetId the document _id this tocItem is linked to (the target).
   * @param type the type of TocItem to return. If type === undefined or "" then return tocItems
   *                      of all types for target targetId
   * @param visibility any of "private | semi-private | public". Determines which DB to look into.
   * @memberof TocItemService
   */
  protected getItemsForTarget(
    targetId: string,
    type = this.tiType,
    tiScope = this.tiScope
  ): Promise<TocItem[]> {
    // This should happen after a synchronization where the cache is reset to undefined.
    if (!this._tocItemsForTarget) {
      this._tocItemsForTarget = [];
    }

    // When routing to search page, store.currentDMC is set to undefined
    if (!targetId) {
      return Promise.resolve([]);
    }

    // If no scope is specified, we get tocItems from every scopes
    if (!tiScope) {
      return Promise.all(
        Object.keys(this.visibilityMap).map((visibility: string) =>
          this.getCaller(visibility)
            .getItemsForTarget(targetId, type, true, this.tiTarget)
            .then((docs: TocItem[]) => this.shouldSynchronize(visibility).then(() => docs))
        )
      ).then((results: TocItem[][]) => {
        const docs = results.flat();
        this._tocItemsForTarget = docs;
        this.updateTocItemForTargetToNext();
        return docs;
      });
    }

    // Get items of specified scope
    return this.getCaller(tiScope)
      .getItemsForTarget(targetId, type, true, this.tiTarget)
      .then((docs: TocItem[]) => {
        this._tocItemsForTarget = docs;
        return this.shouldSynchronize(tiScope).then(() => {
          this.updateTocItemForTargetToNext();
          return docs;
        });
      });
  }

  /**
   * Check if the specified scope needs to be synchronized, and start the synchronization process if needed.
   *
   * @param tiScope The scope to check.
   */
  protected shouldSynchronize(tiScope = "private"): Promise<boolean> {
    if (TocItemService.syncPending || !this.synchroScopes.includes(tiScope)) {
      TocItemService.shouldSyncPromMap.delete(tiScope + this.tiTarget);
      return Promise.resolve(false);
    }

    if (!TocItemService.shouldSyncPromMap.has(tiScope + this.tiTarget)) {
      const prom = this.getCaller(tiScope)
        .shouldSynchronize()
        .then((shouldSync: boolean) => {
          if (shouldSync) {
            dispatchEvent(new Event("startSynchro"));
          }
          TocItemService.shouldSyncPromMap.delete(tiScope + this.tiTarget);
          return shouldSync;
        });
      TocItemService.shouldSyncPromMap.set(tiScope + this.tiTarget, prom);
    }

    return TocItemService.shouldSyncPromMap.get(tiScope + this.tiTarget);
  }

  /**
   * Get all TocItems of a certain Type
   *
   * @param type the type of TocItem to return.
   * @param visibility any of "private | semi-private | public". Determines which DB to look into.
   * @returns
   * @memberof TocItemService
   */
  protected getItemsOfType(
    type = this.tiType,
    tiScope = this.tiScope,
    shouldEmit = true
  ): Promise<TocItem[]> {
    // This should happen after a synchronization where the cache is reset to undefined.
    if (!this._tocItemsOfType) {
      this._tocItemsOfType = [];
    }

    if (this._tocItemsOfType.length > 0) {
      return this.shouldSynchronize(tiScope).then(() => this._tocItemsOfType);
    }

    // If no scope is specified, we get tocItems from all scopes
    if (!tiScope) {
      return Promise.all(
        Object.keys(this.visibilityMap).map((visibility: string) =>
          this.getCaller(visibility)
            .getItemsOfType(type, this.tiTarget)
            .then(docs => this.shouldSynchronize(visibility).then(() => docs))
        )
      ).then((results: TocItem[][]) => {
        const docs = results.flat();
        this._tocItemsOfType = docs;
        if (shouldEmit) {
          this.updateTocItemOfTypeToNext();
        }
        return docs;
      });
    }

    // By default we get tocItems from local database
    // The local database will updated with remote with sync()
    return this.getCaller(tiScope)
      .getItemsOfType(type, this.tiTarget)
      .then((docs: TocItem[]) => {
        this._tocItemsOfType = docs;
        return this.shouldSynchronize(tiScope).then(() => {
          if (shouldEmit) {
            this.updateTocItemOfTypeToNext();
          }
          return docs;
        });
      });
  }

  protected updateTocItemOfTypeToNext(): void {
    this.tocItemOfTypeSubject.next(this._tocItemsOfType);
  }

  protected preSync(): Promise<void> {
    return Promise.resolve();
  }

  protected postSync(): Promise<void> {
    return Promise.resolve();
  }

  // We consider to had the design children in remote if it missing it will don't work
  // The design will be allowed to be added in this function with the proxy
  private doSynchro(tiScope = this.tiScope): Promise<void> {
    if (!tiScope) {
      return Promise.reject("Scope should be defined");
    } else {
      return this.getCaller(tiScope).sync();
    }
  }

  /**
   * Call doSynchro function on a specified scope.
   *
   * @param tiScope The TI scope to synchronize.
   */
  private synchronizeScope(tiScope = this.tiScope): Promise<void> {
    return this.ngZone.runOutsideAngular(() => this.doSynchro(tiScope));
  }

  private doPreSync(): Promise<void> {
    return new Promise((resolve, reject) => {
      TocItemService.hasPreSync.subscribe(
        () => {
          this.preSyncCount++;
          if (this.preSyncCount === TI_SERVICES_PRE_SYNC_TOTAL) {
            this.preSyncCount = 0;
            TocItemService.hasPreSync.complete();
          }
        },
        err => {
          console.error(err);
          reject(err);
        },
        () => {
          resolve();
        }
      );
      TocItemService.shouldPreSync.next();
    });
  }

  private doPostSync(): Promise<void> {
    return new Promise((resolve, reject) => {
      TocItemService.hasPostSync.subscribe(
        () => {
          this.postSyncCount++;
          if (this.postSyncCount === TI_SERVICES_POST_SYNC_TOTAL) {
            this.postSyncCount = 0;
            TocItemService.hasPostSync.complete();
          }
        },
        err => {
          console.error(err);
          reject(err);
        },
        () => {
          resolve();
        }
      );
      TocItemService.shouldPostSync.next();
    });
  }
}
