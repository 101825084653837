import xpath from "xpath";
import { Util, OBJECT_SEPARATOR, ARRAY_SEPARATOR } from "@orion2/msm-formatter/util";
import { Interval } from "@orion2/msm-formatter/interval";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";

export class Margin {
  public static extractLegacy(xmlNode: Node): string {
    const data: string[] = [];

    const margins = xpath.select("MAINLIMI/NEXTPERF/INTERV", xmlNode);

    margins.forEach((margin: Node) => {
      const changed = xpath.select("string(.//@CHANGEMARK)", margin).length > 0;
      const val = Util.cleanXml(xpath.select("string(MARGIN/VAL)", margin));
      const unit = Util.cleanXml(xpath.select("string(MARGIN/UNIT)", margin));
      const operator = Util.cleanXml(xpath.select("string(MSMLIMITOPERATOR)", margin));

      data.push(
        operator + OBJECT_SEPARATOR + val + OBJECT_SEPARATOR + unit + OBJECT_SEPARATOR + changed
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static extractH160(xmlNode: Node): string {
    const data: string[] = [];

    const margins = xpath.select(
      "timeLimit/limitType/threshold | limit/descendant::threshold",
      xmlNode
    );
    margins.forEach((margin: Node) => {
      const changed =
        xpath.select("string(tolerance/@changeMark)", margin).length > 0 ||
        xpath.select("string(ancestor::limitType/@changeMark|ancestor::limit/@changeMark)", margin)
          .length > 0;
      const val = Util.cleanXml(xpath.select("string(tolerance/@toleranceValue)", margin));
      const unit = Interval.getIntervalUnit(
        Util.cleanXml(xpath.select("string(tolerance/@thresholdUnitOfMeasure)", margin))
      );

      const operator = Util.cleanXml(
        xpath.select(
          "string(timeLimit/limitType/remarks/simplePara | limit/remarks/simplePara)",
          margin
        )
      );

      data.push(
        operator +
          OBJECT_SEPARATOR +
          val +
          OBJECT_SEPARATOR +
          (unit === undefined ? "" : unit) +
          OBJECT_SEPARATOR +
          changed
      );
    });

    return data.join(ARRAY_SEPARATOR);
  }

  public static unserialize(data: string): TaskNamespace.Margin[] {
    return data
      ?.split(ARRAY_SEPARATOR)
      .map((margin: string) => margin.split(OBJECT_SEPARATOR))
      .map((val: string[]) => ({
        operator: val[0],
        val: val[1],
        unit: val[2],
        changed: val[3] === "true"
      }));
  }
}
