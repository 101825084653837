// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  cursor: pointer;
  font-size: 14px;
}
:host mat-card {
  margin: 15px;
}
:host td {
  padding: 0.3em 0.5em;
}
:host th {
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
}
:host .key {
  font-weight: bold;
  min-width: 100px;
  width: 30%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNtLWNhcmQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsZUFBQTtBQUNGO0FBQ0U7RUFDRSxZQUFBO0FBQ0o7QUFFRTtFQUNFLG9CQUFBO0FBQUo7QUFHRTtFQUNFLGlCQUFBO0VBQ0EsZUFBQTtFQUNBLGtCQUFBO0FBREo7QUFJRTtFQUNFLGlCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxVQUFBO0FBRkoiLCJmaWxlIjoiY20tY2FyZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgY3Vyc29yOiBwb2ludGVyO1xuICBmb250LXNpemU6IDE0cHg7XG5cbiAgbWF0LWNhcmQge1xuICAgIG1hcmdpbjogMTVweDtcbiAgfVxuXG4gIHRkIHtcbiAgICBwYWRkaW5nOiAwLjNlbSAwLjVlbTtcbiAgfVxuXG4gIHRoIHtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICBwYWRkaW5nOiAxMHB4IDA7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICB9XG5cbiAgLmtleSB7XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgbWluLXdpZHRoOiAxMDBweDtcbiAgICB3aWR0aDogMzAlO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/shared-module/cm-card/cm-card.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;AADJ;AAIE;EACE,iBAAA;EACA,gBAAA;EACA,UAAA;AAFJ;AACA,g0BAAg0B","sourcesContent":[":host {\n  cursor: pointer;\n  font-size: 14px;\n\n  mat-card {\n    margin: 15px;\n  }\n\n  td {\n    padding: 0.3em 0.5em;\n  }\n\n  th {\n    font-weight: bold;\n    padding: 10px 0;\n    text-align: center;\n  }\n\n  .key {\n    font-weight: bold;\n    min-width: 100px;\n    width: 30%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
