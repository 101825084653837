export namespace Basepub {
  export interface Product {
    srdFamilyLabel: string;
    occCode: string;
    langCode: string;
    title?: string;
    description?: string;
    customizationCode?: string;
    booklet: string;
    certification?: {
      code: string;
      label: string;
    };
    manual: Manual;
    isOnline: boolean;
    priority: number;
    isConsultable: boolean;
    displayRevisionNumber: boolean;
    revisions: ProductRevision[];
  }

  export interface Manual {
    imgName: string;
    category: string;
    code: string;
    label: string;
    typeCode: string;
    priority: number;
  }

  export interface ProductRevision {
    editionNumber: string;
    revNumber: string;
    indiceVersion: string;
    status: RevisionStatus;
    statusDate?: string;
    filename?: string;
    fileSize?: number;
    date: string;
    technicalRevisionNumber?: number;
    importedDate?: string;
    isTracked?: boolean;
    consultUrl?: string;
    uidRev?: string;
    classifications?: string[];
  }

  export enum RevisionStatus {
    CREATED = "CREATED",
    IMPORTED = "IMPORTED",
    AVAILABLE = "AVAILABLE",
    OBSOLETE = "OBSOLETE"
  }

  export interface Contract {
    uidAhead: string;
    number: string;
    renewalNumber: string;
    companyId: string;
    companyFullName: string;
    startDate: string;
    endDate: string;
    aircraftTechnicalName: string;
    aircrafts: {
      serialNumber: string;
      customizationCode: string;
      version: string;
    }[];
    infos?: string;
  }

  export interface Aircraft {
    commercialName: string;
    version: string[];
    technicalName: string;
  }

  export interface ContractSearchData {
    contractNumber?: string[];
    aircrafts?: Aircraft[];
    productType?: string[];
    language?: string[];
    customizationCode?: string[];
    booklet?: string[];
  }
}
