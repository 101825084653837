import { Injectable } from "@angular/core";
import { ProductType, PubDoc } from "libs/models/couch.models";
import { PouchService } from "@viewer/core/pouchdb";
import { user_manual } from "@viewer-assets/extra-docs/user_manual";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { ConfService } from "@viewer/core/conf/conf.service";
import { LocalStorageBackend } from "@openid/appauth/built/storage";
import { PubMeta } from "@viewer/core/pouchdb/caller";
import { Basepub } from "@orion2/models/basepub.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { getDisplayedRevision } from "@orion2/utils/front.utils";
import { ProductsService } from "@viewer/home/service/products.service";

@Injectable()
export class ProductsBP1Service implements ProductsService {
  private storage = new LocalStorageBackend();
  private pubs: PubDoc[];

  constructor(
    private basepubUserService: BasepubUserService,
    private pouchService: PouchService,
    private confService: ConfService
  ) {}

  public getPubDoc(pubId: string): PubDoc {
    return this.pubs?.find((pub: PubDoc) => pubId === pub._id);
  }

  public getLocalProducts(): Promise<PubDoc[]> {
    return this.getOfflinePubs().then((pubs: PubDoc[]) => {
      this.pubs = [...pubs, user_manual];
      return this.sortPubs(this.pubs);
    });
  }

  public getOnlineProducts(): Promise<PubDoc[]> {
    return this.getOnlinePubs().then((onlinePubs: PubDoc[]) => {
      this.pubs = onlinePubs;
      return this.sortPubs(this.pubs);
    });
  }

  public enrichPubDoc(pub: PubDoc): Promise<PubDoc> {
    return Promise.all([this.isLast(pub), this.hasStartedDownload(pub)]).then(
      ([isLast, hasStartedDownload]: boolean[]) => {
        pub.isLast = isLast;
        pub.hasStartedDownload = hasStartedDownload;
        pub.displayRevision = true;
        pub.isConsultable = true;
        pub.productType = ProductType.ONLINE;

        pub.infos = [
          pub.packageId,
          pub.revisionDate,
          pub.status,
          pub.revision,
          pub.occurrenceCode,
          pub.verbatimText,
          pub.manualCode,
          pub.subtitle
        ].join(" ");

        return pub;
      }
    );
  }

  /**
   * Store in local storage the last revision for every publication in remote database.
   */
  public storeLastRevisions(pubs: PubMeta[]): Promise<void[]> {
    const currentEnv = this.confService.conf.label;
    const uniqueRes = new Set<string>();
    const proms = pubs.map((res: PubMeta) => {
      // For each occCode, revisions are sorted by descending order; the first encoutered is the most recent.
      if (!uniqueRes.has(res.key)) {
        uniqueRes.add(res.key);
        return this.storage.setItem(`${currentEnv}_${res.key}`, res.value);
      }
      return Promise.resolve();
    });

    return Promise.all(proms);
  }

  private sortPubs(pubs: PubDoc[]): PubDoc[] {
    return pubs.sort((pub1: PubDoc, pub2: PubDoc) => {
      // publication cards are grouped by occurenceCode
      // we want to sort groups by verbatimText
      // and in each group we sort publication cards by displayed revisions
      if (pub1.occurrenceCode !== pub2.occurrenceCode) {
        return pub1.verbatimText.localeCompare(pub2.verbatimText);
      }
      return getDisplayedRevision(pub2).localeCompare(getDisplayedRevision(pub1));
    });
  }

  private getPubDocs(ids: string[], target = DBConnexionType.REMOTE): Promise<PubDoc[]> {
    return this.pouchService.pubCaller
      .getPubs(ids, target)
      .then((pubs: PubDoc[]) => Promise.all(pubs.map((pub: PubDoc) => this.enrichPubDoc(pub))));
  }

  private getSubscriptionIds(): Promise<string[]> {
    if (!this.confService.useAuth) {
      return this.pouchService.pubCaller
        .getPubsMeta(["all"])
        .then((pubMetas: PubMeta[]) => pubMetas.map((meta: PubMeta) => meta.id));
    }

    const params = {
      status: "AVAILABLE",
      productType: "ONLINE"
    };
    return this.basepubUserService
      .getProducts(params, "basepub/api/v1")
      .then((basepubProducts: Basepub.Product[]) => {
        const occCodes = basepubProducts.map((product: Basepub.Product) =>
          product.occCode.toString()
        );

        return this.pouchService.pubCaller
          .getPubsMeta(occCodes)
          .then((pubMetas: PubMeta[]) =>
            this.storeLastRevisions(pubMetas).then(() => pubMetas.map((meta: PubMeta) => meta.id))
          );
      });
  }

  private isLast(pub: PubDoc): Promise<boolean> {
    const currentEnv = this.confService.conf.label;
    return this.storage
      .getItem(`${currentEnv}_${pub.occurrenceCode}`)
      .then((lastRevision: string) => (lastRevision ? pub.revision === lastRevision : true))
      .catch(() => true);
  }

  private hasStartedDownload(pub: PubDoc): Promise<boolean> {
    return this.storage
      .getItem(`${pub._id}__downloaded`)
      .then((download: string) => parseFloat(download) > 0);
  }

  private getOnlinePubs(): Promise<PubDoc[]> {
    return this.getSubscriptionIds().then((ids: string[]) =>
      this.getPubDocs(ids, DBConnexionType.REMOTE)
    );
  }

  private getOfflinePubs(): Promise<PubDoc[]> {
    return this.pouchService.pubCaller
      .getPubsMeta(["all"], DBConnexionType.LOCAL)
      .then((response: PubMeta[]) => {
        const ids = response.map((pubMeta: PubMeta) => pubMeta.id);
        return this.getPubDocs(ids, DBConnexionType.LOCAL);
      });
  }
}
