import { escapeHTML } from "@viewer/shared-module/helper.utils";
import { getSymbolId } from "@orion2/utils/functions.utils";

export class StepObject {
  id: string = undefined;
  chip = "";
  illusId: string = undefined;
  overlayIds?: string[] = undefined;
  internalRefId: string = undefined;
  internalRefTargetType: string = undefined;
  ref: string = undefined;
  stepValue: string = undefined;
  level = 0;
}

export interface PrincipalStepObject {
  title: string;
  stepId: string;
  mediaId: string;
}

export class HotspotObject {
  internalRefId: string = undefined;
  internalRefTargetType: string = undefined;
  hotspotRef: string = undefined;
  hotspotValue: string = undefined;
}

export class MultiMediaInfo {
  id: string = undefined;
  ref: string = undefined;
  value: string = undefined;
}

export function getChipAndLevel(stepFragment: Element): { label: string; level: number } {
  const chips = stepFragment.querySelectorAll(".proced_level_chip_type3_class");
  let label = ""; // ex : 1.1.2
  chips.forEach(chip => {
    label += chip.innerHTML;
  });
  return {
    label,
    level: chips.length
  };
}

/**
 * Get the illustration ID (infoEntityIdent) associated to a given step
 *
 * @param xmlNodes
 * @param step
 */
function getIllustrationID(xmlNodes: Document, step: Element): string {
  // Check if step has an image as illustration
  const graphic = step.querySelector(":scope > figure > graphic");

  if (graphic) {
    return graphic.getAttribute("infoEntityIdent");
  }

  // Check if step has a video as illustration
  // A step illustrated with a video is identified by irtt10
  const internalRefIrtt10 = step.querySelector("internalRef[internalRefTargetType = 'irtt10']");
  if (internalRefIrtt10) {
    const internalRefId = internalRefIrtt10.getAttribute("internalRefId");
    return xmlNodes
      .querySelector(`multimediaObject[id='${internalRefId}']`)
      ?.getAttribute("infoEntityIdent");
  }
  // No illustration found
  return undefined;
}

export function getSymbolReferential(
  isLegacyImport: boolean,
  xmlNodes,
  dmc: string
): { [key: string]: string[] } {
  // add symbols
  const referential = {};
  const symbolXMLNodes = xmlNodes.querySelectorAll("symbol");

  for (const symbol of symbolXMLNodes) {
    const associateStep = getSymbolStep(symbol);
    const stepRef = symbol.getAttribute("infoEntityIdent");

    // concat in case of multiple symbols
    if (stepRef) {
      const symbolId = getSymbolId(isLegacyImport, dmc, stepRef);
      referential[associateStep] = referential[associateStep]
        ? [symbolId, ...referential[associateStep]]
        : [symbolId];
    }
  }
  return referential;
}

/**
 *
 * @param symbolXmlNode
 */
function getSymbolStep(symbolXmlNode: Element): string {
  const closest = symbolXmlNode.closest("proceduralStep");
  return closest ? closest.getAttribute("id") : "";
}

/**
 * Get Internal ref for hotspot
 *
 * @param xmlNodes
 * @param currentDMC
 * @param multiMediaInfo
 */
export function createStepObjects(
  xmlNodes,
  isLegacyImport,
  dmc: string,
  multiMediaInfo: MultiMediaInfo[],
  proceduralStep
): StepObject[] {
  const overlayReferential = getSymbolReferential(isLegacyImport, xmlNodes, dmc);
  let _stepInfo = new MultiMediaInfo();

  // Construct hotspotObject
  return Array.from(proceduralStep).map((step: Element) => {
    const stepObject = new StepObject();
    stepObject.illusId = getIllustrationID(xmlNodes, step);
    stepObject.id = step.getAttribute("id");
    stepObject.overlayIds = overlayReferential[stepObject.id];
    if (!stepObject.id) {
      stepObject.id = "firstLevel";
    }

    // some steps do not have an internal ref
    const internalRef = step.querySelector("internalRef");
    if (internalRef) {
      stepObject.internalRefId = internalRef.getAttribute("internalRefId");
      stepObject.internalRefTargetType = internalRef.getAttribute("internalRefTargetType");

      // Some DMCs do not have a multimedia tag
      if (multiMediaInfo && multiMediaInfo.length > 0) {
        _stepInfo = multiMediaInfo.find(multiMedia => multiMedia.id === stepObject.internalRefId);
        if (_stepInfo) {
          stepObject.ref = _stepInfo.ref;
          stepObject.stepValue = _stepInfo.value;
        }
      }
    }
    return stepObject;
  });
}

/**
 * Create HotSpot Object
 *
 * @param xmlNodes
 */
export function createHotspotObject(
  xmlNodes: Element,
  multiMediaInfo: MultiMediaInfo[]
): HotspotObject[] {
  const internalRefs = xmlNodes.querySelectorAll("proceduralStep > para > internalRef");

  // Construct hotspotObject
  return Array.from(internalRefs).map((internalRef: Element) => {
    const hotspotObject = new HotspotObject();
    hotspotObject.internalRefTargetType = internalRef.getAttribute("internalRefTargetType");

    if (
      hotspotObject.internalRefTargetType === "irtt55" ||
      hotspotObject.internalRefTargetType === "irtt54" ||
      hotspotObject.internalRefTargetType === "irtt11"
    ) {
      hotspotObject.internalRefId = internalRef.getAttribute("internalRefId");
      const hotspot: MultiMediaInfo = multiMediaInfo.find(
        multiMedia => multiMedia.id === hotspotObject.internalRefId
      );

      if (hotspot && hotspot.value && hotspot.ref) {
        const index: number = hotspot.value.indexOf("-") + 1;
        // There are two formats for hotspots:
        // Format 1: hotspot && Format 2: view#hotspot
        // The player just needs the value of "hotspot". In case we are in format 2, I only get the hotspot value
        hotspotObject.hotspotValue = hotspot.value
          .substr(index, hotspot.value.length)
          .split("#")[0]
          .trim();
        hotspotObject.hotspotRef = hotspot.ref.includes("#")
          ? hotspot.ref.split("#")[0]
          : hotspot.ref;
      }
      return hotspotObject;
    }
  });
}

/**
 * Get Procedural Object in XML
 *
 * @param xmlNodes
 */
export function getProceduralStepObject(xmlNodes) {
  return xmlNodes.querySelectorAll("proceduralStep");
}

/**
 * Get Multimedia Object with multimediaType as "3D" or "mp4" in XML.
 *
 * @param xmlNodes
 */
export function getMultimediaObject(xmlNodes: Element): Element {
  return xmlNodes.querySelector(
    "mainProcedure > multimedia > multimediaObject[multimediaType='3D']," +
      "mainProcedure > multimedia > multimediaObject[multimediaType='mp4']"
  );
}

/**
 * Return multiMedia Object with information
 *
 * @param xmlNodes
 */
export function getMultimediaInformation(multiMediaObject): MultiMediaInfo[] {
  const multiMediaObjectAttribute = multiMediaObject.querySelectorAll("parameter");

  return Array.from(multiMediaObjectAttribute).map(
    (multiMedia: Element) =>
      ({
        id: multiMedia.getAttribute("id"),
        ref: multiMedia.getAttribute("parameterIdent"),
        value: multiMedia.getAttribute("parameterValue")
      } as MultiMediaInfo)
  );
}

/**
 * Change Hotspot value by parameterValue in multimedia Tag
 *
 * @param frag HTML
 * @param multiMediaObject
 */
export function changeHotspotValue(frag: Element, multiMediaObject: MultiMediaInfo[]) {
  const hotspots = frag.querySelectorAll(".AHX4_ref_hotspot");
  if (multiMediaObject && multiMediaObject.length > 0) {
    hotspots.forEach(hotspot => {
      // if internalrefid is undefined, find crash
      const internalrefid: string = hotspot.getAttribute("internalrefid") || "";
      const brutData: MultiMediaInfo = multiMediaObject.find(
        multiMedia => multiMedia.id === internalrefid
      );
      // BrutData can be undefined if xslt is not a last version.
      if (brutData) {
        const brutValue = brutData.value;
        const index: number = brutValue.indexOf("-") + 1;
        let value: string = brutValue.substr(index, brutValue.length).split("#")[0].trim();
        // Change special caracter with html code for create a safestring #ORION20-3321
        value = escapeHTML(value);
        hotspot.innerHTML = value;
      }
    });
  }
}
