import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { SearchService } from "@viewer/core/search/search.service";
import { LoggerService } from "@viewer/core/logger/logger.service";
import { PouchService } from "@viewer/core/pouchdb/pouch.service";
import { Store } from "@viewer/core/state/store";
import { reaction } from "mobx";
import { ESearchState } from "@viewer/core/search/searchState";

@Injectable()
export class BackgroundService implements OnDestroy {
  readonly displayDoneListener: Function;

  private _launcSearchIndex: BehaviorSubject<boolean>;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly launcSearchIndex: Observable<boolean>;

  constructor(
    protected logger: LoggerService,
    protected searchService: SearchService,
    protected pouchService: PouchService,
    private store: Store
  ) {
    this.displayDoneListener = reaction(
      () => ({
        pubId: this.store.publicationID,
        pouchReady: this.store.pouchReady
      }),
      async state => {
        if (state.pubId && state.pouchReady) {
          return this.launchSearchBuild();
        }
        return false;
      }
    );
  }

  /**
   * Remove display done subscription
   */
  ngOnDestroy(): void {
    this.displayDoneListener();
  }

  /**
   * Launch the search index build in background
   */
  public async launchSearchBuild() {
    if (this._launchSearchCondition()) {
      this.searchService.reloadIndex();
    }
  }

  /**
   *
   * @returns
   * @memberof BackgroundService
   */
  public forceSearchReload() {
    return this.searchService.reloadIndex();
  }

  /**
   * Launch the search if not already load
   */
  private _launchSearchCondition() {
    const state: ESearchState = this.store.offlineLoadingState;
    return !(state === ESearchState.READY || state === ESearchState.LOADING);
  }
}
