import { default as PouchDB } from "pouchdb";
import PouchdbFind from "pouchdb-find";

export class OrionDbCreator {
  static createDb(dbName, dbParams) {
    PouchDB.plugin(PouchdbFind);

    return new PouchDB(dbName, dbParams);
  }
}
