import { AppAuthError } from "@openid/appauth/built/errors";
import { AuthFlowConfiguration } from "@orion2/auth/auth.flow.conf";
import { OakRequest } from "@orion2/auth/oak.request";
import { OakError, OakErrorJson, OakResponse, OakResponseJson } from "@orion2/auth/oak.response";
import { MimeType } from "@orion2/models/enums";
import { CustomRequestor } from "@orion2/auth/auth.flow";
import { RequestHandler } from "@orion2/auth/request.handler";

/**
 * The default token request handler.
 */
export class OakRequestHandler extends RequestHandler<OakResponse> {
  get key(): string {
    return "OAK_REQUEST_HANDLER";
  }

  private requestor: CustomRequestor;

  public setRequestor(requestor: CustomRequestor): void {
    this.requestor = requestor;
  }

  public performOakRequest(
    configuration: AuthFlowConfiguration,
    request: OakRequest
  ): Promise<OakResponse> {
    let url = `${configuration.baseUrl}/oak`;
    if (request.occCode) {
      url += `?occCode=${request.occCode}`;
    }

    const settings = {
      url,
      method: "GET",
      dataType: "json",
      headers: {
        Authorization: `Bearer ${request.bearer.access_token}`,
        "Content-Type": MimeType.JSON,
        Origin: configuration.baseUrl,
        Referer: configuration.baseUrl
      }
    };
    if (request.oak) {
      settings.headers["oak"] = request.oak;
    }

    this.requestor
      .xhrWithErrorBody<OakResponseJson | OakErrorJson>(settings)
      .then((response: OakResponseJson | OakErrorJson) => {
        if (this.isOakResponse(response)) {
          this.resolvePromise(new OakResponse(response, request.bearer.access_token));
        } else {
          this.rejectPromise(new AppAuthError(response.error, new OakError(response)));
        }
      })
      // TODO DG rework the error message
      .catch((err: Error) => this.rejectPromise({ code: 400, message: err }));
    return this.promise;
  }

  private isOakResponse(response: OakResponseJson | OakErrorJson): response is OakResponseJson {
    return (response as OakErrorJson).error === undefined;
  }
}
