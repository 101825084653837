export const infoDuXSL = `<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsl="http://www.w3.org/1999/XSL/Transform" version="1.0">
<xsl:output doctype-public="doctype html" encoding="iso-8859-1" indent="yes" method="html"/>

<xsl:param name="isMobile" select="false()"/>
<xsl:param name="CHANGEMARK_CARRE" select="'changemarkCarre'"/>
<xsl:param name="STATUS_DIV_CLASS">duStatusDiv</xsl:param>
<xsl:param name="CHANGEMARK_LEGEND" select="&quot;[CHANGEMARK_LEGEND_LABEL] &quot;"/>
<xsl:param name="STATUS_DISPLAYED_CLASS">status_displayed</xsl:param>
<xsl:param name="STATUS_HIDDEN_CLASS">status_hidden</xsl:param>
<xsl:param name="STATUS_TAB_TITLE" select="&quot;[STATUS_ADDRESS_STATUS_REFERENCE_LABEL]&quot;"/>
<xsl:param name="STATUS_TABLE_LABEL_CLASS" select="'status_table_label_class'"/>
<xsl:param name="STATUS_REFERENCE_ELEMENT" select="'[STATUS_REFERENCE_LABEL]'"/>
<xsl:param name="STATUS_TABLE_TITLE_CLASS" select="'status_table_title_class'"/>
<xsl:param name="STATUS_DMCODE_ELEMENT" select="&quot;[STATUS_DMC_LABEL]&quot;"/>
<xsl:param name="STATUS_TABLE_VALUE_CLASS" select="'status_table_value_class'"/>
<xsl:param name="UNDERSCORE_SEPARATOR" select="&quot; - &quot;"/>
<xsl:param name="STATUS_LANGAGE_ELEMENT" select="&quot;[STATUS_LANGUAGE_LABEL]&quot;"/>
<xsl:param name="OPENING_PARENTHESIS" select="&quot;(&quot;"/>
<xsl:param name="CLOSING_PARENTHESIS" select="&quot;)&quot;"/>
<xsl:param name="SPACE" select="' '"/>
<xsl:param name="STATUS_ISSUEDATE_ELEMENT" select="&quot;[STATUS_EDITION_DATE_LABEL]&quot;"/>
<xsl:param name="DATE_SEPARATOR" select="&quot;.&quot;"/>
<xsl:param name="STATUS_APPLICABILITY_ELEMENT" select="&quot;[STATUS_APPLICABILITY_LABEL]&quot;"/>
<xsl:param name="STATUS_APPLICABILITIES_SHOW_HIDE" select="'applicabilities_show_hide'"/>
<xsl:param name="STATUS_APPLICABILITIES_DISPLAYED" select="'applicabilities_displayed'"/>
<xsl:param name="DISPLAY_NONE" select="'display: none;'"/>
<xsl:param name="DISPLAY_INLINE" select="'display: inline;'"/>
<xsl:param name="STATUS_APPLICABILITIES_HIDDEN" select="'applicabilities_hidden'"/>
<xsl:param name="STATUS_SHOW_HIDE_APPLICABILITIES_ELEMENT" select="'[STATUS_SHOW_HIDE_APPLICABILITIES_LABEL]'"/>
<xsl:param name="STATUS_APPLICABILITY_ODD_TR" select="'status_applicability_odd_tr'"/>
<xsl:param name="STATUS_APPLICABILITY_TR" select="'status_applicability_tr'"/>
<xsl:param name="STATUS_APPLICABILITY_MODEL_ELEMENT" select="&quot;[STATUS_APPLICABILITY_MODEL_LABEL]&quot;"/>
<xsl:param name="STATUS_APPLICABILITY_VERSION_ELEMENT" select="&quot;[STATUS_APPLICABILITY_VERSION_LABEL]&quot;"/>
<xsl:param name="COMMA" select="&quot;,&quot;"/>
<xsl:param name="STATUS_APPLICABILITY_SERIALNBR_ELEMENT" select="&quot;[STATUS_APPLICABILITY_SERIALNBR_LABEL]&quot;"/>
<xsl:param name="STATUS_STATUS_ELEMENT" select="&quot;[STATUS_STATUS_TITLE_LABEL]&quot;"/>
<xsl:param name="STATUS_STATUS_SUBTITLE" select="&quot;[STATUS_STATUS_LABEL]&quot;"/>
<xsl:param name="STATUS_MAINT_AFFECTED_ELEMENT_DESCRIPTION" select="'[STATUS_MAINT_AFFECTED_DESCRIPTION_LABEL]'"/>
<xsl:param name="STATUS_REASONFORUPDATE_ELEMENT" select="&quot;[STATUS_UPDATING_REASON_LABEL]&quot;"/>
<xsl:param name="STATUS_MAINT_AFFECTED_ELEMENT" select="&quot;[STATUS_MAINT_AFFECTED_LABEL]&quot;"/>
<xsl:param name="TRUE" select="&quot;[TRUE_LABEL]&quot;"/>
<xsl:param name="FALSE" select="&quot;[FALSE_LABEL]&quot;"/>

<xsl:template match="identAndStatusSection" name="identAndStatusSection">
    <xsl:if test="//node()[@CHANGEMARK='1'] or //node()[@changemark='1']">
        <xsl:element name="div">
            <xsl:element name="span">
                <xsl:attribute name="class">
                    <xsl:value-of select="$CHANGEMARK_CARRE"/>
                </xsl:attribute>
            </xsl:element>
            <xsl:element name="span">
                <xsl:attribute name="style">font-weight:bold</xsl:attribute>
                <xsl:value-of select="$CHANGEMARK_LEGEND"/>
            </xsl:element>
        </xsl:element>
    </xsl:if>

    <xsl:if test="not($isMobile)">
        <xsl:element name="div">
            <xsl:attribute name="class"><xsl:value-of select="$STATUS_DIV_CLASS"/></xsl:attribute>
            <xsl:apply-templates select="child::node()"/>
        </xsl:element>
    </xsl:if>
</xsl:template>

<xsl:template match="dmAddress" name="translateDmAddress">
    <xsl:element name="table">
        <xsl:attribute name="class">
            <xsl:value-of select="'status_table'"/>
        </xsl:attribute>
        <xsl:element name="tr">
            <xsl:element name="th">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_LABEL_CLASS"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_REFERENCE_ELEMENT"/>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_DMCODE_ELEMENT"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:attribute name="id">
                    <xsl:value-of select="'DMC_VALUE'"/>
                </xsl:attribute>
                <xsl:if test="not(dmIdent/dmCode[@systemCode='00' and @subSystemCode='0' and @subSubSystemCode='0' and @assyCode='00' and @disassyCode='00' and @disassyCodeVariant='000'])">
                    <xsl:value-of select="ancestor::*/WP6Status/shortDmc"/>
                </xsl:if>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_TAB_TITLE"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:choose>
                    <xsl:when test="./dmAddressItems/dmTitle/infoName!=''">
                        <xsl:value-of select="concat(./dmAddressItems/dmTitle/techName, $UNDERSCORE_SEPARATOR,./dmAddressItems/dmTitle/infoName)"/>
                    </xsl:when>
                    <xsl:otherwise>
                        <xsl:value-of select="dmAddressItems/dmTitle/techName"/>
                    </xsl:otherwise>
                </xsl:choose>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_LANGAGE_ELEMENT"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="concat(./dmIdent/language/@countryIsoCode,$OPENING_PARENTHESIS,$SPACE,./dmIdent/language/@languageIsoCode,$CLOSING_PARENTHESIS,$SPACE)"/>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_ISSUEDATE_ELEMENT"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="concat(dmAddressItems/issueDate/@year,$DATE_SEPARATOR,dmAddressItems/issueDate/@month,$DATE_SEPARATOR,dmAddressItems/issueDate/@day)"/>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="th">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_LABEL_CLASS"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_APPLICABILITY_ELEMENT"/>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_APPLICABILITIES_SHOW_HIDE"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$SPACE"/>
                <xsl:value-of select="$STATUS_SHOW_HIDE_APPLICABILITIES_ELEMENT"/>
            </xsl:element>
        </xsl:element>

       <xsl:for-each select="ancestor::*/WP6Status/WP6ApplicList">
            <xsl:element name="tr">
                <xsl:choose>
                    <xsl:when test="(position() mod 2) = 0">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_APPLICABILITY_ODD_TR"/>
                        </xsl:attribute>
                    </xsl:when>
                    <xsl:otherwise>
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_APPLICABILITY_TR"/>
                        </xsl:attribute>
                    </xsl:otherwise>
                </xsl:choose>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_APPLICABILITY_MODEL_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="./applic[1]/@model"/>
                </xsl:element>
            </xsl:element>
           <xsl:element name="tr">
                <xsl:choose>
                    <xsl:when test="(position() mod 2) = 0">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_APPLICABILITY_ODD_TR"/>
                        </xsl:attribute>
                    </xsl:when>
                    <xsl:otherwise>
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_APPLICABILITY_TR"/>
                        </xsl:attribute>
                    </xsl:otherwise>
                </xsl:choose>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_APPLICABILITY_VERSION_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                    </xsl:attribute>
                    <xsl:for-each select="applic">
                        <xsl:choose>
                            <xsl:when test="position() = 1">
                                <xsl:value-of select="./@version"/>
                            </xsl:when>
                            <xsl:otherwise>
                                <xsl:value-of select="concat($COMMA, $SPACE, ./@version)"/>
                            </xsl:otherwise>
                        </xsl:choose>
                    </xsl:for-each>
                </xsl:element>
            </xsl:element>
           <xsl:element name="tr">
                <xsl:choose>
                    <xsl:when test="(position() mod 2) = 0">
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_APPLICABILITY_ODD_TR"/>
                        </xsl:attribute>
                    </xsl:when>
                    <xsl:otherwise>
                        <xsl:attribute name="class">
                            <xsl:value-of select="$STATUS_APPLICABILITY_TR"/>
                        </xsl:attribute>
                    </xsl:otherwise>
                </xsl:choose>
                <xsl:element name="td">
                    <xsl:attribute name="class">
                        <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                    </xsl:attribute>
                    <xsl:value-of select="$STATUS_APPLICABILITY_SERIALNBR_ELEMENT"/>
                </xsl:element>
                <xsl:element name="td">
                    <xsl:attribute name="class"><xsl:value-of select="concat($STATUS_TABLE_VALUE_CLASS, ' applicability_serial_numbers_value')"/></xsl:attribute>
                    <xsl:for-each select="applic">
                        <xsl:choose>
                            <xsl:when test="position() = 1">
                                <xsl:value-of select="./@serialno"/>
                            </xsl:when>
                            <xsl:otherwise>
                                <xsl:value-of select="concat($COMMA, $SPACE, ./@serialno)"/>
                            </xsl:otherwise>
                        </xsl:choose>
                    </xsl:for-each>
                </xsl:element>
            </xsl:element>
        </xsl:for-each>

        <xsl:element name="tr">
            <xsl:element name="th">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_LABEL_CLASS"/>
                </xsl:attribute>
                <xsl:attribute name="colspan">
                    <xsl:value-of select="2"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_STATUS_ELEMENT"/>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_STATUS_SUBTITLE"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:call-template name="getStatus">
                    <xsl:with-param name="status" select="following-sibling::dmStatus/@issueType"/>
                    <xsl:with-param name="urtCode" select="following-sibling::dmStatus/reasonForUpdate/@updateReasonType"/>
                </xsl:call-template>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_MAINT_AFFECTED_ELEMENT_DESCRIPTION"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:call-template name="getChangeDesc">
                    <xsl:with-param name="code" select="following-sibling::dmStatus/reasonForUpdate/@updateReasonType"/>
                </xsl:call-template>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_REASONFORUPDATE_ELEMENT"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="following-sibling::dmStatus/reasonForUpdate"/>
            </xsl:element>
        </xsl:element>
        <xsl:element name="tr">
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_TITLE_CLASS"/>
                </xsl:attribute>
                <xsl:value-of select="$STATUS_MAINT_AFFECTED_ELEMENT"/>
            </xsl:element>
            <xsl:element name="td">
                <xsl:attribute name="class">
                    <xsl:value-of select="$STATUS_TABLE_VALUE_CLASS"/>
                </xsl:attribute>
                <xsl:variable name="code" select="following-sibling::dmStatus/reasonForUpdate/@updateReasonType"/>
                <xsl:choose>
                    <xsl:when test="$code='urt01' or $code='urt02' or $code='urt03' or $code='urt52' or $code='urt54' or $code='urt56' or $code='urt58' or $code='urt60' or $code='urt62'">
                        <xsl:value-of select="$FALSE"/>
                    </xsl:when>
                    <xsl:when test="$code='urt04' or $code='urt51' or $code='urt53' or $code='urt55' or $code='urt57' or $code='urt59' or $code='urt61' or $code='urt63'">
                        <xsl:value-of select="$TRUE"/>
                    </xsl:when>
                    <xsl:when test="following-sibling::dmStatus/reasonForUpdate/@updateHighlight = '1'">
                        <xsl:value-of select="$TRUE"/>
                    </xsl:when>
                    <xsl:when test="following-sibling::dmStatus/reasonForUpdate/@updateHighlight = '0'">
                        <xsl:value-of select="$FALSE"/>
                    </xsl:when>
                </xsl:choose>
            </xsl:element>
        </xsl:element>
    </xsl:element>
</xsl:template>

<xsl:template name="getStatus">
    <xsl:param name="status"/>
    <xsl:param name="urtCode"/>
    <xsl:choose>
        <xsl:when test="$urtCode='urt63' or $status='deleted'">
            <xsl:value-of select="'D'"/>
        </xsl:when>
        <xsl:when test="$status='changed'">
            <xsl:value-of select="'R'"/>
        </xsl:when>
        <xsl:when test="$status='status'or $status='unchanged'">
            <xsl:value-of select="'U'"/>
        </xsl:when>
        <xsl:when test="$status='new'">
            <xsl:value-of select="'N'"/>
        </xsl:when>
    </xsl:choose>
</xsl:template>

<xsl:template name="getChangeDesc">
    <xsl:param name="code"/>
    <xsl:choose>
        <xsl:when test="$code='urt51' or $code='urt52'">
            <xsl:value-of select="'N'"/>
        </xsl:when>
        <xsl:when test="$code='urt53' or $code='urt54' or $code='urt81'">
            <xsl:value-of select="'M'"/>
        </xsl:when>
        <xsl:when test="$code='urt01' or $code='urt82'">
            <xsl:value-of select="'F'"/>
        </xsl:when>
        <xsl:when test="$code='urt55' or $code='urt56' or $code='urt83'">
            <xsl:value-of select="'I'"/>
        </xsl:when>
        <xsl:when test="$code='urt59' or $code='urt60' or $code='urt84'">
            <xsl:value-of select="'R'"/>
        </xsl:when>
        <xsl:when test="$code='urt57' or $code='urt58' or $code='urt85'">
            <xsl:value-of select="'C'"/>
        </xsl:when>
        <xsl:when test="$code='urt61' or $code='urt62'">
            <xsl:value-of select="'O'"/>
        </xsl:when>
    </xsl:choose>
</xsl:template>

<xsl:template match="ENTITE_GRAPHIQUE"></xsl:template>
<xsl:template match="dmStatus" name="displayDmStatus"/>
<xsl:template match="content" name="displayContent"></xsl:template>
<xsl:template match="WP6Status" name="displayWP6Status"></xsl:template>
<xsl:template match="WP6RefBy"></xsl:template>
<xsl:template match="WP6PmInformation"></xsl:template>

</xsl:stylesheet>
`;
