// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
:host.hide {
  display: none;
}
:host :host-context(.fulltext) {
  display: none;
}

.nav-buttons {
  background-color: #fafafa !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1lZGlhLXN3aXRjaC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGFBQUE7RUFDQSxzQkFBQTtFQUNBLHVCQUFBO0FBREY7QUFHRTtFQUNFLGFBQUE7QUFESjtBQUlFO0VBQ0UsYUFBQTtBQUZKOztBQU1BO0VBQ0Usb0NBQUE7QUFIRiIsImZpbGUiOiJtZWRpYS1zd2l0Y2guY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwibGlicy90aGVtZS9vcmlvbi10aGVtZVwiO1xuXG46aG9zdCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuXG4gICYuaGlkZSB7XG4gICAgZGlzcGxheTogbm9uZTtcbiAgfVxuXG4gIDpob3N0LWNvbnRleHQoLmZ1bGx0ZXh0KSB7XG4gICAgZGlzcGxheTogbm9uZTtcbiAgfVxufVxuXG4ubmF2LWJ1dHRvbnMge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAkY29sb3Itd2hpdGUgIWltcG9ydGFudDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/shared-module/media-switch/media-switch.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,aAAA;AAFJ;;AAMA;EACE,oCAAA;AAHF;AACA,wvBAAwvB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n\n  &.hide {\n    display: none;\n  }\n\n  :host-context(.fulltext) {\n    display: none;\n  }\n}\n\n.nav-buttons {\n  background-color: $color-white !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
