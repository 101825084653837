import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Store, NoteService, ConfService } from "@viewer/core/index";
import { MediaService } from "@viewer/media-module/media/media.service";
import { runInAction, reaction } from "mobx";
import { ActivatedRoute, Router } from "@angular/router";
import xpath from "xpath";
import { lastValueFrom, Subscription } from "rxjs";
import { PlayerService } from "@viewer/core/player/player.service";
import packageJSON from "@package-json";
import { environment } from "@viewer-env/environment";
import { HotspotObject } from "@viewer/content-provider/step-dom-manipulator";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { getMediaId } from "@orion2/utils/functions.utils";
import { FullHttpService } from "libs/http/fullHttp.service";
import { IpcDetail } from "@orion2/models/ipc.models";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "o-player",
  templateUrl: "./player.component.html",
  styleUrls: ["./player.component.scss"]
})
export class PlayerComponent implements OnInit, OnDestroy {
  public isIPC3D = false;
  public runSwitchView = false;
  public playerIsVisible = false;

  private experience = undefined;
  private player3DPlay = undefined;
  private documentId: string;
  private currentHotspotIdReaction: Function;
  private stepMetaMediaReaction: Function;
  private _currentViewIdReaction: Function;
  private player3DIsReadyReaction: Function;
  private eventLstBind: EventListener;
  private blobUrl: string;
  private isHotspotBind;
  private playerCanvas = undefined;
  private playerOption = {
    smgUIAssemblyPanel: "false",
    smgUIDressupsPanel: "false",
    smgUIScenariosPanel: "false",
    smgUIPropertiesPanel: "false",
    smgPaper: "false",
    smgUI: "false"
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private SMGView = new Map();
  private _subscriptions: Subscription[] = [];
  private lastHotspotId: string = undefined;
  private lastSMG: string;

  constructor(
    private mediaService: MediaService,
    public store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private noteService: NoteService,
    private playerService: PlayerService,
    private cd: ChangeDetectorRef,
    private http: FullHttpService,
    private httpClient: HttpClient,
    private confService: ConfService
  ) {}

  ngOnInit() {
    // Init Player 3D after DOM is ready.
    this.instantiate3DPlayer();
    this.currentViewIdReaction();
    this.playerStarter();

    // stepMetaMedia can be undifine in application start.
    // This reaction is necessary for start player in DMC changement
    this.stepMetaMediaReaction = reaction(
      () => ({
        stepMetaMedia: this.store.stepMetaMedia,
        mediaType: this.store.mediaType
      }),
      () => {
        this.playerStarter();
      }
    );

    // Load new SMG after player is ready.
    this.player3DIsReadyReaction = reaction(
      () => this.store.player3DIsReady,
      (state: boolean) => {
        if (state) {
          this.loadAsset(this.blobUrl);
        }
      }
    );

    // Create reaction for HotSpot
    this.currentHotspotIdReaction = reaction(
      () => this.store.currentHotspotId,
      () => {
        this.goToHotspot();
      }
    );

    const noteSubscription = this.noteService.tocItemsForTarget.subscribe(() => {
      this.cd.markForCheck();
    });
    this._subscriptions.push(noteSubscription);
  }

  ngOnDestroy() {
    if (this.player3DPlay) {
      this.player3DPlay.dispose();
    }

    const iframe = document.getElementById("iframe");
    while (iframe.firstChild) {
      iframe.removeChild(iframe.firstChild);
    }

    // Destroy reaction
    if (this.stepMetaMediaReaction) {
      this.stepMetaMediaReaction();
    }
    if (this.currentHotspotIdReaction) {
      this.currentHotspotIdReaction();
    }
    if (this._currentViewIdReaction) {
      this._currentViewIdReaction();
    }
    if (this.player3DIsReadyReaction) {
      this.player3DIsReadyReaction();
    }

    // API Manager is not observable vatiables
    this.blobUrl = undefined;
    this.lastSMG = undefined;
    this.SMGView = undefined;
    this.isIPC3D = false;
    this._subscriptions.map(sub => sub.unsubscribe());
    this.experience = undefined;

    runInAction(() => {
      this.store.apiManager = null;
      this.store.playerIsCallOneTime = false;
      this.store.player3DIsReady = false;
      this.store.stepMetaMedia = "";
      this.store.currentMediaId = "";
      this.store._3DisAvailable = false;
    });

    /// Remove EventListener
    if (this.playerCanvas) {
      this.playerCanvas.removeEventListener("click", this.isHotspotBind);
    }
    window.top.document.removeEventListener("3DPLAYREADY", this.eventLstBind, true);
  }

  /**
   * Show or hide hotspot
   *
   * @param hotspot hotspot Object
   */
  selectHotspot(hotspotId: string): void {
    if (hotspotId && hotspotId !== this.lastHotspotId) {
      this.store.apiManager.SelectHotspot(hotspotId);
      this.lastHotspotId = hotspotId;
    }
  }

  /**
   * Redirect to the given view
   *
   * @param view
   */
  setView(view: string): Promise<boolean> {
    const url = this.store.currentDMC;
    return this.router.navigate(["du", url, "fulltext", view], {
      relativeTo: this.route,
      replaceUrl: true
    });
  }

  /**
   * Add Event listener on Player Canvas for intercepte click on hostpot
   */
  addEventListenerOnPlayerCanvas(): void {
    const iframe = document.querySelector<HTMLIFrameElement>("#iframe iframe");
    if (iframe) {
      this.playerCanvas = iframe.contentWindow.document.body.querySelector(".vcx-canvas-view");
      this.isHotspotBind = this.isHotspot.bind(this);
      this.playerCanvas.addEventListener("click", this.isHotspotBind);
    }
  }

  /**
   * Get different view on IPC 3D
   */
  getIPCView(): void {
    this.SMGView.set(
      "viewGeneral",
      xpath.select(
        "string(//multimediaObject[@infoEntityIdent='" +
          this.store.currentMediaId +
          "']/parameter[@parameterName='View_general']/@parameterIdent)",
        this.store.duObject.xml
      )
    );
    this.SMGView.set(
      "viewExploded",
      xpath.select(
        "string(//multimediaObject[@infoEntityIdent='" +
          this.store.currentMediaId +
          "']/parameter[@parameterName='View_exploded']/@parameterIdent)",
        this.store.duObject.xml
      )
    );
  }

  /**
   * Create reaction for change view
   *
   * @param viewTarget
   */
  public changeCurentView(viewTarget: string): void {
    if (!this.runSwitchView) {
      runInAction(() => {
        this.runSwitchView = true;
        this.store.currentViewId = viewTarget;
      });
    }
  }

  /**
   * Init var required for player
   */
  private playerStarter() {
    if (
      this.store.stepMetaMedia !== undefined &&
      this.store.stepMetaMedia !== "" &&
      this.store.mediaType === "3D"
    ) {
      this.documentId = getMediaId(
        this.store.isLegacyImport,
        this.store.currentDMC,
        this.store.stepMetaMedia
      );
      // Reset HotSpot before reload new smg
      this.lastHotspotId = "";
      this.runSwitchView = false;
      // Define if current view is IPC
      this.isIPC3D = this.store.ipcXML !== undefined ? true : false;
      this.getBlobUrl();

      // Initialize IPC View.
      if (this.isIPC3D) {
        runInAction(() => (this.store.currentViewId = "viewGeneral"));
        this.setIPC3DView();
      }
    }
  }

  /**
   * Get SMG as Blob in remote DB
   */
  private getBlobUrl(): Promise<void> {
    // if the last consulted SMG is the same as now, not reloaded.
    if (this.documentId !== this.lastSMG) {
      runInAction(() => {
        this.store._3DisAvailable = false;
      });
      return this.mediaService
        .getBlob(this.documentId)
        .then((blob: Blob) => {
          const blobUrl = URL.createObjectURL(blob);
          this.lastSMG = this.documentId;
          this.blobUrl = blobUrl;
          runInAction(() => {
            this.store._3DisAvailable = true;
          });

          this.getIPCView();
          this.loadSMGFile(this.blobUrl);
        })
        .catch(err => {
          this.store.isPlayerActivated = false;
          console.warn("SMG not found in base", err);
        });
    } else {
      this.displayPlayer3D();
    }
  }

  /**
   * Wait player3D is Ready for load real SMG in player.
   *
   * @param blobUrl
   */
  private loadSMGFile(blobUrl: string): void {
    if (this.store.player3DIsReady && blobUrl) {
      if (this.store.apiManager) {
        // Force unselect hospot, before SMG switch.
        this.store.apiManager.DeselectHotspot(this.store.currentHotspotId);
      }
      runInAction(() => (this.store.currentHotspotId = ""));
      this.loadAsset(blobUrl);
    }
  }

  /**
   * instantiate 3D Player
   */
  private instantiate3DPlayer(): void {
    const params = {
      debugplayer: false, // Add Automatically callbacks in 3DPlaySyndication.html
      input: {
        asset: "../assets/models/temp.smg"
      },
      options: {
        ...this.playerOption,
        loading: "autoplay",
        enopad: {
          offline: "true"
        },
        playOptions: {
          play: "true",
          transparentBackground: "true"
        }
      }
    };

    this.eventLstBind = this.eventListener.bind(this);
    window.top.document.addEventListener("3DPLAYREADY", this.eventLstBind, true);
    localStorage.setItem("DS_COMPOSER_EXPORT_WEB", "true");

    // setting to instantiate the web application
    const params2 = JSON.stringify(params);
    const player = document.createElement("iframe");
    player.style.width = "100%";
    player.style.height = "100%";
    player.style.border = "0px";
    player.style.top = "0px";
    player.style.left = "0px";

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    player.allowfullscreen = player.mozAllowFullScreen = player.webkitAllowFullScreen = true;
    player.setAttribute("allowFullScreen", "");

    // SPEC When the auth is enabled we should fetch player files with oak and access_token
    // (All files insides c/UWA directory and the file AmdLoader.js aren't protected by the proxy)
    // Warning: In this case we should patch player with the command "yarn viewer:patch-3d-player"
    if (environment.platform === "browser") {
      // Define fetchWithCredential add header oak + access_token in the http request
      // This function is inject by the gulp task
      //TODO: Use simpleHttpService when it's merged
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).fetchWithCredential = (url: string) => {
        const request = this.confService.useAuth
          ? this.http.sendRequest(HttpMethod.GET, url, { responseType: "blob" })
          : lastValueFrom(this.httpClient.request(HttpMethod.GET, url, { responseType: "blob" }));
        return request.then((response: Blob) => window.URL.createObjectURL(response));
      };
    }

    // instantiating the web application with all the parameters (webapp + url)
    const src = window.hasOwnProperty("cordova")
      ? this.playerService.getPlayerSrc(packageJSON.playerVersion)
      : "./assets/libs/DS/3DPlayHelper/3DPlaySyndication.html";
    player.src = `${src}?params=${encodeURI(params2)}`;
    document.getElementById("iframe").appendChild(player);
  }

  private eventListener(e) {
    this.player3DPlay = e.detail.helper;

    // playerIsCallOneTime fix multiple call.
    if (
      this.player3DPlay &&
      this.player3DPlay.getExperience &&
      this.store.playerIsCallOneTime === false
    ) {
      this.store.playerIsCallOneTime = true;
      this.experience = this.player3DPlay.getExperience();

      this.experience.addCallbacks({
        asset: {
          LoadingStarted: () => {
            runInAction(() => {
              this.store.playerLoadingIsfinished = false;
            });
            this.displayPlayer3D();
          },
          LoadingError: error => {
            console.error("[3D Player] Loading error:", error);
          },
          loadingfinished: () => {
            // Once the final SMG is loaded, launch navigation actions on the SMG
            if (this.store.player3DIsReady) {
              runInAction(() => {
                // Add API to store
                this.store.apiManager = this.experience.experienceBase.getManager("VCXAPIManager");

                // Configure selectable object on player
                this.store.apiManager.preventPartsFromBeingSelectable = true;
                this.store.apiManager.keepHotspotSelectable = true;
                this.store.apiManager.keepLinkSelectable = false;
              });

              // Move to current step after 3D player Load
              this.switchToCurrentStep();
              this.goToHotspot();
              runInAction(() => {
                this.store.playerLoadingIsfinished = true;
              });
            }

            // Send reaction for load the real SMG
            if (!this.store.player3DIsReady) {
              this.addEventListenerOnPlayerCanvas();
              runInAction(() => (this.store.player3DIsReady = true));
            }
          }
        }
      });
    } else {
      console.error("[3D Player] No experience found");
    }
  }

  /**
   * Listen currentViewId and go to currentViewId
   */
  private currentViewIdReaction(): void {
    if (this.store.player3DIsReady) {
      this.displayPlayer3D();
    }

    // currentViewIdReaction is call One time after player is ready
    this._currentViewIdReaction = reaction(
      () => this.store.currentViewId,
      () => {
        this.displayPlayer3D();
        this.switchToCurrentStep();
      }
    );
  }

  /**
   * Move to current Step if 3D
   */
  private switchToCurrentStep(): void {
    if (this.store.apiManager) {
      // The management of changes in view is different between the IPC and the AMM
      if (this.isIPC3D) {
        this.store.apiManager.GoToScenario(this.SMGView.get(this.store.currentViewId), () => {
          this.runSwitchView = false;
        });
      } else {
        const index: number = this.store.currentStepIndex;
        const step = this.store.stepObjects[index];
        if (step && step.internalRefTargetType === "irtt53") {
          return this.store.apiManager.GoToScenario(step.ref);
        }
        if (step && step.internalRefTargetType === "irtt51") {
          return this.store.apiManager.GoToMarker(step.ref);
        }
      }
    }
  }

  /**
   * Get Hotspot value for send hotspot ID to API
   *
   * @param swithView : Allows to disable the view switch when clicking on a hotspot.
   */
  private goToHotspot(): void {
    if (this.store.apiManager && this.store.player3DIsReady) {
      // Desactivation of the old hotspot when clicking on a new one
      if (this.lastHotspotId) {
        this.store.apiManager.DeselectHotspot(this.lastHotspotId);
      }

      if (this.store.currentHotspotId) {
        let hotspot;
        // The hotspotobject is different between the IPC and the stepbystep
        if (this.isIPC3D) {
          const selectedPart = this.hotspotToSelectedId("IPC") as string;
          if (selectedPart) {
            hotspot = this.store.currentHotspotId;
            // Update selected part to display the right card in the ipc
            runInAction(() => {
              this.store.selectedParts = selectedPart;
            });
            // If view is General, switch to exploded view for activate hotspot
            if (this.store.currentViewId === "viewGeneral") {
              this.store.currentViewId = "viewExploded";
            }
          }
        } else {
          const selectHotspot = this.hotspotToSelectedId() as HotspotObject;
          hotspot = selectHotspot ? selectHotspot.hotspotRef : undefined;
        }
        this.selectHotspot(hotspot);
      } else {
        this.lastHotspotId = undefined;
      }
    }
  }

  /**
   * Get hotstop ID on hotspot Objects
   *
   * @param view
   */
  private hotspotToSelectedId(view = ""): IpcDetail | HotspotObject | string {
    if (view === "IPC") {
      const selectedHotspot = xpath.select(
        "string(//multimediaObject[@infoEntityIdent='" +
          this.store.currentMediaId +
          "']/parameter[@parameterIdent='" +
          this.store.currentHotspotId +
          "']/@parameterName)",
        this.store.duObject.xml,
        true
      ) as string;
      // parameterName contains ex : HOT010A (in ORION we use hotspotIds without HOT)
      return selectedHotspot.split("HOT")[1];
    }

    return this.store.hotspotObjects.find(
      (hotspotObject: HotspotObject) => hotspotObject.hotspotRef === this.store.currentHotspotId
    );
  }

  /**
   * Load blob smg file in player
   */
  private loadAsset(SMGBlobUrl: string): void {
    const params = {
      experience: {
        filename: "DS/VCXWebAuthoringApp/VCXWebPlayerExperience"
      },
      asset: {
        filename: SMGBlobUrl,
        provider: "FILE",
        type: "smg",
        format: "smg",
        mimetype: "smg"
      }
    };

    // Load new smg
    this.player3DPlay.loadAsset(params, this.playerOption);
    // Ecrase old Experience with new smg Experience
    this.experience = this.player3DPlay.getExperience();

    // Clean Blob URL for save RAM.
    URL.revokeObjectURL(this.blobUrl);
  }

  /**
   * Show / Hide 3D Player
   */
  private displayPlayer3D(): void {
    const contentProvider = document.querySelector("o-content-provider");
    // If the player is not instantiated
    if (!contentProvider) {
      return;
    }

    const isProcedureStep = !["prepa", "close", ""].includes(this.store.currentViewId);
    const className = this.isIPC3D ? "IPC3D" : "player_mode";
    if (isProcedureStep && this.store.mediaType === "3D" && this.store._3DisAvailable) {
      this.playerIsVisible = true;
      contentProvider.classList.add(className);
    } else if (!isProcedureStep || !this.store._3DisAvailable) {
      this.playerIsVisible = false;
      contentProvider.classList.remove(className);
    }
  }

  /**
   * Update selectedObjects if click on 3D
   */
  private isHotspot(): void {
    const selectedObjects: string[] = this.store.apiManager.GetSelectedObjects();
    // tip to solve the hotspot unselect problem
    this.store.apiManager.DeselectHotspot(selectedObjects[0]);
    // Reset last hotspot on click for not re-selected hotstop on second click.
    this.lastHotspotId = undefined;

    runInAction(() => {
      this.store.currentHotspotId = selectedObjects[0];
    });
  }

  /**
   * Set default IPC View
   */
  private setIPC3DView(): void {
    // We check if a detail view is engaged to initialize the player on the right view
    // Without actually clicking on a map, selectedParts is not defined, I don't know why.
    this.route.queryParams.subscribe(params => {
      if (params["hotspotId"]) {
        runInAction(() => {
          this.store.selectedParts = params["hotspotId"];
          this.store.currentViewId = "viewExploded";
        });
      }
    });
  }
}
