import {
  Column,
  Content,
  ContentColumns,
  DynamicContent,
  Margins,
  PredefinedPageSize,
  TableCell,
  TDocumentDefinitions
} from "pdfmake/interfaces";

export interface PdfModel extends TDocumentDefinitions {
  pageSize: PredefinedPageSize;
  pageMargins: Margins;
  footer?: DynamicContent | Content;
  content: Content[];
}

export class PrintTaskModel {
  public static primaryColor = "#00205b";
  public static accentColor = "#0082d5";
  public static redColor = "#e4002b";
  public static greenColor = "#bbffc4";

  _pdfModel: PdfModel = {
    // a string or { width: number, height: number }
    pageSize: "A4",
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [40, 60, 40, 60],
    content: []
  };

  public static card(ponctual: boolean): Content {
    return {
      table: {
        widths: ["*", 40],
        body: []
      },
      fontSize: 8,
      margin: [5, 2, 10, 5],
      unbreakable: true,
      // hLineWidth and vLineWidth take function which return a value in "pt" for each horizontal and vertical lines
      layout: {
        hLineColor: "black",
        vLineColor: "black",
        hLineWidth: () => 0.75,
        vLineWidth: () => 0.75,
        hLineStyle: () => (ponctual ? { dash: { length: 4 } } : null),
        vLineStyle: () => (ponctual ? { dash: { length: 4 } } : null)
      }
    };
  }

  public static bodyCard(): TableCell[][] {
    return [
      [
        [[]],
        {
          text: ["Initial"],
          alignment: "center"
        }
      ]
    ];
  }

  public static bodyTable(
    firstColumn = "",
    secondColum: TableCell[][] = [],
    color = "black"
  ): ContentColumns {
    return {
      columns: [
        {
          width: 115,
          text: firstColumn,
          bold: true
        },
        {
          width: "*",
          table: {
            body: secondColum
          },
          layout: "noBorders",
          color
        }
      ],
      margin: [0, 3]
    };
  }

  public static column(columns: Column[], color = "black", fontSize = 8, fillColor = ""): Content {
    return {
      columns,
      color,
      fontSize,
      fillColor,
      columnGap: 10
    };
  }
}
