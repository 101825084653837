import { Injectable } from "@angular/core";
import { runInAction } from "mobx";
import { PouchService, Store, ViewerMessageService } from "@viewer/core";
import { environment } from "@viewer-env/environment";
import { TranslateService } from "@ngx-translate/core";
import { UserManagementResponse, guestUser } from "libs/auth/src/role.interfaces";
import { LocalStorageBackend, TokenResponse } from "@openid/appauth";
import { User } from "libs/auth/src/user";
import { Router } from "@angular/router";
import { HomeRoute } from "@viewer/home/models";

/*
  This service is used for oidc connection.
  It use background Auth service that have login/logout methods
*/
@Injectable()
export class RoleService {
  private storage = new LocalStorageBackend();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleRolePromise: Promise<any>;

  constructor(
    private pouchService: PouchService,
    private store: Store,
    private messageService: ViewerMessageService,
    private translate: TranslateService,
    private router: Router
  ) {
    runInAction(() => {
      // load from LocalStorage if user already connected
      // https://gitlab.altengroup.net/tma/airbus/orion2/orion2/-/wikis/Gestion%20des%20bases%20Utilisateurs#r%C3%A8gles-orion-2
      this.storage
        .getItem("user_role")
        .then((userString: string) => {
          const user = new User(JSON.parse(userString));
          this.store.user = user;
          const msg = this.translate.instant("app.start.user", {
            username: user.userName
          });
          this.messageService.success(msg, undefined, 4000);
        })
        .catch(() => {
          this.store.user = new User(guestUser);
          const msg = this.translate.instant("app.start.guest");
          this.messageService.warning(msg, undefined, 4000);
        });
    });
    addEventListener("roleResponse", this.setRoleHandler.bind(this));
  }

  /**
   *
   * @param roleResponse: UserManagementResponse response form role api.
   * @param isLoggedIn: boolean value to set if user is loggedin
   */
  setUser(roleResponse: UserManagementResponse, isLoggedIn: boolean): Promise<boolean | void> {
    runInAction(() => {
      this.store.isLoggedIn = isLoggedIn;
      this.store.user = new User(roleResponse);
    });
    this.updateCallers();
    if (this.store.user.userName === "USER Guest") {
      return Promise.resolve(true);
    }
    return this.storage.setItem("user_role", JSON.stringify(roleResponse)).catch(error => {
      console.error("Error with localStorage", error);
      return Promise.reject(false);
    });
  }

  /**
   * Use Auth service to login in addition with the "fetch" function redefined by CoreCaller
   * When authentificated we call the service to get the user role
   * If an error is throw then the publist is cleaned and store "isLoggedIn" is set to false.
   */
  public login(): Promise<TokenResponse> {
    return environment.authService
      .login()
      .then((tokenResponse: TokenResponse) => {
        if (tokenResponse === undefined) {
          // if login fail, redirect to /local
          this.messageService.error(`${this.translate.instant("login.message.error")}`);
          // force user as guest
          this.setUser(guestUser, false)
            .then(() => this.router.navigateByUrl(HomeRoute.LOCAL))
            .catch(error => {
              console.error("Error on setting user:", error);
            });
        }
        return tokenResponse;
      })
      .catch(error => {
        if (this.store.isLoggedIn) {
          runInAction(() => {
            this.store.isLoggedIn = false;
          });
        }
        console.error("Error with login: ", error);
        this.messageService.error(`${this.translate.instant("login.message.error")}`);
        return Promise.reject(error);
      });
  }

  /**
   * On logout, messageService inform the user that he is offline
   * Set the store isLoggedIn to false on success
   * TODO: Save the userRights
   */
  public logout(): Promise<boolean> {
    return environment.authService
      .logout()
      .then((isLoggedOut: boolean) => this.removeLocalSessionOnLogout(isLoggedOut))
      .catch(error => {
        console.warn(
          `An error on logout occured, your session may be not closed remotly.
          Local session parameters are reset to permit a new connexion.
          You will be a Guest user.`,
          error
        );
        this.messageService.error(`${this.translate.instant("logout.message.error")}`);
        return false;
      });
  }

  public handleRoleStatus(status: boolean): void {
    this.store.deteriorate = !status;

    if (this.store.deteriorate) {
      this.messageService.error(
        this.translate.instant("degradation.disclaimer"),
        undefined,
        60 * 1000 // SPEC: Message displayed for 60 seconds in snackbar and permanent in settings menu
      );
    }
  }

  /**
   * this event is trigger on "roleResponse" message
   *
   * @param event send by auth.service
   * contain detail about the event
   */
  private setRoleHandler(event): void {
    if (event.detail.response && event.detail.response.message === undefined) {
      if (!this.handleRolePromise) {
        const roleResponse: UserManagementResponse = event.detail.response;
        this.handleRolePromise = this.setUser(roleResponse, true)
          .then(() => {
            this.messageService.success(`${this.translate.instant("login.message.success")}`);
            dispatchEvent(
              new CustomEvent("roleResponseSet", {
                detail: {
                  response: true
                }
              })
            );
            this.handleRolePromise = undefined;
          })
          .then(() => {
            this.pouchService.userCaller.getUserAutoSynchro().then((userAutoSynchro: boolean) => {
              runInAction(() => {
                this.store.userAutoSynchro = userAutoSynchro;
              });
            });
          })
          .catch(error => {
            console.error("Error on setting user :", error);
            this.setUser(guestUser, false)
              .then(() => {
                console.warn("User guest is set by default");
              })
              .catch(err => {
                console.error("ERROR: No user can be set.", err);
              });
            this.handleRolePromise = undefined;
          });
      }
    } else {
      dispatchEvent(
        new CustomEvent("roleResponseSet", {
          detail: {
            response: false
          }
        })
      );
    }
  }

  /**
   * Remove local parameters
   */
  private removeLocalSessionOnLogout(success: boolean): Promise<boolean> {
    if (!success) {
      return new Promise(resolve => {
        resolve(false);
      });
    }
    this.storage.removeItem("user_role");
    // Use case, reset role to guest on explicit logout:
    // https://gitlab.altengroup.net/tma/airbus/orion2/orion2/-/wikis/Gestion%20des%20bases%20Utilisateurs#r%C3%A8gles-orion-2
    this.setUser(guestUser, false);
    this.messageService.success(`${this.translate.instant("logout.message.success")}`);
    return this.router.navigateByUrl(HomeRoute.LOCAL);
  }

  private updateCallers() {
    this.pouchService.switchUser();
  }
}
