import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { ViewerMessageService } from "@viewer/core/message/viewer.message.service";
import { SearchService } from "@viewer/core/search/search.service";
import { Store } from "@viewer/core/state/store";
import { reaction } from "mobx";
import { InspectionService } from "@viewer/core/toc-items/inspection.service";
import { BookmarkTreeService } from "@viewer/toc-items/bookmark-module/bookmark-list/bookmark-tree.service";

export enum SynchroIcon {
  ERROR = "cloud-alert",
  OFF = "cloud-off-outline",
  OK = "cloud-check-outline",
  PENDING = "autorenew"
}

@Injectable()
export class SynchroService {
  public statusIcon = SynchroIcon.OK;

  constructor(
    private translate: TranslateService,
    private messageService: ViewerMessageService,
    private tocItemService: TocItemService,
    private searchService: SearchService,
    private store: Store,
    private inspectionService: InspectionService,
    private bookmarkTreeService: BookmarkTreeService
  ) {
    window.addEventListener("startSynchro", this.synchronize.bind(this));

    reaction(
      () => [this.store.userAutoSynchro, this.store.isLoggedIn, this.store.syncSettings],
      () => {
        if (!this.store.isLoggedIn) {
          this.statusIcon = SynchroIcon.OFF;
        } else if (this.statusIcon === SynchroIcon.OK || this.statusIcon === SynchroIcon.OFF) {
          // SPEC: Admin can disable the synchro inside the admin interface
          this.statusIcon =
            this.store.userAutoSynchro && this.store.syncSettings.enable
              ? SynchroIcon.OK
              : SynchroIcon.OFF;
        }
      },
      { fireImmediately: true }
    );
  }

  /**
   * Entrypoint to trigger a synchronization.
   */
  public synchronize(): Promise<void> {
    if (TocItemService.syncPending) {
      return Promise.resolve();
    }

    // SPEC: To avoid NG0100: Expression has changed after it was checked
    setTimeout(() => {
      this.statusIcon = SynchroIcon.PENDING;
      TocItemService.syncPending = true;
    });

    return this.tocItemService
      .synchronize()
      .then(() => {
        TocItemService.shouldResetCache.next();
        this.inspectionService.listShouldUpdate.next(true);
        this.bookmarkTreeService.getTree();
        this.searchService.updateSearch();
        this.statusIcon =
          this.store.userAutoSynchro && this.store.syncSettings.enable
            ? SynchroIcon.OK
            : SynchroIcon.OFF;
      })
      .catch((err: Error) => {
        console.error("Error during synchronization : ", err);
        this.statusIcon = SynchroIcon.ERROR;
        this.messageService.error(this.translate.instant("synchronize.error"));
      })
      .finally(() => {
        TocItemService.syncPending = false;
      });
  }
}
