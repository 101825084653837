import { Injectable } from "@angular/core";
import { MessageService } from "@orion2/message-service/message.service";
import { FullHttpService } from "libs/http/fullHttp.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Store } from "@viewer/core/state/store";
import { DbItemInterface, TocInfo } from "@orion2/models/couch.models";
import { TranslateService } from "@ngx-translate/core";
import { SearchResult } from "@viewer/core/search/searchModel";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { StatusCodes } from "http-status-codes";

// Superseded Request interfaces
export interface SupersededFindIpcsRequest {
  limit?: number;
  skip?: number;
  ipcName?: string;
  hcType?: string;
  hcVersion?: string;
  pn?: string;
  sn?: string;
  ata?: string;
  descending?: boolean;
}

// Superseded Response interfaces
export interface SupersededIpc extends DbItemInterface {
  createAt: number;
  aircraft: {
    hcModel: string;
    hcVersion: string;
  };
  pn: string[];
  sn: string[];
  ata: string;
  revDate: string;
  title?: string;
  chg?: string;
}

export interface SupersededPdf {
  type: string;
  data: number[];
}

export interface SupersededError {
  errorCode: number;
  errorDescription: string;
  methode: string;
  err?: {
    type: string;
    data: number[];
  };
}

@Injectable()
export class SupersededService {
  constructor(
    private httpService: FullHttpService,
    private messageService: MessageService,
    private translate: TranslateService,
    private store: Store
  ) {}

  public isSupersededSearch(input: string): boolean {
    return this.store.isLoggedIn && input.length >= 3 && /\d/.test(input);
  }

  public getReference(ipc: SupersededIpc): string {
    return `${this.translate.instant("search.superseded")} ${ipc.ata}`;
  }

  public createId(ipc: SupersededIpc): string {
    return "superseded__" + ipc._id + "__" + ipc.ata;
  }

  public createSearchObject(ipc: SupersededIpc, index: number): SearchResult {
    return {
      from: "superseded",
      applicabilityMD5: undefined,
      dmc: this.createId(ipc),
      id: index,
      manual: "IPC",
      // SPEC: ipc.ata contain the ipc ata numbers, for example : 67-13-00-01
      reference: ipc.ata,
      revision: "superseded",
      score: 0,
      shortTitle: ipc.title ? ipc.title : Object.keys(ipc._attachments)[0],
      versions: [ipc.aircraft.hcVersion],
      parents: undefined,
      date: ipc.revDate
    };
  }

  public createTocNode(dmc: string): Promise<TocInfo> {
    // DMC exemple superseded__EC175_24310001_EN__24-31-00-01
    // And we want to extract EC175_24310001_EN
    const ipcId = dmc.split("__")[1];
    return this.findIpcById(ipcId).then(ipc => ({
      dmc,
      _id: this.store.currentDMC,
      title: ipc.title ? ipc.title : Object.keys(ipc._attachments)[0],
      reference: this.getReference(ipc),
      minRevision: this.store.pubInfo.revision,
      applicabilityMD5: undefined,
      parent: "root"
    }));
  }

  public findIpcById(id: string): Promise<SupersededIpc> {
    return this.httpService
      .sendRequest(HttpMethod.GET, `superseded/ipcs/${id}`)
      .then((response: SupersededIpc | SupersededError[]) => {
        const firstElement = response[0] as SupersededError;
        // If the first element is a SupersededError => It's an error
        if (Array.isArray(response) && "errorCode" in firstElement) {
          this.sendErrorMessage(firstElement);
          return undefined;
        }
        return response as SupersededIpc;
      })
      .catch(this.sendErrorMessage.bind(this));
  }

  /**
   * Enable superseded after research.
   *
   * @param searchInput
   */
  public enableSupersededSeach(searchInput) {
    return this.isSupersededSearch(searchInput) && this.store.pubInfo.capabilities.superseded
      ? this.findIpcsByPN(searchInput)
      : Promise.resolve([]);
  }

  public findIpcsByPN(pn: string): Promise<SupersededIpc[]> {
    return this.httpService
      .sendRequest(HttpMethod.GET, "superseded/ipcs", {
        params: {
          occCode: this.store.pubInfo.occurrenceCode,
          pn
        }
      })
      .then((response: SupersededError[] | SupersededIpc[]) => {
        const firstElement = response[0];
        // If the first element is a SupersededError => It's an error
        if (Array.isArray(response) && "errorCode" in firstElement) {
          this.sendErrorMessage(firstElement);
          return undefined;
        }
        return response as SupersededIpc[];
      })
      .catch(this.sendErrorMessage.bind(this));
  }

  public getPdf(supersedIpcId: string): Promise<SupersededPdf> {
    return this.httpService
      .sendRequest(HttpMethod.GET, `superseded/ipcs/${supersedIpcId}/pdf`)
      .then((res: SupersededPdf | SupersededError) => {
        // If it's a SupersededError
        if ("errorCode" in res) {
          this.sendErrorMessage(res);
          return undefined;
        }
        return res;
      })
      .catch(this.sendErrorMessage.bind(this));
  }

  /**
   * Send error message to user if superseded error
   */
  private sendErrorMessage(err: HttpErrorResponse | SupersededError | Error): void {
    let errorMessage;
    if ("errorCode" in err) {
      if (err.errorCode !== StatusCodes.NOT_FOUND) {
        errorMessage = `Error ${err.errorCode} : ${err.errorDescription}`;
      }
    } else if ("error" in err) {
      const supersededError: SupersededError = err.error;
      if (supersededError && typeof supersededError === "object") {
        errorMessage = `Error ${err.status} : ${supersededError.errorDescription}`;
      } else {
        errorMessage = `Error ${err.status} : ${err.statusText}`;
      }
    } else {
      errorMessage = `Error ${err.message}`;
    }
    if (errorMessage) {
      this.messageService.error(errorMessage);
    }
  }
}
