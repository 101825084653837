import { environment as template } from "@viewer-env/environment.template";
import { BrowserAuthService } from "@orion2/auth/browser.auth.service";

export const environment = {
  ...template,
  authService: BrowserAuthService,
  platform: "browser",
  useWorker: false,
  postHog: {
    api_host: "",
    project_api_key: ""
  }
};
