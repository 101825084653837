import { Response } from "express";
import { getReasonPhrase, StatusCodes } from "http-status-codes";

export class HttpError {
  public code: number;
  public objectError: Object;
  private error: unknown;
  private context: string;

  constructor(code: number, context: string, error?: unknown, objectError?: Object) {
    this.error = error;
    this.code = code;
    this.objectError = objectError || {};
    this.context = context;
  }

  public static error(res: Response, err: Error | HttpError, objectError?: Object) {
    if (err instanceof HttpError) {
      return res.status(err.code).json({
        code: err.code,
        reason: err.toString(),
        ...err.objectError,
        ...objectError
      });
    } else {
      return HttpError.error(
        res,
        new HttpError(StatusCodes.INTERNAL_SERVER_ERROR, HttpError.name, err)
      );
    }
  }

  public toString(): string {
    const message = this.getMessage() || getReasonPhrase(this.code);
    return `[${this.context}] Error ${this.code}: ${message}`;
  }

  public getMessage(): string {
    if (typeof this.error === "string") {
      return this.error;
    } else if (this.error instanceof Error) {
      return this.error.message;
    } else {
      return "";
    }
  }
}
