import { Injectable } from "@angular/core";
import { Store, PouchService } from "@viewer/core";
import { XmlDoc } from "@orion2/models/couch.models";
import { reaction } from "mobx";
import xpath from "xpath";

@Injectable()
export class CirPartsService {
  private customizedParts: string[];

  constructor(private pouchService: PouchService, private store: Store) {
    reaction(
      () => ({
        pubId: this.store.publicationID,
        pubRev: this.store.publicationRevision
      }),
      (state: { pubId: string; pubRev: string }) => {
        if (state.pubId && state.pubRev) {
          // we have only one cirpartsDU by publication
          this.init();
        }
      },
      { fireImmediately: true }
    );
  }

  public setCustomizedParts(cirpartsDU: Document) {
    // all partNumberValue with param commercialClassification='cc51' are customized part
    const customizedPartsNodes = xpath.select(
      "/dmodule//partIdent[@commercialClassification='cc51']",
      cirpartsDU
    );
    this.customizedParts = customizedPartsNodes.map((part: Node) =>
      xpath.select("string(@partNumberValue)", part).toString()
    );
  }

  public isCustomizedPart(partNumberValue: string): boolean {
    return this.customizedParts?.includes(partNumberValue);
  }

  private init(): Promise<void> {
    return this.pouchService.xmlCaller
      .get(this.store.pubInfo.specificParams?.["cirpartsDU"])
      .then((xml: XmlDoc) => {
        const parser = new DOMParser();
        const cirpartsDU = parser.parseFromString(xml.data, "text/xml");
        this.setCustomizedParts(cirpartsDU);
      })
      .catch(e => {
        console.error("No CIR Parts for this pub", e);
      });
  }
}
