import { OrionDBs } from "@viewer/core/pouchdb/orionDBs";
import { DatabaseConfObject, DbParams } from "@viewer/core/pouchdb/types";

/**
 * Create orionDbs and keep one instance by package
 *
 * @export
 * @class OrionDBsFactory
 */
export class DbsFactory {
  private _orionDBsCache: Map<string, OrionDBs> = new Map<string, OrionDBs>();
  private databaseConf: DatabaseConfObject;

  constructor(databaseConf: DatabaseConfObject) {
    this.databaseConf = databaseConf;
  }
  /**
   * Get the orionDBs
   *
   * @private
   * @param prefix Usually prefix is simply publicationId but its not the case for TocItems databases,
   * @param suffix Usually one of DBEnum
   * @returns
   * @memberof OrionDBsFactory
   */
  public getDBs(prefix: string, suffix: string, dbParams): OrionDBs {
    return (
      this._orionDBsCache.get(this._cacheKey(suffix, prefix)) ||
      this._createDBs(prefix, suffix, dbParams)
    );
  }

  // public updateCouchUri(uri: string) {
  //   this.databaseConf.couchBaseUrl = uri;
  // }

  /**
   * Get the state of the cache map
   *
   * @returns
   * @memberof OrionDBsFactory
   */
  cacheMap(): Map<string, OrionDBs> {
    return this._orionDBsCache;
  }

  // Because pouchdb.close is async OrionDBs.close is async.
  // It's important to be able to wait until the closing process is
  // complete otherwise it become easy to call an unavailable db
  // leading to errors.
  public resetCacheMap(): Promise<boolean> {
    const dbsList: OrionDBs[] = Array.from(this._orionDBsCache.values());
    return Promise.all(dbsList.map((dbs: OrionDBs) => dbs.close()))
      .then(() => {
        this._orionDBsCache = new Map<string, OrionDBs>();
        return true;
      })
      .catch(err => {
        console.error("Error while closing databases:", err);
        return false;
      });
  }

  /**
   * Create the DBs if not present in cache
   *
   * @private
   * @param prefix Usually publicationId
   * @param suffix Usually one of DBEnum
   * @param dbParams params passed to pouchdb constructor
   * @returns
   * @memberof OrionDBsFactory
   */
  private _createDBs(prefix: string, suffix: string, dbParams: DbParams): OrionDBs {
    const orionDbs = new OrionDBs(prefix, suffix, this.databaseConf, dbParams);
    this._orionDBsCache.set(this._cacheKey(suffix, prefix), orionDbs);
    return orionDbs;
  }

  /**
   * generate the cache key
   *
   * @param packageId
   * @returns
   * @memberof OrionDBsFactory
   */
  private _cacheKey(name: string, prefix: string): string {
    // everything to do on name aka :  lowercase / prefix
    return `${name}_${prefix}`;
  }
}
