import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from "@angular/core";
import { BasicViewService } from "@viewer/content-provider/basic-view.service";
import { CmData } from "@viewer/shared-module/cm-preview/cm-preview.service";

@Component({
  selector: "o-cm-card",
  templateUrl: "./cm-card.component.html",
  styleUrls: ["./cm-card.component.scss"]
})
export class CmCardComponent implements AfterViewInit {
  @ViewChildren("links") links: QueryList<ElementRef>;
  @Output() linkClick = new EventEmitter<boolean>();
  @Input() data: CmData;

  constructor(private basicViewService: BasicViewService) {}

  ngAfterViewInit(): void {
    if (this.links.length) {
      this.links.forEach((link: ElementRef) => {
        link.nativeElement.setAttribute(
          "onclick",
          `gotoRefExt("${link.nativeElement.id}","${link.nativeElement.title}","false")`
        );
      });
      const parent = this.links.first.nativeElement.parentElement;
      this.basicViewService.addDuLinks(parent);
    }
  }
}
