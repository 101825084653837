// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-content {
  text-align: center;
  word-wrap: break-word;
}

.mat-dialog-actions {
  justify-content: center;
  margin-bottom: 0;
  margin-top: 20px;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0Usa0JBQUE7RUFDQSxxQkFBQTtBQURGOztBQUlBO0VBQ0UsdUJBQUE7RUFDQSxnQkFBQTtFQUNBLGdCQUFBO0VBQ0EsVUFBQTtBQURGIiwiZmlsZSI6ImNvbmZpcm0tZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcImxpYnMvdGhlbWUvb3Jpb24tdGhlbWVcIjtcblxuLm1hdC1kaWFsb2ctY29udGVudCB7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgd29yZC13cmFwOiBicmVhay13b3JkO1xufVxuXG4ubWF0LWRpYWxvZy1hY3Rpb25zIHtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIG1hcmdpbi1ib3R0b206IDA7XG4gIG1hcmdpbi10b3A6IDIwcHg7XG4gIHBhZGRpbmc6IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/components/confirm-dialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,qBAAA;AADF;;AAIA;EACE,uBAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AADF;AACA,opBAAopB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n.mat-dialog-content {\n  text-align: center;\n  word-wrap: break-word;\n}\n\n.mat-dialog-actions {\n  justify-content: center;\n  margin-bottom: 0;\n  margin-top: 20px;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
