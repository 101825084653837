import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";
import { ApplicDoc } from "@orion2/models/couch.models";

export class ApplicCaller extends CoreCaller {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(processor: any, injector: Injector, dbSchema: DbSchema) {
    super(dbSchema, processor, injector);
  }

  getFileData(id: string): Promise<string[]> {
    return this.get(id).then((entry: ApplicDoc) => entry.data);
  }
}
