import { PromiseWorker } from "promise-worker";
import { PubDoc } from "libs/models/couch.models";
import { environment } from "@viewer-env/environment";
import { OakResponse } from "@orion2/auth/oak.response";
import { TokenResponse } from "@openid/appauth";
import { BaseGateway } from "@viewer/core/pouchdb/core/baseGateway";
import { AuthHandler } from "@orion2/models/auth.models";
import {
  ProcessorGateway,
  DatabaseConfObject,
  DbQuery,
  WorkerRequest
} from "@viewer/core/pouchdb/types";

/**
 * Gateway between worker and db processor
 * Will send post message
 *
 * @export
 * @class WorkerProcessorGateway
 * @implements {ProcessorGateway}
 */
export class WorkerProcessorGateway extends BaseGateway implements ProcessorGateway, AuthHandler {
  private worker: PromiseWorker;

  constructor(workerProcessor: Worker) {
    super();
    this.worker = workerProcessor;
    this.worker._worker.addEventListener("message", this.handleMessage.bind(this));
  }

  public initializeFactory(databaseConf: DatabaseConfObject): Promise<void> {
    return this.sendMessage("initializeFactory", databaseConf) as Promise<void>;
  }

  public switchPublication(pubInfo: PubDoc): Promise<boolean> {
    if (pubInfo) {
      this.publicationId = pubInfo.packageId;
      this.publicationRevision = pubInfo.revision;
    }

    return this.sendMessage("switchPublication", [pubInfo]) as Promise<boolean>;
  }

  public exec(dbQuery: DbQuery) {
    // TODO: Find if a callback object is sent in param instead this conditions
    if (dbQuery.query === "replicateRemote") {
      // here params contains a Map
      // The first element is an object with callbacks functions
      this.saveCallbacks(dbQuery.params[0]);
      dbQuery.callbacksName = Object.keys(dbQuery.params[0]);
    }
    return this.sendMessage("exec", dbQuery);
  }

  /**
   * Handle incoming messages
   *
   * @param e
   */
  public handleMessage(e: MessageEvent): void {
    // If the message is specially addressed to this file
    const [addressedFile, callback] = (e.data?.message || "").split(".");
    if (addressedFile === "WorkerProcessorGateway") {
      switch (callback) {
        case "getAccessToken":
          this.accessToken(e.data.payload.id, e.data.payload.db);
          break;
        case "getOak":
          this.oak(e.data.payload.id, e.data.payload.db);
          break;
        case "getLastSequence":
          this.getLastSequence(e.data.payload.id, e.data.payload.db, e.data.payload.params.key);
          break;
        case "saveLastSequence":
          this.saveLastSequence(
            e.data.payload.id,
            e.data.payload.db,
            e.data.payload.params.key,
            e.data.payload.params.value
          );
          break;
        case "callback":
          this.callback(e.data.message, e.data.payload.value);
          break;
        default:
          console.error("WorkerProcessorGateway", "Message not handled", e.data.message);
          break;
      }
    }
  }

  /**
   * Get OAK and send it via message through Worker
   *
   * @param id
   * @param db
   */
  public oak(id: number, db: string): void {
    environment.authService
      .oak()
      .then((oakResponse: OakResponse) => {
        this.postMessage(id, db, "sendOak", oakResponse);
      })
      .catch(err => {
        this.postMessage(id, db, "error", err);
      });
  }

  /**
   * Get AccessToken and send it via message through worker
   *
   * @param id
   * @param db
   */
  public accessToken(id: number, db: string): void {
    environment.authService
      .accessToken()
      .then((tokenResponse: TokenResponse) => {
        this.postMessage(id, db, "sendAccessToken", tokenResponse);
      })
      .catch(err => {
        this.postMessage(id, db, "error", err);
      });
  }

  public getLastSequence(id: number, db: string, key: string): void {
    const val = localStorage.getItem(key);
    this.postMessage(id, db, "sendGetLastSequence", val);
  }

  public saveLastSequence(id: number, db: string, key: string, val: string): void {
    localStorage.setItem(key, val);
    this.postMessage(id, db, "sendSaveLastSequence", true);
  }

  /**
   * Send a message to OrionDBs
   *
   * @param id
   * @param db
   * @param message
   * @param response
   */
  public postMessage(id: number, db: string, message: string, response: unknown): void {
    this.worker._worker.postMessage({
      payload: {
        id,
        db,
        response,
        message: "OrionDBs." + message,
        origin: "WorkerProcessorGateway"
      }
    });
  }

  private sendMessage(query: string, param?: unknown): Promise<unknown> {
    const message = {
      query,
      payload: param
    } as WorkerRequest;
    return this.worker.postMessage(message).catch(e => {
      console.error(e.message);
    });
  }
}
