// Mock PropertiesReader just for typing to avoid unused import just for importer
namespace PropertiesReader {
  export type Reader = any;
}

export class BaseTranslateUtils {
  /**
   * Take on line and produce a translated copy
   */
  public translateLine(
    line: string,
    prop: PropertiesReader.Reader | Map<string, string>,
    toc: boolean
  ): string {
    let copy = line;
    const containLabel = toc ? this.translateKeyToc(line) : this.translateKeyInString(line);
    containLabel.forEach((match: string) => {
      const key = this.getTranslationKey(match);
      const translation = prop.get(key) as string;
      if (key) {
        copy = copy.replace(match, translation);
      }
    });

    return copy;
  }

  /**
   * Return all the [translation key] present in the line
   */
  protected translateKeyInString(line: string): string[] {
    const regex = new RegExp("\\[\\w+_LABEL\\]", "g");
    let onGoingMatches: string[];
    const matches: string[] = [];
    while ((onGoingMatches = regex.exec(line))) {
      onGoingMatches.forEach(onGoingResult => matches.push(onGoingResult));
    }
    return matches;
  }

  /**
   * Return all the translation key present in the line toc line, the RegEx is different
   */
  protected translateKeyToc(line: string): RegExpMatchArray | any[] {
    const regex = new RegExp("(\\w+_LABEL)");
    return regex.exec(line) || [];
  }

  /**
   * The translation key in XML or TOC is [key] but we'll look for key in properties reader.
   */
  protected getTranslationKey(str: string): string {
    return str.replace("[", "").replace("]", "");
  }
}
