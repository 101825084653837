import { Injectable, NgZone } from "@angular/core";
import { PouchService } from "@viewer/core/pouchdb";
import { Store } from "@viewer/core/state/store";
import { environment } from "@viewer-env/environment";
import { DbItem, PubDoc } from "@orion2/models/couch.models";
import { Router } from "@angular/router";
import { BackgroundMode } from "@orion2/background-mode/index";
import { DBPubSchemaEnum } from "libs/transfert/model/pubSchema";
import { CallbacksFnInterface } from "@viewer/core/pouchdb/orionDBs";
import { ActionEnum } from "@viewer/core/pouchdb/types";
import { MessageService } from "@orion2/message-service/message.service";
import { TranslateService } from "@ngx-translate/core";
import { HomeRoute } from "@viewer/home/models";

@Injectable()
export class PubReplicateService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public progressHandlers: { [key: string]: any } = {};

  private completed = 0;
  private aborted = 0;
  private startTime: number;
  private initialState = {
    downloaded: 0,
    startTime: new Date(0)
  };

  constructor(
    public store: Store,
    private pouch: PouchService,
    private router: Router,
    private ngZone: NgZone,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  /**
   * Starts the replication of all db for current DMC
   */
  public startReplication(): Promise<void> {
    BackgroundMode.enableBackGroundMode();
    // If a handler is registered then we have a pending download
    // else the relevant pub-replication-progress hasn't been constructed yet
    if (this.progressHandlers[this.store.pubInfo._id]?.progress) {
      this.progressHandlers[this.store.pubInfo._id].progress({
        ...this.initialState,
        resumeState: true
      });
    }
    this.completed = 0;
    this.aborted = 0;
    this.startTime = new Date().getTime();
    const callbacks: CallbacksFnInterface = {
      error: this.handleError.bind(this),
      denied: () => {},
      change: this.handleChange.bind(this),
      complete: this.handleCompleted.bind(this)
    };

    this.pouch.statsCaller.logAction(
      ActionEnum.START,
      this.store.pubInfo._id,
      this.store.pubInfo.occurrenceCode
    );

    return this.pouch.replicateAll(callbacks);
  }

  /**
   * Handle replication results and send an approximation of volume data transferred
   * to progress component.
   *
   * BE CAREFUL: an uncaught exception in this function leads the replication to abort.
   */
  public handleChange(change: PouchDB.Replication.ReplicationResult<{}> & { dbName: string }) {
    const event = {
      startTime: this.startTime,
      downloaded: 0
    };

    // In cordova, attachments are return in Blob type
    if (environment.platform === "cordova") {
      event.downloaded += this.computeAttachmentsSizeCordova(change);
    }

    // if download === 0, use JSON.stringify to approximate data loaded
    // Adjustment variables are empirical
    if (event.downloaded === 0) {
      event.downloaded =
        (JSON.stringify(change.docs).length / Math.pow(10, 9)) *
        (environment.platform === "cordova" ? 0.9 : 0.5);
    }

    this.progressHandlers[this.store.pubInfo._id].progress(event);
  }

  public handleCompleted(
    state: PouchDB.Replication.ReplicationResultComplete<{}> & { dbName: string }
  ) {
    if (state.status === "cancelled") {
      return;
    }

    if (state.dbName === `${this.store.pubInfo.packageId}_${DBPubSchemaEnum.toc}`) {
      // this is used to force the creation of the ToC index.
      this.pouch.tocCaller.refreshIndex();
    }

    this.completed++;

    if (this.completed === this.pouch.getReplicableDBs().length) {
      this.pouch.statsCaller.logAction(
        ActionEnum.COMPLETE,
        this.store.pubInfo._id,
        this.store.pubInfo.occurrenceCode
      );
      return this.completeReplication();
    }

    const endedReps = this.aborted + this.completed;
    if (endedReps === this.pouch.getReplicableDBs().length) {
      return this.abortReplication();
    }
  }

  /**
   * Stops the current replication and save replication infos
   */
  public stopReplication(): Promise<void> {
    return this.pouch.stopReplicateAll().then(() => {
      // if we don't do this, it's not possible to restart a download after a pause.
      this.ngZone.run(() =>
        this.router.navigate([HomeRoute.ONLINE], { queryParams: { lastConsulted: true } })
      );
      BackgroundMode.disableBackGroundMode();
      this.pouch.statsCaller.logAction(
        ActionEnum.PAUSE,
        this.store.pubInfo._id,
        this.store.pubInfo.occurrenceCode
      );
    });
  }

  public resetProgress(pubToDelete: PubDoc = this.store.pubInfo): void {
    this.completed = 0;
    if (this.progressHandlers[pubToDelete._id] && this.progressHandlers[pubToDelete._id].complete) {
      this.progressHandlers[pubToDelete._id].complete();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerProgressHandlers(pubId: string, callback: any): void {
    this.progressHandlers[pubId] = callback;
  }

  private handleError(error): void {
    // happens when we switch to my library or click to browse the publication
    if (error.result?.status === "aborting") {
      this.aborted++;
    }

    const endedReps = this.aborted + this.completed;
    if (endedReps === this.pouch.getReplicableDBs().length) {
      return this.abortReplication();
    }
  }

  private computeAttachmentsSizeCordova(change): number {
    if (change.dbName.match(/search|pdf|media/)) {
      const size = change.docs.reduce((batchSize, doc) => {
        if (doc._attachments) {
          const docSize = Object.keys(doc._attachments).reduce((attSize, attName) => {
            // this is necessary in order to deal with _design docs for ex.
            if (doc._attachments[attName].data) {
              return attSize + doc._attachments[attName].data.size;
            }
            return attSize;
          }, 0);
          return batchSize + docSize;
        }
        return batchSize;
      }, 0);

      return (size * 8) / Math.pow(10, 9) / 2.67;
    }
    return 0;
  }

  private abortReplication(): void {
    BackgroundMode.disableBackGroundMode();
    // if we don't do this, it's not possible to restart a download after an abort.
    this.ngZone.run(() => this.router.navigate([""]));
  }

  /**
   * Call when replication is over.
   * Stops the display refresh and saves the db as offline.
   */
  private completeReplication(): Promise<boolean> {
    this.resetProgress();

    return this.saveDbIsOffline().then(() => {
      BackgroundMode.disableBackGroundMode();
      this.messageService.success(`${this.translate.instant("download.complete")}`);
      return this.ngZone.run(() => this.router.navigate([HomeRoute.LOCAL]));
    });
  }

  private saveDbIsOffline(): Promise<DbItem> {
    return this.pouch.pubCaller.saveOfflinePublication(this.store.pubInfo);
  }
}
