import { querySelectorAllToList } from "@viewer/content-provider/dom-manipulator";

export interface SynchroInformation {
  id: string; // pme-0003
  internalRef: string;
  frame: string; // 300 frame rate = 25
}

/**
 * Build sorted synchronization metadata
 *
 * @param proceduralStepObject
 * @param multimediaObject
 */
export function buildSynchroReferential(
  proceduralStepObject,
  multimediaObject
): Map<string, SynchroInformation> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const referential: Map<string, any> = new Map<string, SynchroInformation>();
  try {
    const proceduralSteps = querySelectorAllToList(proceduralStepObject);
    if (multimediaObject) {
      const isMP4 = multimediaObject.getAttribute("multimediaType").toLowerCase() === "mp4";
      if (isMP4) {
        // Exception for first level (there is no id in xsl)
        referential.set("0", {
          id: "firstLevel",
          frame: 0
        });
      }
      proceduralSteps.forEach(stepXMLElement => {
        const stepId = stepXMLElement.getAttribute("id");
        const internalRef = getInternalRefId(stepXMLElement);
        const time = getTimeForInternalRefId(multimediaObject, internalRef);
        if (stepId && internalRef && time) {
          referential.set(time, {
            id: stepId,
            frame: time,
            internalRef
          });
        }
      });
    }
  } catch (e) {
    console.error("Error to retrieve video information", e.message);
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return referential;
  }
}

/**
 *
 * @param multimediaXmlObject
 * @param internalRefId
 */
function getTimeForInternalRefId(multimediaXmlObject, internalRefId: string): string | undefined {
  const paramElement = multimediaXmlObject.querySelector(`parameter[id=${internalRefId}]`);
  const parameterName = paramElement ? paramElement.getAttribute("parameterName") : undefined;
  const isValid =
    (paramElement && parameterName === "Block_Frame_Anim") || parameterName === "Marker_Frame_Anim";

  return isValid ? paramElement.getAttribute("parameterIdent") : undefined;
}

/**
 *
 * @param stepXMLElement
 */
function getInternalRefId(stepXMLElement): string | undefined {
  const containHotspot = stepXMLElement.querySelector(
    `hotspot[hotspotType="thumbnail"] > internalRef[internalRefTargetType="irtt51"],
    hotspot[hotspotType="thumbnail"] > internalRef[internalRefTargetType="irtt53"]`
  );
  return containHotspot ? containHotspot.getAttribute("internalRefId") : undefined;
}

/**
 * Metamedia are video or 3D
 */
export function findMetaMedia(frag): string {
  const multimediaObject = frag.querySelector("multimediaObject");
  const mediaType = multimediaObject ? multimediaObject.getAttribute("multimediaType") || "" : "";

  // only mp4 are supported right now
  return mediaType.toLowerCase() === "mp4" || mediaType.toLowerCase() === "3d"
    ? multimediaObject.getAttribute("infoEntityIdent") || ""
    : "";
}
