import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "o-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"]
})
export class SearchBarComponent implements OnInit {
  @Input() placeHolder: string;
  @Input() defaultText = "";
  @Output() textEnter = new EventEmitter<string>();
  @Output() goSearch = new EventEmitter<string>();
  public text: string;

  ngOnInit() {
    this.text = this.defaultText;
  }

  public search(): void {
    this.goSearch.emit(this.text);
  }

  public valueChange(newText: string): void {
    this.textEnter.emit(newText);
  }

  public empty(): void {
    this.text = "";
    this.textEnter.emit(this.text);
  }
}
