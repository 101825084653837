import { DbItem } from "@orion2/models/couch.models";
import { MimeType } from "libs/models/enums";

export const DEFAULT_ATTACHMENT_SIZE = 1000000;

/**
 * Function to join chunks into 1 Blob, Buffer or string
 *
 * @export
 * @param {PouchDB.Core.AttachmentData[]} chunks
 * @return {PouchDB.Core.AttachmentData}
 */
export function joinChunks(chunks: PouchDB.Core.AttachmentData[]): PouchDB.Core.AttachmentData {
  const [firstChunk] = chunks;

  if (typeof firstChunk === "string") {
    return chunks.join("");
  }
  // BUFFER
  else if (typeof firstChunk === "object" && "length" in firstChunk) {
    return Buffer.from(chunks.join(""));
  }
  // Blob
  else if (typeof firstChunk === "object" && "size" in firstChunk) {
    return new Blob(chunks);
  }

  return Buffer.from("");
}

/**
 * Function to chunk Buffer into smaller buffers
 *
 * @export
 * @param {PouchDB.Core.AttachmentData} buffer
 * @param {number} chunkSize
 * @return {PouchDB.Core.AttachmentData[]}
 */
export function chunkBuffer(
  data: PouchDB.Core.AttachmentData,
  chunkSize: number
): PouchDB.Core.AttachmentData[] {
  if (!isNaN(chunkSize) && chunkSize <= 0) {
    throw new Error("Chunk size should be positive number");
  }

  const result: PouchDB.Core.AttachmentData[] = [];
  const dataSize = typeof data === "string" || "length" in data ? data.length : data.size;

  for (let i = 0; i < dataSize; i += chunkSize) {
    result.push(data.slice(i, i + chunkSize));
  }

  return result;
}

/**
 * Function to create a pouchdb attachement object (chunk if needed)
 *
 * @export
 * @param {DbItem} doc
 * @param {PouchDB.Core.AttachmentData} data
 * @param {number} chunkSize
 * @param {(MimeType | string)} [mime=MimeType.BIN]
 * @param {(boolean | string)} [useSuffixIdOrName=false]
 */
export function createAttachement(
  doc: DbItem,
  data: PouchDB.Core.AttachmentData,
  chunkSize: number,
  mime: MimeType | string = MimeType.BIN,
  useSuffixIdOrName: boolean | string = false
): void {
  const chunks: PouchDB.Core.AttachmentData[] = chunkBuffer(data, chunkSize);
  doc._attachments = {};

  const fileName =
    (typeof useSuffixIdOrName === "string" && useSuffixIdOrName) ||
    (useSuffixIdOrName && doc._id) ||
    "att";

  chunks.forEach((chunk: Buffer, index: number) => {
    const paddedIndex = `00000${index}`.slice(-5);
    const id = `${fileName}__${paddedIndex}`;
    doc._attachments[id] = {
      content_type: mime,
      data: chunk
    };
  });
}
