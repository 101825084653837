import { Injectable } from "@angular/core";
import { ProductType, PubDoc } from "libs/models/couch.models";
import { PouchService } from "@viewer/core/pouchdb";
import { user_manual } from "@viewer-assets/extra-docs/user_manual";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { ConfService } from "@viewer/core/conf/conf.service";
import { LocalStorageBackend } from "@openid/appauth/built/storage";
import { PubMeta } from "@viewer/core/pouchdb/caller";
import { Basepub } from "@orion2/models/basepub.models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";
import { getDisplayedRevision } from "@orion2/utils/front.utils";
import { Params } from "@angular/router";
import { ProductsService } from "@viewer/home/service/products.service";

@Injectable()
export class ProductsBP2Service implements ProductsService {
  private basepubProducts: Basepub.Product[] = [];
  private storage = new LocalStorageBackend();
  private pubs: PubDoc[];

  constructor(
    private basepubUserService: BasepubUserService,
    private pouchService: PouchService,
    private confService: ConfService
  ) {}

  public getPubDoc(pubId: string): PubDoc {
    return this.pubs?.find((pub: PubDoc) => pubId === pub._id);
  }

  public getLocalProducts(): Promise<PubDoc[]> {
    return this.getOfflinePubs().then((pubs: PubDoc[]) => {
      this.pubs = [...pubs, user_manual];
      return this.sortPubs(this.pubs);
    });
  }

  public getOnlineProducts(params?: Params): Promise<PubDoc[]> {
    return this.getOnlinePubs(params).then((onlinePubs: PubDoc[]) => {
      this.pubs = [...onlinePubs, ...this.getNonConsultableProducts()];
      return this.sortPubs(this.pubs);
    });
  }

  public enrichPubDoc(pub: PubDoc): Promise<PubDoc> {
    const productRef = this.basepubProducts.find(
      (product: Basepub.Product) => product.occCode.toString() === pub.occurrenceCode
    );

    const revisionRef = productRef?.revisions?.find(
      (rev: Basepub.ProductRevision) => pub.uidRev === rev.uidRev
    );

    return Promise.all([this.isLast(pub), this.hasStartedDownload(pub)]).then(
      ([isLast, hasStartedDownload]: boolean[]) => {
        pub.isLast = isLast;
        pub.hasStartedDownload = hasStartedDownload;
        pub.priority = productRef?.manual?.priority || pub.priority;
        pub.manualCategory = productRef?.manual?.category;
        pub.filename = revisionRef?.filename;
        pub.fileSize = revisionRef?.fileSize;
        pub.status = revisionRef?.status || pub.status;
        pub.classifications = revisionRef?.classifications;
        pub.title = productRef?.title;
        pub.description = productRef?.description;
        pub.displayRevision = true;
        pub.isConsultable = true;
        pub.productType = ProductType.ONLINE;

        if (pub.manualCode !== "ORIO") {
          pub.imgName = productRef?.manual?.imgName || pub.imgName;
          pub.jacket = this.getProductAvatar(pub.imgName);
        }

        pub.infos = [
          pub.packageId,
          pub.revisionDate,
          pub.status,
          pub.revision,
          pub.occurrenceCode,
          pub.verbatimText,
          pub.manualCode,
          pub.subtitle
        ].join(" ");

        return pub;
      }
    );
  }

  /**
   * Store in local storage the last revision for every publication in remote database.
   */
  public storeLastRevisions(pubs: PubMeta[]): Promise<void[]> {
    const currentEnv = this.confService.conf.label;
    const uniqueRes = new Set<string>();
    const proms = pubs.map((res: PubMeta) => {
      // For each occCode, revisions are sorted by descending order; the first encoutered is the most recent.
      if (!uniqueRes.has(res.key)) {
        uniqueRes.add(res.key);
        return this.storage.setItem(`${currentEnv}_${res.key}`, res.value);
      }
      return Promise.resolve();
    });

    return Promise.all(proms);
  }

  /**
   * Convert a Basepub.Product to a PubDoc.
   *
   * @param product The Basepub.Product to convert
   * @param revision The concerned revision
   */
  private mapBasepubProductToPubDoc(
    product: Basepub.Product,
    revision: Basepub.ProductRevision
  ): PubDoc {
    const subtitle = product?.certification?.label
      ? `${product.manual.label} - ${product?.certification?.label}`
      : product.manual.label;

    const pub: PubDoc = {
      _id: "",
      jacket: this.getProductAvatar(product.manual.imgName),
      consultUrl: revision.consultUrl,
      displayRevision: product.displayRevisionNumber,
      filename: revision.filename,
      fileSize: revision.fileSize,
      isConsultable: false,
      isLast: revision === product.revisions[0],
      isPublished: true,
      lang: product.langCode?.toLowerCase(),
      manualCategory: product.manual.category,
      manualCode: product.manual.code,
      occurrenceCode: product.occCode.toString(),
      priority: product.manual.priority,
      productType: product.isOnline ? ProductType.ONLINE : ProductType.OFFLINE,
      revNumber: revision.revNumber,
      indiceVersion: revision.indiceVersion,
      uidRev: revision.uidRev,
      revision: revision.uidRev,
      classifications: revision.classifications,
      revisionDate: revision.date,
      status: revision.status,
      subtitle,
      verbatimText: product.srdFamilyLabel,
      isPackagePDF: false,
      // SPEC: Theses attributes are just a placeholder, beceause it's offline only publication
      packageId: "",
      versionImporter: "",
      import_date: "",
      isS1000D: false,
      dbs: {},
      sizes: {},
      pubSchema: undefined,
      capabilities: undefined,
      minRelease: "",
      reasonForUpdate: []
    };

    pub.infos = [
      pub.verbatimText,
      pub.revision,
      pub.revisionDate,
      pub.status,
      pub.occurrenceCode,
      pub.manualCode,
      pub.subtitle
    ].join(" ");

    return pub;
  }

  private sortPubs(pubs: PubDoc[]): PubDoc[] {
    return pubs.sort((pub1: PubDoc, pub2: PubDoc) => {
      // Pubs are first sorted by priority
      if (pub1.priority !== pub2.priority) {
        return pub1.priority - pub2.priority;
      }
      // In case of equality, we use alphabetical order on verbatimText
      if (pub1.verbatimText !== pub2.verbatimText) {
        return pub1.verbatimText.localeCompare(pub2.verbatimText);
      }
      // If equality again, it is the same product: we sort the revisions
      return getDisplayedRevision(pub2).localeCompare(getDisplayedRevision(pub1));
    });
  }

  private getPubDocs(ids: string[], target = DBConnexionType.REMOTE): Promise<PubDoc[]> {
    return this.pouchService.pubCaller
      .getPubs(ids, target)
      .then((pubs: PubDoc[]) => Promise.all(pubs.map((pub: PubDoc) => this.enrichPubDoc(pub))));
  }

  private getSubscriptionIds(params?: Params): Promise<string[]> {
    if (!this.confService.useAuth) {
      return this.pouchService.pubCaller
        .getPubsMeta(["all"])
        .then((pubMetas: PubMeta[]) => pubMetas.map((meta: PubMeta) => meta.id));
    }

    return this.basepubUserService
      .getProducts(params)
      .then((basepubProducts: Basepub.Product[]) => {
        const occCodes = basepubProducts.map((product: Basepub.Product) =>
          product.occCode.toString()
        );

        return this.pouchService.pubCaller.getPubsMeta(occCodes).then((pubMetas: PubMeta[]) =>
          this.storeLastRevisions(pubMetas).then(() => {
            const occCodesFound = pubMetas.map((meta: PubMeta) => meta.key);
            this.basepubProducts = basepubProducts.map((product: Basepub.Product) => {
              product.isConsultable = occCodesFound.includes(product.occCode.toString());
              return product;
            });

            return pubMetas.map((meta: PubMeta) => meta.id);
          })
        );
      });
  }

  private isLast(pub: PubDoc): Promise<boolean> {
    const currentEnv = this.confService.conf.label;
    return this.storage
      .getItem(`${currentEnv}_${pub.occurrenceCode}`)
      .then((lastRevision: string) => (lastRevision ? pub.revision === lastRevision : true))
      .catch(() => true);
  }

  private hasStartedDownload(pub: PubDoc): Promise<boolean> {
    return this.storage
      .getItem(`${pub._id}__downloaded`)
      .then((download: string) => parseFloat(download) > 0);
  }

  private getOnlinePubs(params?: Params): Promise<PubDoc[]> {
    return this.getSubscriptionIds(params).then((ids: string[]) =>
      this.getPubDocs(ids, DBConnexionType.REMOTE)
    );
  }

  private getOfflinePubs(): Promise<PubDoc[]> {
    return this.pouchService.pubCaller
      .getPubsMeta(["all"], DBConnexionType.LOCAL)
      .then((response: PubMeta[]) => {
        const ids = response.map((pubMeta: PubMeta) => pubMeta.id);
        return this.getPubDocs(ids, DBConnexionType.LOCAL);
      });
  }

  private getNonConsultableProducts(): PubDoc[] {
    if (!this.basepubProducts) {
      return [];
    }

    return this.basepubProducts
      .filter((product: Basepub.Product) => !product.isConsultable)
      .map((product: Basepub.Product) =>
        product.revisions.map((revision: Basepub.ProductRevision) =>
          this.mapBasepubProductToPubDoc(product, revision)
        )
      )
      .flat();
  }

  /**
   * Return the path of the avatar for the given category.
   *
   * @param imgName A category image name.
   */
  private getProductAvatar(imgName: string): string {
    return imgName ? `assets/img/${imgName}.png` : "assets/img/default-thumbnail.jpg";
  }
}
