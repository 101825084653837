import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ServiceBulletin } from "@orion2/models/tocitem.models";
import { ApplicabilityService, Store } from "@viewer/core";
import { CiraService } from "@viewer/core/ipc/cira/cira.service";
import { SearchResult } from "@viewer/core/search/searchModel";
import { ServiceBulletinService } from "@viewer/core/toc-items/service-bulletin.service";
import { IReactionDisposer, reaction } from "mobx";

export interface OrionCardData {
  dmc?: string;
  title?: string;
  subtitle?: string;
  documentType?: string;
  footer?: OrionCardFooter;
  checkImpactSB?: boolean;
  displayApplicBlock?: boolean;
  forceVisibility?: boolean;
}

export interface OrionCardFooter {
  key: string;
  parameters?: Object;
}

@Component({
  selector: "o-orion-card",
  templateUrl: "./orion-card.component.html",
  styleUrls: ["./orion-card.component.scss"]
})
export class OrionCardComponent implements OnInit, OnDestroy {
  @Input() data: OrionCardData;

  public metadata: SearchResult;
  public isNotApplicable = false;
  public applicabilities: string[] = undefined;
  public applicabilityOpen = false;
  public isImpactedDM = false;

  private applicReaction: IReactionDisposer;
  private searchReaction: IReactionDisposer;

  constructor(
    public store: Store,
    private applicabilityService: ApplicabilityService,
    private ciraService: CiraService,
    private sbService: ServiceBulletinService
  ) {}

  ngOnInit(): void {
    if (this.data.dmc) {
      this.searchReaction = reaction(
        () => this.store.offlineSearchReady,
        () => {
          if (this.store.offlineSearchReady) {
            this.metadata = this.store.getDUMeta(this.data.dmc);

            if (!this.metadata) {
              return;
            }

            this.setApplicReaction();

            if (this.data.displayApplicBlock) {
              this.getCiraApplics();
            }

            if (this.data.checkImpactSB) {
              this.checkIsImpactingSB();
            }
          }
        },
        { fireImmediately: true }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.searchReaction) {
      this.searchReaction();
    }

    if (this.applicReaction) {
      this.applicReaction();
    }
  }

  public toggleApplic(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.applicabilityOpen = !this.applicabilityOpen;
  }

  private setApplicReaction() {
    this.applicReaction = reaction(
      () => ({
        applicMd5: this.store.applicableMD5,
        show: this.store.showNotApplicable,
        isFilteringAvailable: this.store.isFilteringAvailable
      }),
      () => {
        this.isNotApplicable = !this.applicabilityService.isApplicable(this.metadata);
      },
      { fireImmediately: true }
    );
  }

  private getCiraApplics() {
    this.ciraService
      .getConditionalApplic(this.metadata.applicabilityMD5)
      .then((applicabilities: string[]) => {
        this.applicabilities = applicabilities;
      });
  }

  private checkIsImpactingSB() {
    this.sbService.getAllImpactingSB(this.data.dmc).then((sbs: ServiceBulletin[]) => {
      this.isImpactedDM = sbs.length > 0;
    });
  }
}
