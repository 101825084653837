export class EndSessionRequest {
  private idToken: string;
  private logoutRedirectUri: string;

  constructor(idToken: string, logoutRedirectUri: string) {
    this.idToken = idToken;
    this.logoutRedirectUri = logoutRedirectUri;
  }

  public getQueryParam(): string {
    return `id_token_hint=${this.idToken}&post_logout_redirect_uri=${this.logoutRedirectUri}`;
  }
}
