import { AuthorizationRequest } from "@openid/appauth/built/authorization_request";
import { AuthorizationRequestResponse } from "@openid/appauth/built/authorization_request_handler";
import { AuthorizationServiceConfiguration } from "@openid/appauth/built/authorization_service_configuration";
import {
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest
} from "@openid/appauth/built/token_request";
import { TokenResponse } from "@openid/appauth/built/token_response";
import { StringMap } from "@openid/appauth/built/types";
import { ExtendedTokenRequestHandler } from "@orion2/auth/extended.token.request.handler";
import { AuthRequestHandler } from "@orion2/auth/auth.request.handler";
import { log } from "@openid/appauth/built/logger";
import { AuthorizationResponse } from "@openid/appauth/built/authorization_response";
import { Requestor } from "@openid/appauth/built/xhr";
import { AuthFlowConfiguration } from "@orion2/auth/auth.flow.conf";
import { OakResponse } from "@orion2/auth/oak.response";
import { OakRequest } from "@orion2/auth/oak.request";
import { OakRequestHandler } from "@orion2/auth/oak.request.handler";
import { RevokeTokenRequest } from "@openid/appauth/built/revoke_token_request";
import { ExtendedAuthorizationServiceConfiguration } from "@orion2/auth/extendedAuthorizationServiceConfiguration";
import { RoleRequest, UserManagementResponse } from "@orion2/auth/role.interfaces";
import { RoleRequestHandler } from "@orion2/auth/role.request.handler";
import { EndSessionRequest } from "libs/auth/src/end.session.request";
import { Crypto } from "@openid/appauth/built/crypto_utils";
import { UserinfoResponse } from "openid-client";

export interface CustomRequestor extends Requestor {
  xhrWithErrorBody?: <T>(settings: JQueryAjaxSettings) => Promise<T>;
}

export class AuthFlow {
  public configuration:
    | ExtendedAuthorizationServiceConfiguration
    | AuthorizationServiceConfiguration
    | undefined;

  public oakResponse: OakResponse | undefined;

  private authorizationHandler: AuthRequestHandler;
  private tokenHandler: ExtendedTokenRequestHandler;

  private crypto: Crypto;

  private requestor: CustomRequestor; // state

  private refreshToken: string | undefined;
  private accessTokenResponse: TokenResponse | undefined;
  private checkedOccCodes: string[] = [];
  private authConf: AuthFlowConfiguration;
  private hasBp2 = false;
  private pendingAuthentication = false;
  private pendingAccessToken = false;
  private pendingRefreshRequest = false;
  private pendingOakRequest = false;
  private oakHandler: OakRequestHandler;
  private roleHandler: RoleRequestHandler;
  private profilePromise: Promise<UserinfoResponse> = undefined;

  private stayLoggedIn = false;
  private stayLoggedInCallbackBind: () => {};

  constructor(conf: AuthFlowConfiguration) {
    this.authConf = conf;
    this.hasBp2 = conf.hasBp2;
    this.authorizationHandler = this.authConf.authorizationHandler;
    this.requestor = this.authConf.requestor;
    this.tokenHandler = new ExtendedTokenRequestHandler(this.requestor);
    this.oakHandler = this.authConf.oakHandler;
    this.oakHandler.setRequestor(this.requestor);
    this.crypto = this.authConf.crypto;
    this.roleHandler = this.authConf.role;
    // Cast as any to remove compilation error
    this.requestor.xhrWithErrorBody = this.authConf.xhrWithErrorBody;
    this.roleHandler.setRequestor(this.requestor);
    this.stayLoggedInCallbackBind = this.stayLoggedInCallback.bind(this);
  }

  setRefreshToken(token: string): void {
    this.refreshToken = token;
  }

  public fetchServiceConfiguration(): Promise<boolean> {
    if (this.configuration) {
      return Promise.resolve(true);
    }
    return ExtendedAuthorizationServiceConfiguration.fetchFromIssuer(
      this.authConf.openIdConnectUrl,
      this.requestor
    ).then((response: AuthorizationServiceConfiguration) => {
      this.configuration = response;
      return true;
    });
  }

  makeAuthorizationRequest(): Promise<void | AuthorizationRequestResponse> {
    if (!this.configuration) {
      return Promise.reject({
        code: 500,
        message: "Unknown service configuration"
      });
    }

    if (this.pendingAuthentication) {
      return this.authorizationHandler.promise;
    }

    this.pendingAuthentication = true;
    const extras: StringMap = {
      // prompt: "consent",
      access_type: "offline",
      acr_values: this.authConf.clientId,
      client_secret: this.authConf.clientSecret
    };

    // create a request
    const request = new AuthorizationRequest(
      {
        client_id: this.authConf.clientId,
        redirect_uri: this.authConf.redirectUri,
        scope: this.authConf.scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        extras
      },
      this.crypto
    );

    return this.authorizationHandler
      .performAuthorizationRequest(this.configuration, request)
      .then(authResponse => {
        this.pendingAuthentication = false;
        this.authorizationHandler.resetPromise();
        return authResponse;
      })
      .catch(err => {
        console.log("AuthFlow error : ", err);
        this.pendingAuthentication = false;
        this.authorizationHandler.resetPromise();
        throw err;
      });
  }

  processOakResponse(oakResponse: OakResponse): OakResponse {
    this.oakResponse = oakResponse;
    return oakResponse;
  }

  public login(): Promise<TokenResponse | { code: number; message: Error }> {
    return this.makeAuthorizationRequest()
      .then((authResponse: AuthorizationRequestResponse) => {
        if (authResponse) {
          return this.makeRefreshTokenRequest(
            this.configuration,
            authResponse.request,
            authResponse.response
          );
        }
        this.setStayLoggedIn(false);
        return this.tokenHandler.promise.then(response => this.processTokenResponse(response));
      })
      .catch(err => ({ code: 400, message: err }));
  }

  public profile(): Promise<UserinfoResponse> {
    if (!this.profilePromise) {
      this.profilePromise = this.getValidAccessToken()
        .then((accessToken: TokenResponse) =>
          this.requestor.xhr<UserinfoResponse>({
            url: this.configuration.userInfoEndpoint,
            method: "GET",
            dataType: "json",
            headers: {
              Authorization: `Bearer ${accessToken.accessToken}`,
              Origin: this.authConf.baseUrl,
              Referer: this.authConf.baseUrl
            },
            cache: false
          })
        )
        .catch(error => {
          console.error("Profile error : ", error);
          return Promise.reject(error);
        });
    }
    return this.profilePromise;
  }

  public logout(idToken: string): Promise<boolean> {
    if (!this.configuration) {
      return Promise.reject({
        code: 500,
        message: "Unknown service configuration"
      });
    }

    window.addEventListener("stay_logged_in", this.stayLoggedInCallbackBind);

    return this.authorizationHandler
      .performEndSessionRequest(
        this.configuration,
        new EndSessionRequest(idToken, this.authConf.postLogoutRedirectUri)
      )
      .then(() => {
        window.removeEventListener("stay_logged_in", this.stayLoggedInCallbackBind);

        if (this.stayLoggedIn) {
          this.setStayLoggedIn(false);
          return false;
        }

        return this.tokenHandler
          .performRevokeTokenRequest(
            this.configuration,
            new RevokeTokenRequest({
              token: this.accessTokenResponse.refreshToken,
              token_type_hint: "refresh_token",
              client_id: this.authConf.clientId
            })
          )
          .then(() => {
            console.log("Access token is revoked.");
            this.resetToken();
            this.resetOak();
            // Reset profile response
            this.profilePromise = undefined;
            this.roleHandler.resetPromise();
            this.authorizationHandler.resetPromise();

            console.log("Logout successful.");
            return true;
          });
      })
      .catch(err => {
        console.error("logout ", err);
        return false;
      });
  }

  public getValidAccessToken(): Promise<TokenResponse | { code: number; message: Error }> {
    if (!this.configuration) {
      return Promise.reject({
        code: 500,
        message: "Unknown service configuration"
      });
    }

    if (!this.refreshToken) {
      return Promise.reject({
        code: 500,
        message: "Missing refreshToken"
      });
    }

    if (
      this.accessTokenResponse &&
      this.accessTokenResponse.isValid(this.authConf.tokenExpireBuffer)
    ) {
      // do nothing
      return Promise.resolve(this.accessTokenResponse);
    }

    if (this.pendingRefreshRequest) {
      return this.tokenHandler.promise.then(response => this.processTokenResponse(response));
    }

    this.pendingRefreshRequest = true;

    const request = new TokenRequest({
      client_id: this.authConf.clientId,
      redirect_uri: this.authConf.redirectUri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: this.refreshToken,
      extras: {
        access_token_manager_id: "jwttoken",
        client_secret: this.authConf.clientSecret
      }
    });
    return this.tokenHandler
      .performTokenRequest(this.configuration, request)
      .then((response: TokenResponse) => {
        this.pendingRefreshRequest = false;
        this.tokenHandler.resetPromise();
        // We have to reset the OAK so that it stay bound to the access_token
        this.resetOak();
        return this.processTokenResponse(response);
      })
      .catch(() => {
        dispatchEvent(new CustomEvent("login_status", { detail: { isLoggedIn: false } }));
        this.pendingRefreshRequest = false;
        this.tokenHandler.resetPromise();
        return this.login();
      });
  }

  getValidOak(occCode?: string): Promise<OakResponse> {
    if (!this.configuration) {
      return Promise.reject({
        code: 500,
        message: "Unknown service configuration"
      });
    }

    if (!this.accessTokenResponse) {
      return Promise.reject({
        code: 401,
        message: "Unauthorized : Missing access token"
      });
    }

    // SPEC: Oak needs update if specified occCode is not checked and BP2 mode is enable
    // TODO [LWR] : OAK BUG to investigate
    const oakNeedsEnhancement =
      !!occCode && this.hasBp2 && !this.checkedOccCodes.find((el: string) => occCode === el);

    if (!oakNeedsEnhancement) {
      if (this.oakResponse?.isValid(this.authConf.tokenExpireBuffer)) {
        return Promise.resolve(this.oakResponse);
      }

      if (this.pendingOakRequest) {
        return this.oakHandler.promise.then((oakResponse: OakResponse) =>
          this.processOakResponse(oakResponse)
        );
      }
    }

    this.pendingOakRequest = true;

    const request = new OakRequest({
      bearer: { access_token: this.accessTokenResponse.accessToken }
    });
    if (occCode) {
      this.checkedOccCodes.push(occCode);
      request.occCode = occCode;
      if (this.oakResponse?.isValid(this.authConf.tokenExpireBuffer)) {
        request.oak = this.oakResponse.oak;
      }
    }

    // TODO: Unify with performAccessRequest and performOakRequest
    return this.oakHandler
      .performOakRequest(this.authConf, request)
      .then((oakResponse: OakResponse) => {
        this.pendingOakRequest = false;
        this.oakHandler.resetPromise();
        return this.processOakResponse(oakResponse);
      })
      .catch(err => {
        this.pendingRefreshRequest = false;
        this.oakHandler.resetPromise();
        return Promise.reject(err);
      });
  }

  public getUserRole(userId: string, fromAdmin?: boolean): Promise<UserManagementResponse> {
    // When we come from the admin we don't have an OAK.
    if ((this.oakResponse && !this.oakResponse.deprecated) || fromAdmin) {
      const request = new RoleRequest({
        bearer: { access_token: this.accessTokenResponse.accessToken },
        params: { userId }
      });
      const url = this.authConf.baseUrl + "/roles/" + userId;
      return this.roleHandler.performRoleRequest(request, url);
    } else {
      return Promise.reject({
        error: "ROLE_KO"
      });
    }
  }

  /**
   * Method that all user DBs related to occCode are created
   *
   * @param occCode
   */
  checkUserDbs(occCode: string): Promise<boolean> {
    return this.getValidAccessToken()
      .then(() => this.getValidOak(occCode !== "9999" ? occCode : undefined))
      .then((oakResponse: OakResponse) =>
        this.requestor.xhr<{ db: string; ok: boolean }[]>({
          url: `${this.authConf.baseUrl}/ums-api/check_user_dbs/${occCode}`,
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: `Bearer ${oakResponse.accessToken}`,
            oak: oakResponse.oak,
            Origin: this.authConf.baseUrl,
            Referer: this.authConf.baseUrl
          },
          cache: false
        })
      )
      .then((response: { db: string; ok: boolean }[]) =>
        // return true if all user DBs are created
        // Array.from(response) is needed for electron, because it doesn't response to be a real array
        Array.from(response).reduce(
          (sum: boolean, next: { db: string; ok: boolean }) => sum && next.ok,
          true
        )
      )
      .catch(error => {
        // TODO LWR: this message is mostly wrong, errors can be thrown by oak generation (with roles/products APIs...)
        console.error("Error while checking user DBs: ", error);
        return false;
      });
  }

  public fetchHasPending(
    occCode: string
  ): Promise<{ private: number; corp: number; public: number; total: number }> {
    return this.getValidAccessToken()
      .then(() => this.getValidOak())
      .then((oakResponse: OakResponse) =>
        this.requestor.xhr<{ private: number; corp: number; public: number; total: number }>({
          url: `${this.authConf.baseUrl}/ums-api/has_pending/${occCode}`,
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: `Bearer ${oakResponse.accessToken}`,
            oak: oakResponse.oak,
            Origin: this.authConf.baseUrl,
            Referer: this.authConf.baseUrl
          },
          cache: false
        })
      )
      .then((response: { private: number; corp: number; public: number; total: number }) => {
        console.log(`Has pending for occCode ${occCode} :`, response);
        return response;
      })
      .catch(error => {
        console.error("Error while fetching has pending dbs: ", error);
        return undefined;
      });
  }

  public fetchTransferHasPending(occCode: string, transferPrivate: boolean): Promise<boolean> {
    return this.getValidAccessToken()
      .then(() => this.getValidOak())
      .then((oakResponse: OakResponse) =>
        this.requestor.xhr<{ ok: true }>({
          url: `${this.authConf.baseUrl}/ums-api/transfert/${occCode}/${transferPrivate}`,
          method: "POST",
          dataType: "json",
          headers: {
            Authorization: `Bearer ${oakResponse.accessToken}`,
            oak: oakResponse.oak,
            Origin: this.authConf.baseUrl,
            Referer: this.authConf.baseUrl
          },
          cache: false
        })
      )
      .then((response: { ok: boolean }) => {
        console.log(`Transfer for occCode ${occCode} :`, response);
        return response.ok;
      })
      .catch((error: Error) => {
        console.error("Error while transfer pending databases : ", error);
        return false;
      });
  }

  private processTokenResponse(response: TokenResponse): TokenResponse {
    this.accessTokenResponse = response;
    this.refreshToken = response.refreshToken;
    return response;
  }

  private makeRefreshTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest,
    response: AuthorizationResponse
  ): Promise<TokenResponse | { code: number; message: Error }> {
    if (this.pendingAccessToken) {
      return this.tokenHandler.promise.then(tokenResponse => {
        this.pendingAccessToken = false;
        return this.processTokenResponse(tokenResponse);
      });
    }

    this.pendingAccessToken = true;

    let extras: StringMap | undefined;
    extras = undefined;
    if (request && request.internal) {
      extras = {};
      extras["code_verifier"] = request.internal["code_verifier"];
      extras["client_secret"] = this.authConf.clientSecret;
    }

    const tokenRequest = new TokenRequest({
      client_id: this.authConf.clientId,
      redirect_uri: this.authConf.redirectUri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: response.code,
      refresh_token: undefined,
      extras
    });

    return this.tokenHandler
      .performTokenRequest(configuration, tokenRequest)
      .then((tokenResponse: TokenResponse) => {
        this.pendingAccessToken = false;
        this.tokenHandler.resetPromise();
        log(`Refresh Token is ${tokenResponse.refreshToken}`, tokenResponse);
        return this.processTokenResponse(tokenResponse);
      })
      .catch((err: Error) => {
        dispatchEvent(new CustomEvent("login_status", { detail: { isLoggedIn: false } }));
        this.pendingAccessToken = false;
        this.tokenHandler.resetPromise();
        return { code: 400, message: err };
      });
  }

  private resetOak(): void {
    this.pendingOakRequest = false;
    this.oakHandler.resetPromise();
    this.oakResponse = undefined;
    this.checkedOccCodes = [];
  }

  private resetToken(): void {
    this.accessTokenResponse = undefined;
    this.refreshToken = undefined;
    this.tokenHandler.resetPromise();
  }

  private setStayLoggedIn(stayLoggedIn: boolean): void {
    this.stayLoggedIn = stayLoggedIn;
  }

  private stayLoggedInCallback(): void {
    this.setStayLoggedIn(true);
  }
}
