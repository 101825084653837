// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-actions {
  justify-content: center;
  margin-bottom: 0;
  margin-top: 20px;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInB1Yi1kZXN0cm95LW1vZGFsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsdUJBQUE7RUFDQSxnQkFBQTtFQUNBLGdCQUFBO0VBQ0EsVUFBQTtBQURGIiwiZmlsZSI6InB1Yi1kZXN0cm95LW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcImxpYnMvdGhlbWUvb3Jpb24tdGhlbWVcIjtcblxuLm1hdC1kaWFsb2ctYWN0aW9ucyB7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBtYXJnaW4tYm90dG9tOiAwO1xuICBtYXJnaW4tdG9wOiAyMHB4O1xuICBwYWRkaW5nOiAwO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/replication/pub-destroy-modal/pub-destroy-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AADF;AACA,wgBAAwgB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n.mat-dialog-actions {\n  justify-content: center;\n  margin-bottom: 0;\n  margin-top: 20px;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
