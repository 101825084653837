import { Injectable } from "@angular/core";
import { XSLService } from "@viewer/content-provider/xsl.service";
import { Store } from "@viewer/core";
import {
  IpcPreviewData,
  IpcPreviewDataATA100Model,
  IpcPreviewDataS1000DModel,
  IpcPreviewTarget
} from "@viewer/shared-module/ipc-preview/ipc-preview.models";

@Injectable()
export class IpcPreviewService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialogRef: any;

  constructor(private xslService: XSLService, private store: Store) {}

  retrieveData(attributes: string[]): Promise<IpcPreviewData[]> {
    if (attributes.length > 1) {
      const targets: string[] = attributes[1].split("||");
      const targetArray: IpcPreviewTarget[] = targets.map((target: string) => {
        const data = target.split("##");
        return {
          dmc: data[0],
          csn: data[1] || undefined,
          isnId: data[2] || undefined
        };
      });

      const metadata = targetArray.map((target: IpcPreviewTarget) =>
        this.xslService
          .getXMLNode(target.dmc)
          .then((xml: Document) =>
            this.store.isS1000D
              ? this.extractDataS1000D(xml, target)
              : this.extractDataATA100(xml, target)
          )
      );
      return Promise.all(metadata);
    }

    return Promise.resolve(undefined);
  }

  extractDataATA100(xml: Document, target: IpcPreviewTarget): IpcPreviewDataATA100Model {
    const row: Element = xml?.querySelector(`CSN[CSN='${target.csn}']`);
    const isn: Element = row?.querySelector(`ISN[ID='${target.isnId}']`);
    return {
      dmc: target?.dmc || "",
      item: row?.attributes["ITEM"].value || "",
      csn: target.csn || "",
      qty: isn?.querySelector("QNA") ? isn.querySelector("QNA").innerHTML : "",
      fscm: isn?.querySelector("MFC") ? isn.querySelector("MFC").innerHTML : "",
      mpn: isn?.querySelector("PNR") ? isn.querySelector("PNR").innerHTML : "",
      desc: [
        isn?.querySelector("DFP") ? isn.querySelector("DFP").innerHTML : "",
        isn?.querySelector("CAN") ? isn.querySelector("CAN").innerHTML : ""
      ]
    };
  }

  extractDataS1000D(xml: Document, target: IpcPreviewTarget): IpcPreviewDataS1000DModel {
    const row: Element = xml?.querySelector(`catalogSeqNumber[id='${target.csn}']`);
    const partRef: Element = row?.querySelector("partRef");
    const mpn = target?.isnId ? target.isnId : partRef && partRef.getAttribute("partNumberValue");
    const mfc = partRef && partRef.getAttribute("manufacturerCodeValue");
    const item = row?.getAttribute("item");
    const qty = row?.querySelector("quantityPerNextHigherAssy").innerHTML;
    return {
      dmc: target?.dmc || "",
      item: item || "",
      csn: target?.csn || "",
      qty: qty || "",
      fscm: mfc || "",
      mpn: mpn || ""
    };
  }
}
