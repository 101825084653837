import { FacetSelection } from "@viewer/core/search/facet.service";
import { FacetedSearchResult } from "@viewer/core/search/searchModel";

export type FacetCategory =
  | "versions"
  | "chapter"
  | "section"
  | "manual"
  | "revision"
  | "chapterATA"
  | "limitType"
  | "intervalType"
  | "interval"
  | "climat"
  | "inspection";

export const getResultMap = (selectionMap: FacetSelection, facetedSearchResult) => {
  const result = {};
  Object.keys(selectionMap).forEach((categoryKey: FacetCategory) => {
    const categorySelection = selectionMap[categoryKey];
    const allResultForCateg = facetedSearchResult[categoryKey];

    const resultForCategory =
      categorySelection.length > 0
        ? flatten(allResultForCateg, categorySelection)
        : flatten(allResultForCateg);
    result[categoryKey] = resultForCategory;
  });
  return result;
};

const flatten = (facetResultMap, facetFields: string[] = Array.from(facetResultMap.keys())) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  facetFields.reduce((accumulator: any[], key: string) => {
    // fix
    // do a search, select a facet, click on the cross to reset the research,
    // Then the toc is not displayed when click on the burger button
    if (facetResultMap.get(key)) {
      const facetResult = Array.from(facetResultMap.get(key).values());
      return accumulator.concat(facetResult);
    }
    return [];
  }, []);

// can use precomputed and not use loop

/**
 * facetCategoryMap match displayed data in facets
 * resultMap match categories id
 * facetedSearchResult contains ids by categories
 *
 * @param facetCategoryMap {chapter : Map<clé, obj> , manual : Map<clé, obj>, revision : Map<clé, obj> }
 * @param resultMap { chapter : [ ids ] , manual [ ids ], revision[ ids ] }
 * @param facetedSearchResult {chapter : Map<clé, [ ids ]> , manual : Map<clé, [ ids ]>, revision : Map<clé, [ ids ]> }
 * Algorithm
 * { manual : [ ids ], chapter : [ ids ], revision : [ ids ] } => To array
 * manual : [ ids ] ∩ chapter : [ ids ] ∩ revision : [ ids ] =>  intersection between each categories
 * Array of ids   => [ SelectedIds ]
 * ForEach category
 * Count = [ catégorieIds ] ∩ [ SelectedIds ]
 */
export const updateCount = (
  facetCategoryMap,
  resultMap,
  facetedSearchResult: FacetedSearchResult
) => {
  const candidateIdsByCategory = [];
  Object.keys(resultMap).forEach(
    (
      category // chapter / manual / revision
    ) => {
      const candidateIdsForCategory = getCandidatesIds(category, resultMap);
      candidateIdsByCategory.push(candidateIdsForCategory);
      updateCategoryMap(
        facetCategoryMap[category],
        facetedSearchResult[category],
        candidateIdsForCategory
      );
    }
  );
  return intersection(...candidateIdsByCategory);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCandidatesIds = (category: string, resultMap): any[] =>
  // intersection between each facet to collect only ids
  intersection(
    ...Object.keys(resultMap)
      .filter(cat => cat !== category)
      .map(k => resultMap[k]) // Flatten array by category
  );

/**
 * Retrun ids for the selected category
 *
 * @param setToUpdate Facet category to compare, object that contains result to be displayed
 * @param searchResult Collect all ids of the selected category
 * @param selecedIds List of selected ids
 */
const updateCategoryMap = (facetCategoryMap, facetedSearchResult, candidateIdsForCategory) => {
  const tempMap = new Map();
  candidateIdsForCategory.forEach(id => tempMap.set(id, 1));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Array.from(facetCategoryMap.keys()).forEach((field: any) => {
    const resultForField = facetedSearchResult.get(field);
    const intersectionDMC = resultForField.filter(id => tempMap.get(id) === 1);
    facetCategoryMap.set(field, intersectionDMC);
  });
};

/**
 * Build our intersection, because lodash use Array
 * With a Map that use id for keys performances are better
 *
 * @param args array
 */
const intersection = (...args) => {
  let tempMap = new Map();
  args[0].forEach(val => tempMap.set(val, 1));
  for (let i = 0; i < args.length - 1; i++) {
    const res = new Map();
    args[i + 1].forEach(val => {
      if (tempMap.get(val) === 1) {
        res.set(val, 1);
      }
    });
    tempMap = res;
  }

  return Array.from(tempMap.keys());
};
