export class SearchIndexBuilder {
  // index name and their keys, TODO: export and link them in importer and viewer //
  readonly textKey = "a";
  readonly infoNameKey = "b";
  readonly techNameKey = "c";
  readonly shortDmcKey = "d";
  readonly keyMap = {
    a: "text",
    b: "infoName",
    c: "techName",
    d: "shortDmc"
  };
  //////////////////////////////////////////////////////////////////////////
  readonly mapKey = "map";
  readonly indexKey = "index";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private dmcMap: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private index: any;

  public lunrIndex(pouchDocument) {
    const docs = this._idAsKey(pouchDocument);
    this.checkRequiredDocument(docs);
    this.index = createRootIndex();
    this.dmcMap = docs[this.mapKey];
    const lunrIndex = docs[this.indexKey];
    lunrIndex.index = this.index;
    return lunrIndex;
  }

  /**
   * Create map of couchDB documents with _id as key
   *
   * @private
   * @param docs
   * @returns
   * @memberof SearchService
   */
  private _idAsKey(docs) {
    const map = [];
    docs.forEach(doc => {
      map[doc._id] = doc;
    });
    return map;
  }

  /**
   * Check all docs are present to build the index
   *
   * @private
   * @param docMap
   * @returns
   * @memberof SearchService
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private checkRequiredDocument(docMap: any): void {
    if (!docMap[this.mapKey] || !docMap[this.indexKey]) {
      throw new Error("master documents (map or index) not found for index creation");
    }
  }
}

/*
 * Create the base structure of index
 *
 * @private
 * @returns
 * @memberof SearchService
 */
export function createRootIndex() {
  return {
    text: {
      root: {}
    },
    infoName: {
      root: {}
    },
    techName: {
      root: {}
    },
    shortDmc: {
      root: {}
    }
  };
}

/**
 * Define the type of index (text, title) from the couchDB doc id
 *
 * @private
 * @param node
 * @returns
 * @memberof SearchService
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getIndexTypeFromId(node: any) {
  if (!node._id || node._id.length < 3) {
    // id is a_key or b_key or c_key ect...
    throw new Error(`wrong id for ${JSON.stringify(node)}`);
  }
  const indexKeyName = node._id.charAt(0); // a or b

  const idLeaf = node._id.substring(2);
  return {
    idLeaf,
    indexKeyName
  };
}
