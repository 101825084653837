import { DatePipe } from "@angular/common";
import { Injectable, Injector } from "@angular/core";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { TaskService } from "@viewer/core/toc-items/task.service";
import { Subject, Observable } from "rxjs";
import { TaskInspectionDoc, Inspection, TaskId, TocItem } from "@orion2/models/tocitem.models";
import { replaceRevisionFromDoc } from "@viewer/shared-module/helper.utils";
import cloneDeep from "fast-copy";
import { CsvService } from "@viewer/core/csv/csv.service";
import { Charset } from "libs/download/download-file.service";

@Injectable()
export class InspectionService extends TocItemService {
  public _activateUpdate: Subject<boolean>;
  public inspectionUpdated = new Subject<Inspection>();
  public listShouldUpdate = new Subject<boolean>();

  protected tiType = "inspection";
  protected tiScope = "private";

  private taskService: TaskService;
  private csvService: CsvService;

  constructor(injector: Injector) {
    super(injector);
    this.taskService = injector.get(TaskService);
    this.csvService = injector.get(CsvService);
    this._activateUpdate = new Subject<boolean>();
  }

  public get allTasks(): TaskInspectionDoc[] {
    return this.taskService.allTasks;
  }

  public get notFoundTasks(): TaskInspectionDoc[] {
    return this.taskService.notFoundTasks;
  }

  // Override the getter from tocItemService to ensure we doing the transfert function
  public get tocItemsOfType(): Observable<Inspection[]> {
    return this.tocItemOfTypeSubject.asObservable() as Observable<Inspection[]>;
  }

  // Only Subject<boolean> is send here, boolean is just there so
  // that both getter and setter have same type
  get activateUpdate(): Subject<boolean> | boolean {
    return this._activateUpdate;
  }

  // Use only boolean here, Subject<boolean> is just there so
  // that both getter and setter have same type
  set activateUpdate(val: Subject<boolean> | boolean) {
    this._activateUpdate.next(val as boolean);
  }

  public getInspectionTask(task_id: string): Promise<TaskInspectionDoc> {
    return this.getItemsForTarget(task_id, this.tiType, this.tiScope).then(
      (doc: TaskInspectionDoc[]) => doc[0]
    );
  }

  /**
   * Initialises a new inspection object
   * @param inspectionTitle the title
   * @param tasks the tasks selected from the search
   * @returns an initilised inspection
   */
  public initialiseInspection(inspectionTitle: string, taskIds: TaskId[] = []): Inspection {
    // might be a good idea to refactor all toc items into objects to encapsulate this kind of logic in the constructor.
    return {
      _id: this.createInspectionId(inspectionTitle),
      title: inspectionTitle,
      tasks: taskIds,
      type: "inspection",
      minRevision: this.store.publicationRevision,
      author: this.store.user.userName,
      date: new Date()
    };
  }

  /**
   * Create and save inspection
   *
   * @param route
   * @returns
   * @memberof InspectionService
   */
  public addInspection(inspectionTitle: string, taskIds: TaskId[]): Promise<Inspection> {
    const inspection: Inspection = this.initialiseInspection(inspectionTitle, taskIds);
    return this.bulk([inspection]).then(res => {
      this.updateSearchAndSendMessage(
        res,
        "inspection.creation.success",
        "inspection.creation.error"
      );
      return inspection;
    });
  }

  public updateInspection(
    inspection: Inspection,
    taskIds: Array<TaskId>,
    title?: string
  ): Promise<Inspection> {
    const updatedInspection = { ...inspection };
    const oldInspection = inspection;

    const mergedTasksIds = inspection.tasks;
    taskIds.forEach((taskId: TaskId) => {
      // this if is here in order to prevent adding of already presents tasks
      if (!mergedTasksIds.find(id => taskId.idRefDM === id.idRefDM)) {
        mergedTasksIds.push(taskId);
      }
    });

    if (title) {
      updatedInspection._id = this.createInspectionId(title);
      updatedInspection.title = title;
      delete updatedInspection._rev;
    }

    const inspectionWithLatestRevision: Inspection | undefined =
      this.inspectionWithLatestRevision(updatedInspection);

    const toBePutDocs: Inspection[] = inspectionWithLatestRevision
      ? [inspectionWithLatestRevision, updatedInspection]
      : [updatedInspection];

    return Promise.all([
      this.bulk(toBePutDocs),
      title ? this.delete(oldInspection) : Promise.resolve(true)
    ]).then(([isUpdateSucessful, isDeleteSucessful]) => {
      const isRequestSucessful = isUpdateSucessful && ((title && isDeleteSucessful) || !title);
      this.updateSearchAndSendMessage(
        isRequestSucessful,
        "inspection.update.success",
        "inspection.update.error"
      );

      // if we don't get the inspection form the db the _rev is not up to date so other update are ignored
      return this.getInspection(updatedInspection._id);
    });
  }

  // If inspection.service is a singleton instantiated with the app
  // Even if inspection.service is instanciated once when the inspection module
  // is loaded
  // then there's no need to fetch the inspection in db every time.
  public getInspection(id: string): Promise<Inspection> {
    // getItemsForTarget should return only one result
    // if id is at least of the form "xyz__inspection"
    // remember that getItemsForTarget filter for min and max revs
    return this.getItemsForTarget(id).then((docs: Inspection[]) =>
      docs.length > 0 ? docs[0] : undefined
    );
  }

  public transfertSearchKeys(newDoc: Inspection): void {
    newDoc.tasks.forEach((task: TaskId) => {
      // Remove the idRefDM revision for tasks
      const refDm = replaceRevisionFromDoc(task.idRefDM);
      task.idRefSearch = this.store.resultToReferenceMap.get(refDm);
    });
  }

  public getAllInspection(): Promise<Inspection[]> {
    return this.applyAll(this.tiType, this.tiScope, this.transfertSearchKeys.bind(this)).then(
      () => this.getItemsOfType(this.tiType) as unknown as Inspection[]
    );
  }

  public getAllTasks(
    inspections: Inspection[]
  ): Promise<{ all: TaskInspectionDoc[]; notFound: TaskInspectionDoc[] }> {
    this.taskService.tasksMap.clear();
    this.taskService.notFoundTasksMap.clear();
    inspections.forEach((inspection: Inspection) => {
      inspection.tasks.forEach(task => {
        // if this task already in the map I get the parents (i.e. linked inspections) else I get []
        const parents = this.taskService.tasksMap.get(task.idRefDM) || [];
        // I add the inspection as a parent of this task.
        parents.push({ id: inspection._id, title: inspection.title });
        // some tasks on inspections can be deleted on a highter revision
        // we look if the task reference is on search map
        if (this.store.resultToReferenceMap.get(task.idRefDM)) {
          this.taskService.tasksMap.set(task.idRefDM, parents);
        } else {
          this.taskService.notFoundTasksMap.set(task.idRefDM, parents);
        }
      });
    });

    return Promise.resolve({
      all: this.allTasks,
      notFound: this.notFoundTasks
    });
  }

  public getAllInspectionTasks(): Promise<TaskInspectionDoc[]> {
    return this.taskService.getAllInspectionTasks();
  }

  public deleteWithUndo(inspection: Inspection): Promise<boolean> {
    const docCopy: Inspection = { ...inspection };

    return this.delete(inspection)
      .then(() => {
        const deleteMsg = this.translate.instant("tocItem.deletedMsg", {
          tocItemType: this.tiType
        });
        const undoMsg = this.translate.instant("undo");
        this.messageService.success(deleteMsg, undoMsg);

        // Leave the possibility to undo the deletion via a button in the snackbar
        this.messageService.onAction.subscribe(this.undoDeleteFactory(docCopy));
        this.activateUpdate = true;
        this.listShouldUpdate.next(true);
        return true;
      })
      .catch(error => {
        console.error(error);
        const errMessage = this.translate.instant("tocItem.errorDelete", {
          tocItemType: this.tiType
        });
        this.messageService.error(errMessage);
        return false;
      });
  }

  public deleteAll(savedList: Inspection[]): Promise<boolean> {
    const copylist = cloneDeep(savedList);
    return this.getItemsOfType()
      .then((inspections: Inspection[]) =>
        Promise.all(inspections.map(item => this.delete(item))).then(() => {
          this.messageService.success(
            this.translate.instant("inspection.deleteAll.success"),
            this.translate.instant("undo")
          );

          // Leave the possibility to undo the deletion via a button in the snackbar
          this.messageService.onAction.subscribe(this.undoDeleteFactory(copylist));
          this.activateUpdate = true;
          this.listShouldUpdate.next(true);
          return true;
        })
      )
      .catch(() => {
        this.messageService.error(this.translate.instant("inspection.deleteAll.error"));
        return false;
      });
  }

  public removeTasksFromInspections(
    tasks: Array<TaskId>,
    inspectionsName: string[]
  ): Promise<Inspection[]> {
    return Promise.all(
      inspectionsName.map(inspectionName =>
        this.removeTasksFromInspection(tasks, `${inspectionName}__inspection`)
      )
    );
  }

  public removeTasksFromInspection(
    tasks: (TaskId & { title?: string })[],
    inspectionId: string | Inspection
  ): Promise<Inspection> {
    // This method can work with either the full inspection or only the inspection ID.
    // If the component that calls it already has the full inspection, no need to fetch it again.
    const getInspectionProm =
      typeof inspectionId === "string"
        ? this.getInspection(inspectionId)
        : Promise.resolve(inspectionId);

    return getInspectionProm.then((inspection: Inspection) => {
      const inspectionWithLatestRevision = this.inspectionWithLatestRevision(inspection);
      const toBePutDocs = inspectionWithLatestRevision
        ? [inspectionWithLatestRevision, inspection]
        : [inspection];

      const inspectionToUse = inspectionWithLatestRevision || inspection;
      const inspectionCopy = cloneDeep(inspectionToUse);
      inspectionToUse.tasks = inspection.tasks.filter((inspectionTask: TaskId) =>
        tasks.every((taskId: TaskId) => taskId.idRefDM !== inspectionTask.idRefDM)
      );

      return this.bulk(toBePutDocs).then((res: boolean) => {
        if (!res) {
          this.messageService.error(this.translate.instant("task.delete.error"));
        } else if (tasks.length > 1) {
          this.activateUpdate = true;
          this.messageService.success(this.translate.instant("task.delete.multi"));
        } else {
          this.activateUpdate = true;
          const deleteMsg = this.translate.instant("task.delete.single", {
            task: tasks[0].title ?? this.translate.instant("task.delete.default.title"),
            inspection: inspection.title
          });
          const undoMsg = this.translate.instant("undo");
          this.messageService.success(deleteMsg, undoMsg, 5000);

          // Leave the possibility to undo the deletion via a button in the snackbar
          this.messageService.onAction.subscribe(() => this.undoTaskDeletion(inspectionCopy));
        }
        return this.getInspection(inspectionToUse._id);
      });
    });
  }

  public createTaskCSV(taskIds: TaskId[]): string[][] {
    const content: string[][] = [];

    // Add column header
    content.push([
      "Work Card",
      "ATA",
      "ATA / SECT",
      "Task number",
      "Description",
      "Remarks",
      "Mod",
      "Documentation",
      "Applicability",
      "Interval",
      "Margin",
      "MPN",
      "PN",
      "Limit Type",
      "Revision"
    ]);
    taskIds.forEach((tasksId: TaskId, i: number) => {
      const index = this.store.resultToReferenceMap.get(tasksId.idRefDM);
      let metadata = this.store.referenceToResultMap[index];
      if (!metadata) {
        // If the metadata is undefined that's mean we have a task "Deleted" so we have to create a SearchResult.
        const taskId = tasksId.idRefDM.split("__")[2];
        metadata = {
          dmc: tasksId.idRefDM,
          revision: "Deleted",
          applicabilityMD5: undefined,
          manual: "",
          reference: undefined,
          shortTitle: undefined,
          id: this.store.referenceToResultMap.length + i,
          versions: [],
          task: [taskId],
          score: -1,
          parents: undefined
        };
      }
      const taskCsv = this.taskService.convertToCSV(metadata);
      if (taskCsv) {
        content.push(taskCsv);
      }
    });
    return content;
  }

  public exportTaskCSV(taskIds: TaskId[], title: string): void {
    const filename = this.getExportFileName(title);
    const content = this.createTaskCSV(taskIds);
    this.csvService.download(filename, content);
  }

  public createCSVForZip(taskIds: TaskId[], title: string): File {
    const filename = this.getExportFileName(title);
    const content = this.createTaskCSV(taskIds);
    const csvString = this.csvService
      .createContentFromArray(content)
      .join(this.csvService.endOfLine);
    const blob = new Blob([csvString], { type: Charset.CSV_ANSI });
    return new File([blob], filename + ".csv");
  }

  protected undoDeleteFactory(tocItemToRestore: TocItem | TocItem[]): () => Promise<TocItem[]> {
    return () =>
      super
        .undoDeleteFactory(tocItemToRestore)()
        .then((tocItems: TocItem[]) => {
          this.activateUpdate = true;
          this.listShouldUpdate.next(true);
          return tocItems;
        });
  }

  private undoTaskDeletion(inspection: Inspection): Promise<Inspection> {
    // We get the inspection in db to update it with the old tasks list
    return this.getInspection(inspection._id).then((inspectionInDb: Inspection) =>
      this.updateInspection(inspectionInDb, inspection.tasks).then(
        (updatedInspection: Inspection) => {
          this.activateUpdate = true;
          this.inspectionUpdated.next(updatedInspection);
          return updatedInspection;
        }
      )
    );
  }

  private inspectionWithLatestRevision(inspection: Inspection): Inspection | undefined {
    if (inspection.minRevision >= this.store.publicationRevision) {
      return undefined;
    }

    const newInspection: Inspection = cloneDeep(inspection);
    newInspection._id = inspection._id.replace(
      inspection.minRevision,
      this.store.publicationRevision
    );
    delete newInspection._rev;
    newInspection.minRevision = this.store.publicationRevision;
    inspection.maxRevision = this.store.publicationRevision;
    return newInspection;
  }

  private createInspectionId(title: string): string {
    return `${title}__inspection__${this.store.pubInfo.revision}`;
  }

  private updateSearchAndSendMessage(
    res: boolean[] | boolean,
    successMsg: string,
    errorMsg: string
  ): void {
    // res could be a results of a Promise.all (an array) and we want to check all results are true
    if (res || !(res as boolean[]).includes(false)) {
      this.messageService.success(this.translate.instant(successMsg));

      // When no search in /search and redirect to /inspection we need
      // to trigger again search() in SearchProvider in order to update facets
      this.activateUpdate = true;
    } else {
      this.messageService.error(this.translate.instant(errorMsg));
    }
  }

  private getExportFileName(title: string): string {
    const exportDate = new Date();
    const userName = this.store.user.userName;

    return [
      "inspection",
      this.store.pubInfo.verbatimText,
      this.store.pubInfo.revision,
      this.store.pubInfo.lang,
      userName,
      new DatePipe(this.store.currentLanguage).transform(exportDate, "yyyy-MM-dd"),
      title
    ].join("_");
  }
}
