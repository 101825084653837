// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host button {
  background-color: white !important;
}
:host button mat-icon {
  color: black !important;
}
:host button mat-icon > span {
  font-size: smaller;
}
:host button.isNotVisible {
  display: none;
}

@media print {
  :host.notes-button {
    display: none;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vdGVzLWJ1dHRvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFLGtDQUFBO0FBRko7QUFJSTtFQUNFLHVCQUFBO0FBRk47QUFJTTtFQUNFLGtCQUFBO0FBRlI7QUFNSTtFQUNFLGFBQUE7QUFKTjs7QUFTQTtFQUNFO0lBQ0UsYUFBQTtFQU5GO0FBQ0YiLCJmaWxlIjoibm90ZXMtYnV0dG9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcImxpYnMvdGhlbWUvb3Jpb24tdGhlbWVcIjtcblxuOmhvc3Qge1xuICBidXR0b24ge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHdoaXRlICFpbXBvcnRhbnQ7XG5cbiAgICBtYXQtaWNvbiB7XG4gICAgICBjb2xvcjogYmxhY2sgIWltcG9ydGFudDtcblxuICAgICAgPiBzcGFuIHtcbiAgICAgICAgZm9udC1zaXplOiBzbWFsbGVyO1xuICAgICAgfVxuICAgIH1cblxuICAgICYuaXNOb3RWaXNpYmxlIHtcbiAgICAgIGRpc3BsYXk6IG5vbmU7XG4gICAgfVxuICB9XG59XG5cbkBtZWRpYSBwcmludCB7XG4gIDpob3N0Lm5vdGVzLWJ1dHRvbiB7XG4gICAgZGlzcGxheTogbm9uZTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/notes-button/notes-button.component.scss"],"names":[],"mappings":"AAGE;EACE,kCAAA;AAFJ;AAII;EACE,uBAAA;AAFN;AAIM;EACE,kBAAA;AAFR;AAMI;EACE,aAAA;AAJN;;AASA;EACE;IACE,aAAA;EANF;AACF;AACA,g1BAAg1B","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  button {\n    background-color: white !important;\n\n    mat-icon {\n      color: black !important;\n\n      > span {\n        font-size: smaller;\n      }\n    }\n\n    &.isNotVisible {\n      display: none;\n    }\n  }\n}\n\n@media print {\n  :host.notes-button {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
