import { Injector, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { CardNewsComponent } from "@viewer/news/card-news/card-news.component";
import { NewsListComponent } from "@viewer/news/news-list/news-list.component";
import { NewsModalComponent } from "@viewer/news/news-modal/news-modal.component";
import { SharedModule } from "@viewer/shared-module/shared.module";
import { newsServiceFactory, NewsServices } from "@viewer/news/services/news.service";

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    SharedModule
  ],
  providers: [
    {
      provide: NewsServices.APP,
      useFactory: newsServiceFactory("app"),
      deps: [Injector]
    },
    {
      provide: NewsServices.APP_USER,
      useFactory: newsServiceFactory("user"),
      deps: [Injector]
    },
    {
      provide: NewsServices.PUB,
      useFactory: newsServiceFactory("public"),
      deps: [Injector]
    },
    {
      provide: NewsServices.PUB_USER,
      useFactory: newsServiceFactory("private"),
      deps: [Injector]
    }
  ],
  declarations: [CardNewsComponent, NewsListComponent, NewsModalComponent]
})
export class NewsModule {}
