import { Injectable } from "@angular/core";
import {
  WebTekAircraftId,
  WebTekIdsService
} from "@viewer/toc-items/note-module/web-tek-ids.service";
import { Store } from "@viewer/core";
import {
  FromTo,
  MeResponse,
  MessagesBody,
  TechnicalEventBody,
  TechnicalEventResult
} from "@viewer/toc-items/note-module/note.module";
import { MessageService } from "@orion2/message-service/message.service";
import { TranslateService } from "@ngx-translate/core";
import { PubDoc } from "libs/models/couch.models";
import { FullHttpService } from "libs/http/fullHttp.service";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { TechnicalEvent, Note } from "@orion2/models/tocitem.models";
import { MimeType } from "@orion2/models/enums";

@Injectable()
export class WebTekService {
  private readonly endpoint = "webtek";

  constructor(
    private httpService: FullHttpService,
    private webTekIdsService: WebTekIdsService,
    private store: Store,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  /**
   * Create the final result with only the data needed by the calling Note
   *
   * @param me {MeResponse} - The result body of the WebTeK identifier resolution
   * @param teRes {TechnicalEventResult} - The result body of the creation's POST of the TE
   */
  private static createResult(me: MeResponse, teRes: TechnicalEventResult): TechnicalEvent {
    return {
      id: teRes.id,
      reference: teRes.reference,
      subId: teRes.subTechnicalEvents[0],
      isInternal: me.isStaffMember
    };
  }

  /**
   * Send a note to WebTek.
   *
   * @param note {Note} - The note to send to WebTek
   * @returns A Promise of the created TechnicalEvent
   */
  public sendToWebTek(note: Note): Promise<TechnicalEvent> {
    // SPEC: Resolve WebTeK identifier
    return this.httpService
      .sendRequest(HttpMethod.GET, `${this.endpoint}/me`)
      .then((me: MeResponse) =>
        this.prepareTechnicalEvent(me, note)
          .then((teBody: TechnicalEventBody) => {
            if (!teBody) {
              throw Error("No TE body");
            }
            // SPEC: Create TE
            return this.httpService.sendRequest(
              HttpMethod.POST,
              `${this.endpoint}/technicalEvents`,
              {
                body: teBody,
                headers: {
                  "Content-Type": MimeType.JSON
                }
              }
            );
          })
          .then((teRes: TechnicalEventResult) =>
            // SPEC: Attach note to TE
            this.httpService
              .sendRequest(HttpMethod.POST, `${this.endpoint}/messaging/messages`, {
                body: this.prepareMessages(me, note, teRes),
                headers: {
                  "Content-Type": MimeType.JSON
                }
              })
              .then(() => WebTekService.createResult(me, teRes))
          )
      )
      .catch(err => {
        console.error("Could not send request to WebTek :", err);
        return undefined;
      });
  }

  /**
   * Prepare the Body to push to '/TechnicalEvent' API
   *
   * @param me {MeResponse} - The response of the '/me' API request containing the user's information
   * @param note {Note} - The note to push to WebTek
   * @returns  A Promise containing the body to sent to '/TechnicalEvent' API
   */
  private prepareTechnicalEvent(me: MeResponse, note: Note): Promise<TechnicalEventBody> {
    // The expected resolving date of the TE is fixed to the next week
    const nextWeek = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);
    const te: TechnicalEventBody = {
      customerPriorityId: 3, // 3 ==> 'Routine'
      requestedFinalAnswerDate: nextWeek.toISOString(),
      targetDate: me.isStaffMember ? nextWeek.toISOString() : undefined, // To provide only if INTERNAL
      subject: note.customTitle,
      visibility: me.isStaffMember ? "INTERNAL" : "EXTERNAL",
      aircrafts: [],
      contact: me.isStaffMember
        ? {
            // To use only if EXTERNAL, otherwise, use :
            centers: {
              maintenance: {},
              operator: {}
            }
          }
        : {
            id: me.id,
            isOperator: me.customerOperator
          },
      owner: me.isStaffMember
        ? {
            // To provide only if INTERNAL
            id: me.id,
            firstName: me.firstName,
            lastName: me.lastName
          }
        : undefined
    };

    // Depending of whether or not the note is associated to a version, we fetch the corresponding aircrafts ids of WebTek
    let versions = [];
    if (note.applic.version) {
      versions = [note.applic.version];
    }
    return this.webTekIdsService
      .getWebTekAircraftIds(this.store.pubInfo.model, versions)
      .then((aircrafts: WebTekAircraftId[]) => {
        te.aircrafts = aircrafts.map(aircraft => ({
          typeId: aircraft.typeId,
          version: aircraft.versionId
            ? {
                id: aircraft.versionId,
                label: aircraft.versionLabel
              }
            : undefined
        }));

        const chapterAta = note.dmc.split("-")[4];
        return this.webTekIdsService
          .getWebTekAtaChapterId(chapterAta)
          .then((ataId: number) => {
            const { eventTypeId, subEventTypeId } = this.getEventTypeForLabel(note.manualLabel);

            te.subTechnicalEvents = [
              {
                businessDomainId: 4, // 4 ==> 'Tech data'
                ataId,
                visibility: me.isStaffMember ? "AH_NETWORK" : "CUSTOMER",
                eventTypeId,
                subEventTypeId,
                orionVersion: this.getOrionVersion(this.store.pubInfo),
                businessDomainMetadata: {
                  techData: {
                    workCardRef: note.shortDMC
                  }
                },
                owner: me.isStaffMember
                  ? {
                      // To provide only if INTERNAL
                      id: me.id,
                      firstName: me.firstName,
                      lastName: me.lastName
                    }
                  : undefined
              }
            ];
            return te;
          })
          .catch(err => {
            console.error("Could not get the Ata WebTek conf :", err);
            this.messageService.error(this.translate.instant("webTek.headers.error"));
            return undefined;
          });
      })
      .catch(err => {
        console.error("Could not get the aircraft WebTek conf :", err);
        this.messageService.error(this.translate.instant("webTek.headers.error"));
        return undefined;
      });
  }

  /**
   * Prepare the Body to push to '/messaging/messages' API
   *
   * @param me {MeResponse} - The response of the '/me' API request containing the user's information
   * @param note {Note} - The note to push to WebTek
   * @param teRes {TechnicalEventResult} - The result of the push to '/TechnicalEvents' API
   * @returns The promise containing the body to push to the API
   */
  private prepareMessages(me: MeResponse, note: Note, teRes: TechnicalEventResult): MessagesBody {
    const fromTo: FromTo = {
      id: me.id,
      name: `${me.firstName} ${me.lastName}`,
      address: me.email,
      isStaffMember: me.isStaffMember
    };
    return {
      from: fromTo,
      to: me.isStaffMember ? fromTo : undefined, // Filling "to" for internal users will set the TE status to "In Progress"
      technicalEventId: teRes.id,
      subTechnicalEventId: teRes.subTechnicalEvents[0],
      channel: me.isStaffMember ? "AH_NETWORK" : "CUSTOMER",
      subject: note.customTitle,
      attachments: [],
      text: note.content,
      actionType: "PROVIDE_INFO",
      draft: false
    };
  }

  /**
   * Get the eventTypeId and subEventTypeId associated to the given manual label.
   *
   * @param label The manual label to look for.
   * @returns An object containing both eventTypeId and subEventTypeId if it exists.
   */
  private getEventTypeForLabel(label: string): {
    eventTypeId: number;
    subEventTypeId?: number;
  } {
    return this.store.webtekSettings.labelMapping[label];
  }

  /**
   * Define the orionVersion field to be passed to subTechnicalEvent in WebTek request.
   *
   * @param {PubDoc} pubDoc The PubDoc object from which to define orionVersion.
   * @returns {string} The string to pass to WebTek orionVersion parameter, for example: "ORION H130 - EC130 EN / 013.00 / 2020-11-09".
   */
  private getOrionVersion(pubDoc: PubDoc): string {
    const { verbatimText, lang, revision, revisionDate } = pubDoc;
    return `ORION ${verbatimText} ${lang.toUpperCase()} / ${revision} / ${
      (revisionDate as string).split("T")[0]
    }`;
  }
}
