export enum HistorySource {
  SEARCH = "search",
  TOC = "toc",
  BOOKMARK = "bookmark",
  NOTES = "notes",
  SHOPPING = "shopping",
  TOC_ITEMS = "tocItems",
  HISTORY = "history",
  NAVIGATION = "navigation",
  BACK = "back",
  DEEPLINK = "deeplink"
}

export enum MimeType {
  JSON = "application/json",
  EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PDF = "application/pdf",
  BIN = "application/octet-stream",
  PLAIN = "text/plain",
  CSV = "text/csv",
  GZIP = "application/gzip"
}

export enum SbStatus {
  incorporated = "Incorporated",
  notIncorporated = "Not Incorporated"
}
