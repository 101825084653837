import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { ConfService, NoteService, Store } from "@viewer/core";
import { TitleService } from "@viewer/header/title.service";
import { IReactionDisposer, makeObservable, reaction, runInAction } from "mobx";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { WebTekDialogComponent } from "@viewer/toc-items/note-module/webtek-dialog/web-tek-dialog.component";
import { Router } from "@angular/router";
import { ApplicabilityService } from "@viewer/core/applicability/applicability.service";
import { observable } from "mobx-angular";
import { SearchResult } from "@viewer/core/search/searchModel";
import { TocInfo } from "@orion2/models/couch.models";
import { Note } from "@orion2/models/tocitem.models";

@Component({
  selector: "o-note",
  templateUrl: "./note.component.html",
  styleUrls: ["./note.component.scss"]
})
export class NoteComponent implements OnInit, OnDestroy {
  @Input() item: Note;
  // first is true for the first drawer note
  @Input() first: boolean;
  @Input() editMode = false;
  @ViewChild("customTitle", { read: ElementRef, static: true })
  title: ElementRef;
  @HostBinding("class.not-applicable") isNotApplicable = false;
  @HostBinding("class.hide-not-applicable") hideNotApplicable = false;

  public currentNote: Note;
  status = ["public", "corporate", "private"];
  availableStatus = [];
  elementRef: ElementRef;
  saved: boolean;
  makeCorpNote = false;
  makePrivateNote = false;
  makePublicNote = false;
  dismissRoleReaction = undefined;
  public canOpenMenu = false;
  public webtek = false;

  private disposers: IReactionDisposer[] = [];
  private duMeta: TocInfo | SearchResult;

  constructor(
    public store: Store,
    public titleService: TitleService,
    elementRef: ElementRef,
    private noteService: NoteService,
    private matDialog: MatDialog,
    private router: Router,
    private confService: ConfService,
    private cd: ChangeDetectorRef,
    private applicabilityService: ApplicabilityService
  ) {
    this.elementRef = elementRef;
    makeObservable<NoteComponent, "duMeta">(this, {
      duMeta: observable
    });
  }

  ngOnInit() {
    this.item.date = new Date(this.item.date);
    // this is for set focus when we create a note with drawer open
    if (this.first) {
      setTimeout(() => {
        this.title.nativeElement.focus();
      }, 0);
    }
    if (this.store.pubInfo && this.store.pubInfo.capabilities.webtek) {
      this.webtek = this.store.pubInfo.capabilities.webtek;
    }

    this.editMode =
      (!this.item.customTitle || this.item.customTitle.length === 0) &&
      (!this.item.content || this.item.content.length === 0);
    this.currentNote = { ...this.item };
    this.disposers.push(
      reaction(
        () => this.store.noteSettings,
        () => {
          this.setNoteColor();
        },
        { fireImmediately: true }
      )
    );

    // When we create an empty note (editMode === true), if "this.saved = true", the user won't be warned about leaving the page
    // without saving (see desactivate guard)
    // Each note is registered in the note.service which keeps track of the saved and unsaved notes (see "saveAllNotes()").
    this.saved = !this.editMode;
    this.noteService.addNoteComponent(this);
    this.updateSelect();
    this.updateCanOpenMenu();

    if (this.editMode) {
      this.title.nativeElement.focus();
    }

    this.disposers.push(
      reaction(
        () => this.store.user,
        () => {
          this.updateSelect();
          this.updateCanOpenMenu();
          this.cd.detectChanges();
        }
      )
    );

    // If the search isn't ready we can't get duMetadata when it is ready we can get the datas
    this.disposers.push(
      reaction(
        () => this.store.offlineSearchReady,
        () => this.updateDuMeta(),
        {
          fireImmediately: true
        }
      )
    );

    this.disposers.push(
      reaction(
        () => ({
          applicMd5: this.store.applicableMD5,
          tocNode: this.duMeta,
          isFilteringAvailable: this.store.isFilteringAvailable
        }),
        state => {
          // We update applic from duMeta (in case TI ORION 1)
          if (state.tocNode && state.tocNode.versions) {
            this.item.applic = {
              version: state.tocNode.versions.toString(),
              serialno: ""
            };
          }
          if (state.isFilteringAvailable && state.tocNode) {
            this.isNotApplicable = !this.applicabilityService.isApplicable(state.tocNode);
          }
        },
        { fireImmediately: true }
      )
    );

    this.disposers.push(
      reaction(
        () => this.store.showNotApplicable,
        () => {
          this.hideNotApplicable = !this.store.showNotApplicable;
        },
        { fireImmediately: true }
      )
    );
  }

  ngOnDestroy(): void {
    this.noteService.removeNoteComponent(this);
    this.disposers.map(disposer => disposer());
  }

  onchange() {
    this.saved = false;
  }

  /**
   * Manage Note card control with user rights
   */
  updateSelect() {
    this.availableStatus = [];
    if (this.store.user) {
      this.makeCorpNote = this.store.user.makeCorpNote;
      this.makePrivateNote = this.store.user.makePrivateNote;
      this.makePublicNote = this.store.user.makePublicNote;
      for (const status of this.status) {
        // "public","corporate", "private"
        if (this.store.user.makePublicNote && status === "public" && this.makePublicNote === true) {
          this.availableStatus.push(status);
        }
        if (this.store.user.makeCorpNote && status === "corporate" && this.makeCorpNote === true) {
          this.availableStatus.push(status);
        }
        if (
          this.store.user.makePrivateNote &&
          status === "private" &&
          this.makePrivateNote === true
        ) {
          this.availableStatus.push(status);
        }
      }
      if (!this.makePrivateNote) {
        this.desactivateNoteCardCursor();
      } else {
        this.activateNoteCardCursor();
      }
    } else {
      this.editMode = false;
      const shouldRemoveNote =
        (!this.item.customTitle || this.item.customTitle.length === 0) &&
        (!this.item.content || this.item.content.length === 0);

      if (shouldRemoveNote) {
        this.cancel();
      } else {
        this.saveNote(this.item);
      }
      this.makePrivateNote = false;
      this.makeCorpNote = false;
      this.desactivateNoteCardCursor();
    }
    this.cd.detectChanges();
  }

  /**
   * Check if the user got the right access to edit/delete, etc...
   * We the variable this.canOpenMenu is equal to false it will disable
   * the menu button
   *
   * @memberof NoteComponent
   */
  public updateCanOpenMenu(): void {
    if (this.store.user) {
      this.canOpenMenu =
        (this.currentNote.status === "private" && this.store.user.makePrivateNote) ||
        (this.currentNote.status === "corporate" && this.store.user.makeCorpNote) ||
        (this.currentNote.status === "public" && this.store.user.makePublicNote);
    } else {
      this.canOpenMenu = false;
    }
  }

  /**
   * Keep current note before editing
   *
   * @memberof NoteComponent
   * User can edit only if he have rights
   */
  startEdit() {
    if (this.store.user) {
      if (
        (this.currentNote.status === "private" && this.store.user.makePrivateNote) ||
        (this.currentNote.status === "corporate" && this.store.user.makeCorpNote) ||
        (this.currentNote.status === "public" && this.store.user.makePublicNote)
      ) {
        this.editMode = true;
      }
    } else {
      this.editMode = false;
    }
  }

  desactivateNoteCardCursor() {
    const allForms = document.querySelectorAll("form");
    for (const form of Array.from(allForms)) {
      form.style.cursor = "default";
    }
    const allTextArea = document.querySelectorAll("textarea");
    for (const textArea of Array.from(allTextArea)) {
      textArea.style.cursor = "default";
    }
    const allInput = document.querySelectorAll("input");
    for (const input of Array.from(allInput)) {
      input.style.cursor = "default";
    }
  }

  activateNoteCardCursor() {
    const allForms = document.querySelectorAll("form");
    for (const form of Array.from(allForms)) {
      form.style.cursor = "pointer";
    }
    const allTextArea = document.querySelectorAll("textarea");
    for (const textArea of Array.from(allTextArea)) {
      textArea.style.cursor = "pointer";
    }
    const allInput = document.querySelectorAll("input");
    for (const input of Array.from(allInput)) {
      input.style.cursor = "pointer";
    }
  }

  /**
   * Navigate to DU
   *
   * @returns
   * @memberOf NoteComponent
   */
  navigateToDU() {
    if (this.allowNavigation()) {
      const url = `pub/${this.store.publicationID}/${this.store.publicationRevision}`;
      return this.router.navigate(["/" + url, "du", this.item.dmc]);
    }
  }

  /**
   * Indicates whether or not the note is in a state when a navigation on click is allowed
   *
   * @returns The result of the test
   * @memberOf NoteComponent
   */
  allowNavigation(): boolean {
    return !this.editMode && this.titleService.headerTitle === "notes.label";
  }

  /**
   * Save current note and keep the previous one
   *
   * @param newNote
   * @memberOf NoteComponent
   */
  public saveNote(newNote: Note): Promise<boolean> {
    this.editMode = false;
    this.saved = true;
    newNote.author = this.store.user.userName;
    // keep previous note
    return this.noteService.update(this.item, newNote, newNote.status).then(success => {
      if (success) {
        this.editMode = false;
        this.currentNote = { ...newNote };
      }
      return success;
    });
  }

  /**
   * Delete note and emit event to list
   *
   * @memberOf NoteComponent
   */
  public delete(): Promise<void> {
    return this.noteService.deleteWithUndo(this.currentNote).then((res: boolean) => {
      this.noteService.removeNoteComponent(this);
      this.saved = res;
    });
  }

  /**
   * Don't save and return to the previous note
   *
   * @param event - The triggering event (if any)
   * @memberOf NoteComponent
   */
  cancel(event?: Event) {
    // If it comes from the 'cancel' button we stop the propagation to avoid navigating to the DU
    if (event) {
      event.stopPropagation();
    }
    this.editMode = false;
    this.currentNote = { ...this.item };
    // we delete the current note if it's empty (no title and no content)
    if (!this.item.customTitle) {
      return this.noteService.deleteWithUndo(this.item).then((res: boolean) => {
        this.saved = res;
      });
    } else {
      this.saved = true;
    }
  }

  /**
   * Open the modal that pushes a note to WebTek
   *
   * @memberOf NoteComponent
   */
  exportToWebTek() {
    this.matDialog.open(WebTekDialogComponent, {
      panelClass: "webTek-dialog-container",
      data: { note: this.item }
    });
  }

  /**
   * Creates the URL to access the created technical event
   *
   * @returns The URL
   * @memberOf NoteComponent
   */
  linkWebTekURL(): string {
    return `${this.confService.conf.webTekUrl}/${this.item.technicalEvent.id}`;
  }

  /**
   * Open the URL using the system default browser
   */
  openURL() {
    window.open(this.linkWebTekURL(), "_system");
  }

  /**
   * Set the color to use for the current note.
   */
  public setNoteColor(): void {
    this.currentNote.color = this.store.noteSettings?.[this.currentNote.status + "Color"];
  }

  // If the currentTocNode is already the good one we don't need to get it in database
  // When we get the data we will trigger the reaction in order to update the applicability
  private updateDuMeta(): void {
    if (this.store.currentTocNode && this.store.currentTocNode.dmc === this.currentNote.dmc) {
      runInAction(() => {
        this.duMeta = this.store.currentTocNode;
      });
    } else {
      if (this.store.offlineSearchReady) {
        const reference = this.store.resultToReferenceMap.get(this.currentNote.dmc);
        runInAction(() => {
          this.duMeta = this.store.referenceToResultMap[reference];
        });
      }
    }
  }
}
