import { Component, OnInit } from "@angular/core";
import { Store } from "@viewer/core";

import { Router } from "@angular/router";

@Component({
  selector: "o-notes-button",
  templateUrl: "./notes-button.component.html",
  styleUrls: ["./notes-button.component.scss"]
})
export class NotesButtonComponent implements OnInit {
  public printPdf: boolean;

  constructor(public store: Store, private router: Router) {}

  ngOnInit() {
    this.printPdf = this.router.url.includes("/print");
  }
}
