// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: calc(100vh - 60px);
}
:host ng2-pdfjs-viewer {
  display: block;
  height: 100%;
  width: 100%;
}
:host ng2-pdfjs-viewer.print_view {
  height: 100vh;
}
:host ng2-pdfjs-viewer ::ng-deep iframe {
  display: block;
}
:host .notes-button {
  position: absolute;
  right: 10px;
  top: 100px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBkZi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7RUFDQSwwQkFBQTtBQUNGO0FBQ0U7RUFDRSxjQUFBO0VBQ0EsWUFBQTtFQUNBLFdBQUE7QUFDSjtBQUNJO0VBQ0UsYUFBQTtBQUNOO0FBRUk7RUFDRSxjQUFBO0FBQU47QUFJRTtFQUNFLGtCQUFBO0VBQ0EsV0FBQTtFQUNBLFVBQUE7QUFGSiIsImZpbGUiOiJwZGYuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGRpc3BsYXk6IGJsb2NrO1xuICBoZWlnaHQ6IGNhbGMoMTAwdmggLSA2MHB4KTtcblxuICBuZzItcGRmanMtdmlld2VyIHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgd2lkdGg6IDEwMCU7XG5cbiAgICAmLnByaW50X3ZpZXcge1xuICAgICAgaGVpZ2h0OiAxMDB2aDtcbiAgICB9XG5cbiAgICA6Om5nLWRlZXAgaWZyYW1lIHtcbiAgICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIH1cbiAgfVxuXG4gIC5ub3Rlcy1idXR0b24ge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICByaWdodDogMTBweDtcbiAgICB0b3A6IDEwMHB4O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/pdf/pdf.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,0BAAA;AACF;AACE;EACE,cAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACE,aAAA;AACN;AAEI;EACE,cAAA;AAAN;AAIE;EACE,kBAAA;EACA,WAAA;EACA,UAAA;AAFJ;AACA,w2BAAw2B","sourcesContent":[":host {\n  display: block;\n  height: calc(100vh - 60px);\n\n  ng2-pdfjs-viewer {\n    display: block;\n    height: 100%;\n    width: 100%;\n\n    &.print_view {\n      height: 100vh;\n    }\n\n    ::ng-deep iframe {\n      display: block;\n    }\n  }\n\n  .notes-button {\n    position: absolute;\n    right: 10px;\n    top: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
