import { Store, ViewerMessageService } from "@viewer/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FullHttpService } from "libs/http/fullHttp.service";
import { DownloadFileService } from "libs/download/download-file.service";
import pako from "pako";
import { MimeType } from "@orion2/models/enums";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { PymResponse } from "@orion2/models/pym.models";
import { MmhService } from "@viewer/core/mmh/mmh.service";

export interface ExcelServiceError {
  code: number;
  message: string;
}

@Injectable()
export class ExcelService {
  constructor(
    private downloadFileService: DownloadFileService,
    private httpService: FullHttpService,
    private messageService: ViewerMessageService,
    private translate: TranslateService,
    private mmhService: MmhService,
    private store: Store
  ) {}

  public exportPymToExcel(pymResponse: PymResponse, filename: string): Promise<void> {
    const body = { pym: pymResponse, mmh: !!this.mmhService.mmhDu };
    const gzippedBody = pako.gzip(JSON.stringify(body));
    return this.httpService
      .sendRequest(HttpMethod.POST, "pym-formater-svc/pym", {
        body: gzippedBody.buffer,
        headers: {
          "Content-Encoding": "gzip",
          "Content-Type": MimeType.JSON
        },
        responseType: "arraybuffer"
      })
      .then((arrayBuffer: ArrayBuffer) => {
        const blob = new Blob([arrayBuffer], { type: MimeType.EXCEL });
        //SPEC: for ios device, some characters are not supported for file title
        // We replace -, *, /, \, : and space by underscores
        // If several underscores follow each other, we replace them by a single
        const regex = /[\\\/\*\-\: _]+/g;
        this.downloadFileService.download(new File([blob], filename.replace(regex, "_") + ".xlsx"));
      })
      .catch(this.createErrorMessage.bind(this));
  }

  private createErrorMessage(err: HttpErrorResponse | ExcelServiceError | Error): void {
    console.error("ExcelServiceError", err);
    if ("status" in err && err["status"] >= 400 && err["status"] < 500) {
      this.messageService.error(this.translate.instant("excel.message.error.available"));
    } else {
      this.messageService.error(this.translate.instant("excel.message.error.service"));
    }
  }
}
