/**
 * @export
 * @interface IpcPart
 */
export namespace PartNamespace {
  export interface IpcPart {
    csn: string;
    isn: string;
    label: string;
    versions: string[];
    parent: string;
    status?: string;
    applic?: string;
    mpn: string;
    mfc: string;
    nsn?: string;
    equivalentPartNb: EPN[];
    airbusPN: EPN[];
    qty?: string;
    totalQty?: string;
    referTo: Refer[];
    referredBy: Refer[];
    indenture: string;
  }

  export interface EPN {
    mfc: string;
    mpn: string;
  }

  export interface Refer {
    label: string;
    link: string;
  }
}
