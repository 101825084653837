// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-content {
  margin: 0;
  padding: 0;
  text-align: center;
}
.mat-dialog-content h1 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.bottom-buttons {
  margin: 20px 0 0;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsU0FBQTtFQUNBLFVBQUE7RUFDQSxrQkFBQTtBQURGO0FBR0U7RUFDRSxlQUFBO0VBQ0EsaUJBQUE7RUFDQSxtQkFBQTtBQURKOztBQUtBO0VBQ0UsZ0JBQUE7RUFDQSxVQUFBO0FBRkYiLCJmaWxlIjoiY29uZmlybS1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwibGlicy90aGVtZS9vcmlvbi10aGVtZVwiO1xuXG4ubWF0LWRpYWxvZy1jb250ZW50IHtcbiAgbWFyZ2luOiAwO1xuICBwYWRkaW5nOiAwO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG5cbiAgaDEge1xuICAgIGZvbnQtc2l6ZTogMjZweDtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICBtYXJnaW4tYm90dG9tOiAyMHB4O1xuICB9XG59XG5cbi5ib3R0b20tYnV0dG9ucyB7XG4gIG1hcmdpbjogMjBweCAwIDA7XG4gIHBhZGRpbmc6IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/shared-module/confirm-dialog/confirm-dialog.component.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,kBAAA;AADF;AAGE;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AADJ;;AAKA;EACE,gBAAA;EACA,UAAA;AAFF;AACA,4uBAA4uB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n.mat-dialog-content {\n  margin: 0;\n  padding: 0;\n  text-align: center;\n\n  h1 {\n    font-size: 26px;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n}\n\n.bottom-buttons {\n  margin: 20px 0 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
