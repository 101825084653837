import { ApplicabilityService } from "@viewer/core";
import { Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { FullHttpService } from "libs/http/fullHttp.service";
import { PymTree } from "@viewer/core/pym/pymTree";
import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { Pym } from "@orion2/models/tocitem.models";
import { MmhService } from "@viewer/core/mmh/mmh.service";
import pako from "pako";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { PymSource, PymResponse } from "@orion2/models/pym.models";
import { MimeType } from "@orion2/models/enums";

@Injectable()
export class PymService extends TocItemService {
  protected tiType = "pym";
  protected tiScope = "private";

  private httpService: FullHttpService;
  private applicabilityService: ApplicabilityService;
  private mmhService: MmhService;

  constructor(protected injector: Injector) {
    super(injector);
    this.httpService = this.injector.get(FullHttpService);
    this.applicabilityService = this.injector.get(ApplicabilityService);
    this.mmhService = this.injector.get(MmhService);
  }

  /**
   * Get the pym tree for visualisation purpose
   * It will return an object with children attribute
   *
   * @param doc
   * @returns
   * @memberof PymService
   */
  public getTree(doc: Pym): PymTree {
    return new PymTree(doc);
  }

  public async extractMaintenanceTree(
    level: string,
    infoType: string[],
    source: PymSource[]
  ): Promise<PymResponse> {
    const criterias = this.applicabilityService.userSelectedCriterias;

    const sourceObject: PymSource[] = source.map((e: PymSource) => ({
      dmc: e.dmc || "",
      taskID: e.taskID || ""
    }));

    const param = {
      pubInfo: {
        occurenceCode: this.store.pubInfo.occurrenceCode,
        revision: this.getRevisionLabel()
      },
      filterBy: {
        version: criterias.version ? [criterias.version] : [],
        serialno: criterias.serialno ? [criterias.serialno] : []
      },
      extraction: {
        level,
        informationType: infoType,
        // SPEC: MMH needs to be set to false if not needed
        mmh: !!this.mmhService.mmhDu
      },
      source: [...sourceObject]
    };
    const gzippedPymResponse = pako.gzip(JSON.stringify(param));
    return this.httpService
      .sendRequest(HttpMethod.POST, "pym/dataModules", {
        body: gzippedPymResponse.buffer,
        headers: {
          "Content-Encoding": "gzip",
          "Content-Type": MimeType.GZIP, // SPEC: Mulesoft RAML is specified as "application/gzip" for gzipped content
          Accept: MimeType.JSON
        }
      })
      .then((pymResponse: PymResponse) => {
        if (pymResponse) {
          return pymResponse;

          // Disable save PYM Doc beacause, the PYM Doc is to fat for sync
          // 413 (Request Entity Too Large) - ORION20-3102
          // TODO : Need compresse this doc.
          // const url: string = this.router.url.includes("/inspections")
          //   ? "inspections"
          //   : this.bookmarkService.currentUrl;
          // const pymDoc = {
          //   _id: `pym__${this.store.currentDMC}__${_guid()}`,
          //   docId: this.store.currentTocNode._id,
          //   url: url,
          //   content: tocInfo,
          //   parents: [this.tiType],
          //   type: this.tiType,
          //   minRevision: this.store.publicationRevision,
          //   date: new Date(),
          //   author: this.store.user.userName,
          //   ...pymResponse
          // };

          // return this.save(pymDoc).then(res => {
          //   if (res) {
          //     this.messageService.success(
          //       this.translate.instant("pym.message.success")
          //     );
          //   }

          //   return pymResponse;
          // });
        } else {
          console.error("PYM API shouldn't return undefined responses");
          this.messageService.error(this.translate.instant("pym.message.error.service"));
          return undefined;
        }
      })
      .catch(this.createErrorMessage.bind(this));
  }

  private createErrorMessage(err: HttpErrorResponse | Error): void {
    console.error("PymServiceError", err);
    if ("status" in err && err["status"] >= 400 && err["status"] < 500) {
      // SPEC in case for the revision of publication during
      // consultation are not available we return the error message coming from PYM
      if (err["error"].errorCode === 2) {
        const splitMsg = err["error"].message.split(" ");
        this.messageService.error(
          this.translate.instant("pym.message.error", {
            rev: splitMsg[splitMsg.length - 1]
          })
        );
        return;
      }
      this.messageService.error(this.translate.instant("pym.message.error.available"));
    } else {
      this.messageService.error(this.translate.instant("pym.message.error.service"));
    }
  }

  /**
   * Delete Rev Technique on pub Revision
   */
  private getRevisionLabel(): string {
    if (this.store.pubInfo.revision !== "undefined") {
      const technicalRevision = this.store.pubInfo.revision.split(".")[2];

      // Remove technical revision from attribute
      const revision = this.store.pubInfo.revision.split(".").slice(0, -1).join(".");

      return technicalRevision ? revision : this.store.pubInfo.revision;
    }
    return "";
  }
}
