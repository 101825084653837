import { Component, Inject } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from "@angular/material/legacy-dialog";

@Component({
  selector: "o-ipc-info-modal",
  templateUrl: "./ipc-info-modal.component.html",
  styleUrls: ["./ipc-info-modal.component.scss"]
})
export class IpcInfoModalComponent {
  constructor(
    public dialogRef: MatDialogRef<IpcInfoModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialogRef.close();
  }
}
