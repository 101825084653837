// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-self: center;
  margin-top: 20px;
  max-width: 900px;
  width: 95%;
}
:host .content {
  font-size: 17px;
  margin-bottom: 15px;
  padding: 0 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInB1Yi1yZXBsaWNhdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGtCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxnQkFBQTtFQUNBLFVBQUE7QUFERjtBQUdFO0VBQ0UsZUFBQTtFQUNBLG1CQUFBO0VBQ0EsZUFBQTtBQURKIiwiZmlsZSI6InB1Yi1yZXBsaWNhdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJsaWJzL3RoZW1lL29yaW9uLXRoZW1lXCI7XG5cbjpob3N0IHtcbiAgYWxpZ24tc2VsZjogY2VudGVyO1xuICBtYXJnaW4tdG9wOiAyMHB4O1xuICBtYXgtd2lkdGg6IDkwMHB4O1xuICB3aWR0aDogOTUlO1xuXG4gIC5jb250ZW50IHtcbiAgICBmb250LXNpemU6IDE3cHg7XG4gICAgbWFyZ2luLWJvdHRvbTogMTVweDtcbiAgICBwYWRkaW5nOiAwIDEwcHg7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/replication/pub-replication/pub-replication.component.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AADF;AAGE;EACE,eAAA;EACA,mBAAA;EACA,eAAA;AADJ;AACA,4pBAA4pB","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n:host {\n  align-self: center;\n  margin-top: 20px;\n  max-width: 900px;\n  width: 95%;\n\n  .content {\n    font-size: 17px;\n    margin-bottom: 15px;\n    padding: 0 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
