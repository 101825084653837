import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
  selector: "o-loading-modal",
  templateUrl: "./loading-modal.component.html",
  styleUrls: ["./loading-modal.component.scss"]
})
export class LoadingModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
