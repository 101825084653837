import { Injectable } from "@angular/core";
import { Pm2 } from "tools/electron/on-prem/helpers/pm2.models";
import { MimeType } from "@orion2/models/enums";

/**
 * This service is a real service, it will be injected to the project
 * with file replacement (see: angular.json), and should be used only
 * for electron environment, for other environment we will use the mocked
 * service from electron.service.browser.ts
 *
 * @export
 * @class ElectronService
 */
@Injectable({
  providedIn: "root"
})
export class ElectronService {
  public get ipcRenderer() {
    return window["ipcRenderer"];
  }

  public isInstalled(): boolean {
    return window["api"].isInstalled();
  }

  public getRootPath(): string {
    return window["api"].getRootPath();
  }

  public getLogsDir(): string {
    return this.getElectronConf() + "/logs";
  }

  public getFilePath(file: string): string {
    return window["api"].getFilePath(file);
  }

  public fileExist(pathFile: string): boolean {
    return window["api"].fileExist(pathFile);
  }

  public readFile(pathFile: string): Promise<string> {
    return window["api"].readFile(pathFile);
  }

  public writeFile(pathFile: string, content: string): void {
    return window["api"].writeFile(pathFile, content);
  }

  // Only for on premise electron app
  public getAppConf(appKey: string): Promise<Object> {
    return this.ipcRenderer.getAppConf(appKey);
  }

  public getApps(): void {
    return this.ipcRenderer.getApps();
  }

  public startApp(appKey: string): void {
    return this.ipcRenderer.startApp(appKey);
  }

  public startApps(processList: Pm2.List): void {
    return this.ipcRenderer.startApps(processList);
  }

  public stopApp(appKey: string, pid: number): void {
    return this.ipcRenderer.stopApp(appKey, pid);
  }

  public stopApps(): void {
    return this.ipcRenderer.stopApps();
  }

  public restartApp(appKey: string, pid: number): void {
    return this.ipcRenderer.restartApp(appKey, pid);
  }

  public registerOnPremListener(event: string, callback: Function): void {
    return this.ipcRenderer.registerOnPremListener(event, callback);
  }

  public removeOnPremListeners(event: string): void {
    return this.ipcRenderer.removeOnPremListeners(event);
  }

  public initGetProcessComplete(callback: Function): void {
    return this.ipcRenderer.initGetProcessComplete(callback);
  }

  public initGetProcessError(callback: Function): void {
    return this.ipcRenderer.initGetProcessError(callback);
  }

  public initStopAllProcessError(callback: Function): void {
    return this.ipcRenderer.initStopAllProcessError(callback);
  }

  public initStartAllProcessError(callback: Function): void {
    return this.ipcRenderer.initStartAllProcessError(callback);
  }

  public print(): Promise<void> {
    return this.ipcRenderer.print();
  }

  public sha(str: string): Promise<string> {
    return this.ipcRenderer.sha(str);
  }

  public selectFolder(message: string): Promise<string> {
    return this.ipcRenderer.selectFolder(message);
  }

  public getElectronConf(): Promise<string> {
    return this.ipcRenderer.getElectronConf();
  }

  public restartElectronApp(): void {
    return this.ipcRenderer.restartElectronApp();
  }

  public isElectron(): boolean {
    return navigator.userAgent.toLowerCase().indexOf(" electron/") >= 0;
  }

  public convertAttachment(blob: Blob): Promise<PouchDB.Core.Attachment> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve({
          content_type: MimeType.PLAIN,
          data: (reader.result as string).split(",")[1]
        });
      reader.readAsDataURL(blob);
    });
  }
}
