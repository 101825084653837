import { Injectable } from "@angular/core";
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable, finalize } from "rxjs";
import { LoaderService } from "@edistribution/core/loader/loader.service";

export const BYPASS_LOADER = new HttpContextToken(() => false);

/**
 * Display loader when sending an HTTP request, and hide it when we get a response from server.
 * This interceptor can be bypassed by using the BYPASS_LOADER token in your request context.
 */
@Injectable({
  providedIn: "root"
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_LOADER) === true) {
      return next.handle(request);
    }

    this.loaderService.show();
    return next.handle(request).pipe(finalize(() => this.loaderService.hide()));
  }
}
