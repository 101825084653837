import { intersection } from "lodash";
import { SearchResult } from "@viewer/core/search/searchModel";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";

export const ONPREMISE_KEY = "onpremise";

export const S1000D_DM_WITH_Z_REGEX =
  /([A-Z])(\d{3})?(-\d{2}-\d{2}-\d{4}-\d{2})Z(-\d{3})[A-Z](-[A-Z])/g;

export const S1000D_DM_REGEX = /[A-Z](\d{3})?-\d{2}-\d{2}-\d{4}-\d{2}[A-Z]-\d{3}[A-Z]-[A-Z]/g;

export const S1000D_DM_REPLACE = "$1([0-9]{3})?$3[A-Z]$4[A-Z]$5";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function idAsKey(docs): any[] {
  const map = [];
  docs.forEach(doc => {
    map[doc._id] = doc;
  });
  return map;
}

export function getNumericValue(value) {
  let numericValue = 0;
  if (value) {
    numericValue = Number(value);
    return isNaN(numericValue) ? 0 : numericValue;
  }
  return numericValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasOneSameValue(array1: any[], array2: any[]): boolean {
  return intersection(array1, array2).length > 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pouchAttachmentToBinary(data: Blob | string): Promise<any> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    // If data is Blob
    if (data instanceof Blob) {
      fileReader.readAsArrayBuffer(data);
    } else {
      // If data is blobUrl
      fetch(data)
        .then(r => r.blob())
        .then(blobRes => fileReader.readAsDataURL(blobRes));
    }

    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = e => {
      reject(e);
    };
  });
}

/**
 * Retrieve the section of the given DMC.
 *
 * @param dmc A full DMC (eg: DMC-H160-F0210-A-64-30-0000-00A-040A-A_en-US)
 * @returns The section the DMC is in.
 */
export function getSectionFromDMC(dmc: string): string {
  // SPEC: dmc examples :
  // superseded: superseded__EC175_24310001_EN__05-51-00-01
  // legacy publication : DMC-EC130-T2-A003-05-51-00-00001-004T-M_en-EN
  // H160 publication : DMC-H160-ORION-A-05-51-0001-00A-282A-A_en-US
  // H160 task : task__DMC-H160-ORION-A-05-51-0000-53A-0B0A-A_en-US__H160-53-10-00-000-0B2-010
  // legacy task: task__DMC-EC130-T2-0000-05-51-00-00000-000S-S_en-EN__62/11/00/000/000/070
  // for all examples the section to extract is 05-51
  const regex =
    /^(superseded__.+__|(task__|part__)?DMC-[a-zA-Z0-9]+-[a-zA-Z0-9]+-[a-zA-Z0-9]+-)(.{2}-.{2})-.*/g;
  const res = regex.exec(dmc);
  // section is the group 3 of regex matching, in case no match we return section 00-00
  return res?.[3] || "00-00";
}

export function sortMapByValue(map: Map<string, number[]>): Map<string, number[]> {
  const entries = map.entries();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Map(Array.from(entries).sort() as any); // warning compatibility
}

export function arrayUpsert(map, key, value) {
  if (!map.get(key)) {
    map.set(key, []);
  }
  map.get(key).push(value);
}

export function replaceRevisionFromDoc(docId: string, value = ""): string {
  // We only add the '__' if there is an actual non-empty value
  return docId.replace(/__\d+(\.\d{2}(\.\d{2})?)?$/g, value === "" ? "" : "__" + value);
}

export function getFullChangeStr(change: string): string {
  switch (change) {
    case "N":
    case "add":
      return "new";
    case "R":
    case "modify":
      return "changed";
    case "D":
    case "delete":
      return "deleted";
    default:
      return "";
  }
}

/**
 * Split an array of string into array of 250 string[] by default (only for cordova devices)
 * This function should when you query the database with specific ids
 *
 * @export
 * @param ids
 * @param [chunkSize=250]
 * @returns
 */
export function splitChunk(ids: string[], chunkSize = 250): string[][] {
  if (window.hasOwnProperty("cordova")) {
    return ids
      .map((_, index) => (index % chunkSize === 0 ? ids.slice(index, index + chunkSize) : null))
      .filter(Boolean);
  }
  return [ids];
}

/**
 * Iterate on each key of the object and concat each value as a single string with a double space as separator
 *
 * @param object
 */
export function concatValues(object: Object): string {
  const accumulator = (acc, key) => `${acc}  ${object[key]}`;
  return Object.keys(object).reduce(accumulator, "").toLowerCase();
}

/**
 * This function return the shorten version of climat label
 * It will be used for label generation and only for S1000D purpose
 *
 * @export
 * @param fullLabel
 * @returns
 */
export function getClimatShortLabel(fullLabel: string): string {
  // SPEC: Should we translate this label ? If yes we need to had a better identifier
  // If yes maybe we can use @applicPropertyIdent but we need the real mapping
  if (fullLabel.includes("Normal")) {
    return "Normal";
  } else if (fullLabel.includes("Tropical")) {
    return "Tropical";
  } else if (fullLabel.includes("Salt-laden")) {
    return "Salt-laden";
  } else if (fullLabel.includes("Sand-laden")) {
    return "Sand-laden";
  } else if (fullLabel.includes("Cold")) {
    return "Cold";
  } else if (fullLabel.includes("Very cold")) {
    return "Very cold";
  } else if (fullLabel.includes("Hot")) {
    return "Hot";
  } else if (fullLabel.includes("Volcanic")) {
    return "Volcanic";
  } else {
    return "-";
  }
}

/**
 * windows.atob() can't convert unicode characters outside the range 0x00 to 0xFF
 * You can use base64ToUtf8() and utf8_to_b64() function in order to encode/decode
 * base64 for UTF-8 string
 *
 * For more technical informations you can see :
 * https://developer.mozilla.org/fr/docs/Web/API/WindowBase64/D%C3%A9coder_encoder_en_base64
 */

/**
 * Decode base64 string to UTF-8 string
 *
 * @export
 * @param base64
 * @returns
 */
export function base64ToUtf8(base64: string): string {
  return decodeURIComponent(escape(atob(base64)));
}

/**
 * Test a string if it's a valid URL
 *
 * @export
 * @param url
 * @returns
 */
export function isUrl(url: string): boolean {
  const reg = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,}([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
  return reg.test(url);
}

/**
 * Transform special characters to HTML code,
 * For exemple & will be converted to &#0047;
 * For more informations see : https://stackoverflow.com/a/62333523
 *
 * @param unsafe
 * @returns
 */
export function escapeHTML(unsafe: string): string {
  return unsafe.replace(
    /[\u0000-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u00FF]/g,
    (c: string) => "&#" + ("000" + c.charCodeAt(0)).substr(-4, 4) + ";"
  );
}

// enrichDocumentation is use on documentation.ts libs that set the documentation.shortDMC with search maps if documentation.shortDMC is missing
export function enrichDocumentation(
  documentation: TaskNamespace.Documentation
): TaskNamespace.Documentation {
  if (!documentation.shortDMC) {
    const index = this.store.resultToReferenceMap.get(documentation.href);
    if (index) {
      documentation.shortDMC = this.store.referenceToResultMap[index].reference;
    }
  }
  return documentation;
}

/**
 * Filter an array of strings according to a given value.
 *
 * @param value The string to look for.
 * @param options The array to filter.
 */
export function filterText(value: string, options: string[]): string[] {
  if (!options) {
    return [];
  }
  const filterValue = value.toLowerCase();
  return options.filter((option: string) => option.toLowerCase().includes(filterValue));
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export function base64ToBlob(base64: string): Promise<Blob> {
  return fetch(base64).then(res => res.blob());
}

export function isBlob(param: unknown): param is Blob {
  return typeof (param as Blob)?.text === "function";
}

export function isBase64(param: unknown): param is string {
  return typeof param === "string" && param.startsWith("data:application/octet-stream;base64,");
}

export function isS1000DSearchByDmc(input: string): boolean {
  return !!input.match(S1000D_DM_REGEX);
}

export function handleS1000DSearchByDmc(
  dmc: string,
  searchResults: SearchResult[]
): SearchResult[] {
  dmc = convertToNewDMC(dmc);
  const regex = new RegExp(dmc.replace(S1000D_DM_WITH_Z_REGEX, S1000D_DM_REPLACE), "g");
  return searchResults.filter((e: SearchResult) => convertToNewDMC(e.dmc).match(regex));
}

export function convertToNewDMC(oldDmc: string): string {
  const regex = /(DMC-)?(\w+)(-ORION)?([-\w+-]+)(_.+)/g;
  return oldDmc.replace(regex, "$2$4");
}

/**
 * Filter predicate to apply on the datasource
 *
 * @param data
 * @param filter
 */
export function customFilterPredicate(data: Object, filter: string): boolean {
  const dataStr = JSON.stringify(data).toLowerCase();

  // Then we check if the cleaned filter is somewhere in the big string
  const transformedFilter = filter.trim().toLowerCase();
  return dataStr.includes(transformedFilter);
}
