import {
  AircraftMappingSettings,
  DataCollectionSettings,
  HistorySettings,
  NoteSettings,
  PlayerSettings,
  SettingsCategory,
  SyncSettings,
  WebtekSettings
} from "@orion2/models/settings.models";

export const DEFAULT_ORION_SETTINGS = {
  history: {
    category: SettingsCategory.HISTORY,
    maxLength: 200
  } as HistorySettings,
  notes: {
    category: SettingsCategory.NOTES,
    publicColor: "#e4002b",
    corporateColor: "#fb8033",
    privateColor: "#0082d5"
  } as NoteSettings,
  player: {
    category: SettingsCategory.PLAYER,
    browser: true,
    electron: true,
    cordova: true
  } as PlayerSettings,
  sync: {
    category: SettingsCategory.SYNC,
    enable: false
  } as SyncSettings,
  dataCollection: {
    category: SettingsCategory.DATA_COLLECTION,
    enable: false
  } as DataCollectionSettings,
  aircraftMapping: {
    category: SettingsCategory.AIRCRAFT_MAPPING,
    mapping: []
  } as AircraftMappingSettings,
  webtek: {
    category: SettingsCategory.WEBTEK,
    labelMapping: {}
  } as WebtekSettings
};

/**
 * Regex used to define word separators for the search.
 * It matches any whitespace character (space, tab, carriage return, ...)
 * Or one of the following: , = : ; ? !
 * Or dots followed by any whitespace character or located at the end of the DM
 */
export const separatorRegex = /[\s,=:;?!]+|\.+\s|\.+$/;

export const incrementalAdminVersion = "2.6.0";

export const onPremConfFolderKey = "on-prem-conf-folder-path";

export const onPremConfFolderMessage = "Select folder for ORION Server conf";

export const DEFAULT_ITEMS_PER_PAGE = 25;
