import { PubDoc } from "@orion2/models/couch.models";
import { DbSchema } from "libs/transfert/model/pubSchema";

export interface WorkerRequest {
  query: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export interface ProcessorGateway {
  initializeFactory(databaseConf: DatabaseConfObject): Promise<void>;
  switchPublication(pubInfo: PubDoc): Promise<boolean>;
  exec(dbQuery: DbQuery);
}

export interface DbQuery {
  dbSchema: DbSchema;
  query: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  dbParams?: DbParams;
  callbacksName?: string[];
  isWorker?: boolean;
  targetDb?: DBConnexionType;
}

export interface DbParams {
  local: Object;
  remote: Object;
  isOffline: boolean;
  isPackagePDF?: boolean;
  useAuth?: boolean;
  useWorker?: boolean;
  isIncremental?: boolean;
  exclude3Dmedia?: boolean;
  revision?: string;
}

export enum ActionEnum {
  START = "start",
  PAUSE = "pause",
  COMPLETE = "complete",
  DELETE = "delete"
}

export enum DBConnexionType {
  REMOTE = "remote",
  LOCAL = "local",
  BOTH = "both"
}

export interface DatabaseConfObject {
  couchBaseUrl: string;
  basepubExternalUrl?: string;
  useCache: boolean;
  displayQuery: boolean;
  cordova: boolean;
  electron: {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    levelDB: any;
  };
}
