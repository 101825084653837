import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@viewer/core";
import { TranslateService } from "@ngx-translate/core";
import { LangService } from "@viewer/core/lang/lang.service";
import { runInAction } from "mobx";

@Component({
  selector: "o-select-lang",
  templateUrl: "./select-lang.component.html",
  styleUrls: ["./select-lang.component.scss"]
})
export class SelectLangComponent implements OnInit {
  @Input() changePubLang: boolean;
  languages: string[];
  selectedLang: string;

  constructor(
    private store: Store,
    private translate: TranslateService,
    private langService: LangService
  ) {}

  ngOnInit() {
    this.languages = this.translate.getLangs();
    this.selectedLang = this.changePubLang ? this.store.pubInfo.lang : this.store.currentLanguage;
  }

  /**
   * Change the app language
   *
   * @memberof SelectLangComponent
   */
  changeLang(): void {
    if (this.changePubLang) {
      this.store.pubInfo.lang = this.selectedLang;
      runInAction(() => {
        this.store.needReload = !this.store.needReload;
      });
    } else {
      this.langService.setLang(this.selectedLang);
    }
  }
}
