/* eslint-disable @typescript-eslint/naming-convention */
import { AuthFlow } from "libs/auth/src/auth.flow";
import { PromisesRepository } from "libs/stream/promises-repository.service";
import { TokenResponse } from "@openid/appauth/built/token_response";
import { FetchRequestor } from "@openid/appauth/built/xhr";
import { AuthFlowConfiguration } from "@orion2/auth/auth.flow.conf";
import { OidcConfiguration } from "@orion2/auth/oidc.conf";
import { OakResponse } from "@orion2/auth/oak.response";
import { UserManagementResponse, guestUser, RoleErrorJson } from "@orion2/auth/role.interfaces";
import { StorageBackend } from "@openid/appauth";
import { RoleRequestHandler } from "@orion2/auth/role.request.handler";
import { ExtendedFetchRequestor } from "@orion2/auth/ExtendedFetchRequestor";
import * as openid from "openid-client";
import { StatusCodes } from "http-status-codes";

export interface GdprAgreementResponse {
  [version: string]: { response: boolean; date: number };
}

const GDPR_PROMISE = "GDPR";
const ROLE_PROMISE = "ROLE";

export class AuthService {
  /***********
   * Before using this class, AuthFlow properties must be set.
   * See AuthElectronMainService and BrowserAuthService for examples
   */
  public static oidcConf: OidcConfiguration;
  // ad mock to test user change on web browser local
  // private static nbconnecMock = 0;
  protected static authFlowConf: AuthFlowConfiguration = {
    crypto: undefined,
    requestor: new FetchRequestor(),
    xhrWithErrorBody: ExtendedFetchRequestor.xhrWithErrorBody,
    role: new RoleRequestHandler(),
    tokenExpireBuffer: 0,
    popup: false
  } as AuthFlowConfiguration;
  protected static storage: StorageBackend;

  protected static checkUserDbsPromisesMap = new Map<string, Promise<boolean>>();

  private static readonly DEFAULT_OCC_CODE = "9999";

  private static _authFlow: AuthFlow;
  private static refreshToken: string;
  private static idToken: string;

  protected static get authFlow() {
    if (!this._authFlow) {
      this._authFlow = new AuthFlow(this.authFlowConf);
    }
    return this._authFlow;
  }

  public static setOidcConf(oidcConf: OidcConfiguration) {
    this.authFlowConf = {
      ...this.authFlowConf,
      ...oidcConf,
      oak: oidcConf.oak ?? true,
      redirectUri: oidcConf.redirectUri || `${oidcConf.baseUrl}/login_success`,
      postLogoutRedirectUri: oidcConf.postLogoutRedirectUri || `${oidcConf.baseUrl}/logout_success`,
      tokenExpireBuffer: oidcConf.tokenExpireBuffer
    };
    this.authFlowConf.authorizationHandler.setConfig(this.authFlowConf);
  }

  public static async login(fromAdmin = false): Promise<TokenResponse> {
    // Mock web change user
    // this.nbconnecMock++;

    // First initializes the conf
    if (await this.authFlow.fetchServiceConfiguration()) {
      // Then try to retrieve a formerly persisted refresh token
      const refreshToken = await this.getStoredRefreshToken();
      if (refreshToken) {
        // set "in memory" the refresh we just retrieved from secured store
        // this.authFlow.setRefreshToken(refreshToken);

        // gets a fresh access token from the refresh token;
        return this.accessToken();
      }

      // No refresh token was persisted so we have to log in
      return this.authFlow
        .login()
        .then((tokenResponse: TokenResponse) => {
          // Login code error
          if (tokenResponse["code"]) {
            // In this case the popup could not be oppened because of the browser blocker, we throw an event
            // to redirect to the '/login' page.
            if (tokenResponse["code"] === 400) {
              const path = window.location.pathname;
              const search = window.location.search;
              window.dispatchEvent(
                new CustomEvent("redirectLogin", {
                  detail: {
                    redirectPath: path,
                    redirectSearch: search
                  }
                })
              );
            }
            // if code is present so there is an error.(404 for instance)
            console.error("Error with token response:", tokenResponse["code"]);
            return Promise.reject(tokenResponse);
          }

          const checkUserProm = !this.authFlowConf.oak
            ? Promise.resolve()
            : this.checkUserDbs(AuthService.DEFAULT_OCC_CODE);

          return checkUserProm.then(() =>
            Promise.all([this.uniquePromiseCheckGdpr(), this.uniquePromiseUserRole(fromAdmin)])
              .then(
                ([signed, roleResponse]: [boolean, UserManagementResponse]) =>
                  roleResponse && signed && this.dispatchRoleResponse(roleResponse)
              )
              .then((isLoggedIn: boolean) => {
                // Login success
                if (tokenResponse && isLoggedIn) {
                  return this.securelyStoreTokens(tokenResponse);
                }
                console.error("No token response available");
                return Promise.reject("No token response available");
              })
              .catch((error: Error) => {
                console.error("Login promise all", error);
                return Promise.reject(error);
              })
          );
        })
        .catch(error => {
          console.log("Error with login:", error);
          return Promise.reject(error);
        });
    } else {
      return Promise.reject("Error while retrieving OpenId Configuration");
    }
  }

  public static logout(): Promise<boolean> {
    if (!this.idToken) {
      console.error("idToken is undefined");
    }

    return this.authFlow
      .logout(this.idToken)
      .then((httpResponse: boolean) => {
        if (httpResponse) {
          // Reset check for user DB
          this.checkUserDbsPromisesMap.delete(AuthService.DEFAULT_OCC_CODE);
          PromisesRepository.reset(ROLE_PROMISE);
          this.deleteSecurelyStoredTokens();
        }
        return httpResponse;
      })
      .catch(err => {
        console.error(err);
        return Promise.reject(false);
      });
  }

  public static oak(): Promise<OakResponse> {
    return this.authFlow.getValidOak();
  }

  public static accessToken(): Promise<TokenResponse> {
    return this.authFlow
      .getValidAccessToken()
      .then((token: TokenResponse) => {
        if (token.refreshToken && token.refreshToken !== this.refreshToken) {
          this.securelyStoreRefreshToken(token.refreshToken);
        }
        return token;
      })
      .catch(error => {
        // The oauth service is not yet configured and ready
        // or refresh token is invalid or expired 400
        if (error.code === StatusCodes.BAD_REQUEST) {
          this.securelyStoreRefreshToken(undefined);
        }
        if (
          error.code === StatusCodes.BAD_REQUEST ||
          error.code === StatusCodes.INTERNAL_SERVER_ERROR
        ) {
          return this.login();
        }

        console.error("Token ", JSON.stringify(error));

        return Promise.reject(error);
      });
  }

  public static userId() {
    return this.getStoredIdToken().then();
  }

  public static profile(): Promise<openid.UserinfoResponse> {
    // Mock web change user
    // if (this.nbconnecMock % 2 === 0) {
    // return Promise.resolve({
    //   sub: "Z22222297344"
    // });
    // } else {
    // return Promise.resolve({
    //   sub: "Z22222297345"
    // });
    // }

    return this.accessToken()
      .then(() => this.authFlow.profile())
      .catch(err => {
        console.error("Profile:", err);
        return {} as openid.UserinfoResponse;
      });
  }

  public static dispatchRoleResponse(roleResponse: UserManagementResponse): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const _listener = event => {
        if ((event as CustomEvent).detail.response) {
          resolve(true);
        }
        reject(false);
      };

      removeEventListener("roleResponseSet", _listener);
      addEventListener("roleResponseSet", _listener);
      dispatchEvent(
        new CustomEvent("roleResponse", {
          detail: {
            response: roleResponse
          }
        })
      );
    });
  }

  static securelyStoreTokens(tokenResponse: TokenResponse): Promise<TokenResponse> {
    if (tokenResponse.refreshToken) {
      this.securelyStoreRefreshToken(tokenResponse.refreshToken);
    }

    if (tokenResponse.idToken) {
      this.securelyStoreIdToken(tokenResponse.idToken);
    }
    return Promise.resolve(tokenResponse);
  }

  static deleteSecurelyStoredTokens(): void {
    this.refreshToken = undefined;
    this.idToken = undefined;
    this.authFlow.setRefreshToken(undefined);
  }

  static securelyStoreRefreshToken(token: string): Promise<void> {
    this.refreshToken = token;
    this.authFlow.setRefreshToken(token);
    return Promise.resolve();
  }

  static getStoredRefreshToken(): Promise<string> {
    return Promise.resolve(this.refreshToken);
  }

  static securelyStoreIdToken(idToken: string): Promise<void> {
    this.idToken = idToken;
    return Promise.resolve();
  }

  static getStoredIdToken(): Promise<string> {
    return Promise.resolve(this.idToken);
  }

  public static checkUserRole(userZid: string, fromAdmin = false): Promise<UserManagementResponse> {
    let roleStatus = true;

    return this.authFlow
      .getUserRole(userZid, fromAdmin)
      .catch((err: RoleErrorJson) => {
        if (
          (err.error === "NO_SUCH_USER_EXCEPTION" &&
            err.message.match(new RegExp(userZid, "gi"))) ||
          err.error === "ROLE_KO"
        ) {
          roleStatus = err.error !== "ROLE_KO";

          // In some cases, INTERNAL users may not be found in /roles API
          // We need to let them access ORION2 with minimum rights
          return this.profile()
            .then(
              profile =>
                ({
                  emailAddress: profile["email"] || userZid,
                  userType: roleStatus ? "INTERNAL" : "CUSTOMER",
                  adLogin: userZid,
                  lastName: "",
                  firstName: "",
                  adGroups: [],
                  companyId: "guest"
                } as UserManagementResponse)
            )
            .catch(error => {
              console.error(
                "ERROR while falling back for unknown users in /roles API",
                error.message
              );
              return Promise.reject(error.message);
            });
        }

        console.error("ERROR while retrieving roles", err.message);
        return Promise.reject(err.message);
      })
      .finally(() => {
        this.dispatchRoleStatus(roleStatus);
      });
  }

  /**
   * Function that checks that GDPR Privacy Policies are signed
   * It first check local to see if we already signed, else check remote.
   * If GDRP is not signed in local or remote, open a popup to sign
   * Return true if signed (i.e. accepted or refused)
   * Return false if an error happened
   */
  public static checkGdpr(): Promise<boolean> {
    return this.getGdprVersion().then((version: string) =>
      this.profile()
        .then((profile: openid.UserinfoResponse) =>
          this.getLocalPrivacyPoliciesAgreements(profile.sub).then(
            (localAgreements: GdprAgreementResponse) => {
              if (!localAgreements?.[version]) {
                // Check remotely via xhr GET
                return this.getRemotePrivacyPoliciesAgreements(
                  profile.sub,
                  this.authFlow.oakResponse
                ).then(({ _rev: rev, agreements: remoteAgreements }) => {
                  if (!remoteAgreements || !remoteAgreements[version]) {
                    return this.openPopup().then((response: boolean) => {
                      // Update remote via xhr PUT
                      remoteAgreements ||= {};
                      remoteAgreements[version] = {
                        response,
                        date: Date.now()
                      };
                      // Update on both remote and local
                      return Promise.all([
                        this.updateLocalPrivacyPoliciesAgreements(profile.sub, remoteAgreements),
                        this.updateRemotePrivacyPoliciesAgreements(
                          profile.sub,
                          this.authFlow.oakResponse,
                          remoteAgreements,
                          rev
                        )
                      ]).then(() => true);
                    });
                  }
                  return this.updateLocalPrivacyPoliciesAgreements(profile.sub, remoteAgreements);
                });
              }
              return true;
            }
          )
        )
        .catch((err: Error) => {
          console.error(err);
          return false;
        })
    );
  }

  /**
   * Getter for GDPR agreements on localStorage
   *
   * @param username
   */
  static getLocalPrivacyPoliciesAgreements(username: string): Promise<GdprAgreementResponse> {
    return this.storage
      .getItem(`user_${username}_gdpr_agreements`)
      .then((localString: string | null) => {
        if (!localString || localString === "undefined") {
          return null;
        }
        return JSON.parse(localString);
      });
  }

  /**
   * Setter for GDPR agreements on localStorage
   *
   * @param username
   * @param newAgreements
   */
  static updateLocalPrivacyPoliciesAgreements(
    username: string,
    newAgreements: GdprAgreementResponse
  ): Promise<boolean> {
    return this.storage
      .setItem(`user_${username}_gdpr_agreements`, JSON.stringify(newAgreements))
      .then(() => true);
  }

  /**
   * Getter for GDPR agreements on remote DB
   *
   * @param username
   * @param token
   */
  static getRemotePrivacyPoliciesAgreements(
    username: string,
    token: OakResponse
  ): Promise<{ _rev: string; agreements: GdprAgreementResponse }> {
    return this.performRequest("GET", username, token).then(
      (response: { _id: string; _rev: string; agreements: GdprAgreementResponse }) => {
        if (!response) {
          return { _rev: undefined, agreements: undefined };
        }
        const { _rev, agreements } = response;
        return { _rev, agreements };
      }
    );
  }

  /**
   * Setter for GDPR agreements on remote DB
   *
   * @param username
   * @param token
   * @param agreements
   * @param rev
   */
  static updateRemotePrivacyPoliciesAgreements(
    username: string,
    token: OakResponse,
    agreements: GdprAgreementResponse,
    rev: string
  ): Promise<boolean> {
    return this.performRequest("PUT", username, token, agreements, rev).then((response: string) => {
      if (!response) {
        console.error("ERROR while updating remote agreements");
      }
      return !!response;
    });
  }

  /**
   * Getter for GDPR version (via message events)
   */
  static getGdprVersion(): Promise<string> {
    return new Promise(resolve => {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function _listener(event) {
        resolve((event as CustomEvent).detail.response);
      }

      removeEventListener("gdprVersionResponse", _listener);
      addEventListener("gdprVersionResponse", _listener);
      dispatchEvent(new CustomEvent("gdprVersionRequest"));
    });
  }

  /**
   * Function that open a GDPR popup (via message events)
   */
  static openPopup(): Promise<boolean> {
    return new Promise(resolve => {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function _listener(event) {
        resolve((event as CustomEvent).detail.response);
      }

      removeEventListener("gdprPopupClosed", _listener);
      addEventListener("gdprPopupClosed", _listener);
      dispatchEvent(new CustomEvent("openGdprPopup"));
    });
  }

  /**
   * Function that send a request (via message events)
   *
   * @param type
   * @param username
   * @param token
   * @param newAgreements
   * @param _rev
   */
  static performRequest(
    type: "GET" | "PUT",
    username: string,
    token: OakResponse,
    newAgreements?: GdprAgreementResponse,
    _rev?: string
  ): Promise<unknown> {
    return new Promise(resolve => {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function _listener(event) {
        resolve((event as CustomEvent).detail.response);
      }

      removeEventListener("requestResponse", _listener);
      addEventListener("requestResponse", _listener);
      const eventToSend = new CustomEvent("sendRequest", {
        detail: {
          type,
          username,
          token,
          newAgreements,
          _rev
        }
      });
      dispatchEvent(eventToSend);
    });
  }

  /**
   * Method that all user DBs related to occCode are created
   *
   * @param occCode
   */
  static checkUserDbs(occCode: string): Promise<boolean> {
    if (!this.checkUserDbsPromisesMap.get(occCode)) {
      this.checkUserDbsPromisesMap.set(occCode, this.authFlow.checkUserDbs(occCode));
    }
    return this.checkUserDbsPromisesMap.get(occCode);
  }

  static checkUserHasPending(
    occCode: string
  ): Promise<{ private: number; corp: number; public: number; total: number }> {
    return this.authFlow.fetchHasPending(occCode);
  }

  static transferTocItemsFromPending(occCode: string, transferPrivate: boolean): Promise<boolean> {
    return this.authFlow.fetchTransferHasPending(occCode, transferPrivate);
  }

  /**
   *
   */
  protected static uniquePromiseUserRole(fromAdmin = false): Promise<UserManagementResponse> {
    const stream = PromisesRepository.get(ROLE_PROMISE);
    if (!stream) {
      PromisesRepository.create<UserManagementResponse>(ROLE_PROMISE);
      this.profile()
        .then((profile: openid.UserinfoResponse) =>
          !profile.sub ? guestUser : this.checkUserRole(profile.sub, fromAdmin)
        )
        .then(response => this.handleUserRole(response))
        .catch(error => {
          console.error(error);
          this.handleUserRole(guestUser);
        });
    }
    return PromisesRepository.getPromise(ROLE_PROMISE);
  }

  protected static handleUserRole(response: UserManagementResponse) {
    PromisesRepository.resolve(ROLE_PROMISE, response);
    PromisesRepository.reset(ROLE_PROMISE);
  }

  /**
   * Unique promise for checkGDPR function
   * Return pending promise if exists
   */
  protected static uniquePromiseCheckGdpr() {
    const stream = PromisesRepository.get(GDPR_PROMISE);
    if (!stream) {
      PromisesRepository.create<boolean>(GDPR_PROMISE);
      this.checkGdpr()
        .then((response: boolean) => {
          PromisesRepository.resolve(GDPR_PROMISE, response);
          PromisesRepository.reset(GDPR_PROMISE);
        })
        .catch((err: Error) => {
          PromisesRepository.reject(GDPR_PROMISE, err);
          PromisesRepository.reset(GDPR_PROMISE);
        });
    }
    return PromisesRepository.getPromise(GDPR_PROMISE);
  }

  protected static dispatchRoleStatus(roleStatus: boolean) {
    dispatchEvent(new CustomEvent("role_status", { detail: { status: roleStatus } }));
  }
}
