// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-wrapper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  left: 0;
  position: fixed;
  top: calc(50vh - 30px);
  width: 100%;
  z-index: 100;
}
.loading-wrapper .spinnerText {
  margin-bottom: 20px;
  text-align: center;
}
.loading-wrapper mat-progress-spinner {
  margin: 0 auto;
}

.hide {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYWRpbmcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw2QkFBQTtFQUNBLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFlBQUE7RUFDQSx1QkFBQTtFQUNBLE9BQUE7RUFDQSxlQUFBO0VBQ0Esc0JBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtBQUNGO0FBQ0U7RUFDRSxtQkFBQTtFQUNBLGtCQUFBO0FBQ0o7QUFFRTtFQUNFLGNBQUE7QUFBSjs7QUFJQTtFQUNFLGFBQUE7QUFERiIsImZpbGUiOiJsb2FkaW5nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxvYWRpbmctd3JhcHBlciB7XG4gIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBoZWlnaHQ6IGF1dG87XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBsZWZ0OiAwO1xuICBwb3NpdGlvbjogZml4ZWQ7XG4gIHRvcDogY2FsYyg1MHZoIC0gMzBweCk7XG4gIHdpZHRoOiAxMDAlO1xuICB6LWluZGV4OiAxMDA7XG5cbiAgLnNwaW5uZXJUZXh0IHtcbiAgICBtYXJnaW4tYm90dG9tOiAyMHB4O1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgfVxuXG4gIG1hdC1wcm9ncmVzcy1zcGlubmVyIHtcbiAgICBtYXJnaW46IDAgYXV0bztcbiAgfVxufVxuXG4uaGlkZSB7XG4gIGRpc3BsYXk6IG5vbmU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,uBAAA;EACA,OAAA;EACA,eAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;EACA,kBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;;AAIA;EACE,aAAA;AADF;AACA,g/BAAg/B","sourcesContent":[".loading-wrapper {\n  background-color: transparent;\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  justify-content: center;\n  left: 0;\n  position: fixed;\n  top: calc(50vh - 30px);\n  width: 100%;\n  z-index: 100;\n\n  .spinnerText {\n    margin-bottom: 20px;\n    text-align: center;\n  }\n\n  mat-progress-spinner {\n    margin: 0 auto;\n  }\n}\n\n.hide {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
