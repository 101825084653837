import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateUtils } from "@viewer/core/translate-utils/translate.utils";

@Injectable({
  providedIn: "root"
})
export class TranslatePropertiesService {
  private translationProperties: Map<string, Map<string, string>> = new Map();

  constructor(private http: HttpClient) {}

  public translate(raw_xsl: string, lang: string): Promise<string> {
    let getTranslationMapping: Promise<Map<string, string>>;

    if (this.translationProperties.has(lang)) {
      getTranslationMapping = Promise.resolve(this.translationProperties.get(lang));
    } else {
      getTranslationMapping = this.getPropertiesFile(lang).then(content => {
        // Properties line is like :
        // LABEL=Test
        const lineProp = content.match(/(.*)\s?=\s?(.*)/gm);

        const propMap = new Map<string, string>();
        // We trim() the key and value to avoid some unwanted space
        lineProp.forEach(prop => {
          const key = prop
            .match(/\w[\w\s]*=/)[0]
            .replace("=", "")
            .trim();
          // Values can be of the type:
          // Test
          // Unit&#233; documentaire
          const value = prop
            .match(/=([\w\s]*.*)/)[0]
            .replace("=", "")
            .trim();

          propMap.set(key, value);
        });
        this.translationProperties.set(lang, propMap);
        return propMap;
      });
    }

    return getTranslationMapping.then(propMap => {
      const translateUtils = new TranslateUtils();
      // For each line we translate
      const xslLines = raw_xsl.split(/\n/);
      let xsl = "";
      xslLines.forEach(line => {
        xsl += translateUtils.translateLine(line, propMap);
      });

      return xsl;
    });
  }

  private getPropertiesFile(lang: string): Promise<string> {
    return this.http
      .get(`./assets/local/translation_${lang}.properties`, {
        responseType: "text"
      })
      .toPromise();
  }
}
