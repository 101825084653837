import { Injectable } from "@angular/core";
import { Store } from "@viewer/core/state/store";
import { HistoryStat } from "libs/models/stats.model";
import { DatePipe } from "@angular/common";
import { when } from "mobx";
import { HistoryElem } from "@orion2/models/tocitem.models";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { FullHttpService } from "libs/http/fullHttp.service";
import { MimeType } from "@orion2/models/enums";

@Injectable()
export class StatsService {
  private readonly endpoint = "stats-svc";

  constructor(private httpService: FullHttpService, private store: Store) {}

  public sendHistory(historyElem: HistoryElem): Promise<void> {
    // SPEC: Wait for content provider to update duObject (History is updated before Content Provider)
    return (
      when(() => this.store.duObject.dmc?.includes(historyElem.dmc))
        .then(() => this.formatHistoryStat(historyElem))
        // SPEC: We disable the stat request to avoid some unwanted error before the deployment of proxy node
        .then((historyStat: HistoryStat) =>
          this.httpService.sendRequest<void>(HttpMethod.POST, `${this.endpoint}/history`, {
            body: historyStat,
            headers: {
              "Content-Type": MimeType.JSON,
              Accept: MimeType.JSON
            }
          })
        )
        .then(() => {})
        .catch(err => console.error(err))
    );
  }

  private formatHistoryStat(historyElem: HistoryElem): HistoryStat {
    const dateFormatter = new DatePipe(this.store.currentLanguage);
    const duMeta = this.store.getDUMeta(historyElem.dmc);
    const duObject = this.store.duObject;
    const tocNode = this.store.currentTocNode;
    const pubTitle = `${this.store.pubInfo.isPackagePDF ? "" : "ORION "}${
      this.store.pubInfo.verbatimText
    }`;
    const dmc = tocNode.dmc;
    const [_, manual, shortDMC] = /^(\w*) (.*)$/g.exec(tocNode.reference) || [];
    const title = tocNode.shortTitle || tocNode.title;
    const issueDate = dateFormatter.transform(new Date(duMeta?.date || tocNode.date), "yyyy.MM.dd");
    const [majorRev, minorRev] = tocNode.minRevision.split(".");
    const issueNumber = duObject.issueNumber || majorRev;
    const inworkNumber = duObject.inWork || minorRev;
    return {
      occCode: this.store.pubInfo.occurrenceCode,
      pubTitle,
      lang: this.store.pubInfo.lang,
      rev: this.store.pubInfo.revision,
      clientId: this.store.user.siebelId,
      userId: this.store.user.samAccountName,
      dmc,
      manual,
      shortDMC,
      title,
      issueDate,
      issueNumber,
      inworkNumber,
      timestamp: historyElem.date.getTime(),
      source: historyElem.from
    };
  }
}
