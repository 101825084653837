export class ExtendedFetchRequestor {
  static xhrWithErrorBody<T>(settings: JQueryAjaxSettings): Promise<T> {
    if (!settings.url) {
      return Promise.reject("A URL must be provided.");
    }
    const url: URL = new URL(settings.url as string);
    const requestInit: RequestInit = {};
    requestInit.method = settings.method;
    requestInit.mode = "cors";

    if (settings.data) {
      if (settings.method && settings.method.toUpperCase() === "POST") {
        requestInit.body = settings.data as string;
      } else {
        const searchParams = new URLSearchParams(settings.data as string);
        // Cast as any to remove compilation error
        (searchParams as any).forEach((value, key) => {
          url.searchParams.append(key, value);
        });
      }
    }

    // Set the request headers
    requestInit.headers = {};
    if (settings.headers) {
      for (const i in settings.headers) {
        if (settings.headers.hasOwnProperty(i)) {
          requestInit.headers[i] = settings.headers[i] as string;
        }
      }
    }

    const isJsonDataType = settings.dataType && settings.dataType.toLowerCase() === "json";

    if (isJsonDataType) {
      requestInit.headers["Accept"] = "application/json, text/javascript, */*; q=0.01";
    }

    return fetch(url.toString(), requestInit).then(response => {
      const contentType = response.headers.get("content-type");
      if (response.status >= 200 && response.status < 300) {
        if (isJsonDataType || (contentType && contentType.indexOf("application/json") !== -1)) {
          return response.json();
        } else {
          return response.text();
        }
      } else {
        if (isJsonDataType) {
          return response.json().then(json => Promise.reject(json));
        } else {
          return Promise.reject(response);
        }
      }
    });
  }
}
