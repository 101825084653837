import { Injectable, Injector, OnDestroy } from "@angular/core";
import { IReactionDisposer, reaction } from "mobx";
import { PubDoc, TocInfo } from "@orion2/models/couch.models";
import { Historic, HistoryElem, Navigation, TocItem } from "@orion2/models/tocitem.models";
import { HistorySettings } from "@orion2/models/settings.models";
import { replaceRevisionFromDoc } from "@viewer/shared-module/helper.utils";
import { Observable, Subscription, filter, map } from "rxjs";
import { TocItemLocalStorageService } from "@viewer/core/toc-items/tocItemLocalStorage.service";
import { HistorySource } from "@orion2/models/enums";
import { SearchResult } from "@viewer/core/search/searchModel";
import { getNextMajorRev } from "@orion2/utils/functions.utils";
import { DEFAULT_ORION_SETTINGS } from "@orion2/utils/constants.utils";
import { StatsService } from "@viewer/core/stats/stats.service";
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class HistoricService extends TocItemLocalStorageService implements OnDestroy {
  protected tiType = "historic";
  protected tiScope = "private";

  // SPEC: By default we only keep the last 200 visited DM
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private MAX_HISTORY_LENGTH = DEFAULT_ORION_SETTINGS["history"].maxLength;

  private historic: Historic;
  private dmRoot: Navigation;
  private potentialDmRoot: Navigation;
  private prevNode: Navigation;

  private readonly reactionResetDisposer: IReactionDisposer;
  private readonly reactionCurrentDMCDisposer: IReactionDisposer;
  private readonly reactionSettingsDisposer: IReactionDisposer;
  private subscriptions = new Subscription();
  private statsService: StatsService;

  constructor(injector: Injector) {
    super(injector);

    this.statsService = this.injector.get(StatsService);
    this.reactionResetDisposer = reaction(
      () => this.store.publicationID,
      () => {
        // If we are not inside a publication we don't want to execute the following code
        if (!this.store.publicationID) {
          return;
        }

        this.resetNavigation();
        this.subscriptions.add(
          this.tocItemsOfType.subscribe((historic: Historic[]) => {
            if (!this.store.publicationID) {
              return;
            }

            if (!historic) {
              return this.refreshLocal();
            }
            this.historic =
              (historic && historic[0]) ||
              ({
                _id: `${this.getDocPrefix()}${this.tiType}__${
                  this.store.publicationID
                }_${uuidv4()}`,
                history: [],
                type: this.tiType,
                minRevision: this.store.publicationRevision,
                maxRevision: getNextMajorRev(
                  this.store.publicationRevision,
                  this.store.pubInfo?.isPackagePDF
                ) // SPEC: History is not incremental
              } as Historic);
          })
        );
      },
      { fireImmediately: true }
    );

    this.reactionCurrentDMCDisposer = reaction(
      () => this.store.currentDMC,
      () => {
        // If we are not inside a publication we don't want to execute the following code
        if (!this.isActive() || !this.store.currentDMC) {
          return;
        }
        // Update the cursor if needed (refresh use case for exemple)
        this.prevNode =
          this.prevNode ||
          this.findNavigationByDMC(this.store.currentDMC, this.dmRoot) ||
          this.potentialDmRoot;

        if (
          (this.prevNode === undefined || this.dmRoot === undefined) &&
          this.potentialDmRoot === undefined
        ) {
          const linkdata = {
            dmc: this.store.currentDMC
          } as HistoryElem;
          if (this.store.currentDMC && linkdata.dmc !== "loap" && linkdata.dmc !== "search") {
            // SPEC: For deeplink & reloads/back/forward with browser buttons/shortcuts
            this.addPotentialDmRoot(linkdata, HistorySource.DEEPLINK);
          }
        }
      }
    );

    this.reactionSettingsDisposer = reaction(
      () => this.store.historySettings,
      (settings: HistorySettings) => {
        // If we are not inside a publication we don't want to execute the following code
        if (!this.store.pubInfo) {
          return;
        }

        if (settings?.maxLength) {
          this.MAX_HISTORY_LENGTH = settings.maxLength;
          const hist: HistoryElem[] = this.getHistory();
          // SPEC: We only keep the last MAX_HISTORY_LENGTH visited DM
          while (hist.length > this.MAX_HISTORY_LENGTH) {
            hist.pop();
          }
          // SPEC: Save flat history
          this.saveLocal(this.historic);
        }
      },
      { fireImmediately: true }
    );
  }

  public get tocItemsOfType(): Observable<Historic[]> {
    return super.tocItemsOfType.pipe(
      filter(Boolean),
      map((history: TocItem[]) => history as Historic[])
    );
  }

  public getLastConsulted(pub: PubDoc): HistoryElem {
    this.pub = pub;
    const [historicDoc]: [Historic] = this.getLocalItemsOfType() as [Historic];

    return historicDoc?.history
      .sort(
        (a: HistoryElem, b: HistoryElem) =>
          new Date(a.date as unknown as string).getTime() -
          new Date(b.date as unknown as string).getTime()
      )
      .pop();
  }

  ngOnDestroy() {
    this.reactionResetDisposer();
    this.reactionCurrentDMCDisposer();
    this.reactionSettingsDisposer();
    this.subscriptions.unsubscribe();
  }

  public getDmRoot(): Navigation {
    return this.dmRoot;
  }

  public getPotentialDmRoot(): Navigation {
    return this.potentialDmRoot;
  }

  public isActive(): boolean {
    return this.store.pubInfo?.capabilities?.historic;
  }

  /**
   * Reset the historic tree (delete in local storage the last saved tree)
   *
   * @memberof HistoricService
   */
  public resetNavigation(): void {
    this.dmRoot = undefined;
    this.potentialDmRoot = undefined;
    this.prevNode = undefined;
  }

  /**
   * Set a potential DMRoot to a DmRoot
   *
   * @memberof HistoricService
   */
  public addDmRoot(): void {
    this.dmRoot = this.potentialDmRoot;
    this.prevNode = this.potentialDmRoot;
    this.potentialDmRoot = undefined;
  }

  // ------------------------- MANAGEMENT DM ROOT -------------------------
  public async addPotentialDmRoot(
    linkdata: HistoryElem | TocInfo | TocItem | SearchResult,
    from: string
  ): Promise<void> {
    let dmc = "dmc" in linkdata ? linkdata.dmc : "";
    // Remove the DMC revision
    dmc = replaceRevisionFromDoc(dmc);
    // For task-card dmc start with task__
    // For part-card dmc start with part__
    if (/^(task|part)__/.test(dmc)) {
      dmc = dmc.split("__")[1];
    }

    if (!this.dmRoot || (this.dmRoot && dmc !== this.dmRoot.dmc)) {
      const id = `${this.getDocPrefix()}__DMRoot__${dmc}_${uuidv4()}`;

      const elem: HistoryElem = {
        dmc,
        from,
        date: new Date()
      };
      this.addToHistory(elem);
      this.potentialDmRoot = {
        _id: id,
        type: "navigation",
        parent: undefined,
        children: [],
        active: true,
        dmc
      };
    }
  }

  public async updateCurrentDMRoot(
    linkdata: HistoryElem | TocInfo | SearchResult,
    from: string
  ): Promise<void> {
    // For task-card dmc start with task__
    // For part-card dmc start with part__
    if (linkdata.dmc && /^(task__|part__)/.test(linkdata.dmc)) {
      linkdata.dmc = linkdata.dmc.split("__")[1];
    }

    if (this.potentialDmRoot && this.potentialDmRoot.dmc !== linkdata.dmc) {
      this.addDmRoot();
    }

    if (this.dmRoot && this.prevNode.dmc !== linkdata.dmc) {
      const id = `${this.tiType}__${this.dmRoot.dmc}__${this.prevNode.dmc}__${
        linkdata.dmc
      }__${uuidv4()}`;

      let node = this.findNavigationNodeInFirstChild(linkdata.dmc, this.prevNode);
      const newHistory: HistoryElem = {
        dmc: linkdata.dmc,
        date: new Date(),
        from
      };
      this.addToHistory(newHistory);
      const newNode: Navigation = {
        _id: id,
        type: "navigation",
        parent: this.prevNode ? this.prevNode._id : this.dmRoot._id,
        children: [],
        dmc: linkdata.dmc,
        active: true
      };

      if (node) {
        if (node.dmc === this.prevNode.dmc) {
          node.children.push(newNode);
        }
      } else {
        node = newNode;
        this.prevNode.children.push(newNode);
      }

      this.prevNode = node;
    }
  }

  /**
   * Set the node cursor to the previous node
   *
   * @param [node]
   * @memberof HistoricService
   */
  public backTo(node?: Navigation): void {
    if (!node) {
      // Back button wih a session
      if (this.prevNode) {
        const elem: HistoryElem = {
          dmc: this.prevNode.dmc,
          date: new Date(),
          from: HistorySource.BACK
        };
        this.addToHistory(elem);
        this.prevNode = this.findNavigationById(this.prevNode.parent, this.dmRoot);
      }

      // Back button after a reload or deepLinking
      if (!this.prevNode) {
        this.potentialDmRoot = undefined;
      }
    } else {
      const from = node.type ? HistorySource.NAVIGATION : HistorySource.HISTORY;
      // Click from History/Navigation
      this.prevNode = node;
      const elem: HistoryElem = {
        dmc: this.prevNode.dmc,
        date: new Date(),
        from
      };
      this.addToHistory(elem);
    }
  }

  public addToHistory(elem: HistoryElem): void {
    // history will not be sent on premise as there is no way to enable it (off by default).
    if (this.store.isLoggedIn && this.store.dataCollectionSettings.enable) {
      this.statsService.sendHistory(elem);
    }
    const hist: HistoryElem[] = this.getHistory();
    // SPEC: Do not add reload as a new HistoryElem
    if (hist[0]?.dmc === elem.dmc) {
      elem.from = hist.splice(0, 1)[0].from;
    }
    hist.unshift(elem);
    // SPEC: We only keep the last MAX_HISTORY_LENGTH visited DM
    while (hist.length > this.MAX_HISTORY_LENGTH) {
      hist.pop();
    }
    // SPEC: Save flat history
    this.saveLocal(this.historic);
  }

  public getNavigation(): Navigation {
    return this.dmRoot ? this.dmRoot : this.potentialDmRoot;
  }

  public getHistory(): HistoryElem[] {
    return this.historic?.history;
  }

  protected preSync(): Promise<void> {
    return Promise.all(
      this.getLocalItemsOfType().map((lsHistory: Historic) => {
        const id = lsHistory._id.replace(this.getDocPrefix(), "");
        // Retrieve remote history for merge
        return this.getRemoteDoc(id)
          .then((remoteHistory: Historic) => {
            if (
              JSON.stringify(lsHistory.history) === JSON.stringify(remoteHistory?.history || [])
            ) {
              // SPEC: if local = remote, do not update local
              return;
            }
            const mergedHistory = [...lsHistory.history, ...(remoteHistory?.history || [])].reduce(
              (acc: HistoryElem[], cur: HistoryElem) => {
                const index = acc.findIndex(
                  (elem: HistoryElem) =>
                    new Date(elem.date).getTime() === new Date(cur.date).getTime() &&
                    elem.dmc === cur.dmc
                );
                if (index === -1) {
                  acc.push(cur);
                }
                return acc;
              },
              []
            );
            this.sortTocItemList(mergedHistory);
            // Limit to MAX_HISTORY_LENGTH items
            lsHistory.history = mergedHistory.slice(0, this.MAX_HISTORY_LENGTH);
            // Save merged history in localStorage
            this.saveLocal(lsHistory);
            // Save merge history in localDB
            return this.save({ ...lsHistory, _id: id });
          })
          .catch(() =>
            // Save even if we there is an error
            this.save({ ...lsHistory, _id: id })
          );
      })
    ).then(() => {});
  }

  private findNavigationNodeInFirstChild(dmc: string, node: Navigation): Navigation {
    if (!node) {
      return undefined;
    }
    if (node.dmc === dmc) {
      return node;
    }
    return node.children.find((child: Navigation) => child.dmc === dmc);
  }

  private findNavigationById(id: string, node: Navigation): Navigation {
    if (!node) {
      return undefined;
    }
    if (node._id === id) {
      return node;
    }
    let foundNode: Navigation;
    for (const child of node.children) {
      foundNode = this.findNavigationById(id, child);
    }
    return foundNode;
  }

  private findNavigationByDMC(dmc: string, node: Navigation): Navigation {
    if (!node) {
      return undefined;
    }
    if (node.dmc === dmc) {
      return node;
    }
    let foundNode: Navigation;
    for (const child of node.children) {
      foundNode = this.findNavigationByDMC(dmc, child);
    }
    return foundNode;
  }
}
