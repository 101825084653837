import { Injectable } from "@angular/core";
import { PouchService, ApplicabilityService } from "@viewer/core";
import { TocInfo } from "@orion2/models/couch.models";

export interface MultiLinkMeta {
  applicableDUs: string[];
  links: TocInfoWithAnchor[];
}

export interface TocInfoWithAnchor {
  anchor: string;
  metadata: TocInfo;
}

@Injectable()
export class MultilinksService {
  private applicableDUs = [];
  private links: MultiLinkMeta["links"] = [];

  constructor(
    private pouchService: PouchService,
    private applicabilityService: ApplicabilityService
  ) {}

  public retrieveData(data: string[]): Promise<MultiLinkMeta> {
    this.applicableDUs = [];
    this.links = [];
    const dmcArray = this.splitData(data);

    return Promise.all(
      dmcArray.map(target =>
        this.pouchService.tocCaller
          .get<TocInfo>(target.dmc)
          .then((metadata: TocInfo) => this.extractData(metadata, target.dmc, target.anchor))
      )
    ).then((links: MultiLinkMeta["links"]) => {
      this.links = links;
      return {
        applicableDUs: this.applicableDUs,
        links: this.links
      };
    });
  }

  private splitData(datas: string[]): { dmc: string; anchor: string }[] {
    return datas.map(elm => {
      const data = elm.split("##");
      return {
        dmc: data[0],
        anchor: data[1] || undefined
      };
    });
  }

  private extractData(metadata: TocInfo, dmc: string, anchor: string): MultiLinkMeta["links"][0] {
    if (metadata !== undefined && dmc !== undefined) {
      if (this.applicabilityService.isApplicable(metadata)) {
        this.applicableDUs.push(dmc);
      }
      return {
        anchor,
        metadata
      };
    }
    return { anchor: undefined, metadata: undefined };
  }
}
