import { Injectable } from "@angular/core";
import { PubDoc } from "libs/models/couch.models";
import { PubMeta } from "@viewer/core/pouchdb/caller";
import { Params } from "@angular/router";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { ConfService, PouchService } from "@viewer/core";
import { ProductsBP2Service } from "@viewer/home/service/products-bp2.service";
import { ProductsBP1Service } from "@viewer/home/service/products-bp1.service";

export function productsServiceFactory(
  basepubUserService: BasepubUserService,
  pouchService: PouchService,
  confService: ConfService
): ProductsService {
  return confService.conf.hasBP2
    ? new ProductsBP2Service(basepubUserService, pouchService, confService)
    : new ProductsBP1Service(basepubUserService, pouchService, confService);
}

@Injectable()
export abstract class ProductsService {
  public abstract getPubDoc(pubId: string): PubDoc;

  public abstract getLocalProducts(): Promise<PubDoc[]>;

  public abstract getOnlineProducts(params?: Params): Promise<PubDoc[]>;

  public abstract enrichPubDoc(pub: PubDoc): Promise<PubDoc>;

  public abstract storeLastRevisions(pubs: PubMeta[]): Promise<void[]>;
}
