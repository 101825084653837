import { Injectable } from "@angular/core";
import { XmlDoc } from "@orion2/models/couch.models";
import { Store, PouchService } from "@viewer/core";
import { reaction } from "mobx";

export interface MFC {
  enterpriseIdent: string;
  enterpriseName: string;
  businessUnit?: BU;
}

export interface BU {
  businessUnitName?: string;
  businessUnitAddress?: BUA;
}
export interface BUA {
  street?: string;
  postalZipCode?: string;
  postOfficeBox?: string;
  city?: string;
  country?: string;
}

@Injectable()
export class VendorService {
  vendorXML: Document;
  manufacturerMap: Map<string, MFC>;

  constructor(private pouchService: PouchService, private store: Store) {
    reaction(
      () => ({
        pubId: this.store.publicationID,
        pubRev: this.store.publicationRevision
      }),
      (state: { pubId: string; pubRev: string }) => {
        if (state.pubId && state.pubRev) {
          // SPEC: Reset map when changing pub
          this.manufacturerMap = new Map<string, MFC>();
          // The DM vendor is renamed in the import. Currently, there is a 1 DM vendor per publication.
          // This DM is differentiated from the others by "00k".
          this.init();
        }
      },
      { fireImmediately: true }
    );
  }

  setManufacturerInformations(manufacturerElement: Element): MFC {
    const enterpriseName = manufacturerElement.querySelector("enterpriseName")
      ? (manufacturerElement.querySelector("enterpriseName").innerHTML as string)
      : undefined;
    const enterpriseIdent = manufacturerElement.querySelector("enterpriseIdent")
      ? manufacturerElement.querySelector("enterpriseIdent").getAttribute("manufacturerCodeValue")
      : undefined;
    const businessUnit = manufacturerElement.querySelector("businessUnit");
    if (businessUnit) {
      const businessUnitName = businessUnit.querySelector("businessUnitName")
        ? (businessUnit.querySelector("businessUnitName").innerHTML as string)
        : undefined;
      const businessUnitAddress = businessUnit.querySelector("businessUnitAddress");
      let street;
      let postOfficeBox;
      let postalZipCode;
      let city;
      let country;
      if (businessUnitAddress) {
        street = businessUnitAddress.querySelector("street")
          ? (businessUnitAddress.querySelector("street").innerHTML as string)
          : undefined;
        postOfficeBox = businessUnitAddress.querySelector("postOfficeBox")
          ? (businessUnitAddress.querySelector("postOfficeBox").innerHTML as string)
          : undefined;
        postalZipCode = businessUnitAddress.querySelector("postalZipCode")
          ? (businessUnitAddress.querySelector("postalZipCode").innerHTML as string)
          : undefined;
        city = businessUnitAddress.querySelector("city")
          ? (businessUnitAddress.querySelector("city").innerHTML as string)
          : undefined;
        country = businessUnitAddress.querySelector("country")
          ? (businessUnitAddress.querySelector("country").innerHTML as string)
          : undefined;
      }

      return {
        enterpriseName,
        enterpriseIdent,
        businessUnit: {
          businessUnitName,
          businessUnitAddress: {
            street,
            postOfficeBox,
            postalZipCode,
            city,
            country
          }
        }
      };
    } else {
      return {
        enterpriseName,
        enterpriseIdent
      };
    }
  }

  public getManufacturerInformations(manufacturerId: string): Promise<MFC> {
    if (this.vendorXML) {
      return Promise.resolve(this.manufacturerMap.get(manufacturerId));
    } else {
      return this.init().then(() => this.manufacturerMap.get(manufacturerId));
    }
  }

  private init(): Promise<void> {
    return this.pouchService.xmlCaller
      .get(this.store.pubInfo.specificParams?.["vendorsDu"])
      .then((xml: XmlDoc) => {
        const parser = new DOMParser();
        this.vendorXML = parser.parseFromString(xml.data, "text/xml");

        const manufacturerList = this.vendorXML.querySelectorAll("enterpriseSpec");
        for (const manufacturer of Array.from(manufacturerList)) {
          const manufacturerId = manufacturer
            .querySelector("enterpriseIdent")
            .getAttribute("manufacturerCodeValue");

          const manufacturerStr = this.setManufacturerInformations(manufacturer);
          if (manufacturerStr.enterpriseName) {
            this.manufacturerMap.set(manufacturerId, manufacturerStr);
          }
        }
      })
      .catch(e => {
        console.error("No vendor for this pub", e);
      });
  }
}
