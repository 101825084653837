import { StringMap } from "@openid/appauth/built/types";

export interface UserRights {
  makePrivateNote: boolean;
  makeCorpNote: boolean;
  makeInspection: boolean;
  makeBookmark: boolean;
  makeOrdering: boolean;
  makePublicNote: boolean;
}
export interface UserRole {
  role: string;
  rights: UserRights;
}

export interface UserManagement {
  userRole: UserManagementResponse;
  userRights: UserRole;
}
export interface UserManagementResponse {
  userId?: number;
  adLogin: string;
  userLogin?: string;
  emailAddress: string;
  civility?: string;
  firstName: string;
  lastName: string;
  birthDate?: number;
  jobTitle?: string;
  focalPoint?: boolean;
  customerRepresentative?: boolean;
  fleetManager?: boolean;
  pilotLicenseNumber?: string;
  pilotLicenseIssueDate?: number;
  pilotLicenseExpirationDate?: number;
  camoLicenseNumber?: string;
  camoLicenseIssueDate?: number;
  camoLicenseExpirationDate?: number;
  camoOrganizationApproval?: boolean;
  mechanicianLicenseNumber?: string;
  mechanicianLicenseIssueDate?: number;
  mechanicianLicenseExpirationDate?: number;
  mechanicianOrganizationApproval?: boolean;
  companyId: string;
  mainOrganizationLabel?: string;
  adGroups: Array<string>;
  status?: string;
  siebelRowId?: string;
  userType?: string;
  jobFunction?: string;
  internalAccessUser?: string;
  internalAccessCompany?: string;
  campaignLimitDate?: number;
  campaignValidatedDate?: number;
}

/**
 * Role request
 */
export type ErrorType = "NO_SUCH_USER_EXCEPTION" | "ROLE_KO";

export interface RoleErrorJson {
  error: ErrorType;
  error_description?: string;
  message?: string;
  error_uri?: string;
}
export interface RoleRequestJson {
  bearer: RoleRequestBearerJson;
  params: RoleRequestParamsJson;
}
export interface RoleRequestParamsJson {
  userId: string;
}
export interface RoleRequestBearerJson {
  access_token: string;
}
export class RoleRequest {
  params: RoleRequestParamsJson;
  bearer: RoleRequestBearerJson;
  constructor(request: RoleRequestJson) {
    this.bearer = request.bearer;
    this.params = request.params;
  }

  /**
   * Serializes the RoleRequest to a JavaScript Object.
   */
  toJson(): Promise<RoleRequestJson> {
    return Promise.resolve({
      bearer: { access_token: this.bearer.access_token },
      params: {
        userId: this.params.userId
      }
    });
  }

  toStringMap(): StringMap {
    return {
      userId: this.params.userId
    };
  }
}

// Default mock role response for guest user.
export const guestUser: UserManagementResponse = {
  userId: 0,
  adLogin: "guest",
  userLogin: "",
  emailAddress: "",
  civility: "mr",
  firstName: "Guest",
  lastName: "User",
  birthDate: -3600000,
  jobTitle: "",
  focalPoint: true,
  customerRepresentative: false,
  fleetManager: false,
  pilotLicenseNumber: "",
  pilotLicenseIssueDate: 0,
  pilotLicenseExpirationDate: 0,
  camoLicenseNumber: "",
  camoLicenseIssueDate: 0,
  camoLicenseExpirationDate: 0,
  camoOrganizationApproval: false,
  mechanicianLicenseNumber: "",
  mechanicianLicenseIssueDate: 0,
  mechanicianLicenseExpirationDate: 0,
  mechanicianOrganizationApproval: false,
  companyId: "guest",
  mainOrganizationLabel: "",
  adGroups: [],
  status: "",
  siebelRowId: "",
  userType: "",
  jobFunction: "",
  internalAccessUser: "",
  internalAccessCompany: "",
  campaignLimitDate: 0,
  campaignValidatedDate: 0
};
