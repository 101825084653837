import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@viewer/core";
import { ServiceBulletinService } from "@viewer/core/toc-items/service-bulletin.service";
import { ServiceBulletin } from "@orion2/models/tocitem.models";
import { IReactionDisposer, reaction } from "mobx";

@Component({
  selector: "o-sb-impacting-warning",
  templateUrl: "./sb-impacting-warning.component.html",
  styleUrls: ["./sb-impacting-warning.component.scss"]
})
export class SbImpactingWarningComponent implements OnInit, OnDestroy {
  public dmcChangeHandlerReaction: IReactionDisposer;
  public isClose = false;
  public sbs: ServiceBulletin[];

  constructor(
    public store: Store,
    public sbService: ServiceBulletinService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dmcChangeHandlerReaction = reaction(
      () => this.store.currentDMC,
      () => {
        this.sbService.getAllImpactingSB(this.store.currentDMC).then((sbs: ServiceBulletin[]) => {
          this.sbs = sbs;
          this.cdr.detectChanges();
        });
      },
      { fireImmediately: true }
    );
  }

  ngOnDestroy() {
    this.dmcChangeHandlerReaction();
  }

  public goToSB(sb: ServiceBulletin) {
    this.router.navigate([
      "pub",
      this.store.publicationID,
      this.store.publicationRevision,
      "du",
      sb._id,
      "pdf",
      Object.keys(sb._attachments)[0].split("__")[0]
    ]);
  }

  public toggle() {
    this.isClose = !this.isClose;
  }
}
