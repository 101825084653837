import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";

export interface ConfirmDialogData {
  message: string[];
  cancelLabels?: string[];
  confirmLabels?: string[];
}

export interface ConfirmDialogResult {
  confirm: boolean;
  status: number;
}

@Component({
  selector: "o-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent implements OnInit {
  public message = [""];
  public cancelLabels: string[] = [];
  public confirmLabels: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  ngOnInit(): void {
    this.message = this.data.message;
    this.cancelLabels = this.data.cancelLabels || ["Cancel"];
    this.confirmLabels = this.data.confirmLabels || ["Confirm"];
  }

  public confirm(status: number): void {
    this.dialogRef.close({ confirm: true, status });
  }

  public cancel(status: number): void {
    this.dialogRef.close({ confirm: false, status });
  }
}
