declare const IonicDeeplink: any; // so that there's no Typescript error

export class DeepLinkingNavigate {
  /**
   * Get Url send by DeepLink on Cordova and navigate.
   *
   * @param app is Appcomponant for access to Angular router
   * @param zone is ngZone
   */
  static deeplinking(app, zone) {
    // We do not use the logic of the Ionic Deeplinking plugin.
    // The plugin only allows us to retrieve the address to use for the redirection.
    // We do not define any address for all requests to be "Nomacth" in order to do the redirection.
    // https://github.com/ionic-team/ionic-plugin-deeplinks
    // PI : If you want change deelinking schema, please remove and add platform on cordova

    // at the firt call on app component, IonicDeeplink will react on any deeplink call (orion://)
    IonicDeeplink.route(
      {},
      () => {},
      nomatch => {
        // return if we don't have nomatch.$link.url
        if (!nomatch.$link.url) {
          return;
        }
        let url: string = nomatch.$link.url.split("://")[1];
        url = decodeURI(decodeURI(url));
        const queryParams = {};
        const paramPart = url.split("?")[1];
        url = url.split("?")[0];
        if (paramPart) {
          const paramTab = paramPart.split("&");
          for (const param of paramTab) {
            const [key, value] = param.split("=");
            queryParams[key] = value;
          }
        }
        const params = { queryParams };
        console.log("deeplink URL =", url);
        // we add ngZone.run() to ensure to use cordova app when we navigate
        zone.run(() => app.router.navigate(["/" + url], params));
      }
    );
  }
}
