import { AuthService } from "@orion2/auth/auth.service";
import { BrowserAuthorizationRequestHandler } from "@orion2/auth/browser.auth.request.handler";
import { AuthFlowConfiguration } from "@orion2/auth/auth.flow.conf";
import { OakRequestHandler } from "@orion2/auth/oak.request.handler";
import { LocalStorageBackend } from "@openid/appauth";

export class BrowserAuthService extends AuthService {
  static authFlowConf: AuthFlowConfiguration = {
    ...AuthService.authFlowConf,
    authorizationHandler: new BrowserAuthorizationRequestHandler(),
    oakHandler: new OakRequestHandler()
  };
  static storage: LocalStorageBackend = new LocalStorageBackend();
}
