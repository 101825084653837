import { Component, Input, OnInit } from "@angular/core";
import { News } from "@orion2/models/tocitem.models";
import { NewsUtilsService } from "@viewer/news/services/news-utils.service";

@Component({
  selector: "o-card-news",
  templateUrl: "./card-news.component.html",
  styleUrls: ["./card-news.component.scss"]
})
export class CardNewsComponent implements OnInit {
  @Input() news: News;
  public content: string;
  public isExpanded = false;

  constructor(private newsUtilsService: NewsUtilsService) {}

  ngOnInit(): void {
    if (!this.news.read) {
      this.expand();
    }
  }

  public expand(): void {
    this.isExpanded = true;
    if (this.content === undefined) {
      this.getContent(this.news);
    }
  }

  public reduce(): void {
    this.isExpanded = false;
  }

  public getContent(news: News): Promise<void> {
    return this.newsUtilsService.getContent(news).then((content: string) => {
      this.content = content;
    });
  }

  public markAsRead(news: News): Promise<void> {
    return this.newsUtilsService.markAsRead(news);
  }
}
