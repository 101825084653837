import { DbProcessor } from "@viewer/core/pouchdb/core/dbProcessor";
import { environment } from "@viewer-env/environment";
import { TokenResponse } from "@openid/appauth";
import { OakResponse } from "@orion2/auth/oak.response";
import { AuthHandler } from "@orion2/models/auth.models";

/**
 * DbProcessor with AuthHandler functions to retrieve authentication info.
 * Used when no Web Workers.
 */
export class MainDbProcessor extends DbProcessor implements AuthHandler {
  constructor() {
    super();
    addEventListener("message", (e: CustomEvent) => this.handleMessage(e), false);
  }

  /**
   * Get OAK and send it via message
   *
   * @param id
   * @param db
   */
  public oak(id: number, db: string): void {
    environment.authService
      .oak()
      .then((oakResponse: OakResponse) => {
        this.postMessage(id, db, "sendOak", oakResponse);
      })
      .catch(err => {
        this.postMessage(id, db, "error", err);
      });
  }

  /**
   * Get AccessToken and send it via message
   *
   * @param id
   * @param db
   */
  public accessToken(id: number, db: string): void {
    environment.authService
      .accessToken()
      .then((token: TokenResponse) => {
        this.postMessage(id, db, "sendAccessToken", token);
      })
      .catch(err => {
        this.postMessage(id, db, "error", err);
      });
  }

  public getLastSequence(id: number, db: string, key: string): void {
    const val = localStorage.getItem(key);
    this.postMessage(id, db, "sendGetLastSequence", val);
  }

  public saveLastSequence(id: number, db: string, key: string, val: string): void {
    localStorage.setItem(key, val);
    this.postMessage(id, db, "sendSaveLastSequence", true);
  }

  /**
   * Send a message to OrionDBs
   *
   * @param id
   * @param db
   * @param message
   * @param response
   */
  public postMessage(id: number, db: string, message: string, response: unknown): void {
    const event = new CustomEvent("message", {
      detail: {
        payload: {
          id,
          db,
          response,
          message: "OrionDBs." + message,
          origin: "MainDbProcessor"
        }
      }
    });
    dispatchEvent(event);
  }

  /**
   * Handle incoming messages
   *
   * @param e
   */
  public handleMessage(e: CustomEvent): void {
    if (e instanceof CustomEvent) {
      // If the message is specially addressed to this file
      const [addressedFile, callback] = (e.detail?.message || "").split(".");
      if (addressedFile === "DbProcessor") {
        switch (callback) {
          case "getAccessToken":
            this.accessToken(e.detail.payload.id, e.detail.payload.db);
            break;
          case "getOak":
            this.oak(e.detail.payload.id, e.detail.payload.db);
            break;
          case "getLastSequence":
            this.getLastSequence(
              e.detail.payload.id,
              e.detail.payload.db,
              e.detail.payload.params.key
            );
            break;
          case "saveLastSequence":
            this.saveLastSequence(
              e.detail.payload.id,
              e.detail.payload.db,
              e.detail.payload.params.key,
              e.detail.payload.params.value
            );
            break;
          case "callback":
            // SPEC: Do nothing
            break;
          default:
            console.error("DbProcessor", "Message not handled", e.detail.message);
            break;
        }
      }
    }
  }
}
