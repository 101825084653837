import { Injectable } from "@angular/core";
import { ConfService, PouchService, Store } from "@viewer/core";
import { ActionEnum } from "@viewer/core/pouchdb/types";
import { PubDoc } from "libs/models/couch.models";

@Injectable()
export class PubDestroyService {
  constructor(
    private store: Store,
    private pouchService: PouchService,
    private confService: ConfService
  ) {}

  public destroyPub(occurrenceCode: string): Promise<PouchDB.Core.Response | void> {
    this.store.pubInfo.status = undefined;
    // SPEC: When we remove a revision, we want to delete definitively some docs related to this revision on the common databases (media,pdf)
    // PouchDB can not do that for the moment (no purge function)
    // So we have to destroy the common database and so delete all revisions of the publication
    return this.getAllPubsWithPubOccCode(occurrenceCode)
      .then((pubs: PubDoc[]) =>
        pubs
          .reduce(
            (previous: Promise<unknown>, pub: PubDoc) =>
              previous.then(() =>
                this.pouchService
                  .switchPublication(pub)
                  .then(() =>
                    Promise.all([
                      this.deletePubDoc(pub.packageId, pub.revision),
                      this.pouchService.deleteCurrentPub(),
                      this.publishStats()
                    ])
                  )
              ),
            Promise.resolve()
          )
          .then(() => this.pouchService.pubCaller.compact())
      )
      .catch(err => console.error("Error when destroying pub:", err));
  }

  private publishStats(): Promise<void> {
    // The logAction function calls directly the remote, so we want to use it only if online
    if (this.confService.useAuth && this.store.isLoggedIn) {
      return this.pouchService.statsCaller.logAction(
        ActionEnum.DELETE,
        this.store.pubInfo._id,
        this.store.pubInfo.occurrenceCode
      );
    }
    return Promise.resolve();
  }

  private getAllPubsWithPubOccCode(occCode: string): Promise<PubDoc[]> {
    return this.pouchService.pubCaller
      .getOfflinePubs()
      .then((pubs: PubDoc[]) => pubs.filter((pub: PubDoc) => pub.occurrenceCode === occCode));
  }

  private deletePubDoc(pubId: string, pubRev: string): Promise<boolean> {
    return this.pouchService.pubCaller.deleteOfflinePub(pubId, pubRev).catch(() => false);
  }
}
