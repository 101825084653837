import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import { Util } from "@orion2/msm-formatter/util";

export class Camo {
  private static FALSE = "FALSE";
  private static TRUE = "TRUE";

  public static createHeaders(send: Function): void {
    const field = [
      "Aircraft Type",
      "Revision",
      "Revision Date",
      "Issue Date",
      "ATAChapter",
      "K-Factor",
      "MI",
      "Titre",
      "Revision",
      "MaintenanceProgrammeSection",
      "Remarks",
      "LbkCertificationText",
      "DuplicateInspRequired (A reprendre manuellement)",
      "MajorScheduleItem",
      "SpecialForecastItem",
      "SpecialForecastItemRemarks",
      "MIReference",
      "CMRItem",
      "MITypeIssuer",
      "TaskCode",
      "NoExtension",
      "OneTime",
      "VariableLife",
      "MIIssuerCategory",
      "Scrap (uncerviceable)",
      "MaintenanceReserve",
      "ShopVisitRequired",
      "PerformAtNextShopVisit",
      "OOP",
      "Task Certified Date",
      "CalcDueFromManufactureDate",
      "CalcDueFromCertificateDate",
      "CalcDueFromInstallationDate",
      "FixedCalendarLife",
      "Finte Due Time Item",
      "Force détache asset",
      "Reason for Force détache asset",
      "MaintenanceSchedule",
      "RevisionNumber",
      "Normal climatic conditions (AII)",
      "Tropical and damp atmosphere (COM)",
      "Damp atmosphere (NH90)",
      "Salt-laden atmosphere (AII)",
      "Sand- and/or dust-laden atmosphere (All)",
      "Cold weather (COM)",
      "Cold, icing and snow weather conditions (NH90)",
      "Very cold weather (COM)",
      '"Hot weather conditions (EC175;NH90)"',
      "Water pumping using water bomber installation (EC225)",
      "LifeCode_1",
      "LifeCode_1_Threshold",
      "LifeCode_1_ThresholdTolerance",
      "LifeCode_1_Life",
      "LifeCode_1_LifeTolerance",
      "LifeCode_2",
      "LifeCode_2_Threshold",
      "LifeCode_2_ThresholdTolerance",
      "LifeCode_2_Life",
      "LifeCode_2_LifeTolerance",
      "LifeCode_3",
      "LifeCode_3_Threshold",
      "LifeCode_3_ThresholdTolerance",
      "LifeCode_3_Life",
      "LifeCode_3_LifeTolerance",
      "LifeCode_4",
      "LifeCode_4_Threshold",
      "LifeCode_4_ThresholdTolerance",
      "LifeCode_4_Life",
      "LifeCode_4_LifeTolerance",
      "MILocation",
      "CardNumber",
      "MPD Task REF",
      "Issuer",
      "Type",
      "RevisionDate",
      "MSRevision",
      "Model",
      "PartNo"
    ];

    const description = [
      "Aircraft Type",
      "Revision of the manual",
      "Revision date of the task",
      "Issue date of the manual",
      "ATAChapter",
      "K-Factor",
      "Maintenance Task reference",
      "Maintenance Task title",
      "Envision Parameter",
      "Maintenance Section",
      "Maintenance Tas remarks",
      "Envision Parameter",
      "Double inspection (notion * / ** )",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "Manual Type",
      "TaskCode",
      "Margin not extension",
      "if PO",
      "Envision Parameter",
      "MSM & ALS Revision",
      "Ticked for Life Limits (SLL/OTL)",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      'If the task is not TSI or TSM value ("TRUE")',
      'If task TSM value ("TRUE")',
      "If not TSM or TSI",
      'If task TSI value ("TRUE")',
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "Envision Parameter",
      "climatic conditions",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Life parameters",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "MSM & ALS chapter",
      "CardNumber",
      "Envision Parameter",
      "Manual Type",
      "",
      "Card Revision date",
      "Card N° Revision",
      "Components touched by the task Maintenance",
      "PartNo"
    ];

    for (const k of field) {
      if (k.length === field.length - 1) {
        send(field[k]);
      } else {
        send(field[k] + ";");
      }
    }

    send("\n");

    for (const k of description) {
      if (k.length === description.length - 1) {
        send(description[k]);
      } else {
        send(description[k] + ";");
      }
    }

    send("\n");
  }

  public static createCamoArray(task: TaskNamespace.MsmTask): string[] {
    const camoArray = [];

    // Aircraft Type
    camoArray.push(task.modelIdentCode);
    // Revision
    camoArray.push("TODO");
    // Revision Date
    camoArray.push(task.revision);
    // Issue Date
    camoArray.push(task.issueDate);
    // ATAChapter
    camoArray.push(task.id.slice(0, 2));
    // K-Factor
    camoArray.push("");
    // MI
    camoArray.push(task.modelIdentCode + task.versions + "/" + task.id);
    // Titre
    camoArray.push(task.title);
    // Revision
    camoArray.push("");
    // MaintenanceProgrammeSection
    // Convert key = CH05SE30SJ00SC05SU21 to 05-30-00
    const [, ch, se, sj] = [...task.section.match(/CH(.*?)SE(.*?)SJ(.*?)SC(.*?)SU(.*?)/)];
    camoArray.push(`${ch}-${se}-${sj}`);
    // Remarks
    // const limit = task.interval[0].temporaryLimit
    //   ? "Provisional Limitation"
    //   : "";
    // camoArray.push(`"${limit}
    // ${task.mod}
    // ${task.description.join(`
    // `)}"`);
    camoArray.push("TODO");
    // LbkCertificationText
    camoArray.push(Camo.FALSE);
    // DuplicateInspRequired
    camoArray.push("");
    // MajorScheduleItem
    camoArray.push(Camo.FALSE);
    // SpecialForecastItem
    camoArray.push(Camo.FALSE);
    // SpecialForecastItemRemarks
    camoArray.push(Camo.FALSE);
    // MIReference
    camoArray.push("");
    // CMRItem
    // camoArray.push(
    //   task.systemCode.match(/^(04-10|04-20)$/) ? Camo.TRUE : Camo.FALSE
    // );
    camoArray.push("TODO");
    // MITypeIssuer
    camoArray.push("AH / ALS/MSM/PRE");
    // TaskCode
    // camoArray.push(task.frequency === "Punctual" ? "PO" : task.limitType);
    camoArray.push("TODO");
    // NoExtension
    let marginSum = 0;
    task.margin.forEach(margin => {
      // Convert to number
      marginSum += +margin.val;
    });
    camoArray.push(marginSum > 0 ? Camo.FALSE : Camo.TRUE);
    // OneTime
    // camoArray.push(task.frequency === "Punctual" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // VariableLife
    camoArray.push(Camo.FALSE);
    // MIIssuerCategory
    camoArray.push("");
    // Scrap
    camoArray.push(task.limitType.match(/^(SLL|OTL)$/) ? Camo.TRUE : Camo.FALSE);
    // MaintenanceReserve
    camoArray.push(Camo.FALSE);
    // ShopVisitRequired
    camoArray.push(Camo.FALSE);
    // PerformAtNextShopVisit
    camoArray.push(Camo.FALSE);
    // OOP
    camoArray.push(Camo.FALSE);
    // Task Certified Date
    camoArray.push(task.limitType.match(/^(TSI|TSM)$/) ? Camo.FALSE : Camo.TRUE);
    // CalcDueFromManufactureDate
    camoArray.push(task.limitType === "TSM" ? Camo.TRUE : Camo.FALSE);
    // CalcDueFromCertificateDate
    camoArray.push(task.limitType.match(/^(TSI|TSM)$/) ? Camo.TRUE : Camo.FALSE);
    // CalcDueFromInstallationDate
    camoArray.push(task.limitType === "TSI" ? Camo.TRUE : Camo.FALSE);
    // FixedCalendarLife
    camoArray.push(Camo.FALSE);
    // Finte Due Time Item
    camoArray.push(task.interval[0].unit === "ED" ? Camo.TRUE : Camo.FALSE);
    // Force détache asset
    camoArray.push(task.limitType === "TBO" ? Camo.TRUE : Camo.FALSE);
    // Reason for Force détache asset
    camoArray.push(task.limitType === "TBO" ? Camo.TRUE : Camo.FALSE);
    // MaintenaceSchedule
    camoArray.push(task.limitType === "TBO" ? "Scheduled" : "");
    // RevisionNumber
    camoArray.push("");
    // Normal climatic conditions
    // camoArray.push(task.climaticCode === "-" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // Tropical and damp atmosphere (COM)
    // camoArray.push(task.climaticCode === "1" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // Damp atmosphere
    camoArray.push(Camo.FALSE);
    // Salt - laden atmosphere
    // camoArray.push(task.climaticCode === "2" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // Sand- and/or dust-laden atmosphere
    // camoArray.push(task.climaticCode === "3" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // Cold weather
    // camoArray.push(task.climaticCode === "4" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // Cold, icing and snow weather conditions
    camoArray.push(Camo.FALSE);
    // Very cold weather
    // camoArray.push(task.climaticCode === "5" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // Hot weather conditions
    // camoArray.push(task.climaticCode === "6" ? Camo.TRUE : Camo.FALSE);
    camoArray.push("TODO");
    // Water pumping using water bomber installation
    camoArray.push(Camo.FALSE);
    // LifeCode_1
    camoArray.push("TODO");
    // LifeCode_1_Threshold
    camoArray.push("TODO");
    // LifeCode_1_ThresholdTolerance
    camoArray.push("TODO");
    // LifeCode_1_Life
    camoArray.push("TODO");
    // LifeCode_1_LifeTolerance
    camoArray.push("TODO");
    // LifeCode_2
    camoArray.push("TODO");
    // LifeCode_2_Threshold
    camoArray.push("TODO");
    // LifeCode_2_ThresholdTolerance
    camoArray.push("TODO");
    // LifeCode_2_Life
    camoArray.push("TODO");
    // LifeCode_2_LifeTolerance
    camoArray.push("TODO");
    // LifeCode_3
    camoArray.push("TODO");
    // LifeCode_3_Threshold
    camoArray.push("TODO");
    // LifeCode_3_ThresholdTolerance
    camoArray.push("TODO");
    // LifeCode_3_Life
    camoArray.push("TODO");
    // LifeCode_3_LifeTolerance
    camoArray.push("TODO");
    // LifeCode_4
    camoArray.push("TODO");
    // LifeCode_4_Threshold
    camoArray.push("TODO");
    // LifeCode_4_ThresholdTolerance
    camoArray.push("TODO");
    // LifeCode_4_Life
    camoArray.push("TODO");
    // LifeCode_4_LifeTolerance
    camoArray.push("TODO");
    // MILocation
    camoArray.push(task.id.slice(0, 5).replace("/", "-"));
    // CardNumber
    // const cardNumber = task.documentation[0].refman.match(/^(AMM|MMA)$/)
    //   ? task.modelIdentCode
    //   : task.documentation[0].refman;
    // camoArray.push(
    //   cardNumber +
    //     (task.documentation[0].refloc ? "." + task.documentation[0].refloc : "")
    // );
    camoArray.push("TODO");
    // MPD Task REF
    // camoArray.push(
    //   cardNumber +
    //     (task.documentation[0].refloc ? "." + task.documentation[0].refloc : "")
    // );
    camoArray.push("TODO");
    // Issuer
    // camoArray.push(
    //   task.documentation[0].refman.match(/^(CMM|EMM)$/) ? "" : "AH"
    // );
    camoArray.push("TODO");
    // Type
    // camoArray.push(task.documentation[0].refman);
    camoArray.push("TODO");
    // RevisionDate
    camoArray.push(task.revision);
    // MSRevision
    camoArray.push("");
    // Model
    camoArray.push("");
    // PartNo
    camoArray.push(task.mpn_pn.map(mpn => mpn.mpn).join(" | "));

    return camoArray;
  }

  public static createLine(couchFcts: { send: Function }, task: TaskNamespace.MsmTask) {
    const camoArray = Camo.createCamoArray(task);
    couchFcts.send(Util.convertCSV(camoArray));
    couchFcts.send("\n");
  }
}
