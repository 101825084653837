import { Injectable } from "@angular/core";
import { HttpContext, HttpEvent } from "@angular/common/http";
import { HttpMethod, AbstractHttpService } from "libs/http/abstractHttp.service";
import { AbstractBasepubService } from "libs/basepub/abstract-basepub.service";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "@orion2/message-service/message.service";
import { BYPASS_LOADER } from "@edistribution/core/interceptors/loader.interceptor";
import { Observable } from "rxjs";
import { MimeType } from "@orion2/models/enums";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";

export const basepubExternalServiceFactory = (
  messageService: MessageService,
  translate: TranslateService,
  degradedStateHandler: DegradedStateService,
  http: AbstractHttpService
) => new BasepubExternalService(http, messageService, translate, degradedStateHandler);

@Injectable()
export class BasepubExternalService extends AbstractBasepubService {
  protected apiPath: string;

  constructor(
    private httpService: AbstractHttpService,
    messageService: MessageService,
    translate: TranslateService,
    degradedStateHandler: DegradedStateService
  ) {
    super(messageService, translate, degradedStateHandler);
  }

  public setApiPath(url: string): void {
    this.apiPath = url;
  }

  /**
   * Fetch the .zip file for a given product from BasePub API.
   *
   * @param occCode The occCode of the product to download.
   * @param revision The revision concerned in BasePub format, ex: "3_0_5".
   */
  public getProductFiles(occCode: string, revision: string): Observable<HttpEvent<ArrayBuffer>> {
    return this.httpService.sendRequest<HttpEvent<ArrayBuffer>>(
      HttpMethod.GET,
      `${this.apiPath}/products/${occCode}/${revision}`,
      {
        headers: { "Content-Type": MimeType.BIN },
        responseType: "arraybuffer",
        observe: "events",
        reportProgress: true,
        context: new HttpContext().set(BYPASS_LOADER, true)
      },
      true
    );
  }
}
