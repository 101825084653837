import {
  AuthorizationServiceConfiguration,
  AuthorizationServiceConfigurationJson
} from "@openid/appauth/built/authorization_service_configuration";
import { Requestor, JQueryRequestor } from "@openid/appauth/built/xhr";

export interface ExtendedAuthorizationServiceConfigurationJson {
  authorization_endpoint: string;
  token_endpoint: string;
  revocation_endpoint: string;
  ping_end_session_endpoint?: string;
  userinfo_endpoint?: string;
}
/**
 * The standard base path for well-known resources on domains.
 * See https://tools.ietf.org/html/rfc5785 for more information.
 */
const WELL_KNOWN_PATH = ".well-known";

/**
 * The standard resource under the well known path at which an OpenID Connect
 * discovery document can be found under an issuer's base URI.
 */
const OPENID_CONFIGURATION = "openid-configuration";

export class ExtendedAuthorizationServiceConfiguration extends AuthorizationServiceConfiguration {
  static fetchFromIssuer(
    openIdIssuerUrl: string,
    requestor?: Requestor
  ): Promise<AuthorizationServiceConfiguration> {
    const fullUrl = `${openIdIssuerUrl}/${WELL_KNOWN_PATH}/${OPENID_CONFIGURATION}`;

    const requestorToUse = requestor || new JQueryRequestor();

    return requestorToUse
      .xhr<ExtendedAuthorizationServiceConfigurationJson>({
        url: fullUrl,
        dataType: "json"
      })
      .then(json => new ExtendedAuthorizationServiceConfiguration(json));
  }

  constructor(
    request: ExtendedAuthorizationServiceConfigurationJson | AuthorizationServiceConfigurationJson
  ) {
    if (request["ping_end_session_endpoint"]) {
      const obj = {
        authorization_endpoint: request["authorization_endpoint"],
        token_endpoint: request["token_endpoint"],
        revocation_endpoint: request["revocation_endpoint"],
        end_session_endpoint: request["ping_end_session_endpoint"],
        userinfo_endpoint: request["userinfo_endpoint"]
      };
      request = obj;
    }
    super(request);
  }
}
