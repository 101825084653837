import { Observable, scan, tap, throwError } from "rxjs";
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DownloadInfo, DownloadState } from "libs/download/download.models";
import { BasepubExternalService } from "libs/basepub/basepub-external.service";
import { DownloadFileService } from "libs/download/download-file.service";
import { MessageService } from "libs/message-service/message.service";

// SPEC: Only two simultaneous downloads are allowed because AH network might not be able to handle more.
const MAX_DOWNLOAD_COUNT = 2;

@Injectable()
export class DownloadProductService {
  private downloadCount = 0;

  constructor(
    private basepubExternalService: BasepubExternalService,
    private downloadFileService: DownloadFileService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  public downloadProduct(
    occCode: string,
    revision: string,
    filename: string
  ): Observable<DownloadInfo> {
    if (this.downloadCount >= MAX_DOWNLOAD_COUNT) {
      this.messageService.warning(this.translate.instant("download.count.warn"));
      return throwError(() => new Error("Download count reached"));
    }

    this.downloadCount += 1;
    return this.basepubExternalService.getProductFiles(occCode, revision).pipe(
      tap({
        error: () => {
          this.messageService.error(this.translate.instant("download.error"));
          this.downloadCount -= 1;
        }
      }),
      scan(
        (previous: DownloadInfo, event: HttpEvent<ArrayBuffer>) => {
          if (this.isHttpProgressEvent(event)) {
            const progress = event.total
              ? Math.round((100 * event.loaded) / event.total)
              : previous.progress;
            return {
              state: DownloadState.IN_PROGRESS,
              progress
            };
          }

          if (this.isHttpResponse(event)) {
            this.downloadCount -= 1;
            const file = new File([event.body], filename);
            this.downloadFileService.download(file);
            return {
              state: DownloadState.DONE,
              progress: 100
            };
          }
          return previous;
        },
        { state: DownloadState.PENDING, progress: 0 }
      )
    );
  }

  private isHttpProgressEvent<T>(event: HttpEvent<T>): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress
    );
  }

  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }
}
