import { Injectable } from "@angular/core";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { PubDoc } from "libs/models/couch.models";
import { Basepub } from "@orion2/models/basepub.models";
import { Subject } from "rxjs";
import { Params } from "@angular/router";

interface FilterOptions {
  [category: string]: Map<string, number>;
}

@Injectable()
export class SearchProductsService {
  public filterReset = new Subject();
  public selectedFilters: Params;
  public optionsByCategory: FilterOptions = {};

  constructor(private basepubUserService: BasepubUserService) {}

  public removeFilter(category: string): void {
    this.filterReset.next(category);
  }

  public getAircrafts(): Promise<Basepub.Aircraft[]> {
    return this.basepubUserService.getAircrafts();
  }

  public filterProducts(products: PubDoc[], filters: Params): PubDoc[] {
    this.optionsByCategory = this.updateFilterOptions(products, filters);
    return this.applyFilters(products, filters);
  }

  /**
   * When searching products, some filters are executed on server side (query params on the /products route)
   * While some others are performed on client side.
   * This method takes a Params object and split it into two distinct object:
   * - First one will be used for client side filtering.
   * - Second will be used as query params for the server request.
   */
  public splitFilters(params: Params = {}): { client: Params; server: Params } {
    const serverSideFilters = [
      "aircraftTechnicalName",
      "aircraftVersion",
      "lastConsulted",
      "occCode",
      "status",
      "productType"
    ];
    const splittedFilters = {
      server: {},
      client: {
        productType: "",
        manualCode: "",
        lang: "",
        manualCategory: ""
      }
    };

    Object.entries(params).forEach(([key, value]: string[]) => {
      const filterSide = serverSideFilters.includes(key) ? "server" : "client";
      splittedFilters[filterSide][key] = value;
    });

    return splittedFilters;
  }

  /**
   * Compute possible values and their count for each filter category.
   */
  private updateFilterOptions(products: PubDoc[], filters: Params): FilterOptions {
    const optionsByCategory = {};
    Object.keys(filters).forEach((category: string) => {
      optionsByCategory[category] = new Map<string, number>();
      const tmpFilters = { ...filters, [category]: "" };
      const tmpProducts = this.applyFilters(products, tmpFilters);
      optionsByCategory[category].set("", tmpProducts.length);
      tmpProducts.forEach((pub: PubDoc) => {
        const optionCount = optionsByCategory[category].get(pub[category]) || 0;
        optionsByCategory[category].set(pub[category], optionCount + 1);
      });
    });
    return optionsByCategory;
  }

  private applyFilters(products: PubDoc[], filters: Params): PubDoc[] {
    if (!products) {
      return [];
    }
    return products.filter((pub: PubDoc) =>
      Object.entries(filters).every(
        ([key, value]: [string, string]) => !value || pub[key] === value
      )
    );
  }
}
