export enum HomeRoute {
  CONTRACT = "/home/contract",
  DBSTATUS = "/home/dbStatus",
  EXTRADOC = "/home/extra-doc",
  LOGIN = "/home/login",
  REDIRECT = "/home/redirect",
  REPLICATE = "/home/replicate",
  HELP = "/home/help",
  SRD = "/home/srd",
  LOCAL = "/home/tabs/local",
  ONLINE = "/home/tabs/online",
  CONTRACTS = "/home/tabs/contracts"
}
