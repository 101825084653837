import { TocItemsModule } from "@viewer/toc-items/toc-items.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NoteModuleRoutingModule } from "@viewer/toc-items/note-module/note-routing.module";
import { NoteListComponent } from "@viewer/toc-items/note-module/note-list/note-list.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NoteComponent } from "@viewer/toc-items/note-module/note/note.component";
import { NoteCanDeactivateGuard } from "@viewer/toc-items/note-module/note/note-can-deactivate-guard.service";
import { SharedModule } from "@viewer/shared-module/shared.module";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { WebTekDialogComponent } from "@viewer/toc-items/note-module/webtek-dialog/web-tek-dialog.component";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { WebTekService } from "@viewer/toc-items/note-module/web-tek.service";
import { WebTekIdsService } from "@viewer/toc-items/note-module/web-tek-ids.service";
import { ParseRevisionPipeModule } from "@orion2/utils/ParseRevisionPipeModule";

@NgModule({
  imports: [
    CommonModule,
    NoteModuleRoutingModule,
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    FormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatToolbarModule,
    ParseRevisionPipeModule,
    TocItemsModule
  ],
  providers: [NoteCanDeactivateGuard, WebTekService, WebTekIdsService],
  declarations: [NoteListComponent, NoteComponent, WebTekDialogComponent],
  exports: [NoteListComponent, NoteComponent]
})
export class NoteModule {}

/**
 * Response body of the GET '/me' of WebTek API
 */
export interface MeResponse {
  id: number;
  email: string;
  isStaffMember: boolean;
  firstName: string;
  lastName: string;
  customerOperator: boolean;
}

/**
 * Request body of the POST '/technicalEvent' of WebTek API
 */
export interface TechnicalEventBody {
  visibility: "EXTERNAL" | "INTERNAL";
  customerPriorityId: number;
  requestedFinalAnswerDate: string; // ex: '2018-11-15T14:15:11.107Z'
  targetDate?: string;
  subject: string;
  aircrafts?: {
    typeId: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    serialNumber?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    version?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registration?: any;
    totalTimeSinceNew?: number;
    landings?: number;
  }[];
  owner?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  contact?: {
    id?: number;
    isOperator?: boolean;
    centers?: {
      maintenance: {};
      operator: {};
    };
  };
  subTechnicalEvents?: {
    businessDomainId: number;
    ataId: number;
    visibility: "CUSTOMER" | "AH_NETWORK" | "AH_AHD";
    orionVersion: string;
    eventTypeId: number;
    subEventTypeId?: number;
    businessDomainMetadata: {
      techData: {
        workCardRef: string;
      };
    };
    owner?: {
      id: number;
      firstName: string;
      lastName: string;
    };
  }[];
}

/**
 * Response body of the POST '/technicalEventResult' of WebTek API
 */
export interface TechnicalEventResult {
  id?: number;
  reference?: string;
  subTechnicalEvents?: number[];
}

export interface FromTo {
  id: number;
  name: string;
  address: string;
  isStaffMember: boolean;
}

/**
 * Request body of the POST '/messaging/message' of WebTek API
 */
export interface MessagesBody {
  from?: FromTo;
  to?: FromTo;
  attachments?: Object[];
  technicalEventId: number;
  subTechnicalEventId: number;
  channel: "AH_AHD" | "AH_NETWORK" | "CUSTOMER";
  subject: string;
  text?: string;
  actionType: "PROVIDE_INFO" | "REQUEST_MORE_INFO" | "FINAL_ANSWER";
  draft: boolean;
}
