export interface DownloadInfo {
  state: DownloadState;
  progress: number;
}

export enum DownloadState {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE"
}
