import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef
} from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ApplicabilityService, PouchService } from "@viewer/core";
import { Store } from "@viewer/core/state/store";
import { ApplicModalComponent } from "@viewer/applic-modal/applic-modal.component";
import { Subscription } from "rxjs";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { TocMode } from "@viewer/layout/toc-mode.model";
import { IReactionDisposer, reaction } from "mobx";
import { PubDoc } from "libs/models/couch.models";
import { Router } from "@angular/router";
import { getDisplayedRevision } from "@orion2/utils/front.utils";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { HomeRoute } from "@viewer/home/models";
import { scrollToSelectedNode } from "@orion2/utils/functions.utils";

@Component({
  selector: "o-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"]
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("toc", { static: true }) tocSidenav: MatSidenav;
  @ViewChild("settings", { read: MatSidenav, static: true }) settingsSidenav: MatSidenav;
  public domIsReady = false;
  public pub: PubDoc;
  public displayedRevision: string;
  public tocHeaderBackground: SafeStyle;

  private duObjectReaction: IReactionDisposer;
  private subscriptions = new Subscription();

  constructor(
    public applicabilityService: ApplicabilityService,
    public store: Store,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialog,
    private cdr: ChangeDetectorRef,
    private pouchService: PouchService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.pub = this.store.pubInfo;
    this.displayedRevision = getDisplayedRevision(this.pub);
    this.pouchService.pubCaller.getJacket(this.pub).then((data: Blob | string) => {
      const imgUrl = typeof data === "string" ? data : URL.createObjectURL(data);
      this.tocHeaderBackground = this.sanitizer.bypassSecurityTrustStyle(`url(${imgUrl})`);
    });

    this.duObjectReaction = reaction(
      () => this.store.duObject,
      () => {
        // SPEC: We close the TOC on small screens when DM changes
        if (this.store.tocMode === TocMode.OVER) {
          this.tocSidenav.close();
        }
      }
    );

    this.subscriptions.add(
      this.tocSidenav.openedStart.subscribe(() => {
        scrollToSelectedNode("auto");
      })
    );
  }

  ngAfterViewInit() {
    // this.store.displayDone is sometimes ready before dom is really loaded
    // If 3D player start before dom is load, the player does'nt work
    this.domIsReady = true;
    this.store.tocMode = window.innerWidth >= 1024 ? TocMode.SIDE : TocMode.OVER;
    this.cdr.detectChanges();

    this.subscriptions.add(
      this.breakpointObserver
        .observe(["(min-width: 1024px)"])
        .subscribe((state: BreakpointState) => {
          this.store.tocMode = state.matches ? TocMode.SIDE : TocMode.OVER;
          this.cdr.detectChanges();
        })
    );
  }

  ngOnDestroy() {
    this.domIsReady = false;
    this.subscriptions.unsubscribe();
    this.duObjectReaction();
  }

  public scrollTop(): void {
    window.scroll(0, 0);
  }

  public openApplicModal(): void {
    this.dialogRef.open(ApplicModalComponent, {
      panelClass: "applic-filter-dialog-container"
    });
  }

  public backToStore(): Promise<boolean> {
    this.store.byPassPopupLeavePub = true;
    return this.router.navigate([HomeRoute.ONLINE], { queryParams: { lastConsulted: true } });
  }

  public backToLoap(): Promise<boolean> {
    return this.router.navigate([
      "/pub",
      this.store.publicationID,
      this.store.publicationRevision,
      "du",
      "loap",
      "loap"
    ]);
  }
}
