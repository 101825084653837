import { model } from "@orion2/proto-buf-model/compiled";

/**
 * Mirror the initial structure of search index when taking data from protobuf
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function protobufToJs(docsFromPouch: any): any[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return docsFromPouch.map((doc: any) => {
    const docId = doc._id;
    const clonedObject = {
      _id: docId,
      _rev: doc._rev
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    const binaryData = doc._attachments["att.txt"].data;
    const protobufData = getProtobufData(docId, binaryData);

    if (docId === "index" || docId === "map") {
      return Object.assign(clonedObject, protobufData);
    }

    clonedObject["docs"] = protobufData;
    return clonedObject;
  });
}

function protobufIndexToJs(binaryData: Uint8Array) {
  const protobufData = model.Index.toObject(model.Index.decode(binaryData), {
    defaults: true
  });
  protobufData.documentStore["docs"] = {};
  const docInfoKey = Object.keys(protobufData.documentStore.docInfo);
  docInfoKey.forEach((dmcKey: string) => {
    protobufData.documentStore["docs"][dmcKey] = null;
  });
  protobufData.documentStore["length"] = docInfoKey.length;
  return protobufData;
}

function getProtobufData(docId: string, binaryData: Uint8Array) {
  // With web workers, binaryData is serialized but it is transformed to Object
  if (!(binaryData instanceof Uint8Array)) {
    binaryData = new Uint8Array(Array.from(values(binaryData)));
  }
  if (docId === "index") {
    return protobufIndexToJs(binaryData);
  }
  if (docId === "map") {
    // proto add docs object
    return model.invMap.decode(binaryData)["docs"];
  }
  // is map instead of array and add data
  const protobufData = model.TSMap.decode(binaryData);
  // toObject "parse" 1 fois pour créer les defaults values
  const protoObject = model.TSMap.toObject(protobufData, { defaults: true });

  // parse une 2sd fois => optilisable
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildMap = (tm: any) => {
    const tmap = { docs: tm.docs, df: tm.df };
    for (let i = 0; i < tm._keys.length; i++) {
      const v = buildMap(tm._values[i]);
      //la transformation pourrait être faite au dernier moment si pb avec certains caractères
      tmap[String.fromCodePoint(tm._keys[i])] = v;
    }

    return tmap;
  };
  return buildMap(protoObject);
}

function* values(obj) {
  // for (let prop in obj)
  for (const prop of Object.keys(obj)) {
    // own properties, you might use
    yield obj[prop];
  }
}
