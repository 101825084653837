import { Injectable } from "@angular/core";
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthService } from "libs/auth/src/auth.service.interface";
import { from, lastValueFrom, Observable, concatMap } from "rxjs";
import { TokenResponse } from "@openid/appauth";
import { MimeType } from "@orion2/models/enums";

export enum HttpMethod {
  POST = "post",
  GET = "get",
  DELETE = "delete"
}

export interface HttpClientOptions {
  body?: unknown;
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  observe?: "body" | "events" | "response";
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
  assets?: boolean;
  context?: HttpContext;
}

export type HttpClientPostOptions = Omit<HttpClientOptions, "body" | "responseType" | "observe"> & {
  observe?: "body";
  responseType?: "json";
};

export interface AppEnvironnment {
  [key: string]: unknown;
  authService: AuthService;
}

@Injectable()
export abstract class AbstractHttpService {
  protected baseUrl: string;
  protected environment: AppEnvironnment;
  protected tokenFn: () => Promise<TokenResponse>;

  constructor(protected http: HttpClient) {}

  public convertToFormData(obj: Object): FormData {
    const formData = new FormData();

    type FormType = Blob | string;

    Object.entries(obj).forEach(([key, value]: [string, FormType | FormType[]]) => {
      if (Array.isArray(value)) {
        value.forEach((element: string | Blob, index: number) => {
          formData.append(`${key}[${index}]`, element);
        });
      } else {
        formData.append(key, value);
      }
    });

    return formData;
  }

  public setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  public setEnv(env: AppEnvironnment, useAuth = false): void {
    this.environment = env;
    this.setTokenFn(
      useAuth
        ? this.environment.authService.accessToken.bind(this.environment.authService)
        : () => Promise.resolve(undefined)
    );
  }

  public setTokenFn(tokenFn?: () => Promise<TokenResponse>) {
    this.tokenFn = tokenFn;
  }

  public sendRequest<T>(
    method: HttpMethod,
    path: string,
    options?: HttpClientOptions,
    observable?: false
  ): Promise<T>;
  public sendRequest<T>(
    method: HttpMethod,
    path: string,
    options?: HttpClientOptions,
    observable?: true
  ): Observable<T>;
  public sendRequest<T>(
    method: HttpMethod,
    path: string,
    options: HttpClientOptions = {},
    observable = false
  ): Promise<T> | Observable<T> {
    // SPEC: For assets we don't want trigger any authentification
    const requestObs = from(
      options.assets ? Promise.resolve(new HttpHeaders({})) : this.getHeaders(options.headers)
    ).pipe(
      concatMap((headers: HttpHeaders) => {
        // If the base url is already inside the path we don't need to add it
        const url = path.includes("http") || options.assets ? path : `${this.baseUrl}/${path}`;
        const body =
          headers?.get("Content-Encoding")?.includes("gzip") || this.isFormData(options.body)
            ? options.body || {}
            : JSON.stringify(options.body || {});
        const params = this.isHttpParams(options.params)
          ? options.params
          : new HttpParams({ fromObject: options.params });

        const opts = {
          body,
          headers,
          params,
          responseType: options.responseType || "json",
          observe: options.observe || "body",
          reportProgress: options.reportProgress || false,
          context: options.context
        } as HttpClientOptions;

        const hasFormDataBody = method === HttpMethod.POST && this.isFormData(body);
        if (hasFormDataBody) {
          opts.responseType = "json";
          opts.observe = "body";
          delete opts.body;
          // When using a FormData as body, the method request from HttpClient doesn't set the header "Content-Type: multipart/form-data" with the good boundary value
          // The post() method will update the header natively
          return this.http.post<T>(url, body, opts as HttpClientPostOptions);
        }

        return this.http.request(method, url, opts);
      })
    );

    return observable ? requestObs : lastValueFrom(requestObs);
  }

  public get<T>(url: string): Promise<T> {
    return this.sendRequest(HttpMethod.GET, url);
  }

  public post<T>(url: string, body: unknown): Promise<T> {
    return this.sendRequest(HttpMethod.POST, url, { body });
  }

  public getAsset<T>(path: string, type: MimeType = MimeType.BIN): Promise<T> {
    return this.sendRequest(HttpMethod.GET, path, {
      assets: true,
      responseType: type === MimeType.PLAIN ? "text" : "blob"
    });
  }

  protected getToken(): Promise<TokenResponse> {
    return this.tokenFn();
  }

  private isFormData(param: unknown): param is FormData {
    return typeof (param as FormData)?.get === "function";
  }

  private isHttpParams(
    param: HttpParams | { [param: string]: string | string[] }
  ): param is HttpParams {
    return typeof (param as HttpParams)?.get === "function";
  }

  protected abstract getHeaders(otherHeaders?: HttpClientOptions["headers"]): Promise<HttpHeaders>;
}
