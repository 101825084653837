import { CoreCaller } from "@viewer/core/pouchdb/core/coreCaller";
import { Injector } from "@angular/core";
import { DbSchema } from "libs/transfert/model/pubSchema";

/**
 * Represent Xml database (DMC and XSL)
 *
 * @export
 * @class XmlDBs
 * @extends {OrionDBs}
 */
export class XmlCaller extends CoreCaller {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(processor: any, protected injector: Injector, dbSchema: DbSchema) {
    super(dbSchema, processor, injector);
  }
}
