// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synchro-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.synchro-modal .label {
  margin-top: 30px;
  text-align: center;
}
.synchro-modal .label .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.synchro-modal .label .icon mat-icon {
  margin: 0 5px;
}
.synchro-modal .label .icon img {
  height: 50px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN5bmNocm8tbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRSxtQkFBQTtFQUNBLGFBQUE7RUFDQSxzQkFBQTtBQURGO0FBR0U7RUFDRSxnQkFBQTtFQUNBLGtCQUFBO0FBREo7QUFHSTtFQUNFLG1CQUFBO0VBQ0EsYUFBQTtFQUNBLHVCQUFBO0VBQ0EsZ0JBQUE7QUFETjtBQUdNO0VBQ0UsYUFBQTtBQURSO0FBSU07RUFDRSxZQUFBO0FBRlIiLCJmaWxlIjoic3luY2hyby1tb2RhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCJsaWJzL3RoZW1lL29yaW9uLXRoZW1lXCI7XG5cbi5zeW5jaHJvLW1vZGFsIHtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcblxuICAubGFiZWwge1xuICAgIG1hcmdpbi10b3A6IDMwcHg7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuXG4gICAgLmljb24ge1xuICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICAgIG1hcmdpbi10b3A6IDEwcHg7XG5cbiAgICAgIG1hdC1pY29uIHtcbiAgICAgICAgbWFyZ2luOiAwIDVweDtcbiAgICAgIH1cblxuICAgICAgaW1nIHtcbiAgICAgICAgaGVpZ2h0OiA1MHB4O1xuICAgICAgfVxuICAgIH1cbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/viewer/src/app/synchro-modal/synchro-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAGE;EACE,gBAAA;EACA,kBAAA;AADJ;AAGI;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AADN;AAGM;EACE,aAAA;AADR;AAIM;EACE,YAAA;AAFR;AACA,ogCAAogC","sourcesContent":["@import \"libs/theme/orion-theme\";\n\n.synchro-modal {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n\n  .label {\n    margin-top: 30px;\n    text-align: center;\n\n    .icon {\n      align-items: center;\n      display: flex;\n      justify-content: center;\n      margin-top: 10px;\n\n      mat-icon {\n        margin: 0 5px;\n      }\n\n      img {\n        height: 50px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
